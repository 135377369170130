import React from 'react';

import { Button, Collapse, Space, Typography } from 'antd';
import { TProduct } from 'src/api/types/sku.types';
import { useAppContext } from 'src/hooks';
import {
  useArchiveProductMutation,
  useDeleteProductMutation,
} from 'src/hooks/queries/product.hooks';

export default function ProductDetailDangerZone({
  data,
  platformLabel,
}: {
  data: TProduct;
  platformLabel: string;
}) {
  const archiveProductMutation = useArchiveProductMutation();
  const deleteProductMutation = useDeleteProductMutation(data.id);
  const { userHasEntitlement } = useAppContext();

  if (data.auto_created) return null;

  const userCanDelete = userHasEntitlement('app.productsku.delete');
  const userCanUpdate = userHasEntitlement('app.productsku.update');
  const inUseByPaywalls = data.delete_warnings.is_in_use_by_paywalls;
  const hasPurchases = data.delete_warnings.has_purchases;
  const productCanBeDeleted = !inUseByPaywalls && !hasPurchases;
  const productCanBeArchived = !inUseByPaywalls; //TODO - can be archived if only in use by archived paywalls

  function getTextContent() {
    if (productCanBeDeleted) {
      return (
        <>
          <Typography.Text strong>
            Deleting this product will permanently remove this product and all
            its data from the Nami Control Center. It will be unavailable for
            sale on any of your paywalls.
          </Typography.Text>
          <Typography.Text>
            This does not delete the product from the {platformLabel} and you
            may re-add the product later.
          </Typography.Text>
          <Typography.Text>
            Archiving a product will keep the product's data in the Control
            Center.
          </Typography.Text>
        </>
      );
    } else {
      if (inUseByPaywalls) {
        return (
          <>
            <Typography.Text>
              This product is in use. To delete or archive this product, first
              remove from live paywalls.
            </Typography.Text>
          </>
        );
      }
      if (productCanBeArchived) {
        return (
          <Typography.Text>
            This product has purchases associated and cannot be deleted.
          </Typography.Text>
        );
      }
    }
    return <></>;
  }

  return (
    <Collapse ghost style={{ marginBottom: 15, marginTop: 20 }}>
      <Collapse.Panel
        header={<h4>Danger Zone</h4>}
        key="1"
        className="intercom-productDelete"
      >
        <Space direction="vertical">
          {getTextContent()}
          <Space direction="horizontal" size="small">
            <Button
              type="primary"
              danger
              disabled={!userCanDelete || !productCanBeDeleted}
              loading={deleteProductMutation.isLoading}
              onClick={() => deleteProductMutation.mutate()}
            >
              Delete Product
            </Button>
            <Button
              loading={archiveProductMutation.isLoading}
              onClick={() =>
                archiveProductMutation.mutate({
                  product_id: data.id,
                  archived: !!data.archived,
                })
              }
              disabled={!userCanUpdate || !productCanBeArchived}
            >
              {data.archived ? 'Restore Product' : 'Archive Product'}
            </Button>
          </Space>
        </Space>
      </Collapse.Panel>
    </Collapse>
  );
}
