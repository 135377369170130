import adobe_s3 from '../../images/adobe_ep.svg';
import amazon_iap from '../../images/amazon.svg';
import apple from '../../images/apple.svg';
import {
  default as custom,
  default as generic,
} from '../../images/custom-audience.svg';
import google from '../../images/google.svg';
import roku_pay from '../../images/roku.svg';
import web from '../../images/web.svg';

export const pathBeginMatches = (path1, path2) => {
  const piece1 = path1.split('/', 2).filter(Boolean)[0];
  const piece2 = path2.split('/', 2).filter(Boolean)[0];
  return piece1 === piece2;
};

export const isSuccessStatus = (status) => {
  return Math.floor(status / 100) === 2;
};

const typeLogoMap = {
  apple,
  google,
  amazon_iap,
  roku_pay,
  custom,
  generic,
  adobe_s3,
  web,
};

export const platformLogo = (type) => typeLogoMap[type];

export const getPlatformMarketplaceIdLabel = (type) => {
  if (type === 'google') return 'Package Name';
  if (type === 'amazon_iap') return 'App SKU';
  if (type === 'roku_pay') return 'Channel ID';
  return 'Bundle Identifier';
};

const platformTypeMap = {
  apple: 'Apple App Store',
  google: 'Google Play Store',
  amazon_iap: 'Amazon Appstore',
  roku_pay: 'Roku Pay',
  web: 'Web',
};

const osTypeMap = {
  apple: 'Apple',
  google: 'Android',
  amazon_iap: 'Amazon Fire',
  roku_pay: 'Roku',
  web: 'Web',
};

export const platformName = (type) => platformTypeMap[type];

export const osName = (type) => osTypeMap[type];

export const copyTextToClipBoard = (string) => {
  return new Promise((resolve, reject) => {
    const range = document.createRange();
    range.selectNodeContents(document.body);
    document.getSelection().addRange(range);
    let success = false;
    const listener = (e) => {
      e.clipboardData.setData('text/plain', string);
      e.preventDefault();
      success = true;
    };
    document.addEventListener('copy', listener);
    document.execCommand('copy');
    document.removeEventListener('copy', listener);
    document.getSelection().removeAllRanges();
    success ? resolve() : reject();
  });
};

export const getHash = (length) =>
  Math.random().toString(36).substring(2, length) +
  Math.random().toString(36).substring(2, length);

export const filterArrayObjects = (array, value) => {
  return array.filter((item) => {
    return Object.values(item)
      .map((key) => {
        return String(key);
      })
      .find((newValue) => {
        return newValue.toLowerCase().includes(value.toLowerCase());
      });
  });
};

export const filterArrayByType = (array, value, type) => {
  if (type === 'eventType' && value) {
    return array.filter((item) => {
      return item.type.toLowerCase() === value.toLowerCase();
    });
  } else {
    return array.filter((item) => {
      return Object.values(item)
        .map((key) => {
          return String(key);
        })
        .find((newValue) => {
          return newValue.toLowerCase().includes(value.toLowerCase());
        });
    });
  }
};

export const getDefaultApp = (apps, appId) => {
  if (apps && apps.length) {
    if (appId) {
      return apps.find((app) => app.id === appId);
    } else {
      return apps[0];
    }
  } else {
    return null;
  }
};

export const defaultImage = (name) => {
  let newName = name.replace(/%20/g, ' ');
  let colours = [
    'f5bb03',
    '3dd94a',
    '9574ea',
    'f262d3',
    '414556',
    'ff424f',
    '002742',
    '41c4ff',
  ];
  let sum = 0;
  for (let i = 0; i < newName.length; i++) {
    sum += newName.charCodeAt(i);
  }
  return `https://ui-avatars.com/api/?name=${name}&size=128&length=2&background=${
    colours[sum % colours.length]
  }&color=FFFFFF`;
};
