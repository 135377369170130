import React, { useMemo } from 'react';

import { LaunchOutlined } from '@mui/icons-material';
import { Col, Image, Row, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import {
  TCustomerAttribute,
  TCustomerPurchase,
  TCustomerPurchaseTransforms,
} from 'src/api/types/customer.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import CustomerAttribute from 'src/components/CustomerAttribute/CustomerAttribute';
import Page from 'src/components/Page/Page';
import {
  useExternalUserQuery,
  usePurchaseQuery,
} from 'src/hooks/queries/customer.hooks';
import { platformLogo } from 'src/services/helpers';
import { namiPureWhite } from 'src/variables';

import CustomerPurchaseEvents from './purchase-events/CustomerPurchaseEvents';

export default function CustomerPurchasePage() {
  const { userID, purchaseID } =
    useParams<{ userID: string; purchaseID: string }>();
  const customerData = useExternalUserQuery(userID);
  const purchaseData = usePurchaseQuery(purchaseID);

  const purchaseAttrs: TCustomerAttribute[] = useMemo(() => {
    if (!purchaseData.data) return [];
    return convertPurchaseToAttributes(purchaseData.data);
  }, [purchaseData]);

  const pageTitle = useMemo(() => {
    if (!purchaseData.data || !purchaseData.data.product) return 'Purchase';
    return `${
      purchaseData.data.product.name || purchaseData.data.product.sku_ref_id
    } Purchase`;
  }, [purchaseData]);

  const customerName = useMemo(() => {
    if (
      !customerData.data ||
      !customerData.data.psuedonym ||
      !customerData.data.external_id
    )
      return userID;
    return customerData.data.psuedonym || customerData.data.external_id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  return (
    <Page title={pageTitle}>
      <Row justify="start" gutter={[0, 36]} style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <Breadcrumb
            items={[
              { url: '/customers-beta/', name: 'Customers' },
              {
                url: `/customers-beta/${userID}`,
                name: customerName,
              },
              { name: pageTitle },
            ]}
          />
        </Col>
      </Row>
      <Row
        gutter={[16, 24]}
        align="top"
        justify="start"
        wrap={false}
        style={{ marginBottom: 16 }}
      >
        <Space direction="horizontal">
          {purchaseData.data?.product_platform_type && (
            <Image
              height={15}
              width={15}
              preview={false}
              src={platformLogo(purchaseData.data.product_platform_type)}
            />
          )}
          <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
            {pageTitle}
          </Typography.Text>
        </Space>
      </Row>
      <Row gutter={[16, 24]} align="top" justify="start" wrap={false}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          style={{
            backgroundColor: namiPureWhite,
            marginRight: 10,
          }}
        >
          <Spin spinning={purchaseData.isLoading}>
            <Space
              direction="vertical"
              size={12}
              style={{ padding: 18, minHeight: 50 }}
            >
              <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
                Attributes
              </Typography.Text>
              {purchaseAttrs.map((attr, index) => (
                <CustomerAttribute attr={attr} key={index} />
              ))}
            </Space>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={12} lg={18}>
          <CustomerPurchaseEvents userID={userID} purchaseId={purchaseID} />
        </Col>
      </Row>
    </Page>
  );

  function convertPurchaseToAttributes(
    purchase: TCustomerPurchase
  ): TCustomerAttribute[] {
    //TODO - conversion impression
    return Object.entries(purchase).reduce((output, [key, value]) => {
      if (TCustomerPurchaseTransforms[key]) {
        return [...output, TCustomerPurchaseTransforms[key](value)];
      }
      if (key === 'product') {
        return [
          ...output,
          {
            title: 'Product',
            value: (
              <a href={`/products/${purchase.product.id}`}>
                <Space direction="horizontal">
                  {purchase.product.name}
                  <LaunchOutlined
                    style={{ fontSize: 13, transform: 'translateY(2px)' }}
                  />
                </Space>
              </a>
            ),
            copyable: false,
            monospace: false,
            empty: !value,
          },
        ];
      }
      return output;
    }, [] as TCustomerAttribute[]);
  }
}
