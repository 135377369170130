import React from 'react';

import Icon from '@ant-design/icons';
import { Col, Layout, Row, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import AvatarMenu from 'src/components/AvatarMenu/AvatarMenu';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import { useAppContext } from 'src/hooks';
import AppSwitchPicker from 'src/pages/admin/AppSwitchPicker';
import { namiPureWhite } from 'src/variables';

import { ReactComponent as HelpIcon } from '../../../images/icons/help-right.svg';

type DesktopHeaderProps = {
  isSidebarOpen: boolean;
  setDefaultApp: (appId: string) => void;
};
export default function DesktopHeader({
  isSidebarOpen,
  setDefaultApp,
}: DesktopHeaderProps) {
  const history = useHistory();
  const context = useAppContext();
  const docsLink = context.getDocsLink();

  return (
    <Layout.Header
      style={{
        backgroundColor: namiPureWhite,
        padding: '0px 24px',
        borderBottom: '0.2px solid rgba(76, 84, 90, 0.10)',
      }}
    >
      <Row>
        <Col flex={isSidebarOpen ? '64px' : '200px'}>
          <div
            style={{ transform: 'translateY(7px)', cursor: 'pointer' }}
            onClick={() => history.push('/overview')}
          >
            {isSidebarOpen ? (
              <NamiIcon
                icon="nami symbol"
                color="primary"
                size="medium-small"
              />
            ) : (
              <NamiIcon icon="ml logo" color="primary" size="large" />
            )}
          </div>
        </Col>
        <Col>
          <AppSwitchPicker onAppClick={setDefaultApp} />
        </Col>
        <Col flex="auto"></Col>
        <Col flex="84px">
          <Tooltip
            title="Help Center"
            placement="right"
            align={{ offset: [-6] }}
            key="help"
          >
            <a
              href={docsLink}
              target="_blank"
              rel="noreferrer"
              className="intercom-desktopHelpCenter"
            >
              <Icon
                component={HelpIcon}
                style={{ fontSize: 20, transform: 'translateY(2px)' }}
              />
            </a>
          </Tooltip>
          <AvatarMenu />
        </Col>
      </Row>
    </Layout.Header>
  );
}
