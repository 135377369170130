import React, { useEffect, useState } from 'react';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Col,
  Modal,
  Row,
  Space,
  Table,
  Typography,
} from 'antd';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import TeamRolesWebPaywall from 'src/components/WebPaywalls/TeamRolesWebPaywall';

import api from '../../../../api';
import Loading from '../../../../components/Loading/Loading';
import PlanUpgradeButton from '../../../../components/PlanUpgradeButton/PlanUpgradeButton';
import { useAppContext, useBooleanState } from '../../../../hooks';
import { defaultImage } from '../../../../services/helpers';
import history from '../../../../services/history';
import InviteMemberModal from './InviteMemberModal';

const TeamTable = () => {
  const { confirm } = Modal;
  const appContext = useAppContext();
  const [orgMembers, setOrgMembers] = useState([]);
  const [isInviteOpen, openInvite, closeInvite] = useBooleanState(false);
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);

  useEffect(() => {
    if (appContext.selectedOrg) {
      api.getOrgMembers(appContext.selectedOrg.id).then(setOrgMembers);
    }
  }, [appContext.selectedOrg]);

  if (!orgMembers?.length) {
    return <Loading />;
  }

  const getFullNameForImage = (user) => {
    if (user.first_name && user.last_name) {
      return `${user.first_name}+${user.last_name}`;
    } else {
      return user.first_name;
    }
  };

  const currentProfile = (user) => {
    if (appContext.user?.username === user.username) {
      return `${user.username} (you)`;
    } else {
      return `${user.username}`;
    }
  };

  const getRole = (user) => {
    if (user?.permissions?.length) {
      return user.permissions
        .find((permission) => permission.id === appContext.selectedOrg.id)
        ?.roles.map((role) => role.display_name);
    } else {
      return null;
    }
  };

  const shouldDisableEditUser = (user) => {
    return appContext.user?.id === user.id;
  };

  const shouldDisplayRemoveUserFromTeamAction = (user) => {
    if (appContext.userHasEntitlement('org.members.update')) {
      return appContext.user?.id !== user.id;
    } else {
      return false;
    }
  };

  const deleteModal = (userIdToRemove) => {
    confirm({
      title: 'Remove User Confirmation',
      content:
        'Completing this action will remove this user from your organization and all assocatied apps. Are you sure you want to continue?',
      okText: 'Yes. Remove User',
      okType: 'danger',
      centered: true,
      icon: null,
      cancelText: 'Cancel',
      onOk() {
        api
          .removeOrgMember(userIdToRemove)
          .then(() => api.getOrgMembers(appContext.selectedOrg.id))
          .then(setOrgMembers);
      },
    });
  };

  const columns = [
    {
      title: <Typography.Text strong>User</Typography.Text>,
      key: 'id',
      render: (user) => (
        <Space>
          <Avatar
            size="large"
            src={user.avatar || defaultImage(getFullNameForImage(user))}
          />
          <Space direction="vertical">
            <Typography.Text
              strong
              ellipsis={true}
              style={{ maxWidth: '375px' }}
            >
              {user.first_name} {user.last_name}
            </Typography.Text>
            <Typography.Text ellipsis={true} style={{ maxWidth: '375px' }}>
              {currentProfile(user)}
            </Typography.Text>
          </Space>
        </Space>
      ),
    },
    {
      title: <Typography.Text strong>Role</Typography.Text>,
      key: 'role',
      responsive: ['lg'],
      render: (user) => getRole(user),
    },
    {
      title: <Typography.Text strong>Actions</Typography.Text>,
      key: 'actions',
      render: (user) => (
        <Space>
          <IconActionButton
            type="text"
            size="small"
            icon={<EditOutlined style={{ fontSize: '13px' }} />}
            disabled={
              !appContext.userHasEntitlement('org.members.update') ||
              shouldDisableEditUser(user)
            }
            onClick={() =>
              history.push(`/settings?tab=team&user_id=${user.id}`)
            }
          >
            Edit
          </IconActionButton>
          {shouldDisplayRemoveUserFromTeamAction(user) && (
            <IconActionButton
              type="text"
              size="small"
              icon={<DeleteOutline style={{ fontSize: 15 }} />}
              disabled={!appContext.userHasEntitlement('org.members.remove')}
              onClick={() => deleteModal(user.id)}
            >
              Delete
            </IconActionButton>
          )}
        </Space>
      ),
    },
  ];

  const dataSource = orgMembers.map((teamMember) => {
    return {
      key: teamMember.id,
      ...teamMember,
    };
  });

  return (
    <>
      <Row style={{ marginBottom: 24, marginTop: 12, float: 'right' }}>
        <Col xs={24}>
          <Space direction="horizontal">
            {!appContext.planHasEntitlement(
              'org.role.granular_permissions'
            ) && (
              <PlanUpgradeButton onClick={openUpgrade}>
                Advanced Roles
              </PlanUpgradeButton>
            )}
            <Button
              icon={<PlusOutlined />}
              onClick={openInvite}
              disabled={!appContext.userHasEntitlement('org.members.update')}
            >
              Add Team Member
            </Button>
          </Space>
        </Col>
      </Row>
      {appContext.userHasEntitlement('org.members.list') && (
        <Table dataSource={dataSource} columns={columns} />
      )}
      <TeamRolesWebPaywall visible={isUpgradeOpen} onCancel={closeUpgrade} />
      <InviteMemberModal
        isOpen={isInviteOpen}
        onClose={closeInvite}
        onUpgradeClick={openUpgrade}
      />
    </>
  );
};

export default TeamTable;
