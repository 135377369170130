import React, { useEffect, useMemo, useState } from 'react';

import { Col, List, Row, Space, Tooltip } from 'antd';
import { PaginationConfig } from 'antd/lib/pagination';
import moment from 'moment';
import {
  eventTypeIcons,
  prettyEventTypeNames,
  TEventLogItem,
} from 'src/api/types/developer.types';
import { TApiOptions } from 'src/api/types/main.types';
import EmptyState from 'src/components/EmptyState/EmptyState';
import { useEventLogQuery } from 'src/hooks/queries/developer.hooks';
import { getListDescriptionForEvent } from 'src/utils/dev';
import { namiDarkGray, namiPureWhite } from 'src/variables';
import styled, { css } from 'styled-components';

import DeveloperFeedEventDetail from './DeveloperFeedEventDetail';

type DeveloperPageListProps = {
  apiOptions: TApiOptions;
  onApiOptionsChange: React.Dispatch<React.SetStateAction<TApiOptions>>;
  filteringActive: boolean;
  live: boolean;
};

const EventTimeItem = styled.span`
  font-size: 13px;
  font-color: ${namiDarkGray};
  float: right;
`;

export const ListItemStyled = styled(List.Item)<{ selected: boolean }>`
  cursor: pointer;
  :hover {
    background-color: #f8f8f8;
  }

  ${({ selected }) => {
    if (selected) {
      return css`
        background-color: #f0f0f0;
      `;
    }
    return css``;
  }}
`;
const IconWrapper = styled.span`
  svg {
    font-size: 18px;
    color: ${namiDarkGray};
  }
`;
export default function DeveloperPageList({
  apiOptions,
  onApiOptionsChange,
  filteringActive,
  live,
}: DeveloperPageListProps) {
  const eventLogQuery = useEventLogQuery(apiOptions, live);
  const loading = eventLogQuery.isFetching || eventLogQuery.isLoading;
  const eventLogItems = eventLogQuery.data?.results || [];

  const [selectedEvent, setSelectedEvent] = useState<TEventLogItem | null>(
    null
  );

  useEffect(() => {
    setSelectedEvent(null);
  }, [apiOptions]);

  const pagination = useMemo(
    (): PaginationConfig => ({
      total: eventLogQuery.data?.count,
      pageSize: apiOptions.pageSize,
      onChange: (page, pageSize) =>
        onApiOptionsChange((state) => ({ ...state, page, pageSize })),
      current: eventLogQuery.data?.page_number,
      showSizeChanger: false,
      style: { paddingBottom: 32, paddingRight: 16 },
      showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
    }),
    [eventLogQuery.data, apiOptions.pageSize, onApiOptionsChange]
  );

  const children =
    eventLogQuery.isFetched && eventLogQuery.data?.count === 0 ? (
      <EmptyState
        title="No events found"
        linkText="Try adjusting your filter criteria"
        link="https://docs.namiml.com/integrations/events-feed"
        icon="sdk"
      />
    ) : (
      <Row gutter={[8, 8]}>
        <Col
          md={12}
          sm={24}
          style={{ backgroundColor: namiPureWhite, width: '100%' }}
        >
          <List
            header={null}
            footer={null}
            loading={loading}
            dataSource={eventLogItems}
            pagination={
              filteringActive &&
              eventLogQuery.isFetched &&
              eventLogItems.length > 0
                ? pagination
                : undefined
            }
            renderItem={(item) => (
              <ListItemStyled
                onClick={() => setSelectedEvent(item)}
                selected={selectedEvent === item}
              >
                <Row
                  gutter={[8, 8]}
                  style={{ width: '100%', padding: '12px 24px' }}
                >
                  <Col md={8}>
                    <Space size="large">
                      <IconWrapper>
                        {eventTypeIcons[item.event_type]}
                      </IconWrapper>
                      <b>{prettyEventTypeNames[item.event_type]}</b>
                    </Space>
                  </Col>
                  <Col md={8}>{getListDescriptionForEvent(item)}</Col>
                  <Col md={8}>
                    <Tooltip
                      title={moment(item.created_date).format(
                        'YYYY-MM-DD h:mm:ss A'
                      )}
                    >
                      <EventTimeItem>
                        {item.created_date_humanized}
                      </EventTimeItem>
                    </Tooltip>
                  </Col>
                </Row>
              </ListItemStyled>
            )}
          />
        </Col>
        <Col md={12} sm={24} style={{ width: '100%' }}>
          {selectedEvent ? (
            <DeveloperFeedEventDetail event={selectedEvent} />
          ) : (
            <EmptyState
              title="Select an Event"
              linkText="Select an event to see more information."
              link="https://docs.namiml.com/integrations/events-feed"
              icon="sdk"
            />
          )}
        </Col>
      </Row>
    );

  return <> {children} </>;
}
