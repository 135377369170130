import React from 'react';

import { Form, Select } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type OnTapSelectorProps = Omit<FieldObject, 'variable'> & {
  removable?: boolean;
  defaultValue?: string | number;
  value?: string | null;
  onChange: (value: any) => void;
  label: string;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

export default function OnTapSelector({
  onChange,
  value,
  component,
  componentLocation,
  ...field
}: OnTapSelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const capabilities = useAppSelector(
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities']
  );
  const currentValue = value.hasOwnProperty('function')
    ? value.function
    : undefined;
  let onTapActions: Array<{ value: string; label: string }> = [
    {
      value: 'namiClosePaywall',
      label: 'Close Paywall',
    },
    {
      value: 'namiSignIn',
      label: 'Login',
    },
    {
      value: 'namiRestorePurchases',
      label: 'Restore Purchase',
    },
  ];

  if ((capabilities || []).includes('product_error_states')) {
    onTapActions = [
      ...onTapActions,
      {
        value: 'namiReloadProducts',
        label: 'Reload Products',
      },
    ];
  }

  return (
    <Form.Item label={<InputLabel {...field} />}>
      <Select
        options={onTapActions}
        value={currentValue}
        onChange={handleChange}
        placeholder={'Choose button action'}
      />
    </Form.Item>
  );

  function handleChange(value: string) {
    actions.updateButtonOnTap({
      id: component?.id,
      value: value,
      variable: field.variable,
    });
  }
}
