import React, { useContext, useMemo } from 'react';

import { FilterOutlined, LockFilled, PlusOutlined } from '@ant-design/icons';
import { BinaryOperator } from '@cubejs-client/core';
import { DeleteOutline } from '@mui/icons-material';
import { Image, Space } from 'antd';
import { FilterOptions, TMetricFilter } from 'src/api/types/analytics.types';
import DisabledBadgeDot from 'src/components/StatusDots/DisabledBadgeDot';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import store from 'src/redux';
import { platformLogo } from 'src/services/helpers';
import { PLATFORMS } from 'src/utils/formatting';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import { AppContext } from '../../../../AppContextProvider';
import countries from '../../../../countries';
import languages from '../../../../languages';
import {
  skuTypesOptions,
  subscriptionDurationOptions,
  trialDurationOptions,
} from '../../products/detail/ProductDetailPage';
import FilterSelect from './FilterSelect';
import MultiSelect from './MultiSelect';
import OperatorSelect from './OperatorSelect';

const PlacementStyledSpan = styled.span`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
`;

export type FilterSelectProps = {
  operator: BinaryOperator;
  values: string[];
  className?: string;
  identifier: TMetricFilter;
  disabled?: boolean;
  onValueChange(values: string[]): void;
  onOperatorChange(operator: BinaryOperator): void;
  onRemove(): void;
};

type IdentifierSelectProps = {
  className?: string;
  disabled?: boolean;
  onChange(identifier: TMetricFilter): void;
  identifierOptions: TMetricFilter[];
};

export type TOption = {
  label: React.ReactNode;
  text: string;
  key: any;
  value: any;
};

export const operatorLabels: {
  [key in BinaryOperator]: string;
} = {
  // in: 'is',
  equals: 'equals',
  notEquals: 'does not equal',
  contains: 'contains',
  notContains: 'does not contain',
  gt: 'greater than',
  gte: 'greater than or equal to',
  lt: 'less than',
  lte: 'less than or equal to',
  startsWith: 'starts with',
  endsWith: 'ends with',
  beforeDate: 'before',
  afterDate: 'after',
  inDateRange: 'in date range',
  notInDateRange: 'not in date range',
} as const;

export function IdentifierSelect({
  onChange,
  identifierOptions,
  ...props
}: IdentifierSelectProps) {
  const appContext = useContext(AppContext);
  const IDENTIFIER_OPTIONS: TOption[] = identifierOptions.map((option) => ({
    key: option,
    label: (
      <>
        <span>
          {!appContext.userHasEntitlement(
            FilterOptions[option].entitlement
          ) && <LockFilled style={{ paddingRight: '5px' }} />}
          {FilterOptions[option].display_label}
        </span>
      </>
    ),
    text: FilterOptions[option].display_label,
    value: option,
    disabled: !appContext.userHasEntitlement(FilterOptions[option].entitlement),
  }));

  return (
    <FilterSelect
      showArrow={true}
      placeholder={
        <Space size="small">
          <FilterOutlined />
          <span>Add Filter</span>
        </Space>
      }
      optionFilterProp="text"
      value={[]}
      options={IDENTIFIER_OPTIONS}
      onChange={(value) => onChange(value as TMetricFilter)}
      suffixIcon={<PlusOutlined />}
      style={{ minWidth: '15rem' }}
      {...props}
    />
  );
}

export function AppPlatformSelect(props: FilterSelectProps) {
  const options = Object.entries(PLATFORMS).map(([slug, label]) => ({
    value: slug,
    key: slug,
    label: (
      <Space>
        <Image
          height={15}
          width={15}
          preview={false}
          src={platformLogo(slug)}
        />
        {label}
      </Space>
    ),
    text: label,
  }));

  return <MultiSelect label="Platform" options={options} {...props} />;
}

export function CountrySelect(props: FilterSelectProps) {
  const options = useMemo(() => {
    return Object.entries(countries).map(([code, name]) => ({
      label: name,
      text: name,
      value: code,
      key: code,
    }));
  }, []);

  return <MultiSelect label="Country" options={options} {...props} />;
}

export function ProductSKUSelect(props: FilterSelectProps) {
  const products = store.getState().analytic.products;

  const options = Object.entries(products).map(([_name, product]) => ({
    label: (
      <Space>
        <Image
          height={15}
          width={15}
          preview={false}
          src={platformLogo(product.platform_type)}
        />
        {product.name}
        {product.archived && (
          <DeleteOutline
            style={{
              fontSize: 15,
              color: namiMediumGray,
            }}
          />
        )}
      </Space>
    ),
    text: product.name,
    value: product.id,
    key: product.id,
  }));

  return <MultiSelect label="Product SKU" options={options} {...props} />;
}

export function PaywallSelect(props: FilterSelectProps) {
  const paywalls = store.getState().analytic.paywalls;

  const options = Object.entries(paywalls).map(([_name, paywall]) => ({
    label: (
      <Space>
        {paywall.rules.some((rule) => rule.enabled && rule.label_enabled) && (
          <LiveBadgeDot />
        )}
        {paywall.name}
        {paywall.archived && (
          <DeleteOutline
            style={{
              fontSize: 15,
              color: namiMediumGray,
            }}
          />
        )}
      </Space>
    ),
    text: paywall.name,
    value: paywall.id,
    key: paywall.id,
  }));

  return <MultiSelect label="Paywall" options={options} {...props} />;
}

export function PlacementSelect(props: FilterSelectProps) {
  const placements = store.getState().analytic.placements;

  const options = Object.entries(placements).map(([_name, placement]) => {
    return {
      text: placement.value,
      value: placement.id,
      key: placement.id,
      label: (
        <Space size={4}>
          {placement.enabled && !placement.archived && <LiveBadgeDot />}
          {!placement.enabled && !placement.archived && <DisabledBadgeDot />}
          {placement.archived && (
            <DeleteOutline
              style={{
                fontSize: 14,
                color: namiMediumGray,
              }}
            />
          )}
          <PlacementStyledSpan>{placement.value}</PlacementStyledSpan>
        </Space>
      ),
    };
  });

  return <MultiSelect label="Placement" options={options} {...props} />;
}

export function CampaignSelect(props: FilterSelectProps) {
  const campaigns = store.getState().analytic.campaigns;

  const options = Object.entries(campaigns).map(([_name, campaign]) => {
    return {
      label: (
        <Space>
          {campaign.enabled && !campaign.archived && <LiveBadgeDot />}
          {!campaign.enabled && !campaign.archived && <DisabledBadgeDot />}
          {campaign.archived && (
            <DeleteOutline
              style={{
                fontSize: 14,
                color: namiMediumGray,
              }}
            />
          )}
          {campaign.name}
        </Space>
      ),
      text: campaign.name,
      value: campaign.id,
      key: campaign.id,
    };
  });

  return <MultiSelect label="Campaign" options={options} {...props} />;
}

export function SKUTypeSelect(props: FilterSelectProps) {
  return <MultiSelect label="SKU Type" options={skuTypesOptions} {...props} />;
}

export function SKUDurationSelect(props: FilterSelectProps) {
  return (
    <MultiSelect
      label="Product Duration"
      options={subscriptionDurationOptions}
      {...props}
    />
  );
}

export function TrialDurationSelect(props: FilterSelectProps) {
  return (
    <MultiSelect
      label="Trial Duration"
      options={trialDurationOptions}
      {...props}
    />
  );
}

export function LanguageSelect(props: FilterSelectProps) {
  const options = useMemo(
    () =>
      Object.entries(languages).map(([code, name]) => {
        return { label: name, text: name, value: code, key: code };
      }),
    []
  );

  return <MultiSelect label="Language" options={options} {...props} />;
}

export function SDKVersionSelect(props: FilterSelectProps) {
  return (
    <OperatorSelect
      {...props}
      label="SDK Version"
      operators={['equals', 'notEquals', 'contains', 'notContains']}
    />
  );
}
