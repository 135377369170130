import React, { useMemo } from 'react';

import {
  CheckBoxOutlined,
  IndeterminateCheckBoxOutlined,
} from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

type TermsStates = 'initial' | 'failed_validation' | 'accepted';

export default function PaywallTermsCheckboxSetter() {
  const { termsAccepted, hasTermsAcceptedValidation, termsValidationStatus } =
    useAppSelector(({ paywallBuilder }) => ({
      termsAccepted: paywallBuilder.termsAcceptedGroups.length > 0,
      hasTermsAcceptedValidation:
        paywallBuilder.hasTermsAcceptedValidationStatus,
      termsValidationStatus: paywallBuilder.termsAcceptedValidationStatus,
    }));

  const actions = useActions(PaywallBuilderSlice.actions);

  const currentState: TermsStates = useMemo(() => {
    if (termsAccepted) return 'accepted';
    if (termsValidationStatus === false) return 'failed_validation';
    return 'initial';
  }, [termsAccepted, termsValidationStatus]);

  const tooltipText: string = useMemo(() => {
    if (currentState === 'initial') {
      if (hasTermsAcceptedValidation)
        return 'Preview the paywall when user attempts to purchase without confirming legal terms';
      return 'Preview the paywall when Terms are confirmed';
    } else if (currentState === 'failed_validation') {
      return 'Preview the paywall when Terms are confirmed';
    }
    return 'Preview the paywall when Terms are not confirmed';
  }, [hasTermsAcceptedValidation, currentState]);

  return (
    <>
      <Tooltip title={tooltipText} mouseEnterDelay={0.8}>
        <Button
          type={currentState === 'initial' ? 'ghost' : 'primary'}
          icon={
            currentState === 'failed_validation' ? (
              <IndeterminateCheckBoxOutlined />
            ) : (
              <CheckBoxOutlined />
            )
          }
          size="small"
          onClick={toggleTermsAccepted}
        />
      </Tooltip>
    </>
  );

  function toggleTermsAccepted() {
    if (currentState === 'initial') {
      if (hasTermsAcceptedValidation) {
        actions.toggleTermsAccepted({
          termsGroupsOn: false,
          validation: false,
        });
      } else {
        actions.toggleTermsAccepted({
          termsGroupsOn: true,
          validation: true,
        });
      }
    } else if (currentState === 'failed_validation') {
      actions.toggleTermsAccepted({
        termsGroupsOn: true,
        validation: true,
      });
    } else {
      actions.toggleTermsAccepted({
        termsGroupsOn: false,
        validation: null,
      });
    }
  }
}
