import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  CodeOutlined,
  DownOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  CollectionsOutlined,
  ComputerOutlined,
  ConnectedTvOutlined,
  CorporateFareOutlined,
  InterestsOutlined,
  SmartphoneOutlined,
  TabletMacOutlined,
} from '@mui/icons-material';
import {
  Button,
  Card,
  Col,
  Dropdown,
  Image,
  Input,
  Layout,
  Menu,
  MenuProps,
  Modal,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { TDevice } from 'src/api/types/paywall.types';
import { TFormFactor } from 'src/pages/admin/campaigns/detail/selectors/mapsAndOptions';
import { namiDarkGray, namiPureWhite, namiSmoke } from 'src/variables';
import styled from 'styled-components';

import { AppContext } from '../../AppContextProvider';
import {
  TPaywallTemplate,
  TRawPaywallTemplate,
} from '../../api/types/paywallTemplate.types';
import {
  useAddPaywallMutation,
  useOrgTemplatesQuery,
  useTemplatesQuery,
} from '../../hooks/queries/paywall.hooks';
import CanvasMarketingImage from '../../images/marketing/canvasMarketing.jpg';
import Paywall0 from '../../images/templates/Paywall0.svg';
import Loading from '../Loading/Loading';
import FeatureRequestModal from '../NewFeatureRequestModal/FeatureRequestModal';
import Responsive from '../Responsive/Responsive';
import UnlockTVTemplateWebPaywall from '../WebPaywalls/UnlockTVTemplateWebPaywall';
import TemplateThumbnail from './TemplateThumbnail';

interface AddPaywallModalProps {
  open: boolean;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  @media (max-width: 1063px) {
    width: 855px !important;
  }

  @media (max-width: 862px) {
    width: 655px !important;
  }

  @media (max-width: 644px) {
    width: 465px !important;
  }
`;

const NoTemplatesMessage = styled.p`
  font-size: 1.1em;
  font-style: italic;
  color: ${namiDarkGray};
`;

const StyledSider = styled(Layout.Sider)`
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: ${namiPureWhite} !important;
  }

  li {
    padding-left: 16px !important;
  }

  .ant-menu-item {
    height: 32px !important;
    :active {
      background-color: ${namiPureWhite} !important;
    }
  }
`;

const StyledCard = styled(Card)`
  background: ${namiSmoke};
  border-radius: 8px;
  border: 1px solid #f1f1f1;
`;

const CreatorTemplatesByDeviceOrientation: Record<TFormFactor, string> = {
  phone: '6ac88c81-e928-4669-8189-a709c527b09a',
  tablet: 'c1cf3851-fa96-40d5-af15-3c7d1918448a',
  television: '0ceff4a4-555e-40c6-b9ad-df86e657bda3',
  desktop: 'd40955a0-7f41-4ede-9db1-92bcdba0dcf0',
};

export default function AddPaywallModal({
  open,
  onClose,
}: AddPaywallModalProps) {
  const { userHasEntitlement, selectedOrg } = useContext(AppContext);
  const [device, setDevice] = useState<TDevice>('phone');
  const [menuItem, setMenuItem] = useState<string>('all');
  const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string | null>(null);
  const [visible, setVisible] = useState(false);
  const addPaywallMutation = useAddPaywallMutation(onClose);
  const [isTVUpgradeOpen, setTVUpgradeOpen] = useState(false);
  const [allBuilderTemplates, setAllBuilderTemplates] = useState<
    TRawPaywallTemplate[]
  >([]);
  const [phoneBuilderTemplate, setPhoneBuilderTemplate] = useState<
    TRawPaywallTemplate | undefined
  >(undefined);
  const [tabletTemplate, setTabletTemplate] = useState<
    TRawPaywallTemplate | undefined
  >(undefined);
  const [televisionTemplate, setTelevisionTemplate] = useState<
    TRawPaywallTemplate | undefined
  >();
  const [desktopTemplate, setDesktopTemplate] = useState<
    TRawPaywallTemplate | undefined
  >();

  const templateQuery = useTemplatesQuery();

  const toggleCollapsed = () => {
    setMenuCollapsed(!menuCollapsed);
  };

  const orgTemplateQuery = useOrgTemplatesQuery((templates) => {
    setMenuItem(templates.length > 0 ? 'org_only' : 'all');
  });

  const hasAccess = useCallback(
    (template: TRawPaywallTemplate) =>
      (template['ui.capabilities'] || []).every((capability) =>
        userHasEntitlement(`app.paywall.capabilities.${capability}`)
      ) &&
      (template['ui.formFactors'] || []).every((formfactor) =>
        userHasEntitlement(`app.paywall.formfactor.${formfactor.form_factor}`)
      ),
    [userHasEntitlement]
  );

  const thumbnailClick = useCallback(
    (
      template: TPaywallTemplate,
      ready: boolean,
      userHasTemplateAccess: boolean,
      device: TDevice
    ): void => {
      if (!userHasTemplateAccess && device === 'television')
        setTVUpgradeOpen(true);
      else if (ready) {
        addPaywallMutation.mutate({
          type: 'component',
          template,
        });
      }
    },
    [addPaywallMutation]
  );

  useEffect(() => {
    setAllBuilderTemplates(
      (templateQuery.data || []).filter((template) => {
        const { 'ui.v2Ready': builderReady } = template;
        return builderReady;
      })
    );
    setPhoneBuilderTemplate(
      (templateQuery.data || []).find((template) => {
        return template.id === CreatorTemplatesByDeviceOrientation['phone'];
      })
    );
    setTabletTemplate(
      (templateQuery.data || []).find((template) => {
        return template.id === CreatorTemplatesByDeviceOrientation['tablet'];
      })
    );
    setTelevisionTemplate(
      (templateQuery.data || []).find((template) => {
        return (
          template.id === CreatorTemplatesByDeviceOrientation['television']
        );
      })
    );
    setDesktopTemplate(
      (templateQuery.data || []).find((template) => {
        return template.id === CreatorTemplatesByDeviceOrientation['desktop'];
      })
    );
  }, [templateQuery.data]);

  let dropdownMenuItems: MenuProps['items'] = [
    {
      label: (
        <Space size={5}>
          <SmartphoneOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />{' '}
          Phone
        </Space>
      ),
      key: 'phone',
      disabled: !phoneBuilderTemplate,
    },
    {
      label: (
        <Space size={5}>
          <TabletMacOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />{' '}
          Tablet
        </Space>
      ),
      key: 'tablet',
      disabled: !tabletTemplate,
    },
  ];

  if (userHasEntitlement('app.paywall.formfactor.television')) {
    dropdownMenuItems.push({
      label: (
        <Space size={5}>
          <ConnectedTvOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />{' '}
          Television
        </Space>
      ),
      key: 'television',
      disabled: !televisionTemplate,
    });
  }
  if (userHasEntitlement('app.paywall.formfactor.desktop')) {
    dropdownMenuItems.push({
      label: (
        <Space size={5}>
          <ComputerOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />{' '}
          Desktop
        </Space>
      ),
      key: 'desktop',
      disabled: !desktopTemplate,
    });
  }
  const creatorTemplateClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'tablet' && tabletTemplate) {
      builderTemplateClick(tabletTemplate);
    } else if (e.key === 'television' && televisionTemplate) {
      builderTemplateClick(televisionTemplate);
    } else if (e.key === 'desktop' && desktopTemplate) {
      builderTemplateClick(desktopTemplate);
    } else {
      if (phoneBuilderTemplate) builderTemplateClick(phoneBuilderTemplate);
    }
  };

  const handleButtonClick = (_e: React.MouseEvent<HTMLButtonElement>) => {
    if (phoneBuilderTemplate) builderTemplateClick(phoneBuilderTemplate);
  };

  const templates = useMemo(() => {
    return (
      (menuItem === 'org_only' ? orgTemplateQuery.data : templateQuery.data) ||
      []
    )
      .map((template) => ({
        userHasAccess: hasAccess(template),
        ...template,
      }))
      .filter((template) => {
        const { id, 'ui.formFactors': factors, codename: name } = template;
        return (
          factors?.some(({ form_factor }) => form_factor === device) &&
          name.toLowerCase().includes(searchQuery?.toLowerCase() || '') &&
          !Object.values(CreatorTemplatesByDeviceOrientation).includes(id)
        );
      });
  }, [
    hasAccess,
    templateQuery.data,
    orgTemplateQuery.data,
    device,
    menuItem,
    searchQuery,
  ]);

  const hasLegacyAccess = userHasEntitlement('app.paywall.legacy.create');
  const hasLinkedAccess = userHasEntitlement('app.paywall.linked.create');

  const formFactorMenu = [
    {
      value: 'phone',
      label: (
        <Space size={4}>
          Phone{' '}
          <SmartphoneOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />
        </Space>
      ),
    },
    {
      value: 'tablet',
      label: (
        <Space size={4}>
          Tablet{' '}
          <TabletMacOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />
        </Space>
      ),
    },
    {
      value: 'television',
      label: (
        <Space size={4}>
          TV{' '}
          <ConnectedTvOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />
        </Space>
      ),
    },
    {
      value: 'desktop',
      label: (
        <Space size={4}>
          Desktop{' '}
          <ComputerOutlined
            style={{
              color: namiDarkGray,
              fontSize: 16,
            }}
          />
        </Space>
      ),
    },
  ];

  const filterRow = (
    <Row align="middle">
      <Responsive size="mdDown">
        <Col xs={6} md={12}>
          <Button onClick={toggleCollapsed}>
            {menuCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </Button>
        </Col>
      </Responsive>
      <Responsive size="mdUp">
        <Col xs={18} md={12}>
          <Input
            placeholder="Search for a design"
            suffix={<SearchOutlined />}
            onChange={(e) => setSearchQuery(e.currentTarget.value)}
            allowClear
          />
        </Col>
      </Responsive>
      <Col xs={18} md={12}>
        <Select
          defaultValue={device}
          onChange={(value) => setDevice(value)}
          options={formFactorMenu}
          style={{ minWidth: '110px', float: 'right' }}
        />
      </Col>
    </Row>
  );

  const modalBodyContent = useMemo(() => {
    const noTemplatesPlaceholder = (
      <Row align="middle" justify="center" style={{ height: '350px' }}>
        <NoTemplatesMessage>No templates found.</NoTemplatesMessage>
      </Row>
    );

    if (menuItem === 'org_only') {
      return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          {templates.length === 0 ? (
            noTemplatesPlaceholder
          ) : (
            <Image.PreviewGroup>
              <Space direction="horizontal" wrap={true} size="middle">
                {templates.map(({ ready, thumbnail, ...template }, index) => (
                  <TemplateThumbnail
                    key={template.id}
                    disabled={!ready}
                    onClick={() =>
                      ready &&
                      addPaywallMutation.mutate({
                        type: 'component',
                        template,
                      })
                    }
                    imageSrc={thumbnail}
                    index={index + 1}
                    device={device}
                    gated={!template.userHasAccess}
                    creatingPaywall={addPaywallMutation.isLoading}
                    template={template}
                  />
                ))}
              </Space>
            </Image.PreviewGroup>
          )}
        </Space>
      );
    } else if (menuItem === 'builder') {
      return (
        <Space direction="vertical" size="large" style={{ width: '100%' }}>
          <StyledCard
            cover={
              <Image
                preview={false}
                src={CanvasMarketingImage}
                width={'100%'}
                height={200}
                style={{ objectFit: 'cover' }}
                alt="build your own paywall template with Nami"
              />
            }
          >
            <Space direction="horizontal" wrap={true}>
              <Typography.Text style={{ fontSize: 'medium', fontWeight: 500 }}>
                Paywall Builder
              </Typography.Text>
            </Space>
            <Typography.Paragraph>
              Drag and drop to create your own paywall with Nami’s full library
              of components
            </Typography.Paragraph>
            <Space direction="horizontal" wrap={true}>
              <Dropdown.Button
                menu={{
                  items: dropdownMenuItems,
                  onClick: creatorTemplateClick,
                }}
                onClick={handleButtonClick}
                disabled={!allBuilderTemplates.length}
                loading={addPaywallMutation.isLoading}
                type="primary"
                icon={<DownOutlined />}
              >
                Get Started
              </Dropdown.Button>
              <Button
                type="link"
                href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator"
                target="_blank"
              >
                Learn More
              </Button>
            </Space>
          </StyledCard>
        </Space>
      );
    }
    return (
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        {templates.length === 0 ? (
          noTemplatesPlaceholder
        ) : (
          <>
            <Image.PreviewGroup>
              <Space direction="horizontal" wrap={true} size="middle">
                {hasLegacyAccess && device === 'phone' && (
                  <TemplateThumbnail
                    disabled={false}
                    onClick={() =>
                      addPaywallMutation.mutate({
                        type: 'paywall_creator',
                      })
                    }
                    imageSrc={Paywall0}
                    key={0}
                    index={0}
                    device={'phone'}
                    gated={false}
                    template={null}
                    creatingPaywall={addPaywallMutation.isLoading}
                  />
                )}
                {templates.map(
                  ({ ready, thumbnail, userHasAccess, ...template }, index) => (
                    <TemplateThumbnail
                      key={template.id}
                      disabled={!ready}
                      gated={!userHasAccess}
                      onClick={() =>
                        thumbnailClick(template, ready, userHasAccess, device)
                      }
                      imageSrc={thumbnail}
                      index={index + 1}
                      device={device}
                      template={template}
                      creatingPaywall={addPaywallMutation.isLoading}
                    />
                  )
                )}
              </Space>
            </Image.PreviewGroup>
            <Button
              onClick={() =>
                addPaywallMutation.mutate({ type: 'paywall_linked' })
              }
              hidden={!hasLinkedAccess}
              icon={<CodeOutlined />}
              disabled={addPaywallMutation.isLoading}
            >
              Code your own paywall design
            </Button>
          </>
        )}
      </Space>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    menuItem,
    templates,
    hasLegacyAccess,
    device,
    addPaywallMutation,
    hasLinkedAccess,
    allBuilderTemplates,
    thumbnailClick,
  ]);

  function builderTemplateClick(builderTemplate: TRawPaywallTemplate) {
    const { ready, thumbnail, ...template } = builderTemplate;
    thumbnailClick(template, ready, true, device);
  }

  const OrgTabName = useMemo(() => {
    return `${
      (selectedOrg?.name || '').length > 16 ? 'Org' : selectedOrg?.name
    } Templates`;
  }, [selectedOrg]);

  let menuItems: ItemType[] = [
    {
      key: 'all',
      label: (
        <Space size={5}>
          <CollectionsOutlined style={{ fontSize: 14 }} />
          Template Gallery
        </Space>
      ),
    },
  ];

  if (
    userHasEntitlement('app.paywall.template_builder') &&
    allBuilderTemplates.length
  ) {
    menuItems.push({
      key: 'builder',
      label: (
        <Space size={6}>
          <InterestsOutlined style={{ fontSize: 14 }} />
          Paywall Builder
        </Space>
      ),
    });
  }

  if ((orgTemplateQuery.data || []).length > 0) {
    menuItems.unshift({
      label: (
        <Space size={5}>
          <CorporateFareOutlined style={{ fontSize: 14 }} />
          {OrgTabName}
        </Space>
      ),
      key: 'org_only',
    });
  }

  const { Header, Content } = Layout;

  return (
    <>
      <StyledModal
        title={<Typography.Title level={4}>Create a Paywall</Typography.Title>}
        width={1065}
        centered
        open={open}
        footer={null}
        onCancel={onClose}
        bodyStyle={{
          overflowY: 'scroll',
          padding: 0,
          height: '650px',
        }}
      >
        <Layout style={{ backgroundColor: namiPureWhite }}>
          {addPaywallMutation.isLoading && <Loading />}
          {!menuCollapsed && (
            <StyledSider
              style={{
                overflow: 'hidden',
                height: 650,
                position: 'fixed',
                backgroundColor: 'unset',
                borderRight: '1px solid #f0f0f0',
              }}
            >
              <StyledMenu
                style={{ width: 225 }}
                mode="inline"
                items={menuItems}
                onClick={(info) => setMenuItem(info.key)}
                selectedKeys={[menuItem]}
              />
              <div style={{ flexGrow: 1 }} />
              <Button
                onClick={() => setVisible(true)}
                disabled={addPaywallMutation.isLoading}
                size="small"
                style={{ margin: '16px 10px' }}
              >
                Request a Design
              </Button>
            </StyledSider>
          )}
          <Layout style={{ marginLeft: menuCollapsed ? 0 : 225 }}>
            {menuItem !== 'builder' && (
              <Header
                style={{
                  width: '100%',
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: namiPureWhite,
                  height: 'fitContent',
                  padding: '2px 15px',
                  boxShadow: `0 3px 6px -6px ${namiDarkGray}`,
                }}
              >
                {filterRow}
              </Header>
            )}
            <Content style={{ backgroundColor: namiPureWhite, padding: 15 }}>
              {modalBodyContent}
            </Content>
          </Layout>
        </Layout>
      </StyledModal>
      <UnlockTVTemplateWebPaywall
        entitlement="app.paywall.formfactor.television"
        visible={isTVUpgradeOpen}
        onCancel={() => setTVUpgradeOpen(false)}
      />
      <FeatureRequestModal
        title="Request a Paywall Template"
        nameLabel="Quick description of the paywall"
        urlLabel=""
        useCaseLabel="Tell us more about the key features of this paywall design."
        showUrlField={false}
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
}
