import React, { useEffect, useMemo, useState } from 'react';

import {
  ApiOutlined,
  CheckCircleOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import {
  DeleteOutline,
  RestoreFromTrashOutlined,
  ShowChartOutlined,
} from '@mui/icons-material';
import {
  Button,
  Col,
  Image,
  Row,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { TAppliedFilters } from 'src/api/types/analytics.types';
import { TProduct } from 'src/api/types/sku.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import EmptyState from 'src/components/EmptyState/EmptyState';
import StatusFilter from 'src/components/StatusFilter/StatusFilter';
import { productStatusFilterOptions } from 'src/components/StatusFilter/statusFilterOptions';
import { truncateString } from 'src/utils/string';
import {
  namiBrightGreen,
  namiDarkOrange,
  namiGreen,
  namiLightOrange,
  namiMediumGray,
} from 'src/variables';
import styled from 'styled-components';

import Page from '../../../components/Page/Page';
import Responsive from '../../../components/Responsive/Responsive';
import SearchInput from '../../../components/SearchInput';
import { useAppContext, useBooleanState, useQueryParams } from '../../../hooks';
import { useApiOptions } from '../../../hooks/queries/apiOptions.hooks';
import { usePlatformsQuery } from '../../../hooks/queries/platform.hooks';
import {
  useArchiveProductMutation,
  useProductsQuery,
} from '../../../hooks/queries/product.hooks';
import { platformLogo } from '../../../services/helpers';
import { DynamicallySizedParagraph } from '../campaigns/CampaignsTable';
import StatusSelect from '../campaigns/StatusSelect';
import NewProductModal from './NewProductModal';

const CodeText = styled(Typography.Text)`
  margin-bottom: -2px !important;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: small;
`;

const WithWidth = styled.div<{ as: any }>`
  width: 100%;
`;

export default function ProductsPage() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const appContext = useAppContext();
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const [status, setStatus] = useState<string | null>(
    queryParams.get('status') || 'enabled'
  );
  const [platformId, setPlatformId] = useState<string>(
    queryParams.get('platform') || ''
  );
  const [apiOptions, updateApiOptions] = useApiOptions({
    pageSize: 20,
    archived: getArchivedStatus(),
  });
  const archiveProductMutation = useArchiveProductMutation();
  const productsQuery = useProductsQuery(apiOptions);
  const platformsQuery = usePlatformsQuery();

  const canCreate = appContext.userHasEntitlement('app.productsku.create');
  const subscriptionManagement = appContext.planHasEntitlement(
    'app.capabilities.entitlement_management'
  );

  const platformSelectOptions = useMemo(() => {
    return (platformsQuery.data || []).map((platform) => ({
      value: platform.id,
      label: (
        <Space>
          <Image
            height={12}
            width={12}
            preview={false}
            src={platformLogo(platform.type)}
          />
          {platform.name}
        </Space>
      ),
    }));
  }, [platformsQuery]);

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      archived: getArchivedStatus(),
      platformId: platformId || '',
    });
    queryParams.set('status', status || 'enabled');
    queryParams.set('platform', platformId || '');
    location.search = queryParams.toString();
    history.push(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, platformId]);

  const isLoading = platformsQuery.isFetching || productsQuery.isFetching;
  let children;
  if (!isLoading && !platformsQuery.data?.length) {
    children = (
      <EmptyState
        title="This app does not have any platform integrations. Add one in order to setup product SKUs."
        linkText="Learn more about App Platform Integrations."
        link="https://learn.namiml.com/public-docs/integrations/billing-platforms"
        icon="integration"
        iconSize="large"
      >
        <Button
          type="primary"
          onClick={() => history.push('/integrations/')}
          icon={<ApiOutlined />}
        >
          Manage Integrations
        </Button>
      </EmptyState>
    );
  } else if (!isLoading && !productsQuery.data?.results.length) {
    children = (
      <EmptyState
        title="Add your first product SKU."
        link="https://learn.namiml.com/public-docs/subscription-management/products"
        linkText="Learn more about product SKUs."
        icon="products"
      >
        <Button
          type="primary"
          icon={<PlusOutlined />}
          className="intercom-addProduct"
          onClick={openModal}
          disabled={!canCreate}
        >
          Add Product SKU
        </Button>
      </EmptyState>
    );
  } else {
    children = (
      <>
        <Responsive size="mdUp">
          <Table
            loading={isLoading}
            dataSource={(productsQuery.data?.results || []).map((product) => ({
              key: product.id,
              ...product,
            }))}
            rowKey="id"
            columns={getColumns('mdUp')}
            pagination={{
              total: productsQuery.data?.count,
              pageSize: apiOptions.pageSize,
              onChange: (page, pageSize) =>
                updateApiOptions({ page, pageSize }),
              current: productsQuery.data?.page_number,
              showSizeChanger: false,
              style: { paddingBottom: 72 },
              showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
            }}
            style={{ cursor: 'default' }}
            scroll={{ x: 1200 }}
            className="horizontalScrollTable"
          />
        </Responsive>
        <Responsive size="mdDown">
          <Table
            loading={isLoading}
            dataSource={(productsQuery.data?.results || []).map((product) => ({
              key: product.id,
              ...product,
            }))}
            rowKey="id"
            columns={getColumns('mdDown')}
            pagination={{
              total: productsQuery.data?.count,
              pageSize: apiOptions.pageSize,
              onChange: (page, pageSize) =>
                updateApiOptions({ page, pageSize }),
              current: productsQuery.data?.page_number,
              showSizeChanger: false,
              style: { paddingBottom: 72 },
              showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
            }}
            style={{ cursor: 'default' }}
            scroll={{ x: 1200 }}
            className="horizontalScrollTable"
          />
        </Responsive>
      </>
    );
  }

  return (
    <Page title="Products">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb items={[{ name: 'Products' }]} />
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ paddingBottom: 12 }}>
        <Col xs={24} md={6}>
          <SearchInput
            placeholder="Search by product name or SKU ID"
            value={apiOptions.search || ''}
            onChange={(search) => {
              updateApiOptions({ search });
            }}
            intercomClassName="intercom-productsSearch"
          />
        </Col>
        <Col xs={12} md={3}>
          <StatusFilter
            status={status}
            onChange={setStatus}
            onClear={() => setStatus(null)}
            options={productStatusFilterOptions}
            intercomClassName="intercom-productStatusFilter"
          />
        </Col>
        <Col xs={12} md={5}>
          <WithWidth
            as={StatusSelect}
            options={platformSelectOptions}
            style={{ width: '100%' }}
            onChange={setPlatformId}
            allowClear
            mode={undefined}
            showArrow={false}
            label={platformId ? 'Platform:' : ''}
            optionLabelProp="label"
            placeholder={
              <>
                Filter by <b>Platform</b>
              </>
            }
          />
        </Col>
        <Col xs={24} md={10}>
          <Button
            type="primary"
            className="intercom-addProduct"
            disabled={!canCreate}
            onClick={openModal}
            icon={<PlusOutlined style={{ marginRight: '0.5rem' }} />}
            style={{ float: 'right' }}
          >
            <Responsive size="mdUp">Add Product</Responsive>
            <Responsive size="mdDown">Add</Responsive>
          </Button>
        </Col>
      </Row>
      {children}
      <NewProductModal isOpen={isModalOpen} onClose={closeModal} />
    </Page>
  );

  function getColumns(size: 'mdUp' | 'mdDown'): ColumnsType<TProduct> {
    let columns: ColumnsType<TProduct> = [
      {
        title: <Typography.Text strong>Name</Typography.Text>,
        key: 'name',
        fixed: size === 'mdUp' ? 'left' : undefined,
        width: 290,
        ellipsis: true,
        render: (product: TProduct) => (
          <Space size={5}>
            <Tooltip title={product.name} mouseEnterDelay={0.3}>
              <DynamicallySizedParagraph
                ellipsis={true}
                onClick={() => history.push(`/products/${product.id}/`)}
              >
                {product.name}
              </DynamicallySizedParagraph>
            </Tooltip>
            {product.archived && (
              <DeleteOutline
                style={{
                  fontSize: 15,
                  color: namiMediumGray,
                }}
              />
            )}
          </Space>
        ),
      },
      {
        title: <Typography.Text strong>SKU ID</Typography.Text>,
        key: 'sku_ref_id',
        ellipsis: true,
        width: 250,
        fixed: size === 'mdUp' ? 'left' : undefined,
        render: ({ sku_ref_id }: TProduct) => (
          <Tooltip title={sku_ref_id}>
            <Space>
              <CodeText>{truncateString(sku_ref_id, 24)}</CodeText>
            </Space>
          </Tooltip>
        ),
      },
      {
        title: <Typography.Text strong>Platform</Typography.Text>,
        ellipsis: false,
        key: 'platform',
        width: 225,
        render: (product: TProduct) => (
          <Space>
            <Image
              src={platformLogo(product.platform_type)}
              height={18}
              width={18}
              preview={false}
            />
            {product.platform_name}
          </Space>
        ),
      },
    ];
    if (subscriptionManagement)
      columns.push({
        title: <Typography.Text strong>Entitlements</Typography.Text>,
        key: 'entitlements',
        width: 175,
        render: ({ entitlements }: TProduct) => {
          const isConfigured = entitlements.length > 0;
          return (
            <Space>
              {isConfigured ? <ConfiguredTag /> : <NotConfiguredTag />}
            </Space>
          );
        },
      });
    columns.push(
      {
        title: <Typography.Text strong>Created</Typography.Text>,
        key: 'created_date',
        ellipsis: true,
        width: 150,
        render: ({ created_date }: TProduct) => {
          const date = moment(created_date);
          return (
            <Tooltip title={date.format('YYYY-MM-DD h:mm A')}>
              <span>{date.fromNow()}</span>
            </Tooltip>
          );
        },
      },
      {
        title: <Typography.Text strong>Updated</Typography.Text>,
        key: 'updated_date',
        ellipsis: true,
        width: 150,
        render: ({ updated_date }: TProduct) => {
          const date = moment(updated_date);
          return (
            <Tooltip title={date.format('YYYY-MM-DD h:mm A')}>
              <span>{date.fromNow()}</span>
            </Tooltip>
          );
        },
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        ellipsis: true,
        render: (product: TProduct) => (
          <Space direction="horizontal" size={6}>
            {getEditingLink(product)}
            {getArchiveLink(product)}
            {getReportingLink(product.id)}
          </Space>
        ),
        width: 325,
      }
    );

    return columns;
  }

  function getEditingLink(product: TProduct): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        size="small"
        icon={<EditOutlined style={{ fontSize: '13px' }} />}
        disabled={!appContext.userHasEntitlement('app.productsku.get')}
        onClick={() => history.push(`/products/${product.id}/`)}
      >
        Edit
      </IconActionButton>
    );
  }

  function getArchiveLink(product: TProduct): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={
          product.archived ? (
            <RestoreFromTrashOutlined
              style={{
                fontSize: 15,
              }}
            />
          ) : (
            <DeleteOutline
              style={{
                fontSize: 15,
              }}
            />
          )
        }
        size="small"
        disabled={
          !appContext.userHasEntitlement('app.productsku.update') ||
          product.delete_warnings.is_in_use_by_paywalls
        }
        onClick={() =>
          archiveProductMutation.mutate({
            product_id: product.id,
            archived: !!product.archived,
          })
        }
      >
        {product.archived ? 'Restore' : 'Archive'}
      </IconActionButton>
    );
  }

  function getReportingLink(productId: string): React.ReactNode {
    const filters: TAppliedFilters = {
      filter_by_product: {
        identifier: 'ProductSKU.id',
        operator: 'equals',
        values: [productId],
      },
    };

    const urlMeta = '?filters=' + JSON.stringify([filters.filter_by_product]);

    return (
      <IconActionButton
        type="text"
        icon={<ShowChartOutlined style={{ fontSize: 15 }} />}
        href={`/insights/purchases/${urlMeta}`}
        size="small"
      >
        Purchases
      </IconActionButton>
    );
  }

  function getArchivedStatus(): boolean {
    if (status === 'archived') return true;
    return false;
  }
}

function ConfiguredTag() {
  const style = { color: namiBrightGreen, borderColor: namiGreen };
  return (
    <Tag color="success" icon={<CheckCircleOutlined />} style={style}>
      Configured
    </Tag>
  );
}

function NotConfiguredTag() {
  const style = { color: namiDarkOrange, borderColor: namiLightOrange };
  return (
    <Tag color="warning" icon={<ExclamationCircleFilled />} style={style}>
      Not Configured
    </Tag>
  );
}
