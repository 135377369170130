import {
  TCustomerDevice,
  TCustomerPurchaseEvent,
} from 'src/api/types/customer.types';

import { capitalize } from './formatting';
import { getUSDCurrency } from './math';

export function getDevicePlatformFormFactorSummary(
  device: TCustomerDevice
): string {
  if (!device.platform && !device.profile.form_factor && !device.profile.model)
    return 'Device';
  const modelName = device.profile.model
    ? device.profile.model
    : device.profile.form_factor
    ? capitalize(device.profile.form_factor)
    : 'Device';
  return modelName;
}

export function getListDescriptionForPurchaseEvent(
  purchaseEvent: TCustomerPurchaseEvent
): string {
  if (purchaseEvent.revenue_in_usd)
    return getUSDCurrency(purchaseEvent.revenue_in_usd);
  return '';
}
