import React, { useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import { Button, Form, Header, Segment } from 'semantic-ui-react';

import api from '../../../api';
import NamiIcon from '../../../components/NamiIcon/NamiIcon';
import Page from '../../../components/Page/Page';
import Wrapper from '../../../components/Wrapper/Wrapper';
import { JOIN_ENABLED } from '../../../config';
import { useAppContext, useInputState, usePromiseState } from '../../../hooks';
import store from '../../../redux';
import { clearRoot } from '../../../redux/actions/rootActions';
import history from '../../../services/history';
import Token from '../../../services/token';
import { extractFieldError } from '../../../services/utilsService';

const LoginPage = () => {
  const context = useAppContext();
  const [email, setEmail] = useInputState('');
  const [password, setPassword] = useInputState('');
  const { isLoading, error, trigger: login } = usePromiseState(getLoginPromise);

  useEffect(() => {
    store.dispatch(clearRoot());
  }, []);

  return (
    <Page title="Sign In">
      <Wrapper>
        <Segment basic textAlign="center">
          <NamiIcon icon="ml logo" color="primary" size="massive" />
        </Segment>
        <Segment>
          <Form size="large">
            <Header textAlign="center" style={{ paddingTop: '1em' }}>
              Sign in to Nami
            </Header>
            <Form.Input
              label="Email"
              name="email"
              onChange={setEmail}
              fluid
              icon="mail"
              iconPosition="left"
              autoCapitalize="none"
              autoCorrect="off"
              placeholder="E-mail address"
              error={extractFieldError(error?.email)}
            />
            <Form.Input
              label="Password"
              name="password"
              onChange={setPassword}
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              error={extractFieldError(error?.password)}
            />
            <Button
              data-cy="login"
              className="nami-primary-button"
              fluid
              size="large"
              onClick={login}
              loading={isLoading}
            >
              Sign In
            </Button>
          </Form>
        </Segment>
        <Segment textAlign="center">
          {JOIN_ENABLED && (
            <span>
              New to Nami?{' '}
              <NavLink to="/join/" onClick={signUpClick}>
                Create an account here.
              </NavLink>
            </span>
          )}
          <br />
          <NavLink to="/forgot-password/">Forgot your password?</NavLink>
        </Segment>
      </Wrapper>
    </Page>
  );

  function signUpClick() {
    window.analytics.track('Sign Up Clicked', null);
  }

  function getLoginPromise() {
    return api.login(email, password).then((payload) => {
      if (payload.pre_auth_token) {
        handlePreAuth(/** @type PreAuthPayloadType */ payload);
      } else if (payload.access_token && payload.refresh_token) {
        context.authorizeAccess(/** @type AuthPayloadType */ payload);
      }
      // TODO: Adapt this to the MFA flow
      window.analytics.track('User Signed In', null);
    });
  }

  /** @param payload {PreAuthPayloadType} */
  function handlePreAuth(payload) {
    Token.setPreAuth(payload.pre_auth_token);
    history.push(`/pre-auth/${payload.authenticators[0].type}/`);
  }
};

export default LoginPage;
