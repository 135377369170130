import React, { useEffect, useMemo, useState } from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Image, notification, Space } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';
import { MenuProps } from 'semantic-ui-react';
import {
  startDateLookbackInHours,
  TEventLogEventType,
} from 'src/api/types/developer.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { usePlatformsQuery } from 'src/hooks/queries/platform.hooks';
import { platformLogo } from 'src/services/helpers';
import styled, { css } from 'styled-components';

import StatusSelect from '../../campaigns/StatusSelect';
import { TIDType } from '../DeveloperPageWrapper';
import DeveloperFeedIdSearchInput from './DeveloperFeedIdSearchInput';
import EventTypeFilter from './EventTypeFilter';

type DeveloperFeedFilterProps = {
  allowDateFiltering: boolean;
  eventTypes: TEventLogEventType[];
  setEventTypes: (value: TEventLogEventType[]) => void;
  platformId: string | null;
  setPlatformId: (value: string | null) => void;
  startDate: string;
  setStartDate: (value: string) => void;
  endDate: string | null;
  setEndDate: (value: string | null) => void;
  deviceId: string | null;
  setDeviceId: (value: string | null) => void;
  externalId: string | null;
  setExternalId: (value: string | null) => void;
  userId: string | null;
  setUserId: (value: string | null) => void;
  cdpId: string | null;
  setCDPId: (value: string | null) => void;
  resetFilters: () => void;
};

type DeveloperFeedFilterTypes = 'platform' | 'id' | 'start_date' | 'end_date';

const DeveloperFeedFilterMeta: Record<DeveloperFeedFilterTypes, string> = {
  platform: 'Platform',
  id: 'User',
  start_date: 'Start Date',
  end_date: 'End Date',
};

const WithWidth = styled.div<{ as: any }>`
  width: 240px;
`;

const StyledDatePicker = styled(DatePicker)<{
  label?: string | null;
}>`
  ${({ label }) => {
    if (!label) return '';
    return css`
      &:before {
        display: block;
        content: '${label}';
        font-weight: 600;
        min-width: fit-content;
        margin-right: 0.5rem;
      }
    `;
  }}
`;

export default function DeveloperFeedFilters({
  allowDateFiltering,
  resetFilters,
  eventTypes,
  setEventTypes,
  platformId,
  setPlatformId,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  deviceId,
  setDeviceId,
  externalId,
  setExternalId,
  userId,
  setUserId,
  cdpId,
  setCDPId,
}: DeveloperFeedFilterProps) {
  const platformsQuery = usePlatformsQuery();
  const platformSelectOptions = useMemo(() => {
    return (platformsQuery.data || []).map((platform) => ({
      value: platform.id,
      label: (
        <Space>
          <Image
            height={12}
            width={12}
            preview={false}
            src={platformLogo(platform.type)}
          />
          {platform.name}
        </Space>
      ),
    }));
  }, [platformsQuery]);
  const disabledStartDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days after today
    return (
      (current && current > moment().endOf('day')) ||
      (current && current > moment(endDate))
    );
  };

  const disabledEndDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days after today
    return (
      (current && current > moment().endOf('day')) ||
      (current && current < moment(startDate))
    );
  };

  const [activeFilters, setActiveFilters] = useState<
    Array<DeveloperFeedFilterTypes>
  >([]);
  const [currentIdType, setCurrentIdType] = useState<TIDType>('external_id');

  const currentId: string | null = useMemo(() => {
    if (externalId) return externalId;
    if (userId) return userId;
    if (deviceId) return deviceId;
    if (cdpId) return cdpId;
    return null;
  }, [externalId, userId, deviceId, cdpId]);

  useEffect(() => {
    if (userId) {
      setCurrentIdType('user_id');
    } else if (deviceId) {
      setCurrentIdType('device_id');
    } else if (cdpId) {
      setCurrentIdType('cdp_id');
    } else {
      setCurrentIdType('external_id');
    }
  }, [userId, deviceId, cdpId]);

  useEffect(() => {
    let tempActiveFilters: DeveloperFeedFilterTypes[] = [];
    if (platformId != null) tempActiveFilters.push('platform');
    if (startDate != null) tempActiveFilters.push('start_date');
    if (endDate != null) tempActiveFilters.push('end_date');
    if (
      deviceId != null ||
      externalId != null ||
      userId != null ||
      cdpId != null
    )
      tempActiveFilters.push('id');
    setActiveFilters(tempActiveFilters);
  }, [platformId, startDate, endDate, deviceId, externalId, userId, cdpId]);

  const filterOptions = useMemo(() => {
    return Object.keys(DeveloperFeedFilterMeta).reduce((output, filterType) => {
      if (activeFilters.includes(filterType as DeveloperFeedFilterTypes))
        return output;
      return [
        ...output,
        {
          label:
            DeveloperFeedFilterMeta[filterType as DeveloperFeedFilterTypes],
          key: filterType,
          disabled:
            !allowDateFiltering &&
            ['start_date', 'end_date'].includes(filterType),
        },
      ];
    }, [] as { label: string; key: string; disabled: boolean }[]);
  }, [activeFilters, allowDateFiltering]);

  return (
    <Space direction="horizontal" style={{ width: '100%' }} wrap={true}>
      <EventTypeFilter
        eventTypes={eventTypes}
        onChange={(value) => {
          if (value && value?.length > 0) {
            setEventTypes(value as TEventLogEventType[]);
          } else {
            notification.warning({
              message: 'At least 1 event type must be selected',
            });
          }
        }}
      />
      <StyledDatePicker
        showTime
        allowClear={false}
        disabledDate={disabledStartDate}
        value={startDate ? moment(startDate) : null}
        placeholder="Provide a start date"
        format="YYYY/MM/DD h:mm a"
        className="intercom-eventsFeedStartDateFilter"
        onChange={(value) =>
          setStartDate(
            value
              ? value.toISOString()
              : moment()
                  .subtract(startDateLookbackInHours, 'hours')
                  .toISOString()
          )
        }
        label={'Start Date:'}
        showNow={false}
      />
      {activeFilters.includes('platform') && (
        <WithWidth
          as={StatusSelect}
          options={platformSelectOptions}
          onChange={setPlatformId}
          allowClear
          value={platformId}
          mode={undefined}
          showArrow={false}
          label={platformId ? 'Platform:' : ''}
          optionLabelProp="label"
          placeholder={
            <>
              Filter by <b>Platform</b>
            </>
          }
        />
      )}
      {activeFilters.includes('end_date') && (
        <StyledDatePicker
          showTime
          disabledDate={disabledEndDate}
          value={endDate ? moment(endDate) : null}
          placeholder="Provide an end date"
          format="YYYY/MM/DD h:mm a"
          onChange={(value) => setEndDate(value ? value.toISOString() : null)}
          showNow={false}
          label={endDate ? 'End Date:' : undefined}
        />
      )}
      {activeFilters.includes('id') && (
        <DeveloperFeedIdSearchInput
          idType={currentIdType}
          setIdType={setCurrentIdType}
          currentId={currentId}
          setId={changeId}
          clearId={removeUserIdFilter}
        />
      )}
      {activeFilters.length < Object.keys(DeveloperFeedFilterMeta).length && (
        <Dropdown
          menu={{ items: filterOptions, onClick: popoverClick }}
          trigger={['click']}
        >
          <Button
            icon={<PlusOutlined />}
            className="intercom-eventsFeedAddFilter"
          >
            Add Filter
          </Button>
        </Dropdown>
      )}
      <IconActionButton
        type="text"
        size="small"
        icon={<CloseOutlined style={{ fontSize: '13px' }} />}
        onClick={resetFilters}
      >
        Reset
      </IconActionButton>
    </Space>
  );

  function popoverClick(props: MenuProps['onClick']) {
    if (!activeFilters.includes(props.key)) {
      let newActiveFilters = [...activeFilters];
      newActiveFilters.push(props.key);
      setActiveFilters(newActiveFilters);
    }
  }

  function removeUserIdFilter() {
    const newActiveFilters = [...activeFilters].reduce((output, filter) => {
      if (filter === 'id') return output;
      return [...output, filter];
    }, [] as DeveloperFeedFilterTypes[]);
    setActiveFilters(newActiveFilters);
    setExternalId(null);
    setDeviceId(null);
    setUserId(null);
    setCDPId(null);
  }

  function changeId(type: TIDType, value: string | null) {
    if (type === 'external_id') {
      setExternalId(value);
      setDeviceId(null);
      setUserId(null);
      setCDPId(null);
    } else if (type === 'device_id') {
      setDeviceId(value);
      setExternalId(null);
      setUserId(null);
      setCDPId(null);
    } else if (type === 'user_id') {
      setUserId(value);
      setDeviceId(null);
      setExternalId(null);
      setCDPId(null);
    } else if (type === 'cdp_id') {
      setCDPId(value);
      setDeviceId(null);
      setExternalId(null);
      setUserId(null);
    }
  }
}
