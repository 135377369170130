import React, { useState } from 'react';

import { PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FileUploadOutlined } from '@mui/icons-material';
import {
  Button,
  Card,
  Form,
  Image,
  Popover,
  Result,
  Select,
  Space,
  Typography,
} from 'antd';
import { createSelector } from 'reselect';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import { platformCompatibleWithFormFactor } from 'src/utils/paywall';
import { namiDarkGray, namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import EmptyState from '../../../../../components/EmptyState/EmptyState';
import { useActions, useAppSelector } from '../../../../../hooks/redux.hooks';
import { RootState } from '../../../../../redux';
import PaywallBuilderSlice from '../../../../../redux/PaywallBuilderSlice';
import { platformLogo } from '../../../../../services/helpers';
import history from '../../../../../services/history';
import ProductGroupsFormItem from '../editor/inputs/ProductGroupsFormItem';
import ImportProductMenus from '../modals/ImportProductMenus';
import { AvailableProductList, SelectedProductList } from './ProductLists';

const QuestionMarkIcon = styled(QuestionCircleOutlined)`
  margin-left: 5px;
`;

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) => paywallBuilder.groupId,
    ({ paywallBuilder }: RootState) => paywallBuilder.platformId,
    ({ paywallBuilder }: RootState) => paywallBuilder.skuItems,
    ({ paywallBuilder }: RootState) => paywallBuilder.availableItems,
    ({ paywallBuilder }: RootState) => paywallBuilder.platforms,
    ({ paywallBuilder }: RootState) => paywallBuilder.formFactor,
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.maxSkus'] || 0,
    ({ paywallBuilder }: RootState) => paywallBuilder.productGroups,
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.displaySingleGroup'],
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.v2Ready'],
  ],
  (
    groupId,
    platformId,
    skuItems,
    availableItems,
    platforms,
    formFactor,
    maxSkus,
    productGroups,
    v2Ready
  ) => {
    const items =
      (groupId && platformId && skuItems[groupId][platformId]) || {};
    const totalSkuItemsCount = productGroups.reduce((output, group) => {
      return platforms.reduce((platOutput, platform) => {
        return platOutput + Object.keys(skuItems[group.id][platform.id]).length;
      }, output);
    }, 0);
    return {
      items,
      platforms,
      formFactor,
      totalItems: platformId
        ? Object.keys(items).length +
          (availableItems[platformId][groupId!] || []).length
        : 0,
      totalSkuItemsCount,
      maxSkus,
      platformId,
      productGroups,
      v2Ready: !Array.isArray(v2Ready) && v2Ready,
    };
  }
);

export default function ProductSelector() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const {
    platforms,
    formFactor,
    totalItems,
    totalSkuItemsCount,
    maxSkus,
    platformId,
    productGroups,
    items,
    v2Ready,
  } = useAppSelector(selector);
  const [isBulkAddModalOpen, setBulkAddModalOpen] = useState(false);

  if (!platforms.length) return <IntegrationEmptyState />;
  const content = (
    <p>
      Click the ➕ icon next to an Available Product to add to the paywall. Drag
      products within the Added Products box to reorder.
    </p>
  );
  return (
    <>
      <Form layout="vertical">
        <ProductGroupsFormItem location="products" />
        <Form.Item
          label={
            <Typography.Text style={{ marginBottom: '0em', fontWeight: 500 }}>
              Platform
            </Typography.Text>
          }
          className="intercom-platformPicker"
        >
          <Select
            onChange={actions.setPlatformId}
            placeholder="Select Platform"
            style={{ height: 'auto', wordWrap: 'break-word' }}
            optionFilterProp="children"
            value={platformId}
            className="dropdown-image"
          >
            {platforms.map((platform) => (
              <Select.Option
                key={platform.id}
                value={platform.id}
                disabled={
                  !platformCompatibleWithFormFactor(platform.type, formFactor)
                }
              >
                <Space>
                  <Image
                    height={18}
                    preview={false}
                    width={18}
                    src={platformLogo(platform.type)}
                  />
                  {platform.name}
                </Space>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {totalItems === 0 ? (
          <ProductsEmptyState />
        ) : (
          <>
            <Form.Item>
              <Space direction="vertical" size={1} style={{ marginBottom: 12 }}>
                <Typography.Text
                  style={{ marginBottom: '0em', fontWeight: 500 }}
                >
                  Added Products
                  <Popover content={content} overlayStyle={{ maxWidth: 250 }}>
                    <QuestionMarkIcon
                      style={{
                        color: namiMediumGray,
                        fontSize: 13,
                        marginLeft: 5,
                      }}
                    />
                  </Popover>
                </Typography.Text>
                {!!maxSkus && !v2Ready && (
                  <Typography.Text
                    style={{ fontSize: 'small', color: namiDarkGray }}
                  >
                    Paywall supports {maxSkus > 1 ? ' up to' : ''} {maxSkus}{' '}
                    product{maxSkus > 1 ? 's' : ''}{' '}
                    {productGroups.length > 1 ? ' per group' : ''}
                  </Typography.Text>
                )}
              </Space>
              {Object.keys(items).length > 0 ? (
                <SelectedProductList />
              ) : (
                selectedProductsEmptyState(totalSkuItemsCount, () =>
                  setBulkAddModalOpen(true)
                )
              )}
            </Form.Item>
            <Form.Item>
              <Typography.Text style={{ fontWeight: 500 }}>
                Available Products
              </Typography.Text>
              <AvailableProductList />
            </Form.Item>
          </>
        )}
      </Form>
      <ImportProductMenus
        isOpen={isBulkAddModalOpen}
        onClose={() => {
          setBulkAddModalOpen(false);
        }}
      />
    </>
  );
}

function IntegrationEmptyState() {
  return (
    <EmptyState
      icon="integration"
      iconSize="large"
      title="This app does not have any mobile payment platforms integrated."
      link="https://learn.namiml.com/public-docs/integrations/billing-platforms"
      linkText="Learn more about payment platform integrations."
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => history.push('/integrations/')}
      >
        Manage Integrations
      </Button>
    </EmptyState>
  );
}

function selectedProductsEmptyState(
  totalProductsAdded: number,
  importOnClick: () => void
) {
  return (
    <Card>
      <Result
        style={{ padding: '40px 12px' }}
        icon={<NamiIcon icon={'add'} size={'medium'} color="lightGray" />}
        title={''}
        extra={
          <>
            <div
              style={{
                marginBottom: 22,
                marginTop: -20,
                color: namiMediumGray,
              }}
            >
              Click an item in the Available Products list to add to the
              paywall.
            </div>
            {totalProductsAdded === 0 && (
              <>
                <div style={{ color: namiMediumGray, marginBottom: 12 }}>
                  or
                </div>
                <Button
                  type="link"
                  icon={
                    <FileUploadOutlined
                      style={{
                        fontSize: 15,
                      }}
                      className="inButton"
                    />
                  }
                  onClick={importOnClick}
                >
                  Import Product Menus
                </Button>
              </>
            )}
          </>
        }
      />
    </Card>
  );
}

function ProductsEmptyState() {
  return (
    <EmptyState
      icon="products"
      iconSize="large"
      title="There are no product SKUs created for this platform"
      link="https://learn.namiml.com/public-docs/subscription-management/products"
      linkText="Learn more about products."
    >
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => history.push('/products/')}
      >
        Add Product
      </Button>
    </EmptyState>
  );
}
