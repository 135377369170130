import React, { useEffect, useMemo, useState } from 'react';

import { FileUploadOutlined } from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import {
  Alert,
  Button,
  Image,
  Modal,
  notification,
  Space,
  Table,
  Typography,
  UploadFile,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Upload, { RcFile } from 'antd/lib/upload';
import Papa, { ParseResult } from 'papaparse';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { PlatformType } from 'src/api/types/main.types';
import { SelectableItemType, TProductGroup } from 'src/api/types/paywall.types';
import { useAppContext } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { platformLogo } from 'src/services/helpers';

type ImportProductMenuProps = {
  isOpen: boolean;
  onClose: () => void;
};
type PlatformIdentifiers =
  | 'Platform 1 ID'
  | 'Platform 2 ID'
  | 'Platform 3 ID'
  | 'Platform 4 ID';
const PlatformIdentifierKeys: Record<PlatformIdentifiers, string[]> = {
  'Platform 1 ID': [
    'Platform 1 SKU ID 1',
    'Platform 1 SKU ID 2',
    'Platform 1 SKU ID 3',
    'Platform 1 SKU ID 4',
  ],
  'Platform 2 ID': [
    'Platform 2 SKU ID 1',
    'Platform 2 SKU ID 2',
    'Platform 2 SKU ID 3',
    'Platform 2 SKU ID 4',
  ],
  'Platform 3 ID': [
    'Platform 3 SKU ID 1',
    'Platform 3 SKU ID 2',
    'Platform 3 SKU ID 3',
    'Platform 3 SKU ID 4',
  ],
  'Platform 4 ID': [
    'Platform 4 SKU ID 1',
    'Platform 4 SKU ID 2',
    'Platform 4 SKU ID 3',
    'Platform 4 SKU ID 4',
  ],
};

type ProductMenuData = {
  'Product Group Reference ID': string;
  'Platform 1 ID': string;
  'Platform 1 SKU ID 1': string;
  'Platform 1 SKU ID 2': string;
  'Platform 1 SKU ID 3': string;
  'Platform 1 SKU ID 4': string;
  'Platform 2 ID': string;
  'Platform 2 SKU ID 1': string;
  'Platform 2 SKU ID 2': string;
  'Platform 2 SKU ID 3': string;
  'Platform 2 SKU ID 4': string;
  'Platform 3 ID': string;
  'Platform 3 SKU ID 1': string;
  'Platform 3 SKU ID 2': string;
  'Platform 3 SKU ID 3': string;
  'Platform 3 SKU ID 4': string;
  'Platform 4 ID': string;
  'Platform 4 SKU ID 1': string;
  'Platform 4 SKU ID 2': string;
  'Platform 4 SKU ID 3': string;
  'Platform 4 SKU ID 4': string;
};
type ParseValues = {
  data: ProductMenuData[];
};

export type IntermediateMenuData = {
  product_group_id: string;
  platform_id: string;
  selectable_items: SelectableItemType[];
};

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  ({ menus, availableItems, productGroups, platforms }) => ({
    menus: menus,
    availableItems: availableItems,
    productGroups: productGroups,
    platforms: platforms,
  })
);

export default function ImportProductMenus({
  isOpen,
  onClose,
}: ImportProductMenuProps) {
  const [file, setFile] = useState<UploadFile | null>(null);
  const [values, setValues] = useState<ParseValues | undefined>();
  const [intermediateValues, setIntermediateValues] = useState<
    IntermediateMenuData[]
  >([]);
  const [error, setError] = useState<string>();
  const [warnings, setWarnings] = useState<string[]>([]);
  const { availableItems, productGroups, platforms } = useAppSelector(selector);
  const actions = useActions(PaywallBuilderSlice.actions);
  const context = useAppContext();

  const productGroupRefIdMap = useMemo(() => {
    return productGroups.reduce((output, group) => {
      return {
        ...output,
        [group.ref]: group,
      };
    }, {} as Record<string, TProductGroup>);
  }, [productGroups]);

  const productGroupIdMap = useMemo(() => {
    return productGroups.reduce((output, group) => {
      return {
        ...output,
        [group.id]: group,
      };
    }, {} as Record<string, TProductGroup>);
  }, [productGroups]);

  const platformIdMap = useMemo(() => {
    return platforms.reduce((output, platform) => {
      return {
        ...output,
        [platform.id]: platform,
      };
    }, {} as Record<string, PlatformType>);
  }, [platforms]);

  useEffect(() => {
    if (values && values.data) {
      const intermediate = values.data.reduce((output, menuRow) => {
        if (
          menuRow['Product Group Reference ID'] &&
          menuRow['Product Group Reference ID'] in productGroupRefIdMap
        ) {
          let usedPlatformIds: string[] = [];
          return Object.keys(PlatformIdentifierKeys).reduce(
            (rowOutput, key) => {
              if ((menuRow as any)[key]) {
                const platformId = (menuRow as any)[key];
                if (
                  platformId in platformIdMap &&
                  !usedPlatformIds.includes(platformId)
                ) {
                  usedPlatformIds.push(platformId);
                  const newProducts = pullOutValidProductIds(
                    menuRow,
                    key as PlatformIdentifiers,
                    productGroupRefIdMap[menuRow['Product Group Reference ID']]
                      .id,
                    platformIdMap[platformId]
                  );
                  if (newProducts.length > 0) {
                    return [
                      ...rowOutput,
                      {
                        product_group_id:
                          productGroupRefIdMap[
                            menuRow['Product Group Reference ID']
                          ].id,
                        platform_id: platformId,
                        selectable_items: newProducts,
                      } as IntermediateMenuData,
                    ];
                  } else {
                    setWarnings((w) => [
                      ...w,
                      `Plaform "${platformId}" has no valid SKUs`,
                    ]);
                  }
                } else if (usedPlatformIds.includes(platformId)) {
                  setWarnings((w) => [
                    ...w,
                    `Platform ID "${platformId}" already provided for this group`,
                  ]);
                } else {
                  setWarnings((w) => [
                    ...w,
                    `Platform ID "${platformId}" can't be found`,
                  ]);
                }
              }
              return rowOutput;
            },
            output as IntermediateMenuData[]
          );
        } else {
          setWarnings((w) => [
            ...w,
            `Product Group Ref ID "${menuRow['Product Group Reference ID']}" can't be found on this paywall`,
          ]);
        }
        return output;
      }, [] as IntermediateMenuData[]);
      setIntermediateValues(intermediate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, productGroupRefIdMap, platformIdMap]);

  function pullOutValidProductIds(
    rawRow: ProductMenuData,
    platformNumber: PlatformIdentifiers,
    groupId: string,
    platform: PlatformType
  ): SelectableItemType[] {
    let result: SelectableItemType[] = [];
    if (availableItems[platform.id] && availableItems[platform.id][groupId]) {
      const availableItemsForGroupAndPlatform =
        availableItems[platform.id][groupId];
      Object.values(PlatformIdentifierKeys[platformNumber]).forEach((key) => {
        if ((rawRow as any)[key]) {
          const productRefId = (rawRow as any)[key];
          const selectableItem = availableItemsForGroupAndPlatform.find(
            (value) => value.sku_ref_id === productRefId
          );
          if (selectableItem) {
            result.push(selectableItem);
          } else {
            setWarnings((w) => [
              ...w,
              `Product Ref ID "${productRefId}" can't be found in platform ${platform.name}`,
            ]);
          }
        }
      });
    }
    return result;
  }

  const getCSV = (fileUrl: string) => {
    Papa.parse(fileUrl, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter: ',',
      preview: 75,
      complete: (results: ParseResult<ProductMenuData>) => {
        if (!results.meta.fields?.includes('Product Group Reference ID')) {
          setError('"Product Group Reference ID" column required');
        } else {
          const productGroupRefIds = results.data.map(
            (row) => row['Product Group Reference ID']
          );
          if (new Set(productGroupRefIds).size !== productGroupRefIds.length) {
            setError('Duplicate Product Group Reference IDs found');
          } else if (!results.meta.fields?.includes('Platform 1 ID')) {
            setError('"Platform 1 ID" required');
          } else if (!results.meta.fields?.includes('Platform 1 SKU ID 1')) {
            setError('"Platform 1 SKU ID 1" required');
          } else {
            setValues(results);
          }
        }
      },
    });
  };

  useEffect(() => {
    if (file && file.url) {
      getCSV(file.url);
    }
  }, [file]);

  return (
    <Modal
      title="Import Product Menus"
      open={isOpen}
      centered
      onCancel={() => {
        reset();
        onClose();
      }}
      zIndex={1005}
      forceRender
      okText={
        intermediateValues.length > 0
          ? `Import ${intermediateValues.length} Product Menu${
              intermediateValues.length > 1 ? 's' : ''
            }`
          : 'Import Product Menus'
      }
      okButtonProps={{
        disabled: !!error || !values || !intermediateValues.length,
      }}
      onOk={(_e) => {
        actions.bulkUpdateProductMenus(intermediateValues);
        notification.success({
          message: `${intermediateValues.length} Product Menu${
            intermediateValues.length > 1 ? 's' : ''
          } imported successfully`,
        });
        reset();
        onClose();
      }}
      width={900}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Upload
          multiple={false}
          fileList={file ? [file] : []}
          accept=".csv"
          customRequest={({ file }: UploadRequestOption) => handleNewFile(file)}
          onRemove={() => {
            reset();
          }}
        >
          {!file && (
            <Button
              icon={
                <FileUploadOutlined
                  style={{ fontSize: 16 }}
                  className="inButton"
                />
              }
            >
              Upload CSV File
            </Button>
          )}
        </Upload>
        {!error && !file && (
          <Alert
            message={
              'A template for the CSV file can be found in the Nami documentation portal.'
            }
            type="info"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                href={getDocsLink()}
                target="_blank"
              >
                Go to Docs
              </Button>
            }
          />
        )}
        {error && <Alert message={error} type="error" showIcon />}
        {warnings &&
          warnings.map((w, index) => (
            <Alert key={index} message={w} type="warning" showIcon closable />
          ))}
        {!error && intermediateValues && intermediateValues.length > 0 && (
          <Table
            dataSource={intermediateValues}
            columns={getColumns()}
            rowKey={(row) => `${row.product_group_id}-${row.platform_id}`}
            size="small"
            pagination={false}
            scroll={{ y: 400 }}
            className="verticalScrollDrawer"
          />
        )}
      </Space>
    </Modal>
  );

  function getDocsLink() {
    const docsLink = context.getDocsLink();

    if (docsLink !== 'https://docs.namiml.com')
      return `${docsLink}how-to/import-product-menus`;
    return 'https://docs.namiml.com/no-code-paywalls/paywall-creator/adding-products#importing-product-menus';
  }

  function reset() {
    setError(undefined);
    setWarnings([]);
    setValues(undefined);
    setIntermediateValues([]);
    setFile(null);
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    setFile(buildFile(file));
  }

  function buildFile(file: string | Blob | RcFile): UploadFile {
    const url = typeof file === 'string' ? file : URL.createObjectURL(file);
    const name =
      typeof file === 'object'
        ? (file as RcFile).name || 'Product Menus.csv'
        : url.split('/').reverse()[0];
    return { uid: '-1', name, status: 'done', url };
  }

  function getColumns(): ColumnsType<IntermediateMenuData> {
    return [
      {
        title: <Typography.Text strong>Product Group</Typography.Text>,
        render: (menu: IntermediateMenuData) => {
          return (
            <>{`${productGroupIdMap[menu.product_group_id].display_name} (${
              productGroupIdMap[menu.product_group_id].ref
            })`}</>
          );
        },
        width: 200,
      },
      {
        title: <Typography.Text strong>Platform</Typography.Text>,
        render: (menu: IntermediateMenuData) => {
          return (
            <Space direction="horizontal">
              <Image
                height={12}
                width={12}
                preview={false}
                src={platformLogo(platformIdMap[menu.platform_id].type)}
              />
              {platformIdMap[menu.platform_id].name}
            </Space>
          );
        },
        width: 300,
      },
      {
        title: <Typography.Text strong>Products</Typography.Text>,
        render: (menu: IntermediateMenuData) => {
          return (
            <ul>
              {menu.selectable_items.map((item) => (
                <li key={item.id}>{item.sku_ref_id}</li>
              ))}
            </ul>
          );
        },
      },
    ] as ColumnsType<IntermediateMenuData>;
  }
}
