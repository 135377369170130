import React from 'react';

import { DeleteOutline } from '@mui/icons-material';
import { namiMediumGray } from 'src/variables';

import DisabledBadgeDot from '../StatusDots/DisabledBadgeDot';
import LiveBadgeDot from '../StatusDots/LiveBadgeDot';

export const placementCampaignStatusFilterOptions = [
  {
    label: <>Live {<LiveBadgeDot />}</>,
    text: 'Live',
    value: 'enabled',
  },
  {
    label: <>Paused {<DisabledBadgeDot />}</>,
    text: 'Paused',
    value: 'paused',
  },
  {
    label: (
      <>
        Archived{' '}
        {
          <DeleteOutline
            style={{
              fontSize: 15,
              color: `${namiMediumGray}`,
              marginLeft: 2,
            }}
          />
        }
      </>
    ),
    text: 'Archived',
    value: 'archived',
  },
];

export const paywallStatusFilerOptions = [
  {
    label: <>Live {<LiveBadgeDot />}</>,
    text: 'Live',
    value: 'enabled',
  },
  {
    label: (
      <>
        Archived{' '}
        {
          <DeleteOutline
            style={{
              fontSize: 15,
              color: `${namiMediumGray}`,
              marginLeft: 2,
            }}
          />
        }
      </>
    ),
    text: 'Archived',
    value: 'archived',
  },
];

export const productStatusFilterOptions = [
  {
    label: <>Available {<LiveBadgeDot />}</>,
    text: 'Available',
    value: 'enabled',
  },
  {
    label: (
      <>
        Archived{' '}
        {
          <DeleteOutline
            style={{
              fontSize: 15,
              color: `${namiMediumGray}`,
              marginLeft: 2,
            }}
          />
        }
      </>
    ),
    text: 'Archived',
    value: 'archived',
  },
];
