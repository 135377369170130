import React, { useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Modal, Row, Switch } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';

type EditCarouselFieldModalProps = {
  variableValue: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (nameValue: string, defaultValue?: string) => void;
};

type TCarouselFieldPayload = {
  name: string;
  defaultValue: string;
};

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.carousels'] || {},
  ],
  (carousels) => carousels
);

export default function EditCarouselFieldModal({
  variableValue,
  isOpen,
  onClose,
  onSubmit,
}: EditCarouselFieldModalProps) {
  const carousels = useAppSelector(selector);
  const [form] = Form.useForm<TCarouselFieldPayload>();
  const field = useMemo(() => {
    return carousels['carouselName'].fields.find(
      (value) =>
        value.variable === `var.${variableValue}` ||
        value.variable === `media.${variableValue}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carousels]);
  const defaultValue = useMemo(() => {
    const raw = carousels['carouselName'].newSlide[variableValue];
    if (field?.type === 'splitTextArea' && typeof raw === 'object') {
      return raw.join('\n');
    }
    return raw;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carousels]);

  return (
    <Modal
      title="Edit Carousel Field"
      open={isOpen}
      footer={null}
      centered
      onCancel={onClose}
      zIndex={1005}
      forceRender
      afterClose={() => {
        form.resetFields();
      }}
    >
      <Form
        layout="vertical"
        form={form}
        requiredMark={false}
        initialValues={{ name: field?.label, defaultValue: defaultValue }}
      >
        <>
          <Row gutter={16}>
            <Col xs={24}>
              <FormItem
                label="Field Name"
                required
                tooltip="Give this carousel field a useful name such as 'Slide Subtext' or 'Trial User Title Text'"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Provide a value',
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormItem>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24}>
              {field?.type === 'text' && (
                <FormItem
                  label="Default Value"
                  required
                  tooltip="The default value will be used for all new slides created."
                  name="defaultValue"
                >
                  <Input maxLength={100} />
                </FormItem>
              )}
              {field?.type === 'textArea' && (
                <FormItem
                  label="Default Value"
                  required
                  tooltip="The default value will be used for all new slides created."
                  name="defaultValue"
                >
                  <Input.TextArea rows={3} />
                </FormItem>
              )}
              {field?.type === 'splitTextArea' && (
                <FormItem
                  label="Default Value"
                  required
                  tooltip="The default value will be used for all new slides created."
                  name="defaultValue"
                >
                  <Input.TextArea rows={3} />
                </FormItem>
              )}
              {field?.type === 'toggle' && (
                <FormItem
                  label="Default Value"
                  required
                  tooltip="The default value will be used for all new slides created."
                  name="defaultValue"
                >
                  <Switch defaultChecked={defaultValue} />
                </FormItem>
              )}
            </Col>
          </Row>
          <Button
            htmlType="submit"
            type="primary"
            disabled={form.getFieldsError().some(({ errors }) => errors.length)}
            onClick={() => {
              form
                .validateFields()
                .then(() =>
                  onSubmit(
                    form.getFieldValue('name'),
                    form.getFieldValue('defaultValue')
                  )
                );
            }}
          >
            Edit
          </Button>
        </>
      </Form>
    </Modal>
  );
}
