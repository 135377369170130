import { Typography } from 'antd';
import { namiPrimaryBlue } from 'src/variables';
import styled, { css } from 'styled-components';

const MonospaceText = styled(Typography.Text)<{ link?: string }>`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
  ${({ link }) => {
    if (link)
      return css`
        :hover {
          text-decoration: underline;
          color: ${namiPrimaryBlue};
        }
      `;
    return css``;
  }}
`;

export default MonospaceText;
