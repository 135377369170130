import React, { useEffect, useMemo, useState } from 'react';

import { CloseOutlined, LockOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteOutline, RestoreFromTrashOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, notification, Row, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'src/api';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import SearchInput from 'src/components/SearchInput';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import OffBadgeDot from 'src/components/StatusDots/OffBadgeDot';
import { placementCampaignStatusFilterOptions } from 'src/components/StatusFilter/statusFilterOptions';
import { InfinitePlacementsWebPaywall } from 'src/components/WebPaywalls/InfinitePlacementsWebPaywall';
import { MultiplePlacementsWebPaywall } from 'src/components/WebPaywalls/MultiplePlacementsWebPaywall';
import { useAppContext, useBooleanState, useQueryParams } from 'src/hooks';
import { useApiOptions } from 'src/hooks/queries/apiOptions.hooks';
import { useCampaignLabelsQuery } from 'src/hooks/queries/campaign.hooks';
import QueryKeys from 'src/hooks/queries/queryKeys';
import { namiLightGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import StatusFilter from '../../../components/StatusFilter/StatusFilter';
import AddPlacementModal from './AddPlacementModal';
import { getEnabledStatus } from './CampaignsWrapper';
import ExportPlacementsButton from './ExportPlacementsButton';
import PlacementsTable from './PlacementsTable';
import TagFilter from './PlacementTagFilter';

export const ToolbarRow = styled(Row)`
  background: ${namiPureWhite};
  border-bottom: 1px solid ${namiLightGray};
  padding: 12px;
`;

export default function PlacementsWrapper() {
  const { userHasEntitlement, planHasEntitlement } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const queryParams = useQueryParams();
  const [status, setStatus] = useState<string | null>(
    queryParams.get('status') === '' ? null : queryParams.get('status')
  );
  const [tag, setTag] = useState<string | null>(
    queryParams.get('tag') === '' ? null : queryParams.get('tag')
  );

  const [apiOptions, updateApiOptions] = useApiOptions({
    pageSize: 20,
    enabled: getEnabledStatus(status),
    archived: getArchivedStatus(),
    tag: tag || '',
  });
  const labelsQuery = useCampaignLabelsQuery({
    ...apiOptions,
  });
  const [isLabelModalOpen, openLabelModal, closeLabelModal] =
    useBooleanState(false);
  const [isMultipleUpgradeOpen, openMultipleUpgrade, closeMultipleUpgrade] =
    useBooleanState(false);
  const [isInfiniteUpgradeOpen, openInfiniteUpgrade, closeInfiniteUpgrade] =
    useBooleanState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      enabled: getEnabledStatus(status),
      archived: getArchivedStatus(),
      tag: tag || '',
    });
    queryParams.set('status', status || '');
    queryParams.set('tag', tag || '');
    location.search = queryParams.toString();
    history.push(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, tag]);

  const placementTagsUnlocked = planHasEntitlement(
    'app.placement.create_infinite'
  );

  const canCreateMorePlacements: boolean = useMemo(() => {
    if (planHasEntitlement('app.placement.create_infinite')) return true;
    const currentCount = labelsQuery.data?.count || 0;
    if (planHasEntitlement('app.placement.create_three') && currentCount < 3)
      return true;
    return currentCount < 1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelsQuery.data]);

  return (
    <>
      <ToolbarRow gutter={[8, 8]} align="middle">
        {hasSelected ? (
          <>
            <Col xs={24} md={12}>
              <IconActionButton
                type="text"
                size="small"
                icon={<CloseOutlined style={{ fontSize: '13px' }} />}
                onClick={() => setSelectedRowKeys([])}
              >
                {`${selectedRowKeys.length} selected`}
              </IconActionButton>
            </Col>
            <Col xs={24} md={12} style={{ textAlign: 'right' }}>
              <Space>
                {(!status || status === 'paused') && (
                  <Button
                    onClick={enableSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                  >
                    <Space direction="horizontal">
                      <LiveBadgeDot />
                      Set Live
                    </Space>
                  </Button>
                )}
                {(!status || status === 'enabled') && (
                  <Button
                    onClick={pauseSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                  >
                    <Space direction="horizontal">
                      <OffBadgeDot />
                      Pause
                    </Space>
                  </Button>
                )}
                {(!status || status !== 'archived') && (
                  <Button
                    onClick={archiveSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                    icon={
                      <DeleteOutline
                        style={{
                          fontSize: 15,
                          marginRight: 3,
                        }}
                      />
                    }
                  >
                    Archive
                  </Button>
                )}
                {status === 'archived' && (
                  <Button
                    onClick={unarchiveSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                    icon={
                      <RestoreFromTrashOutlined
                        style={{
                          fontSize: 15,
                          marginRight: 3,
                        }}
                      />
                    }
                  >
                    Restore
                  </Button>
                )}
              </Space>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} md={6}>
              <SearchInput
                placeholder="Search placement"
                value={apiOptions.search || ''}
                onChange={(search) => {
                  updateApiOptions({ search });
                }}
                intercomClassName="intercom-placementsSearch"
              />
            </Col>
            <Col xs={12} md={6}>
              <StatusFilter
                status={status}
                onChange={setStatus}
                onClear={() => setStatus(null)}
                includeArchived={true}
                options={placementCampaignStatusFilterOptions}
                intercomClassName="intercom-placementStatusFilter"
              />
            </Col>
            {placementTagsUnlocked && (
              <Col xs={12} md={6}>
                <TagFilter
                  selectedTag={tag}
                  onChange={setTag}
                  onClear={() => setTag(null)}
                />
              </Col>
            )}
            <Col xs={24} md={placementTagsUnlocked ? 6 : 12}>
              <Space
                direction="horizontal"
                size="small"
                style={{ float: 'right' }}
              >
                {getExportButton()}
                {getAddButton()}
              </Space>
            </Col>
          </>
        )}
      </ToolbarRow>
      <PlacementsTable
        apiOptions={apiOptions}
        onApiOptionsChange={updateApiOptions}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={onSelectChange}
      />
      <AddPlacementModal isOpen={isLabelModalOpen} onClose={closeLabelModal} />
      <MultiplePlacementsWebPaywall
        visible={isMultipleUpgradeOpen}
        onCancel={closeMultipleUpgrade}
      />
      <InfinitePlacementsWebPaywall
        visible={isInfiniteUpgradeOpen}
        onCancel={closeInfiniteUpgrade}
      />
    </>
  );

  function getExportButton() {
    if (planHasEntitlement('app.placement.create_infinite')) {
      if (planHasEntitlement('app.placement.bulk_update')) {
        return <ExportPlacementsButton />;
      } else {
        return (
          <Button
            icon={<LockOutlined />}
            style={{ float: 'right', cursor: 'pointer' }}
            className="intercom-gatedExportPlacements"
            onClick={openInfiniteUpgrade}
            type="text"
          >
            Export
          </Button>
        );
      }
    }
    return undefined;
  }

  function getAddButton() {
    if (canCreateMorePlacements) {
      return (
        <Button
          type="primary"
          onClick={openLabelModal}
          icon={<PlusOutlined />}
          disabled={!userHasEntitlement('app.campaign.create')}
          className="intercom-addPlacement"
        >
          Add
        </Button>
      );
    } else if (planHasEntitlement('app.placement.create_three')) {
      return (
        <Button
          onClick={openInfiniteUpgrade}
          icon={<LockOutlined />}
          className="intercom-addPlacement"
        >
          Add
        </Button>
      );
    } else {
      return (
        <Button
          onClick={openMultipleUpgrade}
          icon={<LockOutlined />}
          className="intercom-addPlacement"
        >
          Add
        </Button>
      );
    }
  }

  function getArchivedStatus(): boolean {
    if (status === 'archived') return true;
    return false;
  }

  function pauseSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignLabel(rowKey.toString(), { enabled: false })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} placement${
            selectedRowKeys.length > 1 ? 's' : ''
          } paused.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't pause placements",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignLabels]);
        setSelectedRowKeys([]);
      });
  }

  function enableSelection() {
    let promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignLabel(rowKey.toString(), { enabled: true })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} placement${
            selectedRowKeys.length > 1 ? 's' : ''
          } set live.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't set placements live",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignLabels]);
        setSelectedRowKeys([]);
      });
  }

  function unarchiveSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignLabel(rowKey.toString(), { archived: false })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} placement${
            selectedRowKeys.length > 1 ? 's' : ''
          } restored.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't restore placements",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignLabels]);
        setSelectedRowKeys([]);
      });
  }

  function archiveSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignLabel(rowKey.toString(), { archived: true })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} placement${
            selectedRowKeys.length > 1 ? 's' : ''
          } archived.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't archive placements",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignLabels]);
        setSelectedRowKeys([]);
      });
  }
}
