import React from 'react';

import { CloseCircleFilled } from '@ant-design/icons';
import { Input, Select } from 'antd';
import { toReadable } from 'src/utils/string';
import { namiLightGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import { TIDType } from '../DeveloperPageWrapper';

type IDSearchProps = {
  currentId: string | null;
  idType: TIDType;
  setId: (idType: TIDType, value: string | null) => void;
  setIdType: (idType: TIDType) => void;
  clearId: () => void;
};

const StyledInput = styled(Input)`
  .ant-input {
    width: 220px !important;
  }
  .ant-input-group-addon {
    background-color: ${namiPureWhite} !important;
    text-align: left;
    font-weight: 500;

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
      font-weight: 500 !important;
    }
  }

  .ant-input-group-addon:last-child {
    padding: 0px 4px !important;
  }
`;
export default function DeveloperFeedIdSearchInput({
  currentId,
  idType,
  setId,
  setIdType,
  clearId,
}: IDSearchProps) {
  const idTypeOptions = [
    {
      key: 'external_id',
      value: 'external_id',
      label: 'External ID',
    },
    {
      key: 'device_id',
      value: 'device_id',
      label: 'Device ID',
    },
    {
      key: 'user_id',
      value: 'user_id',
      label: 'User ID',
    },
    {
      key: 'cdp_id',
      value: 'cdp_id',
      label: 'CDP ID',
    },
  ];

  const InputBefore = (
    <Select
      style={{ width: 120 }}
      options={idTypeOptions}
      value={idType}
      onChange={(value) => {
        setIdType(value);
        setId(value, currentId);
      }}
    />
  );
  const ClearButton = (
    <CloseCircleFilled style={{ color: namiLightGray }} onClick={clearId} />
  );
  return (
    <StyledInput
      value={currentId || undefined}
      placeholder={`Enter ${toReadable(idType)}, case-sensitive`}
      onChange={(e) => setId(idType, e.target.value)}
      allowClear={false}
      addonBefore={InputBefore}
      suffix={ClearButton}
    />
  );
}
