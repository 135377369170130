import React, { useEffect, useState } from 'react';

import { Button, Divider, notification } from 'antd';
import {
  eventTypeGroups,
  prettyEventTypeNames,
  TEventLogEventType,
} from 'src/api/types/developer.types';

import StatusSelect from '../../campaigns/StatusSelect';

type EventTypeFilterProps = {
  eventTypes: TEventLogEventType[] | null;
  onChange: (value: string[] | null) => void;
};

export default function EventTypeFilter({
  eventTypes,
  onChange,
}: EventTypeFilterProps) {
  const allAvailableTypesCount = Object.keys(prettyEventTypeNames).length;
  const [values, setValues] = useState<string[]>(eventTypes || []);
  const [isSelectOpen, setSelectOpen] = useState<boolean>(false);
  const options = Object.entries(eventTypeGroups).reduce(
    (output, [group, types]) => {
      return [
        ...output,
        {
          label: group,
          options: types.reduce((output, type) => {
            return [
              ...output,
              {
                label: prettyEventTypeNames[type as TEventLogEventType],
                key: type,
                value: type,
              },
            ];
          }, [] as { label: string; key: TEventLogEventType; value: TEventLogEventType }[]),
        },
      ];
    },
    [] as {
      label: string;
      options: {
        label: string;
        key: TEventLogEventType;
        value: TEventLogEventType;
      }[];
    }[]
  );

  useEffect(() => {
    if (eventTypes && eventTypes?.length > 0) setValues(eventTypes);
  }, [eventTypes]);

  useEffect(() => {
    if (!isSelectOpen && values.length === 0) {
      setValues(['device.impression']);
      onChange(['device.impression']);
      notification.warning({
        message: 'At least 1 event type must be selected',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, isSelectOpen]);

  return (
    <StatusSelect
      as={StatusSelect}
      mode="multiple"
      maxTagCount={values.length === allAvailableTypesCount ? 0 : 2}
      optionFilterProp="text"
      options={options}
      value={values || []}
      open={isSelectOpen}
      onChange={(value: string[]) => setValues(value)}
      onDropdownVisibleChange={(visible: boolean) => setSelectOpen(visible)}
      label={values && values.length ? 'Types:' : ''}
      className="intercom-eventsFeedTypeFilter"
      placeholder={
        <>
          Filter by <b>Event Type</b>
        </>
      }
      maxTagPlaceholder={(omittedValues: any[]) => {
        if (omittedValues.length === allAvailableTypesCount) return 'All';
        return `+${omittedValues.length} more`;
      }}
      dropdownMatchSelectWidth={false}
      dropdownStyle={{ minWidth: 275 }}
      dropdownRender={(menu: React.ReactElement) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Button
            type="primary"
            size="small"
            disabled={values.length === 0}
            onClick={() => {
              onChange(values);
              setSelectOpen(false);
            }}
            style={{ float: 'right', margin: '0px 8px' }}
          >
            OK
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => setValues([])}
            disabled={values.length === 0}
            style={{ float: 'right' }}
          >
            Clear all
          </Button>
        </>
      )}
    />
  );
}
