import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Form, Select, Tag, Tooltip } from 'antd';
import { namiPureWhite } from 'src/variables';

type TagEditorProps = {
  tagItems: { label: string; value: string }[];
  setTags: (tags: string[]) => void;
  tags: string[];
  disabled?: boolean;
  intercomClassName: string;
};

export default function TagEditor({
  tagItems,
  tags,
  setTags,
  disabled,
  intercomClassName,
}: TagEditorProps) {
  const [inputVisible, setInputVisible] = useState(false);
  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  return (
    <Form.Item label="Tags" className={intercomClassName}>
      {tags.map((tag) => {
        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            style={{ userSelect: 'none' }}
            key={tag}
            closable={!disabled}
            onClose={() => handleClose(tag)}
          >
            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Select
          style={{ width: 180 }}
          options={tagItems}
          size="small"
          mode="tags"
          placeholder="Type to find or add tag"
          defaultOpen={true}
          autoFocus={true}
          onSelect={(value) => {
            if (tags.indexOf(value) === -1) setTags([...tags, value]);
            setInputVisible(false);
          }}
          onBlur={() => setInputVisible(false)}
        />
      )}
      {!inputVisible && !disabled && (
        <Tag
          style={{
            background: namiPureWhite,
            borderStyle: 'dashed',
            cursor: 'pointer',
          }}
          onClick={() => setInputVisible(true)}
        >
          <PlusOutlined /> New Tag
        </Tag>
      )}
    </Form.Item>
  );
}
