import React from 'react';

import { Table } from 'antd';
import { ColumnType } from 'antd/es/table';
import { DataKey, DataKeys } from 'src/api/types/analytics.types';
import styled from 'styled-components';

//TODO - probably a better place to put these styles
const StyledTable = styled(Table)`
  th.ant-table-cell:not(:first-child) {
    min-width: fit-content;
    white-space: nowrap;
  }

  td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    font-size: 0.9em;
    line-height: 1.25;
  }

  tr.namiStyle-1374DE {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #1374de;
      a {
        color: #1374de !important;
      }
    }
  }

  tr.namiStyle-67D6E0 {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #67d6e0;
      a {
        color: #67d6e0 !important;
      }
    }
  }

  tr.namiStyle-FF5B74 {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #ff5b74;
      a {
        color: #ff5b74 !important;
      }
    }
  }

  tr.namiStyle-FFC669 {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #ffc669;
      a {
        color: #ffc669 !important;
      }
    }
  }

  tr.namiStyle-66CC99 {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #66cc99;
      a {
        color: #66cc99 !important;
      }
    }
  }

  tr.namiStyle-6B6B6B {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #6b6b6b;
      a {
        color: #6b6b6b !important;
      }
    }
  }

  tr.namiStyle-009FDE {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #009fde;
      a {
        color: #009fde !important;
      }
    }
  }

  tr.namiStyle-EB7546 {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #eb7546;
      a {
        color: #eb7546 !important;
      }
    }
  }

  tr.namiStyle-021F3E {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #021f3e;
      a {
        color: #021f3e !important;
      }
    }
  }

  tr.namiStyle-9574EA {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #9574ea;
      a {
        color: #9574ea !important;
      }
    }
  }

  tr.namiStyle-73D13D {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #73d13d;
      a {
        color: #73d13d !important;
      }
    }
  }

  tr.namiStyle-F262D3 {
    td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
      color: #f262d3;
      a {
        color: #f262d3 !important;
      }
    }
  }
`;

type SegmentedInsightsTableProps = {
  loading: boolean;
  dataSource: { key: string; label: any; name: string }[];
  columns: ColumnType<object>[];
  nestedDataSource: { key: string; label: any; name: string }[] | null;
};

export default function SegmentedInsightsTable({
  loading,
  dataSource,
  columns,
  nestedDataSource,
}: SegmentedInsightsTableProps) {
  const expandedRowRender = (record: {
    key: string;
    label: any;
    name: string;
  }) => {
    const keyParts = record.key.split(',');
    const key = keyParts.length > 1 ? keyParts[0] : undefined;
    const filteredNestedData = (nestedDataSource || []).reduce(
      (output, row) => {
        const rowKeyParts = row.key.split(',');
        const rowKey = rowKeyParts.length > 1 ? rowKeyParts[0] : undefined;
        const rowMetric =
          rowKeyParts.length > 1 ? rowKeyParts[1] : rowKeyParts[0];
        const rowLabel =
          DataKeys[rowMetric as DataKey].display_name || 'Unknown';

        if (rowKey === key) {
          return [
            ...output,
            {
              ...row,
              key: row.key,
              label: `${rowLabel}`,
              name: `${rowLabel}`,
            },
          ];
        }
        return output;
      },
      [] as { key: string; label: any; name: string }[]
    );
    if (filteredNestedData) {
      return (
        <>
          <Table
            columns={columns}
            dataSource={filteredNestedData}
            pagination={false}
            bordered
            loading={loading}
            scroll={{ x: true }}
            className="horizontalScrollTable"
          />
        </>
      );
    }
    return null;
  };
  return (
    <StyledTable
      loading={loading}
      dataSource={dataSource}
      columns={columns}
      pagination={false}
      scroll={{ x: true }}
      rowClassName={getClassNameForRow}
      bordered
      expandable={{
        expandedRowRender: (record) =>
          expandedRowRender(
            record as { key: string; label: any; name: string }
          ),
        rowExpandable: () => !!nestedDataSource && nestedDataSource.length > 1,
        showExpandColumn: !!nestedDataSource && nestedDataSource.length > 1,
      }}
      className="horizontalScrollTable"
    />
  );
}

function getClassNameForRow(_record: object, index: number): string {
  const colors = [
    '1374DE',
    '66CC99',
    'FFC669',
    'FF5B74',
    '9574EA',
    '67D6E0',
    '021F3E',
    '73D13D',
    'F262D3',
    'EB7546',
  ];
  return 'namiStyle-' + colors[index % colors.length];
}
