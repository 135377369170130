import React from 'react';

import { Rotate90DegreesCwOutlined } from '@mui/icons-material';
import { Button, Col, Row, Tooltip } from 'antd';

import { formatInputValues } from './formatters';
import { StyledInputNumber } from './GradientStops';
import { DegreesIcon } from './icon';
import { usePicker } from './PickerContextWrapper';

const GradientControls = () => {
  const picker = usePicker();

  if (!picker) return null;
  return <DegreePicker />;
};

export default GradientControls;

const DegreePicker = () => {
  const picker = usePicker();

  if (!picker) return null;
  const { degrees, onChange, value } = picker;

  const handleDegrees = (e: number) => {
    let newValue = formatInputValues(e, 0, 360);
    const remaining = value.split(/,(.+)/)[1];
    onChange(`linear-gradient(${newValue || 0}deg, ${remaining}`);
  };

  const rotateDegrees = () => {
    const newValue = (degrees + 180) % 360;
    handleDegrees(newValue);
  };

  return (
    <Row gutter={[4, 0]} style={{ marginTop: 4 }} align="middle">
      <Col flex={'20px'} style={{ alignSelf: 'end' }}>
        <DegreesIcon />
      </Col>
      <Col flex={'46px'}>
        <Tooltip title="Gradient angle">
          <StyledInputNumber
            value={degrees}
            onChange={(e) => handleDegrees((e as number) || 0)}
            size="small"
            min={0}
            max={360}
            addonAfter={'°'}
            controls={false}
          />
        </Tooltip>
      </Col>
      <Col flex="auto" style={{ alignItems: 'right', textAlign: 'right' }}>
        <Tooltip title="Rotate gradient">
          <Button
            size="small"
            type="text"
            icon={<Rotate90DegreesCwOutlined style={{ fontSize: 15 }} />}
            onClick={() => rotateDegrees()}
          />
        </Tooltip>
      </Col>
    </Row>
  );
};
