import React from 'react';

import { FileDownloadOutlined } from '@mui/icons-material';
import { Button } from 'antd';
import moment from 'moment';
import { TCampaignLabel } from 'src/api/types/campaign.types';
import { useAppContext } from 'src/hooks';
import { useCampaignLabelsQuery } from 'src/hooks/queries/campaign.hooks';

export default function ExportPlacementsButton() {
  const labelsQuery = useCampaignLabelsQuery({ pageSize: 1000 });
  const { userHasEntitlement } = useAppContext();

  return (
    <Button
      icon={
        <FileDownloadOutlined style={{ fontSize: 16 }} className="inButton" />
      }
      disabled={!userHasEntitlement('app.campaign.get')}
      style={{ float: 'right', cursor: 'pointer' }}
      className="intercom-exportPlacements"
      onClick={() => downloadCsv(exportPlacements(), getFilename())}
      loading={labelsQuery.isLoading}
      type="text"
    >
      Export
    </Button>
  );

  function getFilename(): string {
    const now = moment();
    return `nami-placements_export_${now.format('YYYY-MM-DD')}`;
  }

  function exportPlacements(): string {
    const data: TCampaignLabel[] = labelsQuery.data?.results || [];
    const csvArray: Array<string | number>[] = [];
    csvArray.push([
      'ID',
      'Value',
      'Display Name',
      'Description',
      'Type',
      'Enabled',
      'Archived',
      'Created Date UTC',
      'Updated Date UTC',
      '# Campaign Rules',
      '# Live Campaign Rules',
      'Tags',
    ]);

    data.forEach((label) => {
      csvArray.push([
        label.id,
        label.value,
        `"${label.display_name || ''}"`,
        `"${label.description || ''}"`,
        label.type,
        (label.enabled === undefined ? true : label.enabled).toString(),
        (label.archived === undefined ? false : label.archived).toString(),
        label.created_date || '',
        label.updated_date || '',
        (label.rules || []).length,
        (label.rules || []).filter((rule) => rule.enabled).length,
        (label.tags || []).join(','),
      ]);
    });

    return csvArray.map((row) => row.join()).join('\n') + '\n';
  }

  function downloadCsv(content: string, filename: string): void {
    const blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (typeof link.download === 'undefined') return;
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
