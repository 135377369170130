import { useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { TApiOptions } from 'src/api/types/main.types';

import api from '../../api';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function useExternalUsersQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery(
    [QueryKeys.customers, appId, ...spreadApiOptions(apiOptions)],
    () => api.getExternalUsers(appId, apiOptions),
    { keepPreviousData: true }
  );
}

export function useExternalUserQuery(userId: string) {
  const history = useHistory();
  return useQuery([QueryKeys.customers, userId], () =>
    api.getExternalUser(userId).catch(() => {
      notification.error({
        message: "User can't be found",
      });
      history.push('/customers/');
      return null;
    })
  );
}

export function useExternalUserDevicesQuery(
  userId: string,
  apiOptions?: TApiOptions
) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  return useQuery(
    [QueryKeys.devices, userId, ...spreadApiOptions(apiOptions)],
    () => api.getDevicesForExternalUser(userId, apiOptions),
    { keepPreviousData: true }
  );
}

export function useDeviceQuery(deviceId: string) {
  const history = useHistory();
  return useQuery([QueryKeys.devices, deviceId], () =>
    api.getDevice(deviceId).catch(() => {
      notification.error({
        message: "Device can't be found",
      });
      history.push('/customers/');
      return null;
    })
  );
}

export function useExternalUserPurchasesQuery(userId: string) {
  return useQuery([QueryKeys.customerPurchases, userId], () =>
    api.getPurchasesForExternalUser(userId)
  );
}

export function usePurchaseQuery(purchaseId: string) {
  const history = useHistory();
  return useQuery([QueryKeys.customerPurchases, purchaseId], () =>
    api.getPurchase(purchaseId).catch(() => {
      notification.error({
        message: "Purchase can't be found",
      });
      history.push('/customers/');
      return null;
    })
  );
}

export function usePurchaseEventsForPurchaseQuery(purchaseId: string) {
  return useQuery([QueryKeys.purchaseEvents, purchaseId], () =>
    api.getPurchaseEvents(purchaseId)
  );
}

export function usePurchaseEventQuery(eventId: string) {
  const history = useHistory();
  return useQuery([QueryKeys.purchaseEvents, eventId], () =>
    api.getPurchaseEvent(eventId).catch(() => {
      notification.error({
        message: "Purchase Event can't be found",
      });
      history.push('/customers/');
      return null;
    })
  );
}
