import { client } from './clients';
import {
  TSDKV3EligibleDeviceCampaignRule,
  TSDKV3EligibleDevicePaywall,
} from './types/customer.types';
import { TEventLogItem } from './types/developer.types';
import { TApiOptions, TListResult } from './types/main.types';
import { buildURLParams, extractErrorData, extractResponseData } from './utils';

const developer = {
  getEventLog: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TEventLogItem>> => {
    const params = buildURLParams({
      pageSize: 50,
      ...apiOptions,
      app__id: appId,
    });
    return client
      .get(`/api/v2/event_log/?${params}&order_by=-created_date`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getEventsForDevice: (
    appId: string,
    deviceId: string
  ): Promise<TListResult<TEventLogItem>> => {
    const params = buildURLParams({
      pageSize: 10,
      app__id: appId,
      device__id: deviceId,
    });
    return client
      .get(`/api/v2/event_log/?${params}&order_by=-created_date`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getOutboundEventsForPurchaseEvent: (
    appId: string,
    purchaseEventId: string
  ): Promise<TListResult<TEventLogItem>> => {
    const params = buildURLParams({
      pageSize: 20,
      app__id: appId,
      source_id: purchaseEventId,
    });
    return client
      .get(`/api/v2/webhook/?${params}&order_by=-created_date`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getEventLogItem: (eventId: string): Promise<TEventLogItem> => {
    return client
      .get(`/api/v2/webhook/${eventId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  redeliverPurchaseEventNotifications: (eventId: string): Promise<void> => {
    return client
      .post(`/api/v2/purchase_events/${eventId}/redeliver_push/`)
      .catch(extractErrorData) as unknown as Promise<void>;
  },
  getCampaignRulesForDevice: (
    platformId: string,
    deviceId: string
  ): Promise<TListResult<TSDKV3EligibleDeviceCampaignRule>> => {
    return client
      .get(`/sdk/v3/platform/${platformId}/device/${deviceId}/campaign_rules/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPaywallsForDevice: (
    platformId: string,
    deviceId: string
  ): Promise<TListResult<TSDKV3EligibleDevicePaywall>> => {
    return client
      .get(`/sdk/v3/platform/${platformId}/device/${deviceId}/paywalls/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default developer;
