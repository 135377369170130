import React, { useMemo, useState } from 'react';

import { PlusOutlined, ReadOutlined } from '@ant-design/icons';
import { createSelector } from '@reduxjs/toolkit';
import { Button, List, Modal, Space } from 'antd';
import { TCarouselFieldType } from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { deconstructVariable } from 'src/utils/paywall';
import styled from 'styled-components';

import AddCarouselFieldModal from './AddCarouselFieldModal';
import EditCarouselFieldModal from './EditCarouselFieldModal';

const StyledList = styled(List)`
  padding: 0px 12px;
  width: 100%;
`;

const StyledListItem = styled(List.Item)`
  ul.ant-list-item-action {
    margin-left: 16px;
    li {
      padding: 0px 2px;
    }
  }
`;

type EditCarouselDataModalProps = {
  isOpen: boolean;
  onClose: () => void;
  // onSubmit: (label: string) => void;
  // onSubmitAdd: (label: string, type: TProductVariableType, value: any) => void;
};

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.carousels'] || {},
  ],
  (carousels) => carousels
);

export default function EditCarouselDataModal({
  isOpen,
  onClose,
}: EditCarouselDataModalProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const carousels = useAppSelector(selector);
  const [isAddFieldModalOpen, setAddFieldModalOpen] = useState(false);
  const [isEditFieldModalOpen, setEditFieldModalOpen] = useState(false);
  const [editingFieldVariable, setEditingFieldVariable] = useState<
    string | null
  >(null);

  const carouselFieldMeta = useMemo(() => {
    return (carousels['carouselName'].fields || []).reduce((output, field) => {
      const variable = deconstructVariable(field.variable, false, true);
      return {
        ...output,
        [variable]: {
          type: field.type,
          label: field.label,
          default: carousels['carouselName'].newSlide[variable],
        },
      };
    }, {} as { [key: string]: { type: string; label: string; default: string | string[] | boolean } });
  }, [carousels]);

  return (
    <>
      <Modal
        title="Edit Carousel Fields"
        open={isOpen}
        footer={null}
        centered
        closable={true}
        zIndex={1005}
        onCancel={onClose}
        forceRender
        bodyStyle={{ maxHeight: 600, overflowY: 'scroll' }}
      >
        <StyledList itemLayout="horizontal">
          {Object.keys(carouselFieldMeta).map((key) => {
            return (
              <StyledListItem
                key={key}
                actions={[
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 13 }}
                    onClick={() => {
                      setEditingFieldVariable(key);
                      setEditFieldModalOpen(true);
                    }}
                  >
                    Edit
                  </Button>,
                  <Button
                    type="link"
                    size="small"
                    style={{ fontSize: 13 }}
                    disabled={
                      key === 'carouselTitleText' ||
                      key === 'carouselSlideFillImage'
                    }
                    onClick={() => {
                      actions.removeCarouselField(key);
                      setEditingFieldVariable(null);
                    }}
                  >
                    Delete
                  </Button>,
                ]}
              >
                <Space direction="vertical" size={3}>
                  <div style={{ fontWeight: 500 }}>
                    {carouselFieldMeta[key].label}
                  </div>
                  {carouselFieldMeta[key].type !== 'image' &&
                    getDefaultFieldValuePreview(carouselFieldMeta[key].default)}
                </Space>
              </StyledListItem>
            );
          })}
        </StyledList>
        <Space direction="horizontal" style={{ marginLeft: 8, marginTop: 12 }}>
          <Button
            icon={<PlusOutlined />}
            size="small"
            onClick={() => setAddFieldModalOpen(true)}
          >
            Add Field
          </Button>
          <IconActionButton
            icon={<ReadOutlined style={{ fontSize: 13 }} />}
            type="text"
            target="_blank"
            href={`https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities/carousel`}
            size="small"
          >
            Learn
          </IconActionButton>
        </Space>
      </Modal>
      {editingFieldVariable && (
        <EditCarouselFieldModal
          isOpen={isEditFieldModalOpen}
          onClose={() => {
            setEditingFieldVariable(null);
            setEditFieldModalOpen(false);
          }}
          onSubmit={(nameValue: string, defaultValue?: any) => {
            editCarouselFieldLabel(nameValue);
            if (carouselFieldMeta[editingFieldVariable].type !== 'image')
              editCarouselFieldDefaultValue(defaultValue);
            setEditFieldModalOpen(false);
            setEditingFieldVariable(null);
          }}
          variableValue={editingFieldVariable}
        />
      )}
      <AddCarouselFieldModal
        isOpen={isAddFieldModalOpen}
        onClose={() => setAddFieldModalOpen(false)}
        onSubmit={(
          label: string,
          type: TCarouselFieldType,
          defaultValue: any
        ) => {
          addNewCarouselField(label, type, defaultValue);
        }}
      />
    </>
  );

  function addNewCarouselField(
    label: string,
    type: TCarouselFieldType,
    defaultValue: any
  ) {
    actions.addCarouselField({
      name: label,
      value: defaultValue,
      type: type,
    });
    setAddFieldModalOpen(false);
  }

  function editCarouselFieldLabel(value: string) {
    if (editingFieldVariable) {
      actions.editSlideFieldLabel({
        fieldVariable: editingFieldVariable,
        newLabel: value,
      });
    }
  }

  function editCarouselFieldDefaultValue(value: any) {
    if (editingFieldVariable) {
      if (carouselFieldMeta[editingFieldVariable].type === 'splitTextArea') {
        actions.editSlideFieldDefault({
          fieldVariable: editingFieldVariable,
          newValue: value.split('\n'),
        });
      } else {
        actions.editSlideFieldDefault({
          fieldVariable: editingFieldVariable,
          newValue: value,
        });
      }
    }
  }

  function getDefaultFieldValuePreview(
    defaultValue: string | Array<string> | boolean
  ): JSX.Element {
    if (typeof defaultValue === 'object') {
      return (
        <div>
          <ul>
            {defaultValue.map((value, index) => (
              <li key={index}>{value}</li>
            ))}
          </ul>
        </div>
      );
    }
    if (typeof defaultValue === 'boolean') {
      return <div>{defaultValue ? 'Yes' : 'No'}</div>;
    }
    return <div>{defaultValue}</div>;
  }
}
