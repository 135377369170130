import React, { useContext, useMemo } from 'react';

import { createSelector } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { TSemverObj } from 'src/utils/parsing';

import {
  TCarouselSlidesState,
  TComponent,
  TConditionalComponent,
} from '../../api/types/paywallTemplate.types';
import { interpolate } from '../../utils/interpolation';
import { Button } from './components/Button';
import CarouselContainer from './components/CarouselContainer';
import CheckboxContainer from './components/CheckboxContainer';
import CollapseContainer from './components/CollapseContainer';
import Container from './components/Container';
import Image from './components/Image';
import PlayPauseButton from './components/PlayPauseButton';
import ProductContainer from './components/ProductContainer';
import RadioButton from './components/RadioButton';
import RepeatingGrid from './components/RepeatingGrid';
import { SegmentPicker } from './components/SegmentPicker';
import { SegmentPickerItem } from './components/SegmentPickerItem';
import { Spacer } from './components/Spacer';
import Stack from './components/Stack';
import SvgImage from './components/SvgImage';
import { Symbol, Text, TextList } from './components/Texts';
import VideoContainer from './components/VideoContainer';
import VolumeControlButton from './components/VolumeControlButton';
import { ComponentContext, FeaturedContext } from './contexts';
import { conditionComponentMatches, mocks, withOverrides } from './utils';

type ComponentProps<T> = {
  component: T;
  inFocusedState?: boolean;
  groupId: string | null;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
};
type ComponentFC = React.FC<{
  component: any;
  inFocusedState?: boolean;
  groupId: string | null;
  slides?: TCarouselSlidesState;
  minSDKVersion: TSemverObj;
}>;
type ComponentsMapType = { [key: string]: ComponentFC };

const COMPONENTS_MAP: ComponentsMapType = {
  spacer: Spacer,
  symbol: Symbol,
  button: Button,
  text: Text,
  stack: Stack,
  'text-list': TextList,
  container: Container,
  productContainer: ProductContainer,
  carouselContainer: CarouselContainer,
  image: Image,
  videoUrl: VideoContainer,
  svgImage: SvgImage,
  segmentPicker: SegmentPicker,
  segmentPickerItem: SegmentPickerItem,
  checkbox: CheckboxContainer,
  collapseContainer: CollapseContainer,
  responsiveGrid: RepeatingGrid,
  playPauseButton: PlayPauseButton,
  volumeButton: VolumeControlButton,
  radio: RadioButton,
};

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  (paywallBuilder) => ({
    skuItems: paywallBuilder.skuItems,
    platformId: paywallBuilder.platformId,
    selectedGroup: paywallBuilder.groupId,
    anySkuHasTrialOffer: paywallBuilder.anySkuHasTrialOffer,
    anySkuHasIntroOffer: paywallBuilder.anySkuHasIntroOffer,
    anySkuHasPromoOffer: paywallBuilder.anySkuHasPromoOffer,
    hasSelectedFlag: paywallBuilder.hasSelectedFlag,
  })
);

export default function TemplateComponent({
  component,
  inFocusedState,
  groupId,
  slides,
  minSDKVersion,
}: ComponentProps<TComponent | TConditionalComponent>) {
  const featured = useContext(FeaturedContext);
  const upperContext = useContext(ComponentContext);

  const context = useMemo(() => {
    if (!component.flag && !component.context) return upperContext;
    const output = !!component.context
      ? component.context
      : { flag: component.flag };
    return { ...(upperContext || {}), ...output };
  }, [component.flag, component.context, upperContext]);

  const {
    skuItems,
    platformId,
    selectedGroup,
    anySkuHasTrialOffer,
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
    hasSelectedFlag,
  } = useAppSelector(selector);

  //TODO-duplicate work being done in Texts and ProductContainer
  const addtlSkuVariables = useMemo(() => {
    if (platformId === null || selectedGroup === null || !hasSelectedFlag)
      return {};
    const platformItems = Object.values(skuItems[selectedGroup][platformId]);
    const selectedProduct =
      platformItems.find((item) => item.selected) || platformItems[0];
    if (selectedProduct) {
      const entitlementMap = selectedProduct.entitlements.map(
        (entitlement) => entitlement.entitlement_ref_id
      );
      return {
        ...mocks,
        ...selectedProduct.variables,
        freeTrialEligible: anySkuHasTrialOffer,
        introEligible: anySkuHasIntroOffer,
        promoEligible: anySkuHasPromoOffer,
        id: selectedProduct.sku_id,
        entitlements: entitlementMap,
      };
    }
    return {};
  }, [
    platformId,
    selectedGroup,
    hasSelectedFlag,
    skuItems,
    anySkuHasTrialOffer,
    anySkuHasIntroOffer,
    anySkuHasPromoOffer,
  ]);

  if (component.hidden) return null;

  if (component.component === 'condition' || !!component.conditionAttributes) {
    component = interpolate(component, {
      sku: { ...addtlSkuVariables, featured },
      context,
    });
  }

  if (component.component === 'condition') {
    if (!conditionComponentMatches(component)) return null;
    const children = component.components?.map((child, i) => (
      <TemplateComponent
        key={i}
        component={child}
        inFocusedState={inFocusedState}
        groupId={groupId}
        minSDKVersion={minSDKVersion}
      />
    ));
    return <>{children}</>;
  }

  if (!(component?.component in COMPONENTS_MAP)) {
    Sentry.captureMessage(
      `"${component.component}" is unknown to the TemplateComponent.`
    );
    return null;
  }

  component = withOverrides(component);
  const Component = COMPONENTS_MAP[component?.component];
  let components = 'components' in component ? component.components : [];
  if ('interpolateComponents' in component) {
    components = component.interpolateComponents || [];
  }
  const children = components.map((child, i) => (
    <TemplateComponent
      key={i}
      component={child}
      inFocusedState={inFocusedState}
      groupId={groupId}
      slides={slides}
      minSDKVersion={minSDKVersion}
    />
  ));

  const output = (
    <Component
      component={component}
      inFocusedState={inFocusedState}
      groupId={groupId}
      slides={slides}
      minSDKVersion={minSDKVersion}
    >
      {children}
    </Component>
  );
  if (!context) return output;
  return (
    <ComponentContext.Provider value={context}>
      {output}
    </ComponentContext.Provider>
  );
}
