import React from 'react';

import { Card, Statistic, Tag, Typography } from 'antd';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import { namiMediumGray } from 'src/variables';
import styled from 'styled-components';

type SummaryCardProps = {
  loading: boolean;
  title: string;
  value: number | string | undefined;
  link: string;
};

const StyledTag = styled(Tag)`
  background-color: unset;
  border: unset;
  position: absolute;
  right: 0;
`;

const StyledLink = styled(Typography.Link)`
  &:hover {
    text-decoration: underline !important;
  }
`;

export default function SummaryCard({
  loading,
  title,
  value,
  link,
}: SummaryCardProps) {
  const titleLink = (
    <StyledLink href={link} style={{ color: namiMediumGray }}>
      {title}
    </StyledLink>
  );
  return (
    <Card>
      <StyledTag>
        <LiveBadgeDot />
      </StyledTag>
      <Statistic title={titleLink} value={value} loading={loading} />
    </Card>
  );
}
