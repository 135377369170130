import { useQuery } from '@tanstack/react-query';
import { TApiOptions } from 'src/api/types/main.types';

import api from '../../api';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function useEventLogQuery(apiOptions: TApiOptions, live: boolean) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery(
    [QueryKeys.eventsLog, appId, ...spreadApiOptions(apiOptions)],
    () => api.getEventLog(appId, apiOptions),
    {
      refetchInterval: live ? 10000 : false,
    }
  );
}

export function useEventLogForDeviceQuery(deviceId: string) {
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery([QueryKeys.eventsLog, appId, deviceId], () =>
    api.getEventsForDevice(appId, deviceId)
  );
}

export function useEventLogItemQuery(eventId: string) {
  return useQuery([QueryKeys.eventsLog, eventId], () =>
    api.getEventLogItem(eventId)
  );
}

export function useOutboundEventsForPurchaseEventQuery(
  purchaseEventId: string
) {
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery([QueryKeys.outboundEvents, appId, purchaseEventId], () =>
    api.getOutboundEventsForPurchaseEvent(appId, purchaseEventId)
  );
}

export function useEligibleCampaignRulesForDeviceQuery(
  platformId: string,
  deviceId: string
) {
  return useQuery(
    [QueryKeys.deviceCampaignRules, platformId, deviceId],
    () => api.getCampaignRulesForDevice(platformId, deviceId),
    { enabled: false } //TODO
  );
}

export function useEligiblePaywallsForDeviceQuery(
  platformId: string,
  deviceId: string
) {
  return useQuery([QueryKeys.devicePaywalls, platformId, deviceId], () =>
    api.getPaywallsForDevice(platformId, deviceId)
  );
}
