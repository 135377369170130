import React, { useMemo } from 'react';

import {
  EditOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { DashboardOutlined, PaidOutlined } from '@mui/icons-material';
import {
  Button,
  Col,
  Drawer,
  Dropdown,
  MenuProps,
  Modal,
  Popover,
  Row,
  Space,
  Typography,
} from 'antd';
import { useHistory } from 'react-router';
import { createSelector } from 'reselect';
import { ProductType } from 'src/api/types/main.types';
import { TTemplateWarning } from 'src/api/types/paywallTemplate.types';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import Responsive from 'src/components/Responsive/Responsive';
import PaywallCapabilityWebPaywall from 'src/components/WebPaywalls/PaywallCapabilityWebPaywall';
import { useAppContext, useBooleanState } from 'src/hooks';
import { capitalizeFirstCharacter } from 'src/utils/string';
import { namiBrightGreen, namiNavy, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import { useAppSelector } from '../../../../hooks/redux.hooks';
import { RootState } from '../../../../redux';
import { capabilityPlanMap } from './capabilities/CapabilitiesList';
import PaywallBuilderHeaderSDKVersion from './PaywallBuilderHeaderSDKVersion';
import PaywallBuilderWarnings from './PaywallBuilderWarnings';

type PaywallHeaderProps = {
  paywallName: string;
  onSave: () => void;
  isLoading: boolean;
  onOpenSettings: () => void;
  archived: boolean;
  version: 'formGroups' | 'v2Form';
  warnings: Set<TTemplateWarning>;
};

const AlignCenter = styled.div`
  text-align: center;
`;

const AlignRight = styled.div`
  text-align: right;
`;

const PaywallTitle = styled(Typography.Text)`
  color: white;
  font-weight: 500;
  font-size: 16px;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Dot = styled.div`
  border-radius: 50%;
  border: 1px solid ${namiPureWhite};
  background-color: ${namiBrightGreen};
  width: 8px;
  height: 8px;
`;

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  (paywallBuilder) => ({
    rules: paywallBuilder.paywall?.rules,
    capabilities: paywallBuilder.paywall?.template['ui.capabilities'],
    isUpdating: paywallBuilder.updating,
  })
);

export default function PaywallHeader({
  paywallName,
  isLoading,
  onSave,
  onOpenSettings,
  archived,
  version,
  warnings,
}: PaywallHeaderProps) {
  const history = useHistory();
  const { rules, capabilities, isUpdating } = useAppSelector(selector);
  const appContext = useAppContext();
  const [webPaywallIsOpen, openWebPaywall, closeWebPaywall] =
    useBooleanState(false);

  const gated = useMemo(() => {
    let result: boolean = false;
    (capabilities || []).forEach((capability) => {
      if (
        !appContext.planHasEntitlement(`app.paywall.capabilities.${capability}`)
      )
        result = true;
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [capabilities]);

  const isLive = useMemo(() => {
    return rules?.some((rule) => rule.enabled && rule.label_enabled);
  }, [rules]);

  const planRequired: ProductType = useMemo(() => {
    const allPlans = (capabilities || []).reduce((output, capability) => {
      return [...output, capabilityPlanMap[capability]];
    }, [] as ProductType[]);

    if (allPlans.includes('enterprise')) return 'enterprise';
    if (allPlans.includes('business')) return 'business';
    if (allPlans.includes('team')) return 'team';
    return 'starter';
  }, [capabilities]);

  const logoMenuItems = [
    {
      label: 'Back to Control Center',
      key: 'overview',
      icon: <DashboardOutlined style={{ fontSize: 14 }} />,
    },
    {
      label: 'Paywall Settings',
      key: 'settings',
      icon: <SettingOutlined />,
    },
    {
      icon: <QuestionCircleOutlined />,
      key: 'help',
      label: (
        <a
          href={
            version === 'v2Form'
              ? 'https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator'
              : 'https://learn.namiml.com/public-docs/no-code-paywalls/paywall-templates'
          }
          target="_blank"
          rel="noreferrer"
        >
          Help Center
        </a>
      ),
    },
  ];

  const dropdownOnClick: MenuProps['onClick'] = ({ key }) => {
    if (key === 'overview') {
      history.push('/paywalls/');
    } else if (key === 'settings') {
      onOpenSettings();
    }
  };

  return (
    <>
      <Drawer
        bodyStyle={{ padding: 12, background: namiNavy }}
        placement="top"
        open={true}
        height="56px"
        closable={false}
        zIndex={1002}
        mask={false}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{ marginLeft: 8 }}
          wrap={false}
        >
          <Responsive size="mdUp">
            <Col span={6}>
              <Dropdown
                menu={{ items: logoMenuItems, onClick: dropdownOnClick }}
                align={{ offset: [-10, 2] }}
                arrow={true}
              >
                <span style={{ cursor: 'pointer' }}>
                  <NamiIcon
                    icon="nami symbol"
                    size="small"
                    color="primary"
                    className="intercom-builder-logo"
                  />
                </span>
              </Dropdown>
            </Col>
            <Col>
              <AlignCenter>
                {gated && (
                  <Popover
                    title={'Upgrade to Use'}
                    overlayStyle={{ width: 300 }}
                    content={
                      <>
                        <div>
                          This paywall contains Premium features. To launch this
                          paywall in your app, you must upgrade to a{' '}
                          <span style={{ fontWeight: 500 }}>
                            {capitalizeFirstCharacter(planRequired)}
                          </span>{' '}
                          level plan.
                        </div>
                        <Button
                          type="primary"
                          size="small"
                          style={{ marginTop: 8 }}
                          onClick={openWebPaywall}
                        >
                          Upgrade Account
                        </Button>
                        <Button
                          type="link"
                          size="small"
                          href="https://learn.namiml.com/public-docs/no-code-paywalls/paywall-creator/capabilities"
                          target="_blank"
                        >
                          Learn More
                        </Button>
                      </>
                    }
                  >
                    <PaidOutlined
                      style={{
                        color: namiPureWhite,
                        marginRight: 6,
                        fontSize: 18,
                        transform: 'translateY(5px)',
                      }}
                    />
                  </Popover>
                )}
                <PaywallBuilderHeaderSDKVersion />
                <PaywallTitle
                  onClick={onOpenSettings}
                  className="intercom-builder-editname"
                >
                  <>
                    {paywallName}
                    <EditOutlined
                      className="paywall-title-icon"
                      style={{ fontSize: 13, marginLeft: 4 }}
                    />
                  </>
                </PaywallTitle>
              </AlignCenter>
            </Col>
            <Col span={6}>
              <AlignRight>
                <Space>
                  {version === 'v2Form' && (
                    <PaywallBuilderWarnings warnings={warnings} />
                  )}
                  <Button
                    ghost
                    onClick={() => history.push('/paywalls/')}
                    className="intercom-builder-exit"
                  >
                    Exit
                  </Button>
                  <Button
                    loading={isUpdating || isLoading}
                    type="primary"
                    onClick={() => {
                      const method = isLive ? openConfirmationModal : onSave;
                      method();
                    }}
                    disabled={
                      !appContext.userHasEntitlement('app.paywall.update') ||
                      archived
                    }
                    className="intercom-builder-save"
                  >
                    <Space>{isLive && <Dot />} Save</Space>
                  </Button>
                </Space>
              </AlignRight>
            </Col>
          </Responsive>
          <Responsive size="mdDown">
            <Col>
              <Button ghost onClick={() => history.push('/paywalls/')}>
                Exit
              </Button>
            </Col>
            <Col>
              <AlignCenter>
                <PaywallTitle>{paywallName}</PaywallTitle>
              </AlignCenter>
            </Col>
            <Col>
              <AlignRight>
                <Button
                  loading={isUpdating || isLoading}
                  type="primary"
                  onClick={() => {
                    const method = isLive ? openConfirmationModal : onSave;
                    method();
                  }}
                >
                  <Space>{isLive && <Dot />} Save</Space>
                </Button>
              </AlignRight>
            </Col>
          </Responsive>
        </Row>
      </Drawer>
      <PaywallCapabilityWebPaywall
        visible={webPaywallIsOpen}
        onCancel={closeWebPaywall}
      />
    </>
  );

  function openConfirmationModal() {
    Modal.confirm({
      zIndex: 1005,
      title: 'Changing Live Paywall',
      icon: <ExclamationCircleOutlined />,
      content:
        'This paywall is running in a live campaign and any edits will be seen by your app users immediately. Are you sure you want to make these changes?',
      onOk: onSave,
      closable: true,
    });
  }
}
