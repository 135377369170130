import React, { useEffect, useMemo, useState } from 'react';

import {
  FolderOutlined,
  ImageOutlined,
  LinkOutlined,
} from '@mui/icons-material';
import {
  Col,
  Image,
  Input,
  List,
  notification,
  Row,
  Space,
  Spin,
  Tabs,
  Upload,
} from 'antd';
import { RcFile, UploadChangeParam, UploadProps } from 'antd/lib/upload';
import { UploadRequestOption } from 'node_modules/rc-upload/lib/interface';
import { useParams } from 'react-router-dom';
import api from 'src/api';
import {
  TPaywallMedia,
  TPaywallMediaPayload,
} from 'src/api/types/paywall.types';
import {
  TImageComponent,
  TVideoComponent,
} from 'src/api/types/paywallTemplate.types';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import {
  deconstructVariable,
  propertyValueIsVariable,
} from 'src/utils/paywall';
import { isValidURL, replaceWhitespace } from 'src/utils/string';
import { namiBrightBlue, namiNavy } from 'src/variables';
import styled, { css } from 'styled-components';

import { FieldObject } from '../../../utils/formFieldBuilding';
import VariableTagCloud from './VariableTagCloud';

type ImagePickerProps = Omit<FieldObject, 'variable'> & {
  variant: 'image' | 'videoFallbackImage';
};

const StyledUpload = styled(Upload.Dragger)`
  margin-bottom: 12px;
  .ant-upload {
    :hover {
      color: ${namiBrightBlue} !important;
    }
    padding: 24px 0 !important;
  }
`;

const StyledTabs = styled(Tabs)`
  width: 100%;
  background: rgba(239, 244, 247, 0.5);
  padding: 10px 12px;

  .ant-list-item-meta-description {
    font-size: 13px;
    color: #8c8c8c;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${namiNavy};
    font-weight: 500;
  }

  .ant-tabs-ink-bar {
    background: ${namiNavy};
  }

  .ant-list-item {
    padding: 5px;
    margin-bottom: 8px;
  }

  .ant-list-vertical .ant-list-item-meta {
    margin-bottom: 0px;
  }
`;

const StyledListItem = styled(List.Item)<{ selected: boolean }>`
  ${({ selected }) => css`
    background-color: ${selected ? `#EBF1F5` : 'inherit'};
    cursor: pointer;

    h4 {
      font-weight: ${selected ? 500 : 400} !important;
      font-size: 14px !important;
      margin-bottom: 3px !important;
      word-wrap: break-word;
      line-height: 18px !important;
    }
  `}
`;

type TExtendedPaywallMedia = TPaywallMedia & {
  type: string;
};

export default function ImagePicker({
  variant,
  component,
  currentAssertionIndex,
}: ImagePickerProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const paywallId = useParams<{ paywallID: string }>().paywallID;
  const { mediaList, idLocations } = useAppSelector(({ paywallBuilder }) => {
    return {
      mediaList: paywallBuilder.mediaList,
      idLocations: paywallBuilder.idLocations,
    };
  });
  const [fileUploading, setFileUploading] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string | null>();
  const [selectedImage, setSelectedImage] = useState<string | null>();
  const [selectedTab, setSelectedTab] = useState<'files' | 'url'>('files');
  const componentLocation = idLocations[component?.id || ''];
  const checkConditions = currentAssertionIndex !== undefined;

  useEffect(() => {
    if (checkConditions) {
      const conditionAssertion = (component?.conditionAttributes || [])[
        currentAssertionIndex
      ];
      if (variant === 'image') {
        if ((conditionAssertion.attributes as any).url) {
          if (
            propertyValueIsVariable((conditionAssertion.attributes as any).url)
          ) {
            setSelectedImage(
              deconstructVariable(
                (conditionAssertion.attributes as any).url || '',
                false,
                true
              )
            );
            setSelectedTab('files');
            setImageUrl(null);
          } else {
            setImageUrl((conditionAssertion.attributes as any).url);
            setSelectedTab('url');
            setSelectedImage(null);
          }
        }
      } else if (variant === 'videoFallbackImage') {
        if ((conditionAssertion.attributes as any).fallbackImage) {
          if (
            propertyValueIsVariable(
              (conditionAssertion.attributes as any).fallbackImage
            )
          ) {
            setSelectedImage(
              deconstructVariable(
                (conditionAssertion.attributes as any).fallbackImage || '',
                false,
                true
              )
            );
            setSelectedTab('files');
            setImageUrl(null);
          } else {
            setImageUrl((conditionAssertion.attributes as any).fallbackImage);
            setSelectedTab('url');
            setSelectedImage(null);
          }
        }
      }
    } else if (variant === 'image') {
      if ((component as TImageComponent).url) {
        if (propertyValueIsVariable((component as TImageComponent).url)) {
          setSelectedImage(
            deconstructVariable(
              (component as TImageComponent).url || '',
              false,
              true
            )
          );
          setSelectedTab('files');
          setImageUrl(null);
        } else {
          setImageUrl((component as TImageComponent).url);
          setSelectedTab('url');
          setSelectedImage(null);
        }
      }
    } else if (variant === 'videoFallbackImage') {
      if ((component as TVideoComponent).fallbackImage) {
        if (
          propertyValueIsVariable((component as TVideoComponent).fallbackImage)
        ) {
          setSelectedImage(
            deconstructVariable(
              (component as TVideoComponent).fallbackImage || '',
              false,
              true
            )
          );
          setSelectedTab('files');
          setImageUrl(null);
        } else {
          setImageUrl((component as TVideoComponent).fallbackImage);
          setSelectedTab('url');
          setSelectedImage(null);
        }
      }
    }
  }, [component, checkConditions, currentAssertionIndex, variant]);

  const beforeUpload = (file: RcFile) => {
    const isLt1M = file.size / 1024 / 1024 < 1;
    const isPNGorJPG =
      file.type === 'image/png' ||
      file.type === 'image/jpg' ||
      file.type === 'image/jpeg';
    if (!isLt1M) {
      notification.error({ message: 'Image must be smaller than 1MB' });
    }
    if (!isPNGorJPG) {
      notification.error({ message: 'You must upload PNG or JPG files' });
    }
    return isLt1M && isPNGorJPG;
  };

  const mediaArray = useMemo(() => {
    return Object.entries(mediaList).reduce((output, [id, media]) => {
      return [
        {
          ...media,
          id: id,
          type: media.content?.endsWith('png')
            ? 'PNG'
            : media.content?.endsWith('jpeg') || media.content?.endsWith('jpg')
            ? 'JPG'
            : '',
        },
        ...output,
      ];
    }, [] as TExtendedPaywallMedia[]);
  }, [mediaList]);

  let tabItems = [
    {
      label: (
        <Space direction="horizontal">
          <FolderOutlined style={{ fontSize: 14 }} />
          Files
        </Space>
      ),
      key: 'files',
      children: (
        <div style={{ maxHeight: 450, overflowY: 'scroll' }}>
          <StyledUpload
            accept=".jpg,.jpeg,.png" // TODO: Pick these up from Omaha
            onChange={handleChange as UploadProps['onChange']}
            customRequest={({ file }: UploadRequestOption) =>
              handleNewFile(file)
            }
            beforeUpload={beforeUpload}
            fileList={[]}
            maxCount={1}
            disabled={fileUploading}
          >
            {fileUploading ? (
              <Spin />
            ) : (
              <Space direction="horizontal" size={8}>
                <ImageOutlined style={{ fontSize: 18 }} />
                <span>Drop your image or browse</span>
              </Space>
            )}
          </StyledUpload>
          <List
            itemLayout="vertical"
            dataSource={mediaArray}
            split={false}
            renderItem={(item: TExtendedPaywallMedia) => {
              return (
                <StyledListItem
                  key={item.id}
                  selected={selectedImage === item.name}
                  onClick={() => fileSelect(item.name, true)}
                >
                  <List.Item.Meta
                    avatar={
                      <Image
                        src={item.content || undefined}
                        preview={false}
                        height={46}
                        width={46}
                        style={{
                          objectFit: 'contain',
                          backgroundColor: '#DDDDDD80',
                        }}
                      />
                    }
                    title={item.name}
                    description={item.type}
                  />
                </StyledListItem>
              );
            }}
          />
        </div>
      ),
    },
    {
      label: (
        <Space direction="horizontal">
          <LinkOutlined style={{ fontSize: 14 }} />
          URL
        </Space>
      ),
      key: 'url',
      children: (
        <Space direction="vertical">
          <Input
            value={imageUrl || ''}
            placeholder={'URL of PNG or JPEG image file'}
            onChange={(e) => {
              setImageUrl(e.target.value);
              fileSelect(e.target.value, false);
            }}
            status={
              imageUrl &&
              !imageUrl.startsWith('${launch.') &&
              !isValidURL(imageUrl || '', ['png', 'jpg', 'jpeg'])
                ? 'error'
                : undefined
            }
            suffix={<VariableTagCloud handleChange={appendTagToInput} />}
          />
          <span style={{ fontSize: 12, marginBottom: 12 }}>
            By providing an Image URL, you confirm that your organization has
            the license to use the image.
          </span>
        </Space>
      ),
    },
  ];

  const label =
    variant === 'videoFallbackImage' ? (
      <span>Fallback Image:</span>
    ) : checkConditions ? (
      <span>Image</span>
    ) : undefined;

  return (
    <Col xs={24} md={24} key={'imagePicker'} style={{ marginBottom: 16 }}>
      <Row gutter={[0, 10]} style={{ width: '100%' }} wrap>
        {label}
        <StyledTabs
          items={tabItems}
          size="small"
          activeKey={selectedTab}
          onChange={(key) => setSelectedTab(key as 'files' | 'url')}
        />
      </Row>
    </Col>
  );

  function handleChange(info: UploadChangeParam): void {
    if (info.file.status === 'uploading') {
      setFileUploading(true);
      if (info.file?.size && info.file.size > 300000) {
        const description =
          'This file is larger than 300KB. It may be slow to load or not load at all on mobile devices. Consider a smaller image.';
        notification.warning({ message: 'Large file', description });
      }
    } else if (info.file.status === 'done') {
      notification.success({
        message: `${info.file.name} file uploaded successfully.`,
      });
      setFileUploading(false);
    }
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    if (file && typeof file !== 'string') {
      let name = 'temp';
      if (file as RcFile) {
        name =
          replaceWhitespace((file as RcFile).name.split('.')[0], '') || 'temp';
      } else {
        const url = URL.createObjectURL(file);
        name = url.split('/').reverse()[0];
      }

      const payload: TPaywallMediaPayload = {
        paywall: paywallId,
        name: name,
        content: file,
      };

      if (mediaList[name] && mediaList[name].id) {
        //Update existing media
        api
          .updatePaywallMedia(mediaList[name].id || '', payload)
          .then(() => {
            notification.success({
              message: `${name} file updated.`,
            });
          })
          .catch((reason) => {
            notification.error({
              message:
                reason.hasOwnProperty('content') &&
                Array.isArray(reason.content) &&
                reason.content.length > 0
                  ? reason.content[0]
                  : 'There was an error updating your file.',
            });
          })
          .finally(() => {
            setFileUploading(false);
            api.getPaywallMedia(paywallId).then(actions.setMediaList);
          });
      } else {
        //Add media
        api
          .addPaywallMedia(payload)
          .then(() => {
            notification.success({
              message: `${name} file uploaded successfully.`,
            });
          })
          .catch((reason) => {
            notification.error({
              message:
                reason.hasOwnProperty('content') &&
                Array.isArray(reason.content) &&
                reason.content.length > 0
                  ? reason.content[0]
                  : 'There was an error uploading your file.',
            });
          })
          .finally(() => {
            setFileUploading(false);
            api.getPaywallMedia(paywallId).then(actions.setMediaList);
          });
      }
    }
    //TODO - handle string type?
  }

  function fileSelect(name: string, prependMedia: boolean) {
    if (checkConditions) {
      actions.setComponentMediaInCondition({
        location: componentLocation,
        mediaValue: prependMedia ? `\${media.${name}}` : name,
        property: variant === 'image' ? 'url' : 'fallbackImage',
        assertionIndex: currentAssertionIndex,
      });
    } else {
      actions.setComponentMedia({
        location: componentLocation,
        mediaValue: prependMedia ? `\${media.${name}}` : name,
        property: variant === 'image' ? 'url' : 'fallbackImage',
      });
    }
  }

  function appendTagToInput(tagValue: string): void {
    const newMediaValue = `${imageUrl || ''}${tagValue}`;
    if (checkConditions) {
      actions.setComponentMediaInCondition({
        location: componentLocation,
        mediaValue: newMediaValue,
        property: variant === 'image' ? 'url' : 'fallbackImage',
        assertionIndex: currentAssertionIndex,
      });
    } else {
      actions.setComponentMedia({
        location: componentLocation,
        mediaValue: newMediaValue,
        property: variant === 'image' ? 'url' : 'fallbackImage',
      });
    }
  }
}
