import React, { useEffect, useMemo, useState } from 'react';

import { PlusOutlined, StarFilled } from '@ant-design/icons';
import { DeleteOutline, FileUploadOutlined } from '@mui/icons-material';
import {
  Button,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Switch,
  Typography,
} from 'antd';
import { createSelector } from 'reselect';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useAppContext } from 'src/hooks';
import { ListItemStyled } from 'src/pages/admin/developer/DeveloperPageList';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { toSlug } from 'src/utils/string';
import { namiLightGray } from 'src/variables';
import styled from 'styled-components';

import api from '../../../../../api';
import { TProductGroup } from '../../../../../api/types/paywall.types';
import { useActions, useAppSelector } from '../../../../../hooks/redux.hooks';
import { RootState } from '../../../../../redux';
import AddProductGroupModal from './AddProductGroupModal';
import BulkCreateProductGroupModal from './BulkCreateProductGroupModal';

type ProductGroupsModalProps = {
  onClose: () => void;
  onChange(): void;
};

const FormItem = styled(Form.Item)`
  .ant-form-item-required:before {
    display: none !important;
  }
`;

const selector = createSelector(
  [
    ({ paywallBuilder: { productGroups } }: RootState) => productGroups,
    ({ paywallBuilder: { updating } }: RootState) => updating,
    ({ paywallBuilder: { editingProductGroups } }: RootState) =>
      editingProductGroups,
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.capabilities'],
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.dynamicAddGroups'],
  ],
  (
    productGroups,
    updating,
    editingProductGroups,
    capabilities,
    dynamicAddGroups
  ) => ({
    currentProductGroups: productGroups,
    updating,
    showEditingProductGroupsModal: editingProductGroups,
    productGroupCapability: (capabilities || []).includes(
      'conditional_product_groups'
    ),
    multipageCapability: (capabilities || []).includes('multipage'),
    dynamicAddGroups,
  })
);

export default function ProductGroupsEditModal({
  onChange,
  onClose,
}: ProductGroupsModalProps) {
  const {
    currentProductGroups,
    updating,
    showEditingProductGroupsModal,
    productGroupCapability,
    multipageCapability,
    dynamicAddGroups,
  } = useAppSelector(selector);
  const allowAddingProductGroup = dynamicAddGroups;
  const appContext = useAppContext();
  const [isAddModalOpen, setAddModalOpen] = useState(false);
  const [isBulkAddModalOpen, setBulkAddModalOpen] = useState(false);
  const [repeatedRefId, setRepeatedRefId] = useState(false);
  const [groupsSearch, setGroupsSearch] = useState<string>('');
  const [displayedGroups, setDisplayedGroups] =
    useState<TProductGroup[]>(currentProductGroups);
  const [productGroups, setProductGroups] = useState<
    Record<string, TProductGroup>
  >({});
  const [form] = Form.useForm();
  const actions = useActions(PaywallBuilderSlice.actions);
  const [selectedProductGroupId, setSelectedProductGroupId] = useState<string>(
    currentProductGroups[0].id
  );
  const defaultGroupId = useMemo(() => {
    const groups = Object.values(productGroups);
    return groups.find((group) => group.default)?.id || groups[0]?.id;
  }, [productGroups]);

  useEffect(() => {
    const groups = currentProductGroups.reduce((output, productGroup) => {
      return { ...output, [productGroup.id]: productGroup };
    }, {} as Record<string, TProductGroup>);
    setProductGroups(groups);
  }, [currentProductGroups]);

  useEffect(() => {
    const newGroups = currentProductGroups.reduce((output, productGroup) => {
      if (
        groupsSearch.length &&
        !productGroup.ref.toLowerCase().includes(groupsSearch.toLowerCase()) &&
        !productGroup.display_name
          .toLowerCase()
          .includes(groupsSearch.toLowerCase())
      ) {
        return output;
      }
      return [...output, productGroup];
    }, [] as TProductGroup[]);
    setDisplayedGroups(newGroups);
  }, [groupsSearch, currentProductGroups]);

  useEffect(() => {
    const currentRefIds = Object.values(productGroups).reduce(
      (output, group) => {
        return [...output, group.ref];
      },
      [] as Array<string>
    );
    const currentRefIdsSet = new Set(currentRefIds);
    setRepeatedRefId(currentRefIdsSet.size !== currentRefIds.length);
  }, [productGroups]);

  const listFooter = allowAddingProductGroup ? (
    <Space direction="vertical">
      <Button
        icon={<PlusOutlined />}
        onClick={() => {
          setAddModalOpen(true);
        }}
        disabled={
          updating || !appContext.userHasEntitlement('app.productgroup.create')
        }
      >
        Add Product Group
      </Button>
      <Button
        type="text"
        icon={
          <FileUploadOutlined
            style={{
              fontSize: 15,
            }}
            className="inButton"
          />
        }
        onClick={() => {
          setAddModalOpen(false);
          setBulkAddModalOpen(true);
        }}
        disabled={
          updating || !appContext.userHasEntitlement('app.productgroup.create')
        }
      >
        Import Groups
      </Button>
    </Space>
  ) : null;

  return (
    <>
      <Modal
        title="Edit Product Groups"
        open={showEditingProductGroupsModal}
        footer={null}
        centered
        closable={true}
        zIndex={1005}
        onCancel={onClose}
        forceRender
        width={700}
      >
        <>
          <Row style={{ width: '100%' }} gutter={16}></Row>
          <Row style={{ width: '100%' }} gutter={16}>
            <Col
              span={8}
              style={{
                borderRight: `1px solid ${namiLightGray}`,
                height: 400,
                overflowX: 'hidden',
                overflowY: 'scroll',
              }}
            >
              <List
                header={
                  <Input
                    value={groupsSearch}
                    onChange={(e) => setGroupsSearch(e.target.value)}
                    size="small"
                    allowClear={true}
                    placeholder="Search"
                  />
                }
                footer={listFooter}
                dataSource={displayedGroups}
                size="small"
                className="verticalScrollDrawer"
                renderItem={(item) => (
                  <ListItemStyled
                    onClick={() => setSelectedProductGroupId(item.id)}
                    selected={selectedProductGroupId === item.id}
                  >
                    <Space direction="vertical" size="small">
                      <Typography.Text style={{ fontWeight: 500 }}>
                        {item.display_name}
                      </Typography.Text>
                      <MonospaceText>{item.ref}</MonospaceText>
                    </Space>
                  </ListItemStyled>
                )}
              />
            </Col>
            <Col span={16}>
              <Form
                layout="vertical"
                form={form}
                onFinish={updateProductGroups}
              >
                <div key={selectedProductGroupId}>
                  <Row gutter={16}>
                    <Col xs={18}>
                      <FormItem
                        label="Display Name"
                        required
                        tooltip="This value is shown to users on your paywall"
                        style={{ marginBottom: 12 }}
                      >
                        <Input
                          maxLength={100}
                          disabled={updating}
                          onChange={handleDisplayNameChange}
                          value={
                            productGroups[selectedProductGroupId]
                              ?.display_name || ''
                          }
                        />
                      </FormItem>
                    </Col>
                    <Col xs={6}>
                      <FormItem label="Default">
                        <Switch
                          checked={
                            productGroups[selectedProductGroupId]?.default ||
                            selectedProductGroupId === defaultGroupId ||
                            false
                          }
                          onChange={handleDefaultChange}
                          loading={updating}
                          checkedChildren={
                            <StarFilled style={{ fontSize: '0.9em' }} />
                          }
                        />
                      </FormItem>
                    </Col>
                  </Row>
                  {productGroupCapability && (
                    <Row>
                      <Col xs={18}>
                        <FormItem
                          label="Reference ID"
                          required
                          tooltip="This value is for your app code. Provide an alphanumeric string without any special characters."
                          rules={[
                            {
                              pattern: /^[a-zA-Z0-9-]+$/g,
                              message:
                                'Please enter an alphanumeric value with no special characters',
                            },
                            {
                              required: true,
                              message: 'Provide an alphanumeric value',
                            },
                          ]}
                        >
                          <Input
                            maxLength={100}
                            onChange={handleRefChange}
                            value={
                              productGroups[selectedProductGroupId]?.ref || ''
                            }
                            disabled={
                              updating ||
                              !appContext.userHasEntitlement(
                                'app.productgroup.id.update'
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  )}
                </div>
                {repeatedRefId && (
                  <div>
                    <Typography.Text type="danger">
                      Reference ID values must be unique
                    </Typography.Text>
                  </div>
                )}
                <Space direction="horizontal">
                  <Button
                    htmlType="submit"
                    loading={updating}
                    type="primary"
                    disabled={
                      form
                        .getFieldsError()
                        .some(({ errors }) => errors.length) || repeatedRefId
                    }
                  >
                    Save
                  </Button>
                  {!multipageCapability && (
                    <Button
                      type="text"
                      size="small"
                      icon={
                        <DeleteOutline
                          className="inButton"
                          style={{ fontSize: 16 }}
                        />
                      }
                      onClick={() => deleteProductGroup(selectedProductGroupId)}
                    >
                      Delete Group
                    </Button>
                  )}
                </Space>
              </Form>
            </Col>
          </Row>
        </>
      </Modal>
      <AddProductGroupModal
        isOpen={isAddModalOpen}
        onAdd={onChange}
        onClose={() => setAddModalOpen(false)}
      />
      <BulkCreateProductGroupModal
        isOpen={isBulkAddModalOpen}
        onAdd={onChange}
        onClose={() => {
          setBulkAddModalOpen(false);
        }}
      />
    </>
  );

  function handleDisplayNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setProductGroups((state) => {
      const display_name = e.target.value;
      const productGroup = {
        ...state[selectedProductGroupId],
        display_name,
      };
      return { ...state, [selectedProductGroupId]: productGroup };
    });
  }

  function handleRefChange(e: React.ChangeEvent<HTMLInputElement>) {
    setProductGroups((state) => {
      const ref = toSlug(e.target.value, '-');
      const group = { ...state[selectedProductGroupId], ref };
      return { ...state, [selectedProductGroupId]: group };
    });
  }

  function handleDefaultChange(flag: boolean) {
    setProductGroups((state) => {
      return Object.values(state).reduce((state, group) => {
        const value = group.id === selectedProductGroupId ? flag : !flag;
        const newGroup = { ...state[group.id], default: value };
        return { ...state, [group.id]: newGroup };
      }, state);
    });
  }

  function updateProductGroups() {
    actions.setUpdating(true);
    const promises = Object.values(productGroups).map(
      ({ id: groupId, ...group }) => {
        if (!!groupId) return api.updateProductGroup(groupId, group);
        return api.createProductGroup(group);
      }
    );
    Promise.all(promises)
      .then(() => onChange())
      .finally(() => {
        actions.setUpdating(false);
        onClose();
      });
  }

  function deleteProductGroup(groupId: string) {
    actions.setUpdating(true);
    api
      .deleteProductGroup(groupId)
      .then(() => onChange())
      .finally(() => {
        actions.setUpdating(false);
        form.resetFields();
        setSelectedProductGroupId(currentProductGroups[0].id);
        onClose();
      });
  }
}
