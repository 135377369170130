import React, { useContext, useState } from 'react';

import { LockOutlined, PlusOutlined } from '@ant-design/icons';
import {
  ContentCopyOutlined,
  DeleteOutline,
  LaunchSharp,
} from '@mui/icons-material';
import { Button, Col, Divider, Row, Select, Space, Tooltip } from 'antd';
import type { InputStatus } from 'antd/lib/_util/statusUtils';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'src/AppContextProvider';
import { InfinitePlacementsWebPaywall } from 'src/components/WebPaywalls/InfinitePlacementsWebPaywall';
import { useBooleanState } from 'src/hooks';
import { namiDarkGray, namiMediumGray } from 'src/variables';
import styled from 'styled-components';

import type { TCampaignLabel } from '../../../../api/types/campaign.types';
import { useCampaignLabelsQuery } from '../../../../hooks/queries/campaign.hooks';
import AddPlacementModal from '../AddPlacementModal';
import { getLabelDotForDetailPage } from '../utils/campaign.utils';

const PlacementSelect = styled(Select)`
  .ant-select-selection-item {
    margin-top: 0.15rem;
  }
`;

type CampaignLabelSelectProps = {
  disabled?: boolean;
  placeholder?: string;
  status?: InputStatus;
  currentlyArchived?: boolean;
  onNewLabel?: (label: TCampaignLabel) => void;
  onChange: () => void;
  currentLabelId?: string;
  bulkDuplicateAction: () => void;
};

export const PlacementLabel = styled.span`
  font-weight: 500;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
`;

export const PlacementDescription = styled.span`
  font-size: 13px;
`;

export default function CampaignLabelSelect({
  onNewLabel,
  onChange,
  currentLabelId,
  bulkDuplicateAction,
  currentlyArchived,
  ...props
}: CampaignLabelSelectProps) {
  const { userHasEntitlement, planHasEntitlement } = useContext(AppContext);
  const [isModalOpen, setModalOpen] = useState(false);
  const labelsQuery = useCampaignLabelsQuery({ pageSize: 1000 });
  const [selectOpen, setSelectOpen] = useState(false);
  const [isUpgradeOpen, openUpgrade, closeUpgrade] = useBooleanState(false);
  const history = useHistory();

  const placementOptions = (labelsQuery.data?.results || []).map((label) => ({
    key: label.value,
    name: label.value,
    value: label.id,
    label: (
      <Space direction="horizontal">
        {label.archived && (
          <DeleteOutline
            style={{
              fontSize: 15,
              color: namiMediumGray,
            }}
          />
        )}
        {getLabelDotForDetailPage(!!label.enabled, label.archived)}
        <PlacementLabel>{label.value}</PlacementLabel>
        {label.description && ' • '}
        <PlacementDescription>{label.description}</PlacementDescription>
      </Space>
    ),
  }));

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={18}>
          <PlacementSelect
            {...props}
            placeholder="Select a Placement"
            showSearch
            onChange={onChange}
            open={selectOpen}
            loading={labelsQuery.isFetching}
            disabled={!userHasEntitlement('app.campaign.update')}
            optionFilterProp="label"
            optionLabelProp="name"
            className="code-text-styling"
            options={placementOptions}
            onDropdownVisibleChange={(visible) => setSelectOpen(visible)}
            dropdownRender={(menu) => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button
                    type="text"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setSelectOpen(false);
                      setModalOpen(true);
                    }}
                    disabled={!userHasEntitlement('app.campaign.create')}
                  >
                    Add Placement
                  </Button>
                </Space>
                <AddPlacementModal
                  isOpen={isModalOpen}
                  onAdd={onNewLabel}
                  onClose={() => setModalOpen(false)}
                />
              </>
            )}
            filterOption={(input, option) =>
              (option?.name || '').toLowerCase().includes(input.toLowerCase())
            }
          />
        </Col>
        <Col xs={0} md={1}>
          <Tooltip title="Edit Placement" align={{ offset: [0, 5] }}>
            <Button
              type="link"
              style={{ color: namiDarkGray }}
              onClick={() =>
                history.push(
                  `/campaigns/placements/?label_id=${currentLabelId || ''}`
                )
              }
              icon={
                <LaunchSharp style={{ fontSize: 16 }} className="inButton" />
              }
              disabled={labelsQuery.isFetching}
            />
          </Tooltip>
        </Col>
        <Col xs={12} md={5}>
          {getBulkDuplicateButton()}
        </Col>
      </Row>
      <InfinitePlacementsWebPaywall
        visible={isUpgradeOpen}
        onCancel={closeUpgrade}
      />
    </>
  );

  function getBulkDuplicateButton() {
    if (planHasEntitlement('app.placement.create_infinite')) {
      if (planHasEntitlement('app.placement.bulk_update')) {
        return (
          <Button
            type="text"
            icon={
              <ContentCopyOutlined
                style={{ fontSize: 15 }}
                className="inButton"
              />
            }
            onClick={bulkDuplicateAction}
            disabled={
              currentlyArchived || !userHasEntitlement('app.campaign.create')
            }
          >
            Copy to Placements
          </Button>
        );
      } else {
        return (
          <Button type="text" icon={<LockOutlined />} onClick={openUpgrade}>
            Copy to Placements
          </Button>
        );
      }
    }
    return undefined;
  }
}
