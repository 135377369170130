import React, { useContext, useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import { LowPriorityOutlined } from '@mui/icons-material';
import { Space, Tooltip, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'src/AppContextProvider';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

export const DynamicallySizedParagraph = styled(Typography.Paragraph)`
  margin-bottom: -2px !important;
  cursor: pointer;
  font-weight: 500;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  :hover {
    color: ${namiPrimaryBlue};
    text-decoration: underline;
  }
  font-size: small;
  max-width: 260px;
`;

type HoverLabelProps = {
  value: string;
  id: string;
  editAction: () => void;
};

export default function PlacementHoverLabel({
  value,
  id,
  editAction,
}: HoverLabelProps) {
  const history = useHistory();
  const { userHasEntitlement } = useContext(AppContext);
  const [hoverActive, setHoverActive] = useState(false);

  function getActionToolbar() {
    return (
      <Space direction="horizontal" size={0}>
        <IconActionButton
          type="text"
          size="small"
          icon={<EditOutlined style={{ fontSize: '11px' }} />}
          disabled={!userHasEntitlement('app.campaign.get')}
          onClick={editAction}
          style={{ fontSize: 'small' }}
        >
          Edit Placement
        </IconActionButton>
        <IconActionButton
          type="text"
          size="small"
          icon={<LowPriorityOutlined style={{ fontSize: '14px' }} />}
          disabled={!userHasEntitlement('app.campaign.list')}
          onClick={() => history.push(`/campaigns/rules/?label_id=${id}`)}
          style={{ fontSize: 'small' }}
        >
          Manage Rules
        </IconActionButton>
      </Space>
    );
  }

  return (
    <Space
      direction="vertical"
      size={0}
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
    >
      <Tooltip title={value}>
        <DynamicallySizedParagraph
          ellipsis={true}
          onClick={() => history.push(`/campaigns/?label_id=${id}`)}
        >
          {value}
        </DynamicallySizedParagraph>
      </Tooltip>
      {hoverActive && getActionToolbar()}
    </Space>
  );
}
