import React, { useMemo } from 'react';

import { Col, Row, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import {
  TCustomerAttribute,
  TCustomerPurchaseEvent,
  TCustomerPurchaseEventTransforms,
  TNamiPurchaseEventPrettyNames,
} from 'src/api/types/customer.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import CustomerAttribute from 'src/components/CustomerAttribute/CustomerAttribute';
import Page from 'src/components/Page/Page';
import {
  useExternalUserQuery,
  usePurchaseEventQuery,
  usePurchaseQuery,
} from 'src/hooks/queries/customer.hooks';
import { namiPureWhite } from 'src/variables';

import CustomerPurchaseEventOutboundEvents from './outbound-events/CustomerPurchaseEventOutboundEvents';

export default function CustomerPurchaseEventPage() {
  const { userID, purchaseID, eventID } =
    useParams<{ userID: string; purchaseID: string; eventID: string }>();
  const customerData = useExternalUserQuery(userID);
  const purchaseData = usePurchaseQuery(purchaseID);
  const eventData = usePurchaseEventQuery(eventID);

  const customerName = useMemo(() => {
    if (
      !customerData.data ||
      !customerData.data.psuedonym ||
      !customerData.data.external_id
    )
      return userID;
    return customerData.data.psuedonym || customerData.data.external_id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  const purchaseName = useMemo(() => {
    if (!purchaseData.data || !purchaseData.data.product) return 'Purchase';
    return `${
      purchaseData.data.product.name || purchaseData.data.product.sku_ref_id
    } Purchase`;
  }, [purchaseData]);

  const pageTitle = useMemo(() => {
    if (eventData.isLoading || !eventData.data) return 'Event';
    return TNamiPurchaseEventPrettyNames[eventData.data.nami_event_type];
  }, [eventData]);

  const eventAttrs: TCustomerAttribute[] = useMemo(() => {
    if (!eventData.data) return [];
    return convertEventToAttributes(eventData.data);
  }, [eventData]);

  return (
    <Page title={pageTitle}>
      <Row justify="start" gutter={[0, 36]} style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <Breadcrumb
            items={[
              { url: '/customers-beta/', name: 'Customers' },
              {
                url: `/customers-beta/${userID}`,
                name: customerName,
              },
              {
                url: `/customers-beta/${userID}/purchases/${purchaseID}`,
                name: purchaseName,
              },
              { name: pageTitle },
            ]}
          />
        </Col>
      </Row>
      <Row
        gutter={[16, 24]}
        align="top"
        justify="start"
        wrap={false}
        style={{ marginBottom: 16 }}
      >
        <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
          {pageTitle}
        </Typography.Text>
      </Row>
      <Row gutter={[16, 24]} align="top" justify="start" wrap={false}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          style={{
            backgroundColor: namiPureWhite,
            marginRight: 10,
          }}
        >
          <Spin spinning={eventData.isLoading}>
            <Space
              direction="vertical"
              size={12}
              style={{ padding: 18, minHeight: 50 }}
            >
              <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
                Attributes
              </Typography.Text>
              {eventAttrs.map((attr, index) => (
                <CustomerAttribute attr={attr} key={index} />
              ))}
            </Space>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={12} lg={18}>
          <CustomerPurchaseEventOutboundEvents eventId={eventID} />
        </Col>
      </Row>
    </Page>
  );

  function convertEventToAttributes(
    event: TCustomerPurchaseEvent
  ): TCustomerAttribute[] {
    return Object.entries(event).reduce((output, [key, value]) => {
      if (TCustomerPurchaseEventTransforms[key]) {
        return [...output, TCustomerPurchaseEventTransforms[key](value)];
      }
      return output;
    }, [] as TCustomerAttribute[]);
  }
}
