import React, { useMemo } from 'react';

import { LaunchOutlined } from '@mui/icons-material';
import { Col, Image, Row, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import {
  TCustomerAttribute,
  TCustomerDevice,
  TCustomerDeviceTransforms,
} from 'src/api/types/customer.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import CustomerAttribute from 'src/components/CustomerAttribute/CustomerAttribute';
import Page from 'src/components/Page/Page';
import { useDeviceQuery } from 'src/hooks/queries/customer.hooks';
import { platformLogo } from 'src/services/helpers';
import { getDevicePlatformFormFactorSummary } from 'src/utils/customer';
import { namiPureWhite } from 'src/variables';

import DeviceEvents from './DeviceEvents';

export default function DeviceDetail() {
  const deviceId = useParams<{ deviceID: string }>().deviceID;
  const deviceData = useDeviceQuery(deviceId);

  const attrs: TCustomerAttribute[] = useMemo(() => {
    if (!deviceData.data) return [];
    return convertDeviceToAttributes(deviceData.data);
  }, [deviceData]);

  const pageTitle = useMemo(() => {
    if (!deviceData.data) return 'Device';
    return getDevicePlatformFormFactorSummary(deviceData.data);
  }, [deviceData]);

  return (
    <Page title={pageTitle}>
      <Row justify="start" gutter={[0, 36]} style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <Breadcrumb items={[{ name: 'Devices' }, { name: pageTitle }]} />
        </Col>
      </Row>
      <Row
        gutter={[16, 24]}
        align="top"
        justify="start"
        wrap={false}
        style={{ marginBottom: 16 }}
      >
        <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
          {`${pageTitle} Device`}
        </Typography.Text>
      </Row>
      <Row gutter={[16, 24]} align="top" justify="start" wrap={false}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          style={{
            backgroundColor: namiPureWhite,
            marginRight: 10,
          }}
        >
          <Spin spinning={deviceData.isLoading}>
            <Space
              direction="vertical"
              size={12}
              style={{ padding: 18, minHeight: 50 }}
            >
              <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
                Attributes
              </Typography.Text>
              {attrs.map((attr, index) => (
                <CustomerAttribute attr={attr} key={index} />
              ))}
            </Space>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={12} lg={18}>
          <Space direction="vertical" style={{ width: '100%' }} size={14}>
            <DeviceEvents deviceID={deviceId} />
            {/* {deviceId && deviceData.data?.platform.id && (
              <DeviceCampaignRules
                deviceId={deviceId}
                platformId={deviceData.data.platform.id}
              />
            )} */}
          </Space>
        </Col>
      </Row>
    </Page>
  );
}

function convertDeviceToAttributes(
  device: TCustomerDevice
): TCustomerAttribute[] {
  return Object.entries(device).reduce((output, [key, value]) => {
    if (key === 'profile') {
      return [
        ...output,
        ...Object.entries(device.profile).reduce(
          (profileOutput, [profileKey, profileValue]) => {
            if (TCustomerDeviceTransforms[`profile_${profileKey}`]) {
              return [
                ...profileOutput,
                TCustomerDeviceTransforms[`profile_${profileKey}`](
                  profileValue
                ),
              ];
            }
            return profileOutput;
          },
          [] as TCustomerAttribute[]
        ),
      ];
    }
    if (key === 'platform') {
      return [
        ...output,
        {
          title: 'Platform',
          value: (
            <Space>
              <Image
                height={13}
                width={13}
                preview={false}
                src={platformLogo(device.platform.platform_type)}
              />
              <a
                href={`/integrations/${device.platform.platform_type}/${device.platform.id}`}
              >
                {device.platform.name}{' '}
                <LaunchOutlined
                  style={{ fontSize: 13, transform: 'translateY(2px)' }}
                />
              </a>
            </Space>
          ),
          copyable: false,
        },
      ];
    }
    if (TCustomerDeviceTransforms[key]) {
      return [...output, TCustomerDeviceTransforms[key](value)];
    }
    return output;
  }, [] as TCustomerAttribute[]);
}
