import React from 'react';

import { ContentCopyOutlined } from '@mui/icons-material';
import { Button, notification, Space, Typography } from 'antd';
import { TCustomerAttribute } from 'src/api/types/customer.types';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { copyTextToClipBoard } from 'src/services/helpers';
import { namiDarkGray } from 'src/variables';
import styled from 'styled-components';

const AttributeTitle = styled(Typography.Text)`
  font-weight: 600;
  font-size: 14px;
  color: ${namiDarkGray};
`;

const AttributeIcon = styled.span`
  svg {
    font-size: 16px;
    transform: translateY(3px);
    color: ${namiDarkGray};
  }
`;

const BasicValue = styled(Typography.Text)`
  font-size: 13px;
  a:hover {
    text-decoration: underline;
  }
`;

const IconPlaceholder = styled.div`
  width: 16px;
`;

export default function CustomerAttribute({
  attr,
}: {
  attr: TCustomerAttribute;
}) {
  return (
    <Space direction="vertical" size={0} key={attr.title}>
      <Space size="small">
        {attr.icon && <AttributeIcon>{attr.icon}</AttributeIcon>}
        <AttributeTitle>{attr.title}</AttributeTitle>
      </Space>
      <Space size="small">
        {attr.icon && <IconPlaceholder />}
        {attr.monospace ? (
          <MonospaceText>{attr.value}</MonospaceText>
        ) : (
          <BasicValue>{attr.value}</BasicValue>
        )}
        {attr.copyable && !attr.empty && (
          <Button
            icon={
              <ContentCopyOutlined
                style={{ fontSize: 15 }}
                className="inButton"
              />
            }
            onClick={() =>
              copyTextToClipBoard(
                attr.copyValue ? attr.copyValue : attr.value
              ).then(() =>
                notification.success({
                  message: `Copied ${attr.title} to clipboard`,
                })
              )
            }
            type="text"
            size="small"
          />
        )}
      </Space>
    </Space>
  );
}
