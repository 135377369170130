import React, { useMemo } from 'react';

import { CheckCircleTwoTone } from '@ant-design/icons';
import { CancelTwoTone, ContentCopyOutlined } from '@mui/icons-material';
import { Button, Col, List, Modal, notification, Row, Space } from 'antd';
import moment from 'moment-timezone';
import { TCustomerAttribute } from 'src/api/types/customer.types';
import {
  prettyEventTypeNames,
  TEventLogItem,
  TEventLogPartnerMeta,
} from 'src/api/types/developer.types';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useEventLogItemQuery } from 'src/hooks/queries/developer.hooks';
import { copyTextToClipBoard } from 'src/services/helpers';
import { namiCoral, namiGreen } from 'src/variables';
import styled from 'styled-components';

type OutboundEventProps = {
  eventId: string;
  open: boolean;
  onClose: () => void;
};

const StyledPre = styled.pre`
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  font-size: 13px;
`;

export default function OutboundEventDetail({
  eventId,
  open,
  onClose,
}: OutboundEventProps) {
  const eventLogItemData = useEventLogItemQuery(eventId);
  const modalTitle = useMemo(() => {
    if (eventLogItemData.isLoading) return 'Loading';
    if (eventLogItemData.data?.subscription)
      return `${
        prettyEventTypeNames[eventLogItemData.data.event_type]
      } Event > ${
        TEventLogPartnerMeta[eventLogItemData.data.subscription.partner]
      }`;
    return eventLogItemData.data?.event_type || 'Outbound Event';
  }, [eventLogItemData]);

  const eventAttrs = useMemo(() => {
    if (eventLogItemData.isFetched && eventLogItemData.data)
      return convertOutboundEventToAttributes(eventLogItemData.data);
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventLogItemData]);

  return (
    <Modal
      open={open}
      centered={true}
      onCancel={onClose}
      title={modalTitle}
      destroyOnClose
      width={800}
      onOk={onClose}
      okText={'Close'}
      cancelButtonProps={{ hidden: true }}
      bodyStyle={{ overflow: 'scroll' }}
    >
      <List
        header={null}
        footer={null}
        loading={eventLogItemData.isLoading}
        dataSource={eventAttrs}
        renderItem={(item) => (
          <List.Item>
            <Row
              gutter={[8, 8]}
              style={{ width: '100%', padding: 8 }}
              align="middle"
            >
              <Col md={6}>
                <b>{item.title}:</b>
              </Col>
              <Col md={18}>
                <Space wrap={true} style={{ width: '100%' }}>
                  <span>{item.value}</span>
                  {item.copyable && (
                    <Button
                      icon={
                        <ContentCopyOutlined
                          style={{ fontSize: 15 }}
                          className="inButton"
                        />
                      }
                      onClick={() =>
                        copyTextToClipBoard(item.copyValue || item.value).then(
                          () =>
                            notification.success({
                              message: `Copied ${item.title} to clipboard`,
                            })
                        )
                      }
                      type="text"
                      size="small"
                    />
                  )}
                </Space>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Modal>
  );

  function convertOutboundEventToAttributes(
    event: TEventLogItem
  ): TCustomerAttribute[] {
    let result: TCustomerAttribute[] = [];
    result.push({
      title: 'ID',
      copyable: true,
      value: <MonospaceText>{event.id}</MonospaceText>,
    });
    result.push({
      title: 'Date',
      copyable: true,
      value: (
        <MonospaceText>
          {moment(
            typeof event.last_attempt === 'string'
              ? event.last_attempt
              : event.created_date
          ).format('YYYY-MM-DD h:mm:ss A')}
        </MonospaceText>
      ),
      copyValue:
        typeof event.last_attempt === 'string'
          ? event.last_attempt
          : event.created_date,
    });
    if (event.recent_successful_delivery) {
      if (typeof event.recent_successful_delivery === 'object') {
        const delivery = event.recent_successful_delivery;
        if (delivery.request_headers) {
          result.push({
            title: 'Request Headers',
            value: (
              <StyledPre>
                {JSON.stringify(delivery.request_headers, null, 4)}
              </StyledPre>
            ),
            copyable: false,
          });
        }
        if (delivery.request_url) {
          result.push({
            title: 'Request URL',
            value: <MonospaceText>{delivery.request_url}</MonospaceText>,
            copyable: true,
            copyValue: delivery.request_url,
          });
        }
        if (delivery.request_body) {
          if (typeof delivery.request_body === 'object') {
            result.push({
              title: 'Request Body',
              value: (
                <StyledPre>
                  {JSON.stringify(JSON.parse(delivery.request_body), null, 4)}
                </StyledPre>
              ),
              copyable: false,
            });
          } else {
            result.push({
              title: 'Request Body',
              value: <StyledPre>{delivery.request_body}</StyledPre>,
              copyable: false,
            });
          }
        }
        if (delivery.rtt_seconds) {
          result.push({
            title: 'Response Time',
            value: (
              <MonospaceText>{`${delivery.rtt_seconds} seconds`}</MonospaceText>
            ),
            copyable: false,
          });
        }
        if (delivery.status_code) {
          result.push({
            title: 'Response',
            value: (
              <Space direction="horizontal" size={3}>
                {returnIconForHTTPStatusCode(delivery.status_code)}
                <MonospaceText>{delivery.status_code}</MonospaceText>
              </Space>
            ),
            copyable: false,
          });
        }
        if (delivery.response_headers) {
          result.push({
            title: 'Response Headers',
            value: (
              <StyledPre>
                {JSON.stringify(delivery.response_headers, null, 4)}
              </StyledPre>
            ),
            copyable: false,
          });
        }
        if (delivery.response_body) {
          let value = delivery.response_body;
          try {
            JSON.parse(delivery.response_body);
            value = JSON.stringify(JSON.parse(delivery.response_body), null, 4);
          } catch {}

          result.push({
            title: 'Response Body',
            value: <StyledPre>{value}</StyledPre>,
            copyable: false,
          });
        }
      }
    }
    return result;
  }

  function returnIconForHTTPStatusCode(code: number): JSX.Element {
    if (code >= 200 && code < 300) {
      return (
        <CheckCircleTwoTone
          style={{
            color: namiGreen,
            transform: 'translateY(2px)',
            fontSize: 18,
            fill: namiGreen,
          }}
        />
      );
    }
    if (code >= 400 && code < 600) {
      return (
        <CancelTwoTone
          style={{
            color: namiCoral,
            transform: 'translateY(2px)',
            fontSize: 18,
          }}
        />
      );
    }
    return <></>;
  }
}
