import React from 'react';

import {
  AttachMoney,
  AutorenewOutlined,
  BlockOutlined,
  CancelOutlined,
  CancelTwoTone,
  CardGiftcardOutlined,
  ChangeCircleOutlined,
  CheckCircleTwoTone,
  CreditCardOffOutlined,
  MoneyOffOutlined,
  MoreTimeOutlined,
  PaidOutlined,
  PauseCircleOutline,
  PlayCircleOutline,
  PriceChangeOutlined,
  PriceCheckOutlined,
  RefreshOutlined,
  RestartAltOutlined,
  ScienceOutlined,
  SellOutlined,
  SnoozeOutlined,
  SyncDisabledOutlined,
  SyncOutlined,
} from '@mui/icons-material';
import { Image, Space } from 'antd';
import moment from 'moment';
import ReactCountryFlag from 'react-country-flag';
import countries from 'src/countries';
import { platformLogo } from 'src/services/helpers';
import { convertIso8601DurationToReadable } from 'src/utils/date';
import { capitalize, translateStringToLanguage } from 'src/utils/formatting';
import { getUSDCurrency } from 'src/utils/math';
import { capitalizeFirstCharacter, toReadable } from 'src/utils/string';
import { namiCoral, namiGreen } from 'src/variables';

import { formFactorIcons, getOperatingSystemType, osIcons } from './main.types';
import { TDevice, TFormFactorOrientation } from './paywall.types';
import { TPaywallTemplate } from './paywallTemplate.types';

export type TCustomerDevice = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  updated_date: string;
  platform: TCustomerPlatform;
  app_user: TCustomerDeviceAppUser | null;
  platform_account: string | null; //TODO - what is this?
  nami_user_id: string | null;
  nami_user_type: TNamiUserType;
  profile: TCustomerDeviceProfile;
};

export type TCustomerDeviceAppUser = {
  id: string;
  app: {};
  external_user: {};
};

type TCustomerDeviceProfile = {
  advertising_id: string | null;
  app_environment: string | null;
  audience_split_position: number;
  app_version: string | null;
  browser_name: string | null;
  browser_version: string | null;
  cdp_audiences: []; //TODO
  customer_data_platform_id: string | null;
  extended_framework_version: string | null;
  extended_platform: string | null;
  extended_platform_version: string | null;
  external_id: string | null;
  external_user_id: string | null;
  form_factor: string | null;
  geo_ip_country: string | null;
  id: string;
  marketplace_country: string | null;
  marketplace_user_id: string | null;
  model: string | null;
  os_country: string | null;
  os_language: string | null;
  os_name: string | null;
  os_version: string | null;
  sdk_version: string | null;
  signed_in: boolean;
  vendor_id: string | null;
};

export type TCustomerPurchase = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  product: {
    id: string;
    name: string;
    sku_ref_id: string;
  };
  product_platform_type: string;
  partner_guid: string | null;
  marketplace_user_id: string | null;
  platform_account: string | null;
  not_before: string;
  expires_at: string;
  conversion_impression: TCustomerPurchaseConversionImpression | null;
  conversion_attribution: string | null; //TODO - 'direct'
  purchase_country: string | null;
  intro_period: string | null;
  last_push: string | null;
  last_refresh: string | null;
  last_update: string | null;
  is_active: boolean;
  is_verified_and_active: boolean;
  in_verification_grace_period: boolean;
  is_verified: boolean;
  is_subscription: string | boolean | null;
  is_one_time_purchase: boolean;
  is_auto_renewable: boolean;
  is_in_trial_period: boolean;
  is_free_trial_conversion: boolean;
  is_in_intro_offer_period: boolean | null;
  is_production: boolean | null;
  original_purchase: string | null;
  revoked_at: string | null;
  canceled_at: string | null;
  payment_issues_began_at: string | null;
  current_term_length: string | null;
  billing_cycles: number | null;
  purchase_currency: string;
  current_price_in_usd: number | null;
  current_purchase_price: number | null;
  in_account_hold: boolean | null;
  in_payment_grace_period: boolean | null;
  in_pause: boolean | null;
  in_grace_period: boolean | null;
  linked_external_ids: string[];
  devices: [];
};

export type TCustomerPurchaseConversionImpression = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  app_env: string;
  call_to_action: {
    id: string;
    created_date: string;
    created_date_humanized: string;
    name: string;
    description: string;
    paywall_type: 'component'; //TODO - others
  };
};

export type TCustomerPurchaseEvent = {
  id: string;
  created_date: string;
  created_date_humanized: string;
  purchase: string;
  event_time: string;
  nami_event_type: TNamiPurchaseEventType;
  nami_event_sub_type: string | null; //TODO
  revenue: string | null;
  currency: string | null;
  pricing: string | null;
  pricing_source:
    | 'sdk_cross_checked'
    | 'push_notification'
    | 'same_purchase'
    | null;
  revenue_mode: 'testing' | null;
  auto_renews: boolean | null;
  quantity: number | null;
  not_before: string;
  expires_at: string;
  source_type: 'refresh' | 'push';
  source_id: string;
  speculative: boolean;
  disclaimers: string[] | null;
  revenue_in_usd: number | null;
  transaction_id: number | null;
};

export type TNamiPurchaseEventType =
  | 'refresh_required'
  | 'canceled'
  | 'deferred'
  | 'expected_resumption'
  | 'extended'
  | 'expired'
  | 'pause_changed'
  | 'paused'
  | 'pending_payment'
  | 'pending_payment_grace'
  | 'pending_payment_suspend'
  | 'price_consent_requested'
  | 'product_change'
  | 'price_consent'
  | 'purchased'
  | 'recovered'
  | 'refund_requested'
  | 'refund_declined'
  | 'offer_redemption'
  | 'refunded'
  | 'renewed'
  | 'revoked'
  | 'restart'
  | 'resubscribed'
  | 'service_credit'
  | 'unpause'
  | 'autorenew_on'
  | 'autorenew_off'
  | 'expected_end'
  | 'converted_from_free'
  | 'chargeback'
  | 'chargeback_reversed'
  | 'voided';

export const TNamiPurchaseEventPrettyNames: Record<
  TNamiPurchaseEventType,
  string
> = {
  refresh_required: 'Refresh Required', //TODO
  canceled: 'Cancellation',
  deferred: 'Deferred Billing Scheduled', //TODO
  expected_resumption: 'Auto Renew Expected',
  extended: 'Subscription Extention',
  expired: 'Expiration',
  pause_changed: 'Pause Pending',
  paused: 'Pause',
  pending_payment: 'Payment Pending',
  pending_payment_grace: 'Pending Payment (Grace Period)', //TODO
  pending_payment_suspend: 'Pending Payment (Suspended)', //TODO
  price_consent_requested: 'Notification of Price Increase',
  product_change: 'Subscription Product Change', //TODO
  price_consent: 'Price Increase Consent', //TODO
  purchased: 'Purchase',
  recovered: 'Renewal after Payment Failure', //TODO,
  refund_requested: 'Refund Request',
  refund_declined: 'Refund Decline', //TODO
  refunded: 'Purchase Refund', //TODO
  offer_redemption: 'Offer Code Redemption', //TODO
  renewed: 'Renewal',
  revoked: 'Revoked',
  restart: 'Subscription Restart', //TODO
  resubscribed: 'Resubscribe',
  service_credit: 'Service Credit Issue',
  unpause: 'Resume',
  autorenew_on: 'Autorenew Enable',
  autorenew_off: 'Autorenew Disable',
  expected_end: 'Subscription Expected to End', //TODO
  converted_from_free: 'Free Trial Conversion',
  chargeback: 'Chargeback Dispute Initiate', //TODO
  chargeback_reversed: 'Chargeback Dispute Reverse', //TODO
  voided: 'Void', //TODO
};

export const purchaseEventTypeIcons: Record<
  TNamiPurchaseEventType,
  JSX.Element
> = {
  refresh_required: <RefreshOutlined />,
  canceled: <SyncDisabledOutlined />,
  deferred: <SnoozeOutlined />,
  expected_resumption: <AutorenewOutlined />,
  extended: <MoreTimeOutlined />,
  expired: <CancelOutlined />,
  pause_changed: <PauseCircleOutline />,
  paused: <PauseCircleOutline />,
  pending_payment: <CreditCardOffOutlined />,
  pending_payment_grace: <CreditCardOffOutlined />,
  pending_payment_suspend: <CreditCardOffOutlined />,
  price_consent_requested: <PriceChangeOutlined />,
  product_change: <ChangeCircleOutlined />,
  price_consent: <PriceChangeOutlined />,
  purchased: <PaidOutlined />,
  recovered: <AutorenewOutlined />,
  refund_requested: <AttachMoney />,
  refund_declined: <MoneyOffOutlined />,
  refunded: <PriceCheckOutlined />,
  offer_redemption: <SellOutlined />,
  renewed: <AutorenewOutlined />,
  revoked: <BlockOutlined />,
  restart: <RestartAltOutlined />,
  resubscribed: <RestartAltOutlined />,
  service_credit: <CardGiftcardOutlined />,
  unpause: <PlayCircleOutline />,
  autorenew_on: <SyncOutlined />,
  autorenew_off: <SyncDisabledOutlined />,
  expected_end: <CancelOutlined />,
  converted_from_free: <ScienceOutlined />,
  chargeback: <MoneyOffOutlined />,
  chargeback_reversed: <AttachMoney />,
  voided: <BlockOutlined />,
};

export type TNamiUserType =
  | 'anonymous_device'
  | 'signed_in'
  | 'anonymous_buyer'
  | 'signed_in_buyer';

export type TCustomerPlatform = {
  id: string;
  name: string;
  platform_type: string; //TODO
  app: {}; //TODO
};

export type TExternalUser = {
  id: string;
  created_date: string;
  external_id: string;
  psuedonym: string;
};

export type TCustomerAttribute = {
  title: string;
  value: string | JSX.Element;
  copyable: boolean;
  copyValue?: string;
  monospace?: boolean;
  empty?: boolean;
  icon?: JSX.Element;
};

export type TSDKV3EligibleDeviceCampaignRule = {
  rule: string;
  name: string;
  paywall: string;
  segment: string;
  form_factors: []; //TODO
  type: 'label' | 'default' | 'url';
  value: string;
};

export type TSDKV3EligibleDevicePaywall = {
  id: string;
  name: string;
  paywall_type: 'component' | 'legacy'; //TODO
  template: TPaywallTemplate;
  //TODO - sku menus, template, fonts?
};

export const TCustomerPurchaseTransforms: Record<
  string,
  (data: any) => TCustomerAttribute
> = {
  id: convertIdToCDA,
  created_date: convertCreatedDateToCDA,
  not_before: convertNotBeforeToCDA,
  last_update: convertUpdatedDateToCDA,
  current_price_in_usd: convertUSDPriceToCDA,
  current_purchase_price: convertPriceToCDA,
  expires_at: convertExpiredDateToCDA,
  current_term_length: convertTermLengthToCDA,
  purchase_country: convertPurchaseCountryToCDA,
  purchase_currency: convertPurchaseCurrencyToCDA,
  product_platform_type: convertPlatformTypeToCDA,
};

export const TCustomerPurchaseEventTransforms: Record<
  string,
  (data: any) => TCustomerAttribute
> = {
  id: convertIdToCDA,
  event_time: convertCreatedDateToCDA,
  expires_at: convertExpiredDateToCDA,
  currency: convertPurchaseCurrencyToCDA,
  revenue: convertRevenueToCDA,
  revenue_in_usd: convertUSDRevenueToCDA,
  pricing_source: convertSourceTypeToCDA,
  pricing: convertPricingToCDA,
  transaction_id: convertTransactionIdToCDA,
};

export const TCustomerDeviceTransforms: Record<
  string,
  (data: any) => TCustomerAttribute
> = {
  id: convertIdToCDA,
  created_date: convertCreatedDateToCDA,
  updated_date: convertUpdatedDateToCDA,
  profile_external_id: convertExternalIdToCDA,
  profile_form_factor: convertFormFactorToCDA,
  profile_os_name: convertOSNameToCDA,
  profile_os_version: convertOSVersionToCDA,
  profile_app_version: convertAppVersionToCDA,
  profile_app_environment: convertAppEnvToCDA,
  profile_advertising_id: convertAdIdToCDA,
  profile_marketplace_user_id: convertMarketplaceIDToCDA,
  profile_external_user_id: convertUserIdToCDA,
  //profile_audience_split_pos
  //profile_cdp_audiences
  profile_browser_name: convertBrowserNameToCDA, //TODO-same as app version?
  profile_browser_version: convertBrowserVersionToCDA,
  profile_customer_data_platform_id: convertCDPIdToCDA,
  profile_geo_ip_country: convertGeoIPCountryToCDA,
  profile_marketplace_country: convertMarketplaceCountryToCDA,
  profile_os_country: convertOSCountryToCDA,
  profile_os_language: convertLanguageToCDA,
  profile_model: convertModelToCDA,
  profile_sdk_version: convertSDKVersionToCDA,
  profile_signed_in: convertSigninToCDA,
  //profile_vendor_id
};

export function convertIdToCDA(value: string | null): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'ID', true, true);
}

export function convertUserIdToCDA(value: string | null): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'Nami Customer ID', true, true);
}

export function convertExternalIdToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'External ID', true, true);
}

export function convertCDPIdToCDA(value: string | null): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'CDP ID', true, true);
}

export function convertAdIdToCDA(value: string | null): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'Advertising ID', true, true);
}

export function convertMarketplaceIDToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'Marketplace ID', true, true);
}

export function convertCreatedDateToCDA(
  value: string | null
): TCustomerAttribute {
  return constructDateFromCDA(value, 'Created');
}

export function convertNotBeforeToCDA(
  value: string | null
): TCustomerAttribute {
  return constructDateFromCDA(value, 'Purchase Date');
}

export function convertUpdatedDateToCDA(
  value: string | null
): TCustomerAttribute {
  return constructDateFromCDA(value, 'Updated');
}

export function convertOSNameToCDA(value: string | null): TCustomerAttribute {
  let title = value || '-';
  let icon: React.ReactNode = <></>;
  if (value) {
    const osType = getOperatingSystemType(value);
    title = osType === 'android' ? 'Android' : osType;
    icon = osIcons[osType];
  }

  return {
    title: 'OS',
    value: (
      <Space direction="horizontal" size={4}>
        {icon}
        {title}
      </Space>
    ),
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertOSVersionToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'OS Version', true);
}

export function convertAppVersionToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'App Version', true);
}

export function convertAppEnvToCDA(value: string | null): TCustomerAttribute {
  return {
    title: 'App Environment',
    value: value ? toReadable(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertBrowserNameToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'Browser', false, false, true);
}

export function convertBrowserVersionToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(
    value,
    'Purchase Currency',
    false,
    false,
    true
  );
}

export function convertFormFactorToCDA(
  value: string | null
): TCustomerAttribute {
  return {
    title: 'Form Factor',
    value: (
      <Space direction="horizontal" size={4}>
        {formFactorIcons[value as TDevice | TFormFactorOrientation]}
        {value ? capitalize(value) : '-'}
      </Space>
    ),
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertGeoIPCountryToCDA(
  value: string | null
): TCustomerAttribute {
  return {
    title: 'Geolocation',
    value: value ? convertCountryCode(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertMarketplaceCountryToCDA(
  value: string | null
): TCustomerAttribute {
  return {
    title: 'Marketplace Country',
    value: value ? convertCountryCode(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertOSCountryToCDA(
  value: string | null
): TCustomerAttribute {
  return {
    title: 'Region',
    value: value ? convertCountryCode(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertLanguageToCDA(value: string | null): TCustomerAttribute {
  return {
    title: 'Language',
    value: value ? `${translateStringToLanguage(value)} (${value})` : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertSigninToCDA(value: boolean | null): TCustomerAttribute {
  return {
    title: 'Signed In',
    value: (
      <Space direction="horizontal" size={3}>
        {value ? (
          <CheckCircleTwoTone
            style={{
              color: namiGreen,
              transform: 'translateY(2px)',
              fontSize: 18,
            }}
          />
        ) : (
          <CancelTwoTone
            style={{
              color: namiCoral,
              transform: 'translateY(2px)',
              fontSize: 18,
            }}
          />
        )}
        {value ? 'Yes' : 'No'}
      </Space>
    ),
    copyable: false,
  };
}

export function convertModelToCDA(value: string | null): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'Model');
}

export function convertSDKVersionToCDA(
  value: string | null
): TCustomerAttribute {
  return constructBasicCDAFromString(value, 'Nami SDK Version');
}

export function convertCountryCode(code: string): JSX.Element {
  if (code in countries) {
    return (
      <Space size={3}>
        <ReactCountryFlag countryCode={code} />
        {`${countries[code as keyof typeof countries]} (${code})`}
      </Space>
    );
  }
  return <span>{code}</span>;
}

function convertUSDPriceToCDA(value: number | null): TCustomerAttribute {
  return {
    title: 'Price (USD)',
    value: value != null ? getUSDCurrency(value) : '-',
    copyable: false,
    monospace: true,
    empty: value == null,
  };
}

function convertUSDRevenueToCDA(value: number | null): TCustomerAttribute {
  return {
    title: 'Revenue (USD)',
    value: value != null ? getUSDCurrency(value) : '-',
    copyable: false,
    monospace: true,
    empty: value == null,
  };
}

function convertPriceToCDA(value: number | null): TCustomerAttribute {
  return {
    title: 'Purchase Price',
    value: `${value}` || '-',
    copyable: false,
    monospace: true,
    empty: value == null,
  };
}

function convertRevenueToCDA(value: number | null): TCustomerAttribute {
  return {
    title: 'Revenue',
    value: value != null ? `${value}` : '-',
    copyable: false,
    monospace: true,
    empty: value == null,
  };
}

export function convertExpiredDateToCDA(
  value: string | null
): TCustomerAttribute {
  return constructDateFromCDA(value, 'Expiration');
}

export function convertPurchaseCountryToCDA(
  value: string | null
): TCustomerAttribute {
  return {
    title: 'Purchase Country',
    value: value ? convertCountryCode(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertPurchaseCurrencyToCDA(value: string | null) {
  return constructBasicCDAFromString(value, 'Purchase Currency');
}

export function convertPlatformTypeToCDA(value: string | null) {
  return {
    title: 'Platform',
    value: value ? (
      <Space direction="horizontal">
        <Image
          height={15}
          width={15}
          preview={false}
          src={platformLogo(value)}
        />
        {capitalize(value)}
      </Space>
    ) : (
      '-'
    ),
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertTermLengthToCDA(value: string | null) {
  return {
    title: 'Subscription Duration',
    value: value ? convertIso8601DurationToReadable(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertPricingToCDA(value: string | null) {
  return constructBasicCDAFromString(value, 'Pricing', false, false, true);
}

export function convertSourceTypeToCDA(value: string | null) {
  return {
    title: 'Source',
    value: value ? toReadable(value) : '-',
    copyable: false,
    monospace: false,
    empty: !value,
  };
}

export function convertTransactionIdToCDA(value: string | null) {
  return constructBasicCDAFromString(value, 'Transaction ID', true, true);
}

function constructBasicCDAFromString(
  value: string | null,
  title: string,
  monospace: boolean = false,
  copyable: boolean = false,
  capitalize: boolean = false
): TCustomerAttribute {
  const valueFormatted =
    value && capitalize ? capitalizeFirstCharacter(value) : value || '-';
  return {
    title: title,
    value: valueFormatted,
    copyable: copyable,
    monospace: monospace,
    empty: !value,
  };
}

function constructDateFromCDA(
  value: string | null,
  title: string,
  format: string = 'YYYY-MM-DD h:mm:ss A'
): TCustomerAttribute {
  return {
    title: title,
    value: value ? moment(value).format(format) : '-',
    copyable: true,
    copyValue: moment(value).toISOString(),
    monospace: true,
    empty: !value,
  };
}
