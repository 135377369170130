import { useCallback, useContext, useMemo, useState } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { useHistory } from 'react-router';
import { TApiOptions } from 'src/api/types/main.types';
import { AppContext } from 'src/AppContextProvider';

import api from '../../api';
import type {
  TLegacyCampaignApiOptions,
  TRuleApiOptions,
} from '../../api/campaings.api';
import type { AppType } from '../../api/types/app.types';
import type {
  CampaignSegmentPayloadType,
  CampaignSegmentType,
  TCampaignLabel,
  TCampaignLabelPayload,
  TCampaignRule,
} from '../../api/types/campaign.types';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

type DispatchUpdateRuleAction = React.Dispatch<
  React.SetStateAction<TRuleApiOptions>
>;

export function useLegacyCampaignsQuery(
  apiOptions?: TLegacyCampaignApiOptions
) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery(
    [QueryKeys.legacyCampaigns, appId, ...spreadApiOptions(apiOptions)],
    () => api.getCampaignsLegacy(appId, apiOptions)
  );
}

export function useCampaignRuleApiOptions(
  initialValue?: TRuleApiOptions
): [TRuleApiOptions, DispatchUpdateRuleAction] {
  const [value, setValue] = useState<TRuleApiOptions>(initialValue || {});

  const updateValue: DispatchUpdateRuleAction = useCallback((newValue) => {
    setValue((state) => {
      const newState =
        typeof newValue === 'function' ? newValue(state) : newValue;
      const pageIsNotChanged = !newState.page || newState.page === state.page;
      const page = pageIsNotChanged ? 1 : newState.page || state.page;
      return { ...state, ...newState, page };
    });
  }, []);

  return [value, updateValue];
}

export function useCampaignQuery() {
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery([QueryKeys.campaigns, appId], () =>
    api.getCampaignRoot(appId).then(({ results }) => results[0])
  );
}

export function useCampaignLabelsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.campaignLabels, appId, ...spreadApiOptions(apiOptions)],
    () => api.getCampaignLabels(appId, apiOptions)
  );
}

export function useCampaignLabelTagsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.campaignLabelTags, appId, ...spreadApiOptions(apiOptions)],
    () => api.getCampaignLabelTags(appId, apiOptions)
  );
}

export function useCampaignRuleTagsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.campaignRuleTags, appId, ...spreadApiOptions(apiOptions)],
    () => api.getCampaignRuleTags(appId, apiOptions)
  );
}

export function useUpdateCampaignLabelMutation(labelId: string) {
  const history = useHistory();
  const queryClient = useQueryClient();
  return useMutation(
    ['updateCampaignLabel'],
    (values: TCampaignLabel) => api.updateCampaignLabel(labelId, { ...values }),
    {
      onSuccess: () => {
        history.push('/campaigns/placements/');
        notification.success({ message: 'Placement updated.' });
        return queryClient.invalidateQueries([
          QueryKeys.campaignLabels,
          QueryKeys.campaignLabelTags,
        ]);
      },
    }
  );
}

export function useDeleteCampaignLabelMutation() {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(['deleteCampaignLabel'], api.deleteCampaignLabel, {
    onSuccess: () => {
      history.push('/campaigns/placements/');
      return queryClient.invalidateQueries([QueryKeys.campaignLabels]);
    },
  });
}

export function useCampaignLabelIdMap() {
  const labels = useCampaignLabelsQuery({ pageSize: 1000 }).data?.results;
  return useMemo(() => {
    return (labels || []).reduce((output, label) => {
      return { ...output, [label.id]: label };
    }, {} as Record<string, TCampaignLabel>);
  }, [labels]);
}

export function useUnarchivedCampaignLabelIdQuery() {
  const labels = useCampaignLabelsQuery({ pageSize: 1000, archived: false })
    .data?.results;
  return useMemo(() => {
    return (labels || []).reduce((output, label) => {
      return { ...output, [label.id]: label };
    }, {} as Record<string, TCampaignLabel>);
  }, [labels]);
}

export function useCampaignLabelQuery(labelId: string) {
  return useQuery([QueryKeys.campaignLabels, labelId], () =>
    api.getCampaignLabel(labelId)
  );
}

export function useCampaignRulesQuery(apiOptions?: TRuleApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  return useQuery(
    [QueryKeys.campaignRules, appId, ...spreadApiOptions(apiOptions)],
    () => api.getCampaignRules(appId, apiOptions)
  );
}

export function useCampaignRuleQuery(ruleId: string) {
  const history = useHistory();
  return useQuery([QueryKeys.campaignRules, ruleId], () =>
    api.getCampaignRule(ruleId).catch(() => {
      notification.error({
        message: "Campaign can't be found",
      });
      history.push('/campaigns/rules/');
      return null;
    })
  );
}

export function useCampaignSegmentsQuery(ruleId: string) {
  return useQuery([QueryKeys.campaignSegments, ruleId], async () => {
    const segments = await api.getCampaignSegments(ruleId);
    if (segments.length > 0) return segments;
    const segment = { rule: ruleId, paywall: null, split: 100 };
    return api.addCampaignSegment(segment).then((segment) => [segment]);
  });
}

export function useUpdateCampaignRuleMutation(ruleId: string) {
  const queryClient = useQueryClient();
  const segmentsQuery = useCampaignSegmentsQuery(ruleId);

  const segmentIdMap = (segmentsQuery.data || []).reduce((output, segment) => {
    return { ...output, [buildSegmentKey(segment)]: segment.id };
  }, {} as Record<string, string | undefined>);

  return useMutation(
    ['updateCampaignRule'],
    async ({
      rule,
      segments,
    }: {
      rule: Partial<TCampaignRule>;
      segments: (CampaignSegmentType | CampaignSegmentPayloadType)[];
    }) => {
      const payload = {
        ...rule,
        label: rule.label,
      };
      await Promise.all(updateSegments(segments));
      return api.updateCampaignRule(ruleId, payload);
    },
    {
      onSuccess: () => {
        notification.success({ message: 'Campaign updated.' });
        return Promise.all([
          queryClient.invalidateQueries([QueryKeys.campaignRules]),
          queryClient.invalidateQueries([QueryKeys.campaignLabels]),
          queryClient.invalidateQueries([QueryKeys.campaignSegments]),
          queryClient.invalidateQueries([QueryKeys.campaignRuleTags]),
        ]);
      },
    }
  );

  function updateSegments(
    segments: (CampaignSegmentType | CampaignSegmentPayloadType)[]
  ): Promise<any>[] {
    const idsToDelete = findSegmentIdsToDelete(segments);

    return [
      ...segments.map((segment) => {
        const segmentId =
          segmentIdMap[buildSegmentKey(segment)] ||
          ('id' in segment ? segment.id : null);
        return segmentId
          ? api.updateCampaignSegment(segmentId, segment)
          : api.addCampaignSegment(segment);
      }),
      ...idsToDelete.map((segmentId) => api.deleteCampaignSegment(segmentId)),
    ];
  }

  function findSegmentIdsToDelete(
    segments: (CampaignSegmentPayloadType | CampaignSegmentType)[]
  ) {
    // Need to check both IDs and keys sent because the initial segment that's
    // created doesn't have a paywall, which makes it count as a new segment,
    // even though it has an ID. The side effect is that it will be both updated
    // and deleted.
    const idsSent = new Set();
    const sentKeys = new Set();
    for (const segment of segments) {
      sentKeys.add(buildSegmentKey(segment));
      if ('id' in segment) {
        idsSent.add(segment.id);
      }
    }

    return Object.entries(segmentIdMap).reduce((output, [key, segmentId]) => {
      if (sentKeys.has(key) || idsSent.has(segmentId!)) return output;
      return [...output, segmentId!];
    }, [] as string[]);
  }

  function buildSegmentKey(segment: CampaignSegmentPayloadType) {
    return `${segment.rule}-${segment.paywall}`;
  }
}

export function useNewLabelMutation({
  onSuccess,
}: {
  onSuccess: (label: TCampaignLabel) => void;
}) {
  const queryClient = useQueryClient();
  const campaign = useCampaignQuery().data?.id!;

  return useMutation(
    ['addCampaignLabel'],
    (label: Omit<TCampaignLabelPayload, 'campaign'>) =>
      api.addCampaignLabel({ campaign, ...label }),
    {
      onSuccess: (label) => {
        onSuccess(label);
        return queryClient.invalidateQueries([
          QueryKeys.campaignLabels,
          QueryKeys.campaignLabelTags,
        ]);
      },
    }
  );
}

export function useNewCampaignRuleMutation() {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(['addCampaignRule'], api.addCampaignRule, {
    onSuccess: (rule) => {
      history.push(`/campaigns/${rule.id}/`);
      return Promise.all([
        queryClient.invalidateQueries([QueryKeys.campaignLabels]),
        queryClient.invalidateQueries([QueryKeys.campaignRules]),
      ]);
    },
  });
}

export function usePrioritizeRuleMutation(labelId: string) {
  const queryClient = useQueryClient();

  return useMutation(
    ['prioritizeCampaignRules'],
    (rules: Pick<TCampaignRule, 'id'>[]) => {
      return api.prioritizeCampaignLabelRules(labelId, rules);
    },
    {
      onSuccess: () =>
        Promise.all([
          queryClient.invalidateQueries([QueryKeys.campaignLabels]),
          queryClient.invalidateQueries([QueryKeys.campaignRules]),
        ]),
    }
  );
}

export function useNewLegacyCampaignMutation() {
  const history = useHistory();
  const queryClient = useQueryClient();
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  const data = { name: 'Untitled Campaign', app: appId };
  return useMutation(['addLegacyCampaign'], () => api.addCampaignLegacy(data), {
    onSuccess: (campaign) => {
      history.push(`/campaigns/legacy/${campaign.id}/`);
      return queryClient.invalidateQueries([QueryKeys.campaignRules]);
    },
  });
}

export function useToggleCampaignRuleMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateCampaignRule'],
    (rule: TCampaignRule) =>
      api.updateCampaignRule(rule.id, { enabled: !rule.enabled }),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.campaignRules]),
    }
  );
}

export function useArchiveCampaignRuleMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ['archiveCampaignRule'],
    ({ rule_id, archived }: { rule_id: string; archived: boolean }) =>
      api.updateCampaignRule(rule_id, { archived: !archived }),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.campaignRules]),
    }
  );
}

export function useToggleCampaignLabelMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateCampaignLabel'],
    (label: TCampaignLabel) =>
      api.updateCampaignLabel(label.id, { enabled: !label.enabled }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([QueryKeys.campaignLabels]),
    }
  );
}

export function useArchiveCampaignLabelMutation() {
  const queryClient = useQueryClient();
  return useMutation(
    ['archiveCampaignLabel'],
    ({ label_id, archived }: { label_id: string; archived: boolean }) =>
      api.updateCampaignLabel(label_id, { archived: !archived }),
    {
      onSuccess: () =>
        queryClient.invalidateQueries([QueryKeys.campaignLabels]),
    }
  );
}

export function useDeleteCampaignRuleMutation() {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(['deleteCampaignRule'], api.deleteCampaignRule, {
    onSuccess: () => {
      history.push('/campaigns/');
      return queryClient.invalidateQueries([QueryKeys.campaignRules]);
    },
  });
}

export function useDuplicateCampaignRuleMutation({
  ruleId,
  onSuccess,
}: {
  ruleId: string;
  onSuccess: () => void;
}) {
  const history = useHistory();
  const currentAppId = useAppSelector(({ root }) => root.currentApp.id);
  const queryClient = useQueryClient();
  return useMutation(
    ['duplicateCampaignRule'],
    ({
      app,
      duplicatePaywalls,
    }: {
      app: AppType;
      duplicatePaywalls: boolean;
    }) =>
      api
        .duplicateCampaignRule(ruleId, { app: app.id, duplicatePaywalls })
        .then((rule) => {
          if (currentAppId === app.id) {
            history.push(`/campaigns/${rule.id}/`);
          } else {
            const message = `Campaign rule copied to ${app.name} app.`;
            notification.success({ message });
          }
        }),
    {
      onSuccess: () => {
        onSuccess();
        return Promise.all([
          queryClient.invalidateQueries([QueryKeys.campaignRules]),
          queryClient.invalidateQueries([QueryKeys.campaignSegments]),
          queryClient.invalidateQueries([QueryKeys.campaignLabels]),
          queryClient.invalidateQueries([QueryKeys.paywalls]),
        ]);
      },
    }
  );
}

export function useBulkDuplicateRuleToPlacementsMutation({
  ruleId,
  onSuccess,
}: {
  ruleId: string;
  onSuccess: () => void;
}) {
  const queryClient = useQueryClient();
  return useMutation(
    ['bulkDuplicateRuleToPlacements'],
    ({
      app,
      labels,
      name_template,
    }: {
      app: AppType;
      labels: string[];
      name_template: string;
    }) =>
      api
        .bulkDuplicateCampaignRuleToPlacements(ruleId, {
          app: app.id,
          labels,
          name_template: name_template,
        })
        .then(() => {
          const message = `Campaign rule successfully copied`;
          notification.success({ message });
        }),
    {
      onSuccess: () => {
        onSuccess();
        return Promise.all([
          queryClient.invalidateQueries([QueryKeys.campaignRules]),
          queryClient.invalidateQueries([QueryKeys.campaignSegments]),
        ]);
      },
    }
  );
}

export function useCDPAudiencesQuery() {
  const { userHasEntitlement } = useContext(AppContext);
  const appId = useAppSelector(({ root }) => root.currentApp.id);

  return useQuery(
    [QueryKeys.cdpAudiences, appId],
    () => api.getCDPAudiences(appId),
    {
      enabled: userHasEntitlement('org.cdp_integration.list'),
    }
  );
}
