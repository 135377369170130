import React from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';

type SearchInputProps = {
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  style?: React.CSSProperties;
  intercomClassName?: string;
};

export default function SearchInput({
  placeholder,
  value,
  onChange,
  style,
  intercomClassName = 'intercom-searchTable',
}: SearchInputProps) {
  return (
    <Input
      style={style}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      allowClear
      suffix={<SearchOutlined />}
      className={intercomClassName}
    />
  );
}
