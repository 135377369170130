import React, { HTMLAttributes, useContext, useMemo, useState } from 'react';

import {
  ClockCircleTwoTone,
  EditOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import {
  ContentCopyOutlined,
  DeleteOutline,
  RestoreFromTrashOutlined,
  ShowChartOutlined,
} from '@mui/icons-material';
import {
  Popover,
  Skeleton,
  Space,
  Switch,
  Table,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { arrayMoveImmutable } from 'array-move';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  SortEnd,
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc';
import { AppContext } from 'src/AppContextProvider';
import { TRuleApiOptions } from 'src/api/campaings.api';
import { TAppliedFilters } from 'src/api/types/analytics.types';
import {
  TCampaignRule,
  TCampaignRuleAndFullLabel,
  TCampaignRuleQuery,
} from 'src/api/types/campaign.types';
import { PaywallType } from 'src/api/types/paywall.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import TextActionButton from 'src/components/ActionButtons/TextActionButton';
import Responsive from 'src/components/Responsive/Responsive';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import ScheduledDot from 'src/components/StatusDots/ScheduledDot';
import {
  useArchiveCampaignRuleMutation,
  useCampaignRulesQuery,
  usePrioritizeRuleMutation,
  useToggleCampaignRuleMutation,
} from 'src/hooks/queries/campaign.hooks';
import {
  usePaywallIdMap,
  usePaywallRedirect,
} from 'src/hooks/queries/paywall.hooks';
import { useRuleIdentifierJoinerMap } from 'src/hooks/ruleIdentifierOptions.hooks';
import { findBackgroundColor, findBackgroundImage } from 'src/utils/paywall';
import { truncateString } from 'src/utils/string';
import {
  namiGreen,
  namiLightGray,
  namiMediumGray,
  namiPrimaryBlue,
} from 'src/variables';
import styled from 'styled-components';

import CampaignHoverLabel from './CampaignHoverLabel';
import DuplicateRuleModal from './DuplicateRuleModal';
import { PaywallBackground } from './detail/SegmentFieldSection';
import {
  identifierLabel,
  operatorShortLabelByIdentifierMap,
  operatorShortLabelMap,
} from './detail/selectors/mapsAndOptions';
import EmptyCampaignPlacementStates from './utils/EmptyCampaignPlacementStates';

type CampaignsTableProps = {
  apiOptions: TRuleApiOptions;
  onApiOptionsChange: React.Dispatch<React.SetStateAction<TRuleApiOptions>>;
  selectedRowKeys: React.Key[];
  onSelectChange: (selectedRowKeys: React.Key[]) => void;
};

const RulesParagraph = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
`;

export const DynamicallySizedParagraph = styled(Typography.Paragraph)`
  max-width: 240px;
  margin-bottom: 0 !important;
  cursor: pointer;
  font-weight: 500;
  :hover {
    color: ${namiPrimaryBlue};
    text-decoration: underline;
  }
`;

const CodeParagraph = styled(Typography.Paragraph)`
  max-width: 190px;
  font-family: 'Overpass Mono', 'SFMono-Regular', 'Consolas', 'Liberation Mono',
    Menlo, Courier, monospace;
  margin-bottom: 0 !important;
  font-size: 13px;
  cursor: pointer;
  :hover {
    color: ${namiPrimaryBlue};
    text-decoration: underline;
  }
`;

const DragHandle = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'grab',
      color: '#999',
    }}
  />
));

const DragHandleInvisible = SortableHandle(() => (
  <MenuOutlined
    style={{
      cursor: 'default',
      color: 'transparent',
    }}
  />
));

const SortableItem = SortableElement(
  (props: HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />
);

const SortableBody = SortableContainer(
  (props: HTMLAttributes<HTMLTableSectionElement>) => <tbody {...props} />
);

export default function CampaignsTable({
  apiOptions,
  onApiOptionsChange,
  selectedRowKeys,
  onSelectChange,
}: CampaignsTableProps) {
  const { userHasEntitlement } = useContext(AppContext);
  const history = useHistory();
  const [ruleToDuplicate, setRuleToDuplicate] = useState<string | null>(null);
  const labelId = apiOptions.label_id || null;
  const statusNotSet = apiOptions.enabled === undefined && !apiOptions.archived;
  const prioritizeRulesMutation = usePrioritizeRuleMutation(labelId || ''); //TODO-better handling
  const toggleRuleMutation = useToggleCampaignRuleMutation();
  const archiveRuleMutation = useArchiveCampaignRuleMutation();
  const identifierJoinerMap = useRuleIdentifierJoinerMap();
  const paywallIdValueMap = usePaywallIdMap();
  const redirectToPaywall = usePaywallRedirect();
  const rulesQuery = useCampaignRulesQuery({
    ...apiOptions,
  });

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: rulesQuery.data?.count,
      pageSize: apiOptions.pageSize,
      onChange: (page, pageSize) =>
        onApiOptionsChange((state) => ({ ...state, page, pageSize })),
      current: rulesQuery.data?.page_number,
      showSizeChanger: false,
      style: { paddingBottom: 54, paddingRight: 16 },
      showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
    }),
    [rulesQuery.data, apiOptions.pageSize, onApiOptionsChange]
  );

  const rules = rulesQuery.data?.results || [];
  const loading = rulesQuery.isFetching;

  if (rules.length === 0 && !loading && rulesQuery.isFetched) {
    return (
      <EmptyCampaignPlacementStates
        searching={!!apiOptions.search || !!apiOptions.enabled}
        page="campaigns"
      />
    );
  }

  const dataSource = rules.map((rule) => ({ ...rule, key: rule.id }));

  return (
    <>
      <Responsive size="mdUp">
        <Table
          loading={loading || prioritizeRulesMutation.isLoading}
          rowKey="id"
          components={{
            body: {
              wrapper: (props: SortableContainerProps) => (
                <SortableBody
                  useDragHandle
                  disableAutoscroll
                  helperClass="row-dragging"
                  onSortEnd={onSortEnd}
                  {...props}
                />
              ),
              row: ({ className, style, ...props }: any) => {
                const index = dataSource.findIndex(
                  (rule) => rule.id === props['data-row-key']
                );
                return (
                  <SortableItem
                    index={index}
                    key={`row${index}`}
                    {...props}
                    className={
                      selectedRowKeys.includes(props['data-row-key'])
                        ? 'ant-table-row-selected'
                        : 'ant-table-row'
                    }
                  />
                );
              },
            },
          }}
          dataSource={dataSource}
          columns={getColumns('mdUp')}
          pagination={pagination}
          style={{ cursor: 'default' }}
          scroll={{ x: 1200 }}
          className="horizontalScrollTable"
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }}
        />
      </Responsive>
      <Responsive size="mdDown">
        <Table
          loading={loading || prioritizeRulesMutation.isLoading}
          rowKey="id"
          components={{
            body: {
              wrapper: (props: SortableContainerProps) => (
                <SortableBody
                  useDragHandle
                  disableAutoscroll
                  helperClass="row-dragging"
                  onSortEnd={onSortEnd}
                  {...props}
                />
              ),
              row: ({ className, style, ...props }: any) => {
                const index = dataSource.findIndex(
                  (rule) => rule.id === props['data-row-key']
                );
                return <SortableItem index={index} {...props} />;
              },
            },
          }}
          dataSource={dataSource}
          columns={getColumns('mdDown')}
          pagination={pagination}
          style={{ cursor: 'default' }}
          scroll={{ x: 1200 }}
          className="horizontalScrollTable"
        />
      </Responsive>
      <DuplicateRuleModal
        open={!!ruleToDuplicate}
        ruleId={ruleToDuplicate!}
        onClose={() => setRuleToDuplicate(null)}
      />
    </>
  );

  function getColumns(
    size: 'mdUp' | 'mdDown'
  ): ColumnsType<TCampaignRuleAndFullLabel> {
    return [
      {
        title: '',
        width: labelId && statusNotSet ? 30 : 0,
        className: 'drag-visible',
        render: () => (labelId ? <DragHandle /> : <DragHandleInvisible />),
        fixed: size === 'mdUp' ? 'left' : undefined,
        key: 'reorder',
      },
      {
        title: <Typography.Text strong>On/Off</Typography.Text>,
        width: 100,
        fixed: size === 'mdUp' ? 'left' : undefined,
        render: (rule: TCampaignRuleAndFullLabel) => (
          <Tooltip
            title={
              rule.label.archived && rule.enabled
                ? 'Parent Placement is archived. This campaign rule will not be served if the placement is archived.'
                : ''
            }
          >
            <Switch
              checked={rule.enabled}
              size="small"
              onChange={() =>
                toggleRuleMutation.mutate({
                  ...rule,
                  label: rule.label.id,
                } as TCampaignRule)
              }
              loading={toggleRuleMutation.isLoading}
              disabled={!checkIfCampaignValidToEnable(rule)}
              checkedChildren={
                rule.not_before &&
                moment(rule.not_before).isAfter() && (
                  <ClockCircleTwoTone
                    twoToneColor={namiGreen}
                    style={{ fontSize: 'smaller' }}
                  />
                )
              }
              className="campaignSwitch"
            />
          </Tooltip>
        ),
        key: 'enableSwitch',
      },
      {
        title: <Typography.Text strong>Name</Typography.Text>,
        ellipsis: true,
        render: (rule: TCampaignRuleAndFullLabel) => (
          <CampaignHoverLabel
            value={rule}
            canMove={!!labelId}
            prioritizeAction={(rule: TCampaignRuleAndFullLabel) =>
              moveRuleUp(rule)
            }
            deprioritizeAction={(rule: TCampaignRuleAndFullLabel) =>
              moveRuleDown(rule)
            }
            currentIndex={dataSource.findIndex(
              (dataRule) => dataRule.id === rule.id
            )}
            total={dataSource.length}
          />
        ),
        fixed: size === 'mdUp' ? 'left' : undefined,
        width: 300,
        key: 'name',
      },
      {
        title: <Typography.Text strong>Delivery</Typography.Text>,
        render: (rule: TCampaignRuleAndFullLabel) => renderRuleDelivery(rule),
        width: 150,
        key: 'delivery',
      },
      {
        title: <Typography.Text strong>Placement</Typography.Text>,
        render: (rule: TCampaignRuleAndFullLabel) => (
          <Tooltip title={rule.label.value || ''} mouseEnterDelay={0.4}>
            <Space direction="horizontal" size={4}>
              <CodeParagraph
                ellipsis={true}
                onClick={() =>
                  history.push(
                    `/campaigns/placements?label_id=${rule.label.id}`
                  )
                }
              >
                {rule.label.value || ''}
              </CodeParagraph>
              {rule.label.archived && (
                <DeleteOutline
                  style={{
                    fontSize: 15,
                    color: namiMediumGray,
                  }}
                />
              )}
            </Space>
          </Tooltip>
        ),
        width: 225,
        key: 'placement',
      },
      {
        title: <Typography.Text strong>Rules</Typography.Text>,
        ellipsis: true,
        render: (rule: TCampaignRuleAndFullLabel) =>
          renderRulesList(rule.query),
        width: 300,
        key: 'rules',
      },
      {
        title: <Typography.Text strong>Tags</Typography.Text>,
        render: (rule: TCampaignRuleAndFullLabel) => renderTags(rule),
        width: 200,
        key: 'tags',
      },
      {
        title: <Typography.Text strong>Paywalls</Typography.Text>,
        render: (rule: TCampaignRuleAndFullLabel) => renderPaywallsList(rule),
        width: 300,
        key: 'paywalls',
      },
      {
        title: <Typography.Text strong>Created</Typography.Text>,
        dataIndex: 'created_date',
        ellipsis: true,
        render: (created_date: string) => (
          <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer', fontSize: 13 }}>
              {moment(created_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        title: <Typography.Text strong>Updated</Typography.Text>,
        dataIndex: 'updated_date',
        ellipsis: true,
        render: (updated_date: string) => (
          <Tooltip title={moment(updated_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer', fontSize: 13 }}>
              {moment(updated_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        ellipsis: true,
        render: (rule: TCampaignRule) => (
          <Space direction="horizontal" size={6}>
            {getEditingLink(rule)}
            {getDuplicateLink(rule)}
            {getArchiveLink(rule)}
            {getReportingLink(rule.id)}
          </Space>
        ),
        width: 420,
        key: 'actions',
      },
    ];
  }

  function checkIfCampaignValidToEnable(
    rule: TCampaignRuleAndFullLabel
  ): boolean {
    const expiresInFuture =
      rule.expires_at === null || moment(rule.expires_at).isAfter(moment());
    return (
      userHasEntitlement('app.campaign.update') &&
      rule.segments.every(({ paywall }) => !!paywall) &&
      !rule.archived &&
      expiresInFuture
    );
  }

  function renderRulesList(query: TCampaignRuleQuery): JSX.Element {
    const filters = query?.filter || [];
    if (filters.length === 0) {
      return <Typography.Text italic>none</Typography.Text>;
    }
    const content = (
      <>
        {filters.map((filter, index) => {
          let readableFilter = '';
          if (identifierLabel.hasOwnProperty(filter.identifier)) {
            const operator =
              operatorShortLabelByIdentifierMap[filter.identifier] &&
              operatorShortLabelByIdentifierMap[filter.identifier][
                filter.operator
              ]
                ? operatorShortLabelByIdentifierMap[filter.identifier][
                    filter.operator
                  ]
                : operatorShortLabelMap[filter.operator];
            readableFilter = [
              identifierLabel[filter.identifier],
              operator,
              identifierJoinerMap[filter.identifier](filter.values),
            ].join(' ');
          }
          return (
            <RulesParagraph ellipsis={true} key={`filter${index}`}>
              {readableFilter}
              {index < filters.length - 1 ? ' and' : ''}
            </RulesParagraph>
          );
        })}
      </>
    );
    return (
      <Popover content={content} mouseEnterDelay={1}>
        <span>{content}</span>
      </Popover>
    );
  }

  function renderTags(rule: TCampaignRuleAndFullLabel): React.ReactNode {
    return (
      <span>
        {(rule.tags || []).map((tag) => {
          return <Tag key={tag}>{tag}</Tag>;
        })}
      </span>
    );
  }

  function renderRuleDelivery(
    rule: TCampaignRuleAndFullLabel
  ): React.ReactNode {
    if (rule.enabled && !rule.archived && !rule.label.archived) {
      if (rule.not_before && moment(rule.not_before).isAfter(moment())) {
        return (
          <Popover
            content={
              'Scheduled to go live on ' +
              moment(rule.not_before).format('YYYY-MM-DD h:mm A')
            }
          >
            <Space direction="horizontal" size="small">
              <ScheduledDot />
              <span>Scheduled</span>
            </Space>
          </Popover>
        );
      }
      if (rule.expires_at && moment(rule.expires_at).isAfter(moment())) {
        let labelText = 'Live';
        if (moment(rule.expires_at).diff(moment(), 'days') < 3)
          labelText = 'Ending Soon';
        return (
          <Popover
            content={
              'Live until ' +
              moment(rule.expires_at).format('YYYY-MM-DD h:mm A')
            }
          >
            <Space direction="horizontal" size="small">
              <LiveBadgeDot />
              <span>{labelText}</span>
            </Space>
          </Popover>
        );
      }
      if (rule.expires_at) {
        return (
          <Popover
            content={
              'Ended ' + moment(rule.expires_at).format('YYYY-MM-DD h:mm A')
            }
          >
            <Typography.Text style={{ color: namiMediumGray }}>
              Ended
            </Typography.Text>
          </Popover>
        );
      }
      return (
        <Space direction="horizontal" size="small">
          <LiveBadgeDot />
          <span>Live</span>
        </Space>
      );
    } else if (rule.archived || rule.label.archived) {
      return (
        <Tooltip
          title={
            rule.label.archived
              ? 'Parent Placement is archived. This campaign rule will not be served if the placement is archived.'
              : ''
          }
        >
          <Typography.Text style={{ color: namiMediumGray }}>
            Archived
          </Typography.Text>
        </Tooltip>
      );
    }
    return (
      <Typography.Text style={{ color: namiMediumGray }}>Off</Typography.Text>
    );
  }

  function getPaywallFromSegment(paywallId: string | null): PaywallType | null {
    const paywall = paywallId && paywallIdValueMap[paywallId];
    return paywall || null;
  }

  function renderPaywallsList(
    rule: TCampaignRuleAndFullLabel
  ): React.ReactNode {
    const paywallSegments = rule.segments || [];
    const selectedSegments = paywallSegments.slice(0, 3);
    const remainingSegments = paywallSegments.length - 3;

    return (
      <>
        {!paywallIdValueMap || Object.keys(paywallIdValueMap).length === 0 ? (
          <Skeleton paragraph={false} active title={{ width: '80%' }} />
        ) : (
          <Space
            direction="horizontal"
            size={5}
            wrap
            key={`allSegments${rule.id}`}
          >
            {selectedSegments.map((segment, index) => {
              const paywall = getPaywallFromSegment(segment.paywall);
              if (paywall) {
                return (
                  <Tooltip
                    title={paywall.name}
                    mouseEnterDelay={0.75}
                    key={`paywall${segment.id}tooltip`}
                  >
                    <Space
                      direction={'horizontal'}
                      size={0}
                      key={`paywall${segment.id}space`}
                    >
                      <PaywallBackground
                        height={28}
                        preview={false}
                        width={28}
                        color={findBackgroundColor(paywall)}
                        src={findBackgroundImage(paywall) || undefined}
                        onClick={() => paywall && redirectToPaywall(paywall)}
                        style={{
                          cursor: 'pointer',
                          border: `1px solid ${namiLightGray}`,
                        }}
                        key={`paywall${segment.id}Image`}
                      />
                      {selectedSegments.length === 1 && (
                        <TextActionButton
                          type="text"
                          size="small"
                          onClick={() => paywall && redirectToPaywall(paywall)}
                          style={{
                            fontSize: 'small',
                            paddingLeft: 2,
                            marginLeft: 5,
                          }}
                          key={`paywall${segment.id}RemoveButton`}
                        >
                          {truncateString(paywall.name, 30, false)}
                        </TextActionButton>
                      )}
                    </Space>
                  </Tooltip>
                );
              } else {
                if (selectedSegments.length === 1) {
                  return (
                    <span
                      style={{ fontStyle: 'italic' }}
                      key={`noPaywall${index}`}
                    >
                      None
                    </span>
                  );
                }
                return <span key={`emptyPaywall${index}`} />;
              }
            })}
            {remainingSegments > 0 && (
              <Typography.Link key={`remainingSegments${rule.id}`}>
                <Tag onClick={() => history.push(`/campaigns/${rule.id}/`)}>
                  + {remainingSegments} more
                </Tag>
              </Typography.Link>
            )}
          </Space>
        )}
      </>
    );
  }

  function moveRuleUp(rule: TCampaignRuleAndFullLabel) {
    const currentRuleIndex = dataSource.findIndex(
      (dataRule) => dataRule.id === rule.id
    );
    if (currentRuleIndex === 0) return;
    const newData = arrayMoveImmutable(
      dataSource.slice(),
      currentRuleIndex,
      currentRuleIndex - 1
    );
    prioritizeRulesMutation.mutate(newData);
  }

  function moveRuleDown(rule: TCampaignRuleAndFullLabel) {
    const currentRuleIndex = dataSource.findIndex(
      (dataRule) => dataRule.id === rule.id
    );
    if (currentRuleIndex === dataSource.length) return;
    const newData = arrayMoveImmutable(
      dataSource.slice(),
      currentRuleIndex,
      currentRuleIndex + 1
    );
    prioritizeRulesMutation.mutate(newData);
  }

  function onSortEnd({ oldIndex, newIndex }: SortEnd) {
    if (oldIndex === newIndex) return;
    const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex);
    console.log(newData);
    prioritizeRulesMutation.mutate(newData);
  }

  function getEditingLink(rule: TCampaignRule): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        size="small"
        icon={<EditOutlined style={{ fontSize: '13px' }} />}
        disabled={!userHasEntitlement('app.campaign.get')}
        onClick={() => history.push(`/campaigns/${rule.id}/`)}
      >
        Edit
      </IconActionButton>
    );
  }

  function getDuplicateLink(rule: TCampaignRule): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={<ContentCopyOutlined style={{ fontSize: '14px' }} />}
        size="small"
        disabled={!userHasEntitlement('app.campaign.create')}
        onClick={() => setRuleToDuplicate(rule.id)}
      >
        Duplicate
      </IconActionButton>
    );
  }

  function getArchiveLink(rule: TCampaignRule): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={
          rule.archived ? (
            <RestoreFromTrashOutlined
              style={{
                fontSize: 15,
              }}
            />
          ) : (
            <DeleteOutline
              style={{
                fontSize: 15,
              }}
            />
          )
        }
        size="small"
        disabled={!userHasEntitlement('app.campaign.update')}
        onClick={() =>
          archiveRuleMutation.mutate({
            rule_id: rule.id,
            archived: !!rule.archived,
          })
        }
      >
        {rule.archived ? 'Restore' : 'Archive'}
      </IconActionButton>
    );
  }

  function getReportingLink(ruleId: string): React.ReactNode {
    if (!userHasEntitlement('app.analytics.impressions')) return null;
    const filters: TAppliedFilters = {
      filter_by_campaign: {
        identifier: 'CampaignRule.id',
        operator: 'equals',
        values: [ruleId],
      },
    };

    const urlMeta = '?filters=' + JSON.stringify([filters.filter_by_campaign]);

    return (
      <IconActionButton
        type="text"
        icon={<ShowChartOutlined style={{ fontSize: '15px' }} />}
        href={`/insights/impressions/${urlMeta}`}
        size="small"
      >
        Impressions
      </IconActionButton>
    );
  }
}
