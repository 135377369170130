import React, { useState } from 'react';

import {
  BorderClearRounded,
  BorderOuterRounded,
  BorderStyleRounded,
} from '@mui/icons-material';
import { Button, Col, Form, Popover, Row, Space } from 'antd';
import { BorderLocationType } from 'src/api/types/paywallTemplate.types';
import { namiPrimaryBlue } from 'src/variables';
import styled, { css } from 'styled-components';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type RoundBorderSelectProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  value?: string | null;
  onChange: (value: any) => void;
  label: string;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
};

const BorderOptionButton = styled(Button)<{ selected: boolean }>`
  ${({ selected }) => {
    if (selected)
      return css`
        border: 1px solid ${namiPrimaryBlue};

        svg {
          color: ${namiPrimaryBlue};
          font-size: 16px;
        }
      `;
    return css`
      svg {
        font-size: 16px;
      }
    `;
  }}
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

const BorderSelectButton = styled(Button)`
  &,
  &:hover,
  &:focus {
    flex-grow: 1;
    border: 1px solid rgb(229, 229, 229);
  }
`;

export default function RoundBorderSelector({
  value,
  onChange,
  ...field
}: RoundBorderSelectProps) {
  const [roundBorderValues, setRoundBorderValues] =
    useState<Array<BorderLocationType>>(value);

  return (
    <>
      <Form.Item label={<InputLabel {...field} />}>
        <Wrapper className="ant-btn-group">
          <Popover
            overlayStyle={{ padding: 0, width: '150px' }}
            overlayInnerStyle={{ width: '150px' }}
            trigger="click"
            content={
              <>
                <Row style={{ width: '100%' }}>
                  <Col span={12}>
                    <BorderOptionButton
                      icon={<BorderStyleRounded />}
                      type="ghost"
                      selected={roundBorderValues.includes('upperLeft')}
                      onClick={() => toggleBorder('upperLeft')}
                    />
                  </Col>
                  <Col span={12}>
                    <BorderOptionButton
                      icon={<BorderStyleRounded className="iconRotate90" />}
                      type="ghost"
                      selected={roundBorderValues.includes('upperRight')}
                      onClick={() => toggleBorder('upperRight')}
                    />
                  </Col>
                </Row>
                <Row style={{ width: '100%' }}>
                  <Col span={12}>
                    <BorderOptionButton
                      icon={<BorderStyleRounded className="iconRotate270" />}
                      type="ghost"
                      selected={roundBorderValues.includes('lowerLeft')}
                      onClick={() => toggleBorder('lowerLeft')}
                    />
                  </Col>
                  <Col span={12}>
                    <BorderOptionButton
                      icon={<BorderStyleRounded className="iconRotate180" />}
                      type="ghost"
                      selected={roundBorderValues.includes('lowerRight')}
                      onClick={() => toggleBorder('lowerRight')}
                    />
                  </Col>
                </Row>
              </>
            }
          >
            <BorderSelectButton>{buildLabel()}</BorderSelectButton>
          </Popover>
        </Wrapper>
      </Form.Item>
    </>
  );

  function buildLabel(): React.ReactNode {
    if (roundBorderValues.length === 4)
      return (
        <Space direction="horizontal" size={6}>
          <BorderOuterRounded style={{ fontSize: 15 }} />
          All
        </Space>
      );
    if (!roundBorderValues.length) {
      return (
        <Space direction="horizontal" size={6}>
          <BorderClearRounded style={{ fontSize: 15 }} />
          None
        </Space>
      );
    }
    return (
      <Space direction="horizontal" size={6}>
        <BorderStyleRounded style={{ fontSize: 15 }} />
        Mixed
      </Space>
    );
  }

  function toggleBorder(border: BorderLocationType) {
    if (roundBorderValues.includes(border)) {
      const newValues = roundBorderValues.reduce((output, borderVal) => {
        if (borderVal === border) return output;
        return [...output, borderVal];
      }, [] as BorderLocationType[]);
      setRoundBorderValues(newValues);
      onChange(newValues);
    } else {
      setRoundBorderValues([...roundBorderValues, border]);
      onChange([...roundBorderValues, border]);
    }
  }
}
