import React, { useContext, useState } from 'react';

import {
  EditOutlined,
  EyeInvisibleOutlined,
  ForkOutlined,
} from '@ant-design/icons';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import { Space, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import { TCampaignRuleAndFullLabel } from 'src/api/types/campaign.types';
import { AppContext } from 'src/AppContextProvider';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { namiPrimaryBlue } from 'src/variables';

import { DynamicallySizedParagraph } from './CampaignsTable';

type HoverLabelProps = {
  value: TCampaignRuleAndFullLabel;
  canMove: boolean;
  prioritizeAction: (rule: TCampaignRuleAndFullLabel) => void;
  deprioritizeAction: (rule: TCampaignRuleAndFullLabel) => void;
  currentIndex: number;
  total: number;
};

export default function CampaignHoverLabel({
  value,
  canMove,
  prioritizeAction,
  deprioritizeAction,
  currentIndex,
  total,
}: HoverLabelProps) {
  const history = useHistory();
  const { userHasEntitlement } = useContext(AppContext);
  const [hoverActive, setHoverActive] = useState(false);

  // console.log(`campaign ${value.name} at index #${currentIndex} of ${total}`);

  function getActionToolbar() {
    return (
      <Space direction="horizontal" size={0}>
        <IconActionButton
          type="text"
          size="small"
          icon={<EditOutlined style={{ fontSize: '11px' }} />}
          disabled={!userHasEntitlement('app.campaign.get')}
          onClick={() => history.push(`/campaigns/${value.id}/`)}
          style={{ fontSize: 'small' }}
        >
          {`Edit ${canMove ? '' : 'Campaign Rule'}`}
        </IconActionButton>
        {canMove && (
          <>
            <IconActionButton
              type="text"
              size="small"
              icon={<ArrowUpward style={{ fontSize: '14px' }} />}
              disabled={
                !userHasEntitlement('app.campaign.update') || currentIndex === 0
              }
              onClick={() => prioritizeAction(value)}
              style={{ fontSize: 'small' }}
            >
              Move Up
            </IconActionButton>
            <IconActionButton
              type="text"
              size="small"
              icon={<ArrowDownward style={{ fontSize: '14px' }} />}
              disabled={
                !userHasEntitlement('app.campaign.update') ||
                currentIndex === total - 1
              }
              onClick={() => deprioritizeAction(value)}
              style={{ fontSize: 'small' }}
            >
              Move Down
            </IconActionButton>
          </>
        )}
      </Space>
    );
  }

  return (
    <Space
      direction="vertical"
      size={0}
      onMouseEnter={() => setHoverActive(true)}
      onMouseLeave={() => setHoverActive(false)}
    >
      <Space>
        <Tooltip title={value.name}>
          <DynamicallySizedParagraph
            ellipsis={true}
            onClick={() => history.push(`/campaigns/${value.id}/`)}
          >
            {value.name}
          </DynamicallySizedParagraph>
        </Tooltip>
        {(value.segments || []).length > 1 && (
          <ForkOutlined style={{ color: namiPrimaryBlue }} />
        )}
        {value.anonymous && <EyeInvisibleOutlined />}
      </Space>
      {hoverActive && getActionToolbar()}
    </Space>
  );
}
