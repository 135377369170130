import moment from 'moment';
import {
  TEventLogItem,
  TSubscriberStatusLegacy,
} from 'src/api/types/developer.types';
import { platformLogo } from 'src/services/helpers';

import { capitalize } from './formatting';

export const DEV_MODE = (function (): boolean {
  const value = process.env.REACT_APP_DEV_MODE;
  if (!value) return false;
  try {
    return JSON.parse(value.toLowerCase());
  } catch (e) {
    return false;
  }
})();

export type EventLineItem = {
  key: string;
  name: string;
  value: string;
  copyValue?: string;
  copyable: boolean;
  link?: string;
  image?: string;
};

export function convertEventToLineItems(event: TEventLogItem): EventLineItem[] {
  let result: EventLineItem[] = [];
  result.push(
    {
      key: event.id,
      name: 'Id',
      value: event.id,
      copyable: true,
    },
    {
      key: event.created_date,
      name: 'Date',
      value: moment(event.created_date).format('YYYY-MM-DD h:mm:ss A'),
      copyValue: event.created_date,
      copyable: true,
    }
  );

  if (event.event.data) {
    if (event.event.data.app_env) {
      result.push({
        key: event.event.data.app_env,
        name: 'App Environment',
        value: event.event.data.app_env,
        copyable: false,
      });
    }

    if (event.event.data.call_to_action) {
      result.push({
        key: event.event.data.call_to_action,
        name: 'Paywall',
        value:
          event.event.data.call_to_action_name ||
          event.event.data.call_to_action,
        copyable: false,
        link: `/paywalls/${event.event.data.call_to_action}`,
      });
    }
    if (event.event.data.amount) {
      result.push({
        key: event.event.data.amount,
        name: 'Amount',
        value: `${event.event.data.amount} ${event.event.data.currency || ''}`,
        copyable: false,
      });
    }
    if (event.event.data.sku) {
      result.push({
        key: event.event.data.sku,
        name: 'Product',
        value:
          event.event.data.sku_name ||
          event.event.data.sku_ref_id ||
          event.event.data.sku,
        copyable: false,
        link: `/products/${event.event.data.sku}`,
      });
    }
    if (event.event.data.original_transaction_id) {
      result.push({
        key: 'original_transaction_id',
        name: 'Original Transaction ID',
        value: event.event.data.original_transaction_id,
        copyable: true,
      });
    }
    if (event.event.data.transaction_id) {
      result.push({
        key: 'transaction_id',
        name: 'Transaction ID',
        value: event.event.data.transaction_id,
        copyable: true,
      });
    }
  }

  if (event.event) {
    if (event.event.user_id) {
      result.push({
        key: `user_id_${event.event.user_id}`,
        name: 'Nami User ID',
        value: event.event.user_id,
        copyable: true,
        //link: `/customers-beta/${event.event.user_id}/`,
      });
    }
    if (event.event.previous_external_id) {
      result.push({
        key: `previous_external_id_${event.event.previous_external_id}`,
        name: 'Previous External ID',
        value: event.event.previous_external_id,
        copyable: true,
      });
    }
    if (event.event.previous_nami_user_id) {
      result.push({
        key: `previous_nami_user_id_${event.event.previous_nami_user_id}`,
        name: 'Previous Nami User ID',
        value: event.event.previous_nami_user_id,
        copyable: true,
        link: `/customers/${event.event.previous_nami_user_id}/`,
      });
    }
    if (event.event.nami_user_id) {
      result.push({
        key: `nami_user_id_${event.event.nami_user_id}`,
        name: 'Nami User ID',
        value: event.event.nami_user_id,
        copyable: true,
        link: `/customers/${event.event.nami_user_id}/`,
      });
    }
    if (event.event.current_nami_user_id) {
      result.push({
        key: `current_nami_user_id_${event.event.current_nami_user_id}`,
        name: 'Current Nami User ID',
        value: event.event.current_nami_user_id,
        copyable: true,
        link: `/customers/${event.event.current_nami_user_id}/`,
      });
    }
    if (event.event.current_external_id) {
      result.push({
        key: `current_external_id_${event.event.current_external_id}`,
        name: 'External ID',
        value: event.event.current_external_id,
        copyable: true,
      });
    }
    if (event.event.external_id) {
      result.push({
        key: `external_id_${event.event.external_id}`,
        name: 'External ID',
        value: event.event.external_id,
        copyable: true,
      });
    }
    if (event.event.event_platform) {
      result.push({
        key: `event_platform_${event.event.event_platform}`,
        name: 'Platform',
        value: capitalize(event.event.event_platform),
        copyable: false,
      });
    }
    if (
      event.event.active_entitlements &&
      event.event.active_entitlements.length > 0
    ) {
      if (event.event.active_entitlements[0].sku_ref_id) {
        result.push({
          key: event.event.active_entitlements[0].sku_ref_id,
          name: 'Product',
          value:
            event.event.active_entitlements[0].sku_name ||
            event.event.active_entitlements[0].sku_ref_id,
          copyable: false,
          //link: `/products/${event.event.data.sku}`, //TODO
        });
      }
      //TODO - handle multiple entitlements
      if (event.event.active_entitlements[0].entitlement_ref_id) {
        result.push({
          key: event.event.active_entitlements[0].entitlement_ref_id,
          name: 'Entitlement',
          value:
            event.event.active_entitlements[0].name ||
            event.event.active_entitlements[0].entitlement_ref_id,
          copyable: false,
          link: `/entitlements/${event.event.active_entitlements[0].entitlement_id}`,
        });
      }
    }
    if (event.event.subscriber_status) {
      result.push({
        key: 'subscriber_status',
        name: 'Subscriber Status',
        value: convertSubscriberStatusToTag(event.event.subscriber_status),
        copyable: false,
      });
    }
  }

  if (event.device) {
    result.push(
      {
        key: `device_id_${event.device.id}`,
        name: 'Device ID',
        value: event.device.id,
        copyable: true,
        //link: `/customers-beta/devices/${event.device.id}/`,
      },
      {
        key: `platform_${event.device.platform.name}`,
        name: 'Platform',
        value: event.device.platform.name,
        copyable: false,
        link: `/integrations/${event.device.platform.platform_type}/${event.device.platform.id}`,
        image: platformLogo(event.device.platform.platform_type),
      }
    );
  }
  return result;
}

export function getListDescriptionForEvent(event: TEventLogItem): string {
  if (['device.session.start', 'device.session.end'].includes(event.event_type))
    return '';
  if (event.event_type === 'device.impression')
    return event.event.data?.call_to_action_name || '';
  if (event.event_type === 'device.transaction')
    return event.event.data?.sku_name || event.event.data?.sku_ref_id || '';
  if (event.event_type.startsWith('user.subscription.')) {
    if (event.event.active_entitlements && event.event.active_entitlements[0]) {
      return (
        event.event.active_entitlements[0].sku_name ||
        event.event.active_entitlements[0].sku_ref_id
      );
    }
  }
  return '';
}

//TODO - status tags
export function convertSubscriberStatusToTag(
  status: TSubscriberStatusLegacy
): string {
  if (status.in_pause) return 'Paused';
  if (status.is_canceled) return 'Canceled';
  if (status.in_account_hold) return 'Account Hold';
  if (status.in_grace_period) return 'Grace Period';
  if (status.in_trial_period) return 'Trialist';
  if (status.former_subscriber) return 'Former Subscriber';
  if (status.in_intro_offer_period) return 'Intro Offer';
  if (status.active_subscriber) return 'Active Subscriber';
  return 'Lead';
}
