import React, { useContext, useMemo, useState } from 'react';

import { UnlockOutlined } from '@ant-design/icons';
import { Form, Input, Modal, Radio, Tooltip } from 'antd';
import { useHistory } from 'react-router-dom';
import type {
  TCampaignLabel,
  TCampaignLabelPayload,
} from 'src/api/types/campaign.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import TagEditor from 'src/components/TagEditor/TagEditor';
import { InfinitePlacementsWebPaywall } from 'src/components/WebPaywalls/InfinitePlacementsWebPaywall';
import { useBooleanState } from 'src/hooks';
import {
  useCampaignLabelTagsQuery,
  useNewLabelMutation,
} from 'src/hooks/queries/campaign.hooks';
import { namiSmoke } from 'src/variables';

import { AppContext } from '../../../AppContextProvider';
import { isValidURL, toReadable } from '../../../utils/string';

type AddPlacementModalProps = {
  isOpen: boolean;
  onAdd?: (placement: TCampaignLabel) => void;
  onClose: () => void;
};

export default function AddPlacementModal({
  isOpen,
  onAdd = () => {},
  onClose,
}: AddPlacementModalProps) {
  const { userHasEntitlement, planHasEntitlement } = useContext(AppContext);
  const [deeplinkPaywallOpen, openDeeplinkPaywall, closeDeeplinkPaywall] =
    useBooleanState(false);
  const [form] = Form.useForm<TCampaignLabelPayload>();
  const history = useHistory();
  const [tags, setTags] = useState<string[]>([]);
  const labelTagsQuery = useCampaignLabelTagsQuery({ pageSize: 1000 });
  const tagItems = useMemo(() => {
    return (labelTagsQuery.data?.results || []).reduce((output, tag) => {
      if (tags.indexOf(tag.name) !== -1) return output;
      return [
        ...output,
        {
          label: tag.name,
          value: tag.name,
        },
      ];
    }, [] as { label: string; value: string }[]);
  }, [labelTagsQuery, tags]);

  const newLabelMutation = useNewLabelMutation({
    onSuccess: (placement) => {
      onAdd(placement);
      onClose();
      form.resetFields();
      history.push(`/campaigns/?label_id=${placement.id}`);
    },
  });
  const userHasDeeplinkAccess = userHasEntitlement(
    'app.campaign.deeplink_type'
  );
  const deeplinkUpgradeTooltipMessage = (
    <IconActionButton
      type="text"
      size="small"
      icon={<UnlockOutlined style={{ fontSize: '13px' }} />}
      style={{ color: namiSmoke }}
      onClick={openDeeplinkPaywall}
    >
      Upgrade your Plan
    </IconActionButton>
  );

  return (
    <>
      <Modal
        title="Add a New Campaign Placement"
        open={isOpen}
        onOk={() => {
          form.validateFields().then(() =>
            newLabelMutation.mutate({
              ...form.getFieldsValue(),
              tags: tags,
            })
          );
        }}
        onCancel={onClose}
        confirmLoading={newLabelMutation.isLoading}
        okText="Add"
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{ type: 'text' }}
          requiredMark={false}
        >
          <Form.Item
            label="Launch From"
            name="type"
            className="intercom-addCampaignName"
            rules={[
              { required: true, message: 'Please pick a placement type' },
            ]}
          >
            <Radio.Group optionType="button" defaultValue="text">
              <Radio.Button value="text">Label</Radio.Button>
              <Radio.Button value="url" disabled={!userHasDeeplinkAccess}>
                <Tooltip
                  title={
                    !userHasDeeplinkAccess
                      ? deeplinkUpgradeTooltipMessage
                      : undefined
                  }
                >
                  Deeplink URL
                </Tooltip>
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Placement Label"
            name="value"
            className="intercom-addCampaignLabel"
            tooltip="Placement labels are used by app code to raise a paywall at a particular place in your app. When adding a new placement label, be sure to update your app code."
            rules={[
              (formInstance) => {
                const isUrlType = formInstance.getFieldValue('type') === 'url';
                return {
                  message: isUrlType
                    ? 'Please enter a valid URL'
                    : 'Please enter an alphanumeric value with no spaces or special characters',
                  validator: (_, value) => {
                    const isValid = isUrlType
                      ? isValidURL(value)
                      : /^[a-zA-Z0-9-_]+$/g.test(value);
                    return isValid ? Promise.resolve() : Promise.reject();
                  },
                };
              },
            ]}
          >
            <Input
              placeholder="e.g. onboarding_flow"
              onChange={setDisplayName}
            />
          </Form.Item>
          <Form.Item
            label="Placement Display Name"
            name="display_name"
            tooltip="Placement display name is only used internally. Can be the same as the placement label."
            hidden={!planHasEntitlement('app.placement.create_infinite')}
          >
            <Input placeholder="e.g. Onboarding Flow" />
          </Form.Item>
          {planHasEntitlement('app.placement.create_infinite') && (
            <TagEditor
              tagItems={tagItems}
              setTags={setTags}
              tags={tags}
              intercomClassName="intercom-addPlacementTags"
            />
          )}
        </Form>
      </Modal>
      <InfinitePlacementsWebPaywall
        visible={deeplinkPaywallOpen}
        onCancel={closeDeeplinkPaywall}
      />
    </>
  );

  function setDisplayName() {
    if (form.getFieldValue('type') === 'text') {
      form.setFieldValue(
        'display_name',
        toReadable(form.getFieldValue('value'))
      );
    } else {
      form.setFieldValue('display_name', form.getFieldValue('value'));
    }
  }
}
