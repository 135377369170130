import { AxiosError, AxiosResponse } from 'axios'; // eslint-disable-line no-unused-vars

import history from '../services/history';
import Token from '../services/token';
import { parseToSnakeCase } from '../utils/parsing';

export const TOKEN_ERRORS = Object.freeze(
  new Set(
    [
      'Token is expired.',
      'Invalid JWT Signature.',
      'Invalid Token.',
      'Token is revoked.',
    ].map((message) => message.toLowerCase())
  )
);

export function extractResponseData<T extends AxiosResponse>(
  response: T
): T['data'] {
  return response.data;
}

export function extractListResponseData(response: AxiosResponse): any[] {
  return response.data?.results;
}

export function extractErrorData(error: AxiosError): Promise<never> {
  return Promise.reject(error.response?.data);
}

export function handlePreAuthResponseError(error: AxiosError): never {
  if (isTokenError(error)) {
    Token.removePreAuth();
    history.push('/login/');
  }
  throw error;
}

function isTokenError(error: AxiosError): boolean {
  if (error.response?.status !== 401) return false;
  const message = error.response?.data?.detail.toLowerCase();
  return (
    TOKEN_ERRORS.has(message) ||
    message.startsWith('provided token is not of type')
  );
}

export function buildForm(data: { [key: string]: any }): FormData {
  const form = new FormData();
  for (const [key, value] of Object.entries(data)) {
    const values = Array.isArray(value) ? value : [value];
    for (const item of values) {
      form.append(key, item);
    }
  }
  return form;
}

export function buildURLParams(data: { [key: string]: any }): string {
  const newOptions = Object.keys(data).reduce((output, key) => {
    const value = data[key];
    if (value === undefined || value === '') return output;
    return {
      ...output,
      [key]: value,
    };
  }, {} as { [key: string]: any });
  return new URLSearchParams(parseToSnakeCase(newOptions)).toString();
}
