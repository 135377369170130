import React from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { Button, Form, Header, Segment } from 'semantic-ui-react';

import api from '../../../api';
import NamiIcon from '../../../components/NamiIcon/NamiIcon';
import Wrapper from '../../../components/Wrapper/Wrapper';
import { useAppContext, useInputState, usePromiseState } from '../../../hooks';
import Token from '../../../services/token';

const PreAuthBase = ({
  header,
  subheader,
  cancelLabel,
  cancelTo,
  authType,
}) => {
  const context = useAppContext();
  const [code, setCode] = useInputState('');
  const { isLoading, trigger: performConfirmMFA } = usePromiseState(() =>
    api.confirmMFA(authType, code).then((payload) => {
      Token.removePreAuth();
      context.authorizeAccess(payload);
    })
  );

  return (
    <Wrapper>
      <Segment basic textAlign="center">
        <NamiIcon icon="ml logo" color="primary" size="massive" />
      </Segment>
      <Segment>
        <Form size="large">
          <Header style={{ paddingTop: '1em' }}>{header}</Header>
          <p>{subheader}</p>
          <Form.Input
            label="Verification Code"
            name="verificationCode"
            onChange={setCode}
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Code"
            type="number"
            value={code}
          />
          <Button
            className="nami-primary-button"
            fluid
            size="large"
            style={{ marginBottom: 15 }}
            disabled={code.length < 6}
            onClick={performConfirmMFA}
            loading={isLoading}
          >
            Submit
          </Button>
          <Button as={NavLink} to={cancelTo} fluid size="large">
            {cancelLabel}
          </Button>
        </Form>
      </Segment>
    </Wrapper>
  );
};

PreAuthBase.pageTitle = 'Pre Authentication';

PreAuthBase.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  cancelTo: PropTypes.string.isRequired,
  authType: PropTypes.string.isRequired,
};

export default PreAuthBase;
