import React from 'react';

import { TagsOutlined } from '@ant-design/icons';
import { Error } from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import { Col, Row, Select, Space, Tooltip } from 'antd';
import { TProductGroup } from 'src/api/types/paywall.types';
import {
  TComponentLocation,
  TProductContainer,
} from 'src/api/types/paywallTemplate.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiSmoke } from 'src/variables';

type ProductDataSourceProps = {
  component: TProductContainer;
  componentLocation: TComponentLocation | null;
};

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) => paywallBuilder.groupId,
    ({ paywallBuilder }: RootState) => paywallBuilder.platformId,
    ({ paywallBuilder }: RootState) => paywallBuilder.skuItems,
    ({ paywallBuilder }: RootState) => paywallBuilder.productGroups,
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall!.template['ui.capabilities'],
  ],
  (groupId, platformId, skuItems, productGroups, capabilities) => {
    const itemsForGroupAndPlatform =
      (groupId && platformId && skuItems[groupId][platformId]) || {};
    return {
      itemsForGroupAndPlatform,
      allItems: skuItems,
      platformId,
      productGroups,
      capabilities: capabilities || [],
    };
  }
);

export default function ProductDataSourceSelector({
  component,
  componentLocation,
}: ProductDataSourceProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const componentProducts = component.products;
  const componentSubset = component.subsetGroup;
  const {
    itemsForGroupAndPlatform,
    allItems,
    platformId,
    productGroups,
    capabilities,
  } = useAppSelector(selector);
  let selectOptions: { value: string; label: React.ReactNode }[] = [];

  if (componentProducts === 'all') {
    selectOptions = [{ value: 'all', label: getAllProductsLabel() }];
  } else if (componentProducts === 'subset') {
    productGroups.forEach((group, index) => {
      selectOptions.push({
        value: `\${state.groups.${index}.id}`,
        label: getSubsetProductsLabel(group),
      });
    });
  } else {
    console.warn(`Unrecognized products value: ${componentProducts}`);
  }

  const selectValue = capabilities.includes('product_group_carousel')
    ? 'Current Slide Products'
    : componentProducts === 'all'
    ? componentProducts
    : componentSubset;

  return (
    <Col xs={24} md={24} key={'conditions'} style={{ marginBottom: 16 }}>
      <Row gutter={[0, 10]} style={{ width: '100%' }} wrap>
        <Space direction="horizontal" size={5}>
          Data Source
          <TagsOutlined style={{ fontSize: 14 }} />
        </Space>
        <Select
          options={selectOptions}
          style={{ width: '100%' }}
          value={selectValue}
          onChange={(value) => update(value)}
          disabled={capabilities.includes('product_group_carousel')}
        />
      </Row>
    </Col>
  );

  function update(value: string) {
    if (componentLocation)
      actions.updateComponentSubsetGroupValues({
        idLocation: componentLocation,
        subsetGroup: value,
      });
  }

  function getAllProductsLabel(): React.ReactNode {
    if (Object.keys(itemsForGroupAndPlatform).length)
      return (
        <Tooltip
          title={
            <>
              <span>{`${Object.keys(itemsForGroupAndPlatform).length} product${
                Object.keys(itemsForGroupAndPlatform).length > 1 ? 's' : ''
              } added`}</span>
              <IconActionButton
                type="text"
                size="small"
                icon={<TagsOutlined style={{ fontSize: '13px' }} />}
                style={{ color: namiSmoke }}
                onClick={() => {
                  actions.setEditingMenuItem('products');
                }}
              >
                Edit Products
              </IconActionButton>
            </>
          }
        >
          All Products
        </Tooltip>
      );
    return (
      <Tooltip
        title={
          <>
            <span>No products added to platform</span>
            <IconActionButton
              type="text"
              size="small"
              icon={<TagsOutlined style={{ fontSize: '13px' }} />}
              style={{ color: namiSmoke }}
              onClick={() => {
                actions.setEditingMenuItem('products');
              }}
            >
              Configure Products
            </IconActionButton>
          </>
        }
      >
        <Space direction="horizontal" size={6}>
          All Products
          <Error
            style={{
              fontSize: 15,
              color: 'rgb(250, 173, 20)',
            }}
          />
        </Space>
      </Tooltip>
    );
  }

  function getSubsetProductsLabel(group: TProductGroup): React.ReactNode {
    const currentGroupItems = platformId ? allItems[group.id][platformId] : [];
    if (Object.keys(currentGroupItems).length)
      return (
        <Tooltip
          title={
            <>
              <span>{`${Object.keys(currentGroupItems).length} product${
                Object.keys(currentGroupItems).length > 1 ? 's' : ''
              } added`}</span>
              <IconActionButton
                type="text"
                size="small"
                icon={<TagsOutlined style={{ fontSize: '13px' }} />}
                style={{ color: namiSmoke }}
                onClick={() => {
                  actions.setEditingMenuItem('products');
                }}
              >
                Edit Products
              </IconActionButton>
            </>
          }
        >
          {group.display_name}
          {' Products'}
        </Tooltip>
      );
    return (
      <Tooltip
        title={
          <>
            <span>No products added to group</span>
            <IconActionButton
              type="text"
              size="small"
              icon={<TagsOutlined style={{ fontSize: '13px' }} />}
              style={{ color: namiSmoke }}
              onClick={() => {
                actions.setEditingMenuItem('products');
              }}
            >
              Configure Products
            </IconActionButton>
          </>
        }
      >
        <Space direction="horizontal" size={6}>
          {group.display_name}
          {' Products'}
          <Error
            style={{
              fontSize: 15,
              color: 'rgb(250, 173, 20)',
            }}
          />
        </Space>
      </Tooltip>
    );
  }
}
