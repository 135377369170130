import React, { useState } from 'react';

import { ContentCopyOutlined } from '@mui/icons-material';
import { Button, Tabs } from 'antd';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monoBlue } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import { copyTextToClipBoard } from 'src/services/helpers';
import styled from 'styled-components';

export const CopyButton = styled(Button)`
  float: right;
`;

type CodeSnippetProps = {
  dynamicValue: string;
  platformType?: string;
  builders: [
    string,
    ((dynamicValue: string, platformType?: string) => string) | null
  ][];
};

export default function CodeSnippet({
  dynamicValue,
  platformType,
  builders,
}: CodeSnippetProps) {
  const [currentLanguage, setCurrentLanguage] = useState(builders[0][0]);
  return (
    <Tabs activeKey={currentLanguage} onChange={setCurrentLanguage}>
      {builders.map(([language, buildSnippet]) => {
        if (!buildSnippet) return null;
        const snippet = buildSnippet(dynamicValue, platformType);
        return (
          <Tabs.TabPane tab={language} key={language}>
            <SyntaxHighlighter
              showLineNumbers={true}
              language={cleanLanguage(language)}
              style={monoBlue}
            >
              {snippet}
            </SyntaxHighlighter>
            <CopyButton
              icon={
                <ContentCopyOutlined
                  style={{ fontSize: 16 }}
                  className="inButton"
                />
              }
              onClick={() => copyTextToClipBoard(snippet)}
            >
              Copy
            </CopyButton>
          </Tabs.TabPane>
        );
      })}
    </Tabs>
  );
}

function cleanLanguage(language: string): string {
  const libsAndFrameworks: { [key: string]: string } = {
    Flutter: 'dart',
    'React Native': 'javascript',
  };
  const actualLanguage = libsAndFrameworks[language];
  return actualLanguage || language.replace(/[- ]/g, '').toLowerCase();
}
