import React, { useEffect, useState } from 'react';

import { FileUploadOutlined } from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import { useQueryClient } from '@tanstack/react-query';
import {
  Alert,
  Button,
  Modal,
  notification,
  Space,
  Table,
  Typography,
  UploadFile,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import Upload, { RcFile } from 'antd/lib/upload';
import Papa, { ParseResult } from 'papaparse';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { useParams } from 'react-router-dom';
import api from 'src/api';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useAppContext } from 'src/hooks';
import QueryKeys from 'src/hooks/queries/queryKeys';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

type BulkAddProductGroupModalProps = {
  isOpen: boolean;
  onAdd: () => void;
  onClose: () => void;
};

type ProductGroupData = {
  'Display Name': string;
  'Reference ID': string;
};
type ParseValues = {
  data: ProductGroupData[];
};

const selector = createSelector(
  [({ paywallBuilder: { productGroups } }: RootState) => productGroups],
  (productGroups) => ({
    currentProductGroups: productGroups,
  })
);

export default function BulkCreateProductGroupModal({
  isOpen,
  onClose,
  onAdd,
}: BulkAddProductGroupModalProps) {
  const [file, setFile] = useState<UploadFile | null>(null);
  const [values, setValues] = useState<ParseValues | undefined>();
  const [error, setError] = useState<string>();
  const paywallId = useParams<{ paywallID: string }>().paywallID;
  const { currentProductGroups } = useAppSelector(selector);
  const queryClient = useQueryClient();
  const context = useAppContext();
  const actions = useActions(PaywallBuilderSlice.actions);

  const getCSV = (fileUrl: string) => {
    Papa.parse(fileUrl, {
      header: true,
      download: true,
      skipEmptyLines: true,
      delimiter: ',',
      preview: 75,
      complete: (results: ParseResult<ProductGroupData>) => {
        if (!results.meta.fields?.includes('Display Name')) {
          setError('"Display Name" column required');
        } else if (!results.meta.fields.includes('Reference ID')) {
          setError('"Reference ID" column required');
        } else {
          setValues(results);
        }
      },
    });
  };

  useEffect(() => {
    if (file && file.url) {
      getCSV(file.url);
    }
  }, [file]);

  return (
    <Modal
      title="Import Product Groups"
      open={isOpen}
      centered
      onCancel={() => {
        setError(undefined);
        setValues(undefined);
        setFile(null);
        onClose();
      }}
      zIndex={1005}
      forceRender
      okText={
        values && values.data
          ? `Import ${values.data.length} Product Groups`
          : 'Import Product Groups'
      }
      okButtonProps={{ disabled: !!error || !values || !values.data.length }}
      onOk={(_e) => {
        bulkAdd();
        onClose();
      }}
      width={700}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <Upload
          multiple={false}
          fileList={file ? [file] : []}
          accept=".csv"
          customRequest={({ file }: UploadRequestOption) => handleNewFile(file)}
          onRemove={() => {
            setFile(null);
            setValues(undefined);
            setError(undefined);
          }}
        >
          {!file && (
            <Button
              icon={
                <FileUploadOutlined
                  style={{ fontSize: 16 }}
                  className="inButton"
                />
              }
            >
              Upload CSV File
            </Button>
          )}
        </Upload>
        {!error && !file && (
          <Alert
            message={
              'A template for the CSV file can be found in the Nami documentation portal.'
            }
            type="info"
            showIcon
            action={
              <Button
                size="small"
                type="link"
                href={getDocsLink()}
                target="_blank"
              >
                Go to Docs
              </Button>
            }
          />
        )}
        {error && <Alert message={error} type="error" showIcon />}
        {!error && values && (
          <Table
            dataSource={values.data}
            columns={getColumns()}
            rowKey={'Reference ID'}
            size="small"
            pagination={false}
            scroll={{ y: 400 }}
            className="verticalScrollDrawer"
          />
        )}
      </Space>
    </Modal>
  );

  function getDocsLink() {
    const docsLink = context.getDocsLink();

    if (docsLink !== 'https://docs.namiml.com')
      return `${docsLink}how-to/import-product-groups`;
    return 'https://docs.namiml.com/no-code-paywalls/paywall-creator/capabilities/product-groups#import-product-groups';
  }

  function handleNewFile(file: string | Blob | RcFile): void {
    setFile(buildFile(file));
  }

  function buildFile(file: string | Blob | RcFile): UploadFile {
    const url = typeof file === 'string' ? file : URL.createObjectURL(file);
    const name =
      typeof file === 'object'
        ? (file as RcFile).name || 'Product Groups.csv'
        : url.split('/').reverse()[0];
    return { uid: '-1', name, status: 'done', url };
  }

  function getColumns(): ColumnsType<ProductGroupData> {
    return [
      {
        title: <Typography.Text strong>Display Name</Typography.Text>,
        dataIndex: 'Display Name',
      },
      {
        title: <Typography.Text strong>Reference ID</Typography.Text>,
        dataIndex: 'Reference ID',
        render: (id: string) => {
          return <MonospaceText>{id}</MonospaceText>;
        },
      },
    ] as ColumnsType<ProductGroupData>;
  }

  function bulkAdd() {
    if (values?.data && values.data.length) {
      actions.setUpdating(true);
      const startingIndex = currentProductGroups.length;
      let promises = values.data.map((productGroup, index) =>
        api.createProductGroup({
          paywall: paywallId,
          default: false,
          display_name: productGroup['Display Name'],
          ref: productGroup['Reference ID'],
          display_order: startingIndex + 1 + index,
        })
      );
      Promise.all(promises)
        .then(() => {
          notification.success({
            message: `${values.data.length} product group${
              values.data.length > 1 ? 's' : ''
            } imported.`,
          });
        })
        .catch((reason) => {
          notification.error({
            message: reason.detail || "Couldn't import all product groups",
          });
        })
        .finally(() => {
          onAdd();
          queryClient.invalidateQueries([QueryKeys.paywalls, paywallId]);
          actions.setUpdating(true);
        });
    }
  }
}
