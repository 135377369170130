import React, { useMemo } from 'react';

import { usePaywallsQuery } from 'src/hooks/queries/paywall.hooks';
import { INVALID_PAGE_SIZE } from 'src/utils/parsing';

import SummaryCard from './SummaryCard';

export default function PaywallSummaryCard() {
  const paywalls = usePaywallsQuery({
    pageSize: 20,
    enabled: true,
    archived: false,
  });
  const count = useMemo(() => {
    if (paywalls.data && paywalls.data.count !== INVALID_PAGE_SIZE)
      return paywalls.data.count;
    return '-';
  }, [paywalls.data]);

  return (
    <SummaryCard
      title="Live Paywalls"
      loading={paywalls.isLoading}
      value={count}
      link={'/paywalls?status=enabled'}
    />
  );
}
