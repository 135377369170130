import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import StatusSelect from '../../pages/admin/campaigns/StatusSelect';

type StatusFilterProps = {
  status: string | null;
  onChange: (value: string | null) => void;
  onClear: () => void;
  includeArchived?: boolean;
  options: { label: JSX.Element; text: string; value: string }[];
  intercomClassName: string;
};

const WithWidth = styled.div<{ as: any }>`
  width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

export default function StatusFilter({
  status,
  onChange,
  onClear,
  options,
  intercomClassName,
}: StatusFilterProps) {
  return (
    <SelectWrapper>
      <WithWidth
        as={StatusSelect}
        allowClear
        mode={undefined}
        showArrow={false}
        optionFilterProp="text"
        options={options}
        value={status}
        onChange={(value: string) => onChange(value)}
        onClear={onClear}
        clearIcon={<CloseOutlined />}
        label={status ? 'Status:' : ''}
        className={intercomClassName}
        placeholder={
          <>
            Filter by <b>Status</b>
          </>
        }
      />
    </SelectWrapper>
  );
}
