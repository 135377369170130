import { useContext, useMemo } from 'react';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useHistory } from 'react-router-dom';
import { AppContext } from 'src/AppContextProvider';

import api from '../../api';
import { client } from '../../api/clients';
import {
  TEntitlement,
  TEntitlementPayload,
} from '../../api/types/entitlement.types';
import { TApiOptions } from '../../api/types/main.types';
import { extractErrorData } from '../../api/utils';
import { useAppSelector } from '../redux.hooks';
import { useDebouncedApiOptions } from './apiOptions.hooks';
import QueryKeys from './queryKeys';
import { spreadApiOptions } from './utils';

export function useEntitlementsQuery(apiOptions?: TApiOptions) {
  apiOptions = useDebouncedApiOptions(apiOptions);
  const appId = useAppSelector(({ root }) => root.currentApp.id);
  const { userHasEntitlement } = useContext(AppContext);

  return useQuery(
    [QueryKeys.entitlements, appId, ...spreadApiOptions(apiOptions)],
    () => api.getEntitlements(appId, apiOptions),
    {
      enabled: userHasEntitlement('app.entitlement.list'),
      keepPreviousData: true,
    }
  );
}

export function useEntitlementQuery(entitlementId: string) {
  const history = useHistory();
  return useQuery([QueryKeys.entitlements, entitlementId], () =>
    api.getEntitlement(entitlementId).catch(() => {
      notification.error({
        message: "Entitlement can't be found",
      });
      history.push('/entitlements/');
      return;
    })
  );
}

export function useUpdateEntitlementMutation(entitlementId: string) {
  const queryClient = useQueryClient();
  return useMutation(
    ['updateProduct'],
    (values: Pick<TEntitlementPayload, 'name'>) =>
      api.updateEntitlement(entitlementId, values),
    {
      onSuccess: () => {
        notification.success({ message: 'Entitlement updated.' });
        return queryClient.invalidateQueries([
          QueryKeys.entitlements,
          entitlementId,
        ]);
      },
    }
  );
}

export function useAddEntitlementMutation(
  form: FormInstance<TEntitlementPayload>,
  onSuccess: () => void
) {
  const queryClient = useQueryClient();
  return useMutation(
    ['newEntitlement'],
    () => api.createEntitlement(form.getFieldsValue()),
    {
      onSuccess: () => {
        notification.success({ message: 'Added entitlement successfully.' });
        onSuccess();
        form.resetFields();
        return queryClient.invalidateQueries([QueryKeys.entitlements]);
      },
    }
  );
}

export function useDeleteEntitlementMutation(entitlementId: string) {
  const history = useHistory();
  const queryClient = useQueryClient();

  return useMutation(
    ['deleteEntitlement'],
    () => api.deleteEntitlement(entitlementId),
    {
      onSuccess: () => {
        history.push('/entitlements/');
        return queryClient.invalidateQueries([QueryKeys.entitlements]);
      },
      onError: (error: { delete_warnings?: Record<string, string[]> }) => {
        if (!error?.delete_warnings?.is_used_by_skus) return;
        const message = 'This entitlement has product(s) associated.';
        notification.error({ message });
      },
    }
  );
}

export function useUpdateProductAssociationMutation(
  entitlementId: string,
  { onSuccess = () => {} }: { onSuccess: () => void }
) {
  const queryClient = useQueryClient();

  return useMutation(
    ['updateProductAssociation'],
    (skus: { id: string; associated: boolean }[]) => {
      const url = `/private/entitlements/${entitlementId}/update_skus/`;
      return client.post(url, { skus }).catch(extractErrorData);
    },
    {
      onSuccess: () => {
        onSuccess();
        return queryClient.invalidateQueries([
          QueryKeys.entitlements,
          entitlementId,
        ]);
      },
    }
  );
}

export function useEntitlementRefIdMap() {
  const entitlements = useEntitlementsQuery({ pageSize: 100 }).data?.results;
  return useMemo(() => {
    return (entitlements || []).reduce((output, label) => {
      return { ...output, [label.entitlement_ref_id]: label };
    }, {} as Record<string, TEntitlement>);
  }, [entitlements]);
}
