export function getPercentage(
  current: number,
  previous: number
): number | null {
  if (previous === 0) return null;
  const output = (100.0 * (current - previous)) / previous;
  return +output.toFixed(2);
}

export function getUSDCurrency(value: number): string {
  if (value < 1) {
    return `-$${(value * -1).toFixed(2)}`;
  }
  return `$${value.toFixed(2)}`;
}
