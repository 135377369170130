import React from 'react';

import {
  ClearOutlined,
  ReloadOutlined,
  ShareAltOutlined,
} from '@ant-design/icons';
import { ContentCopyOutlined } from '@mui/icons-material';
import { Button, Input, Popover, Tooltip, notification } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { copyTextToClipBoard } from 'src/services/helpers';
import styled from 'styled-components';

type InsightsDetailToolbarProps = {
  reset: () => void;
  hasChanges: boolean;
};

const ToolbarWrapper = styled.div`
  float: right;
`;

export default function InsightsDetailToolbar({
  reset,
  hasChanges,
}: InsightsDetailToolbarProps) {
  const location = useLocation();
  const history = useHistory();

  return (
    <ToolbarWrapper>
      <Button.Group>
        <Tooltip
          title="Clear Filters"
          mouseEnterDelay={0.75}
          placement="bottom"
        >
          <Button
            icon={<ClearOutlined />}
            size="small"
            onClick={reset}
            disabled={!hasChanges}
          />
        </Tooltip>
        <Tooltip title="Refresh" mouseEnterDelay={0.75} placement="bottom">
          <Button
            icon={<ReloadOutlined />}
            size="small"
            onClick={refreshData}
          />
        </Tooltip>
        <Popover
          title="Share"
          content={getShareContent()}
          overlayInnerStyle={{ width: 350 }}
          placement="bottomRight"
        >
          <Button icon={<ShareAltOutlined />} size="small" />
        </Popover>
      </Button.Group>
    </ToolbarWrapper>
  );

  function getCurrentURL() {
    const appURL = process.env.REACT_APP_API_URL || 'https://app.namiml.com';
    const parsedAppURL = appURL.endsWith('/')
      ? appURL.substring(0, appURL.length - 1)
      : appURL;
    return [parsedAppURL, location.pathname, location.search].join('');
  }

  function getShareContent() {
    return (
      <Input.Group compact>
        <Input
          size="small"
          type="url"
          value={getCurrentURL()}
          style={{ width: 290 }}
        />
        <Tooltip title="Copy to clipboard">
          <Button
            size="small"
            icon={
              <ContentCopyOutlined
                style={{ fontSize: 15 }}
                className="inButton"
              />
            }
            onClick={() => copyValue()}
          />
        </Tooltip>
      </Input.Group>
    );
  }

  function copyValue() {
    const message = `Copied share link to clipboard.`;
    copyTextToClipBoard(getCurrentURL()).then(() =>
      notification.success({ message })
    );
  }

  function refreshData() {
    history.go(0);
  }
}
