import React, { useMemo } from 'react';

import { LeftOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Button, Form } from 'antd';

import { TCarouselSettings } from '../../../../../api/types/paywallTemplate.types';
import { useActions, useAppSelector } from '../../../../../hooks/redux.hooks';
import PaywallBuilderSlice from '../../../../../redux/PaywallBuilderSlice';
import { buildFieldObject } from '../../utils/formFieldBuilding';
import { getAttr } from '../../utils/functions';
import PaywallBuilderDrawer from '../PaywallBuilderDrawer';
import PaywallBuilderDrawerRight from '../PaywallBuilderDrawerRight';
import PaywallBuilderDrawerTitle from '../PaywallBuilderDrawerTitle';
import SmartInput from './SmartInput';

type CarouselSlideEditorProps = {
  carouselSettings: TCarouselSettings | null;
  slides:
    | {
        [groupId: string]: {
          [carouselName: string]: { [slideKey: string]: any }[];
        };
      }
    | undefined;
  formVersion: 'formGroups' | 'v2Form';
};

export default function CarouselSlideEditor({
  slides,
  carouselSettings,
  formVersion,
}: CarouselSlideEditorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const [slideId, groupId, productGroups, carousels] = useAppSelector(
    ({ paywallBuilder }) => {
      return [
        paywallBuilder.editingSlideId,
        paywallBuilder.groupId,
        paywallBuilder.productGroups,
        paywallBuilder.paywall?.template['ui.carousels'],
      ];
    }
  );
  const productGroupCarousel =
    carousels && carousels['carouselName']
      ? carousels['carouselName'].productGroupSlides || false
      : false;

  const [carouselName, fields] = useMemo(() => {
    const emptyOutput = [null, []] as const;
    if (!(slideId && slides && carouselSettings && groupId)) {
      return emptyOutput;
    }
    const slideGroupId = productGroupCarousel ? productGroups[0].id : groupId;
    const carouselSlides = Object.entries(slides[slideGroupId]);
    for (const [carouselName, slides] of carouselSlides) {
      for (let i = 0; i < slides.length; i++) {
        if (slides[i].id !== slideId) continue;
        const slide = slides[i];
        const values = { var: slide, media: slide };
        const fields = carouselSettings[carouselName].fields.map((field) => {
          const value = getAttr(values, ...field.variable.split('.'));
          return buildFieldObject(field, value);
        });
        return [carouselName, fields] as const;
      }
    }
    return emptyOutput;
  }, [
    slideId,
    slides,
    carouselSettings,
    groupId,
    productGroupCarousel,
    productGroups,
  ]);

  const children = (
    <Form layout="vertical">
      {fields.map((field) => {
        return (
          <SmartInput
            {...field}
            key={field.variable}
            label={field.variable}
            onChange={handleChange}
            controlled={true}
          />
        );

        function handleChange(value: any) {
          if (!(slideId && carouselName)) return;
          actions.updateSlide({
            slideId,
            carouselName,
            productGroupCarousel,
            attr: field.variable,
            value,
          });
        }
      })}
      {!productGroupCarousel && (
        <Button
          icon={<DeleteOutline className="inButton" style={{ fontSize: 16 }} />}
          danger
          style={{ width: '100%' }}
          onClick={() => actions.removeSlide()}
        >
          Delete Slide
        </Button>
      )}
    </Form>
  );

  if (formVersion === 'v2Form')
    return (
      <PaywallBuilderDrawerRight
        title={
          <PaywallBuilderDrawerTitle>Edit Slide</PaywallBuilderDrawerTitle>
        }
        onClose={() => actions.setEditingSlideId(null)}
        destroyOnClose={true}
        open={!!slideId}
        closeIcon={<LeftOutlined style={{ fontSize: 15 }} />}
        className="intercom-slideDisplayEditor verticalScrollDrawer"
      >
        {children}
      </PaywallBuilderDrawerRight>
    );

  return (
    <PaywallBuilderDrawer
      title={<PaywallBuilderDrawerTitle>Edit Slide</PaywallBuilderDrawerTitle>}
      onClose={() => actions.setEditingSlideId(null)}
      destroyOnClose={true}
      open={!!slideId}
      closeIcon={<LeftOutlined style={{ fontSize: 15 }} />}
      className="intercom-slideDisplayEditor verticalScrollDrawer"
    >
      {children}
    </PaywallBuilderDrawer>
  );
}
