import React from 'react';

import { Button, Form, Segment } from 'semantic-ui-react';

import api from '../../../api';
import NamiAgreementLinks from '../../../components/NamiAgreementLinks/NamiAgreementLinks';
import NamiIcon from '../../../components/NamiIcon/NamiIcon';
import Page from '../../../components/Page/Page';
import PasswordValidator from '../../../components/PasswordValidator/PasswordValidator';
import Wrapper from '../../../components/Wrapper/Wrapper';
import { useFormState, usePromiseState } from '../../../hooks';
import history from '../../../services/history';
import { extractFieldError } from '../../../services/utilsService';
import { disabled } from '../../../services/validatorsService';

const InviteSignUpPage = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const { form, setFormInput } = useFormState({
    username: queryParams.get('account_verified_email'),
  });
  const {
    isLoading,
    error,
    trigger: signUp,
  } = usePromiseState(getSignupPromise);
  const { username, first_name, last_name, password } = form;

  return (
    <Page title="Sign Up">
      <Wrapper>
        <Segment basic textAlign="center">
          <NamiIcon icon="ml logo" color="primary" size="massive" />
        </Segment>
        <Segment>
          <Form size="large" onSubmit={signUp}>
            <Form.Input
              label="Email"
              name="username"
              icon="mail"
              iconPosition="left"
              placeholder="E-mail Address"
              onChange={setFormInput}
              fluid
              autoCapitalize="none"
              autoCorrect="off"
              value={username || ''}
              error={extractFieldError(error?.username)}
            />
            <Form.Input
              label="First Name"
              name="first_name"
              icon="user"
              iconPosition="left"
              placeholder="First Name"
              onChange={setFormInput}
              fluid
              value={first_name || ''}
              error={extractFieldError(error?.first_name)}
            />
            <Form.Input
              label="Last Name"
              name="last_name"
              icon="user"
              iconPosition="left"
              placeholder="Last Name"
              onChange={setFormInput}
              fluid
              value={last_name || ''}
              error={extractFieldError(error?.last_name)}
            />
            <Form.Input
              label="Password"
              name="password"
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              onChange={setFormInput}
              fluid
              type="password"
              autoComplete="new-password"
              value={password || ''}
              error={extractFieldError(error?.password)}
            />
            <PasswordValidator password={password || ''} />
            <NamiAgreementLinks name="Continue" />
            <Button
              disabled={disabled(
                isLoading,
                first_name,
                last_name,
                username,
                password
              )}
              className="nami-primary-button"
              fluid
              size="large"
              type="submit"
            >
              Continue
            </Button>
          </Form>
        </Segment>
      </Wrapper>
    </Page>
  );

  function getSignupPromise() {
    return api.signUp(form).then(() => {
      history.push('/join/confirm/');
    });
  }
};

export default InviteSignUpPage;
