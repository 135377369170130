import React from 'react';

import { createSelector } from '@reduxjs/toolkit';
import { Button, Col, Form, Input, Modal, Row, Space } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { useParams } from 'react-router-dom';
import { TProductGroupPayload } from 'src/api/types/paywall.types';
import { useAddProductGroupMutation } from 'src/hooks/queries/product.hooks';
import { useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import { toSlug } from 'src/utils/string';

type AddProductGroupModalProps = {
  isOpen: boolean;
  onAdd: () => void;
  onClose: () => void;
};

const selector = createSelector(
  [({ paywallBuilder: { productGroups } }: RootState) => productGroups],
  (productGroups) => ({
    currentProductGroups: productGroups,
  })
);

export default function AddProductGroupModal({
  isOpen,
  onAdd,
  onClose,
}: AddProductGroupModalProps) {
  const { currentProductGroups } = useAppSelector(selector);
  const paywallId = useParams<{ paywallID: string }>().paywallID;
  const [form] = Form.useForm<TProductGroupPayload>();
  const newGroupMutation = useAddProductGroupMutation({
    onSuccess: () => {
      onAdd();
      form.resetFields();
    },
  });

  return (
    <Modal
      title="Add a New Product Group"
      open={isOpen}
      footer={null}
      centered
      onCancel={onClose}
      confirmLoading={newGroupMutation.isLoading}
      zIndex={1005}
      forceRender
    >
      <Form layout="vertical" form={form} requiredMark={false}>
        <>
          <Row gutter={16}>
            <Col xs={18}>
              <FormItem
                label="Display Name"
                required
                tooltip="This value is shown to users on your paywall"
                name="display_name"
              >
                <Input
                  maxLength={100}
                  onKeyUp={() => initializeProductGroupRefId()}
                />
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col xs={18}>
              <FormItem
                label="Reference ID"
                required
                name="ref"
                tooltip="This value is for your app code. Provide an alphanumeric string without any special characters."
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9-_]+$/g,
                    message:
                      'Please enter an alphanumeric value with no special characters',
                  },
                  {
                    required: true,
                    message: 'Provide an alphanumeric value',
                  },
                  () => {
                    return {
                      message: 'This reference id is already in use.',
                      validator: (_, value) => {
                        const inUse = currentProductGroups.reduce(
                          (output, group) => {
                            if (group.ref === value) return true;
                            return output;
                          },
                          false
                        );
                        return inUse ? Promise.reject() : Promise.resolve();
                      },
                    };
                  },
                ]}
              >
                <Input maxLength={100} />
              </FormItem>
            </Col>
          </Row>
          <Space direction="horizontal">
            <Button
              htmlType="submit"
              loading={newGroupMutation.isLoading}
              type="primary"
              disabled={form
                .getFieldsError()
                .some(({ errors }) => errors.length)}
              onClick={() => {
                form.validateFields().then(() =>
                  newGroupMutation.mutate({
                    ...form.getFieldsValue(),
                    ref:
                      form.getFieldValue('ref') ||
                      toSlug(form.getFieldValue('display_name'), '-'),
                    paywall: paywallId,
                    display_order: currentProductGroups.length + 1,
                  })
                );
              }}
            >
              Add
            </Button>
            <Button
              type="text"
              onClick={() => {
                form.resetFields();
                onClose();
              }}
            >
              Done Adding Product Groups
            </Button>
          </Space>
        </>
      </Form>
    </Modal>
  );

  function initializeProductGroupRefId() {
    const currentDisplayName = form.getFieldValue('display_name');
    form.setFieldValue('ref', toSlug(currentDisplayName, '-'));
  }
}
