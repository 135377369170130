import React from 'react';

import { VolumeOffRounded, VolumeUpRounded } from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

export default function PaywallVideoVolumeStatePicker() {
  const videoMuted = useAppSelector(
    ({ paywallBuilder: { videoMuted } }: RootState) => videoMuted
  );
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <>
      <Tooltip
        title={`Preview the paywall with ${
          videoMuted ? ' audio on' : ' audio muted'
        }`}
        mouseEnterDelay={0.8}
      >
        <Button
          type="ghost"
          icon={videoMuted ? <VolumeUpRounded /> : <VolumeOffRounded />}
          size="small"
          onClick={toggleVolumeState}
        />
      </Tooltip>
    </>
  );

  function toggleVolumeState() {
    actions.setVideoMuted(!videoMuted);
  }
}
