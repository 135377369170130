import React, { useMemo } from 'react';

import {
  LinkOutlined,
  PermContactCalendarOutlined,
  PersonOutline,
} from '@mui/icons-material';
import { Col, Row, Space, Spin, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { TCustomerAttribute } from 'src/api/types/customer.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import CustomerAttribute from 'src/components/CustomerAttribute/CustomerAttribute';
import Page from 'src/components/Page/Page';
import { useExternalUserQuery } from 'src/hooks/queries/customer.hooks';
import { namiPureWhite } from 'src/variables';

import CustomerDevices from './CustomerDevices';
import CustomerPurchases from './CustomerPurchases';

export default function CustomerDetail() {
  const customerId = useParams<{ userID: string }>().userID;
  const customerData = useExternalUserQuery(customerId);

  const pageTitle = useMemo(() => {
    if (
      !customerData.data ||
      !customerData.data.psuedonym ||
      !customerData.data.external_id
    )
      return customerId;
    return customerData.data.psuedonym || customerData.data.external_id;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);

  const idCardAttrs: TCustomerAttribute[] = useMemo(() => {
    return [
      {
        title: 'Psuedonym',
        value: customerData.data?.psuedonym || '-',
        copyable: false,
        empty: true,
        icon: <PersonOutline />,
      } as TCustomerAttribute,
      {
        title: 'Nami ID',
        value: customerData.data?.id,
        copyable: true,
        monospace: true,
        icon: <PermContactCalendarOutlined />,
      } as TCustomerAttribute,
      {
        title: 'External ID',
        value: customerData.data?.external_id,
        copyable: true,
        icon: <LinkOutlined />,
        monospace: true,
      } as TCustomerAttribute,
      //TODO - cdp id
    ];
  }, [customerData]);

  return (
    <Page title={pageTitle}>
      <Row justify="start" gutter={[0, 36]} style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <Breadcrumb
            items={[
              { url: '/customers-beta/', name: 'Customers' },
              { name: pageTitle },
            ]}
          />
        </Col>
      </Row>
      <Row
        gutter={[16, 24]}
        align="top"
        justify="start"
        wrap={false}
        style={{ marginBottom: 16 }}
      >
        <Typography.Text style={{ fontSize: 18, fontWeight: 500 }}>
          {`${customerData.data?.psuedonym || customerData.data?.external_id}`}
        </Typography.Text>
      </Row>
      <Row gutter={[16, 24]} align="top" justify="start" wrap={false}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          style={{
            backgroundColor: namiPureWhite,
            marginRight: 10,
          }}
        >
          <Spin spinning={customerData.isLoading}>
            <Space direction="vertical" size={12} style={{ padding: 18 }}>
              <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
                Identifiers
              </Typography.Text>
              {idCardAttrs.map((attr, index) => (
                <CustomerAttribute attr={attr} key={index} />
              ))}
            </Space>
          </Spin>
        </Col>
        <Col xs={24} sm={24} md={12} lg={18}>
          <Space direction="vertical" style={{ width: '100%' }} size={14}>
            <CustomerPurchases customerId={customerId} />
            <CustomerDevices customerId={customerId} />
          </Space>
        </Col>
      </Row>
    </Page>
  );
}
