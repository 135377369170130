import React, { useMemo } from 'react';

import { ScreenRotationAltRounded } from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import { Button, Col, Drawer, Row, Select, Space, Tooltip } from 'antd';
import {
  PreviewDeviceMeta,
  TPreviewDevice,
  TPreviewDeviceMetaAndKey,
} from 'src/api/types/paywall.types';
import { useAppContext } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiPureWhite } from 'src/variables';

const selector = createSelector(
  [
    ({ paywallBuilder: { paywall } }: RootState) =>
      paywall?.template['ui.formFactors'],
    ({ paywallBuilder: { orientation } }: RootState) => orientation,
    ({ paywallBuilder: { formFactor } }: RootState) => formFactor,
    ({ paywallBuilder: { previewDevice } }: RootState) => previewDevice,
  ],
  (formFactors, orientation, formFactor, previewDevice) => ({
    tabletFormFactor: formFactors?.[0]?.form_factor === 'tablet', //TODO - simplify
    rotateable:
      formFactors?.[0]?.supports_landscape &&
      formFactors?.[0]?.supports_portrait,
    currentOrientation: orientation,
    formFactor: formFactor,
    previewDevice: previewDevice,
  })
);

export default function PaywallBuilderBottomDrawer({
  Controls,
}: {
  Controls: () => JSX.Element;
}) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { userHasEntitlement } = useAppContext();
  const {
    tabletFormFactor,
    rotateable,
    currentOrientation,
    formFactor,
    previewDevice,
  } = useAppSelector(selector);

  const deviceOptions = useMemo(() => {
    return Object.entries(PreviewDeviceMeta).reduce((output, [key, value]) => {
      if (value.type !== formFactor) return output;
      return [...output, { ...value, name: key as TPreviewDevice }];
    }, [] as TPreviewDeviceMetaAndKey[]);
  }, [formFactor]);

  const deviceSelectOptions = useMemo(() => {
    return deviceOptions.reduce((output, option) => {
      return [
        ...output,
        {
          value: option.name,
          text: option.displayName,
          label: option.displayName,
        },
      ];
    }, [] as Array<{ value: string; text: string; label: string }>);
  }, [deviceOptions]);

  return (
    <Drawer
      bodyStyle={{
        padding: 8,
        background: namiPureWhite,
        marginLeft: 380,
        marginRight: 380,
        overflow: 'hidden',
      }}
      placement="bottom"
      open={true}
      height="42px"
      closable={false}
      style={{ overflow: 'hidden' }}
      mask={false}
    >
      <Row justify="center" align="middle">
        <Col>
          <Space direction="horizontal" size={16}>
            {<Controls />}
            {tabletFormFactor && rotateable && (
              <Tooltip
                title={`Rotate the paywall preview into ${
                  currentOrientation === 'landscape' ? 'portrait' : 'landscape'
                }`}
                mouseEnterDelay={0.8}
              >
                <Button
                  type="default"
                  size="small"
                  icon={<ScreenRotationAltRounded />}
                  onClick={() => toggleOrientation()}
                />
              </Tooltip>
            )}
            {['phone', 'desktop'].includes(formFactor) &&
              userHasEntitlement('app.paywall.preview_devices') && (
                <Select
                  value={previewDevice}
                  options={deviceSelectOptions}
                  size="small"
                  dropdownMatchSelectWidth={false}
                  onChange={(value) => switchDevice(value)}
                  showSearch
                  style={{ width: 240 }}
                />
              )}
          </Space>
        </Col>
      </Row>
    </Drawer>
  );

  function toggleOrientation() {
    actions.setOrientation(
      currentOrientation === 'landscape' ? 'portrait' : 'landscape'
    );
  }

  function switchDevice(device: TPreviewDevice) {
    actions.setPreviewDevice(device);
  }
}
