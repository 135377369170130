import React from 'react';

import { EyeOutlined } from '@ant-design/icons';
import {
  ConfigProvider,
  Empty,
  Table,
  TablePaginationConfig,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TExternalUser } from 'src/api/types/customer.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { namiDarkGray } from 'src/variables';

type CustomersTableProps = {
  customers: TExternalUser[];
  loading: boolean;
  pagination: TablePaginationConfig;
};

export default function CustomersTable({
  customers,
  loading,
  pagination,
}: CustomersTableProps) {
  const history = useHistory();
  return (
    <ConfigProvider
      renderEmpty={() => (
        <Empty
          imageStyle={{ display: 'none' }}
          description={
            <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
              No customers found.
            </span>
          }
        ></Empty>
      )}
    >
      <Table
        loading={loading}
        rowKey="id"
        dataSource={customers}
        columns={getColumns()}
        pagination={pagination}
        className="horizontalScrollTable"
      />
    </ConfigProvider>
  );

  function getColumns(): ColumnsType<TExternalUser> {
    return [
      {
        title: <Typography.Text strong>Psuedonym</Typography.Text>,
        render: (customer: TExternalUser) => {
          return (
            <a href={`/customers-beta/${customer.id}/`}>{customer.psuedonym}</a>
          );
        },
        fixed: 'left',
        width: 250,
      },
      {
        title: <Typography.Text strong>External ID</Typography.Text>,
        dataIndex: 'external_id',
        render: (id: string) => {
          return <MonospaceText>{id}</MonospaceText>;
        },
        width: 350,
      },
      {
        title: <Typography.Text strong>Created</Typography.Text>,
        dataIndex: 'created_date',
        render: (created_date: string) => (
          <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer' }}>
              {moment(created_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        ellipsis: true,
        render: (customer: TExternalUser) => (
          <IconActionButton
            type="text"
            size="small"
            icon={<EyeOutlined style={{ fontSize: '13px' }} />}
            //disabled={!appContext.userHasEntitlement('app.productsku.get')} //TODO
            onClick={() => history.push(`/customers-beta/${customer.id}/`)}
          >
            View
          </IconActionButton>
        ),
        width: 225,
      },
    ];
  }
}
