import React from 'react';

import { Button, Col, List, Row, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  eventTypeIcons,
  prettyEventTypeNames,
} from 'src/api/types/developer.types';
import { useEventLogForDeviceQuery } from 'src/hooks/queries/developer.hooks';
import { getListDescriptionForEvent } from 'src/utils/dev';
import { namiDarkGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const EventTimeItem = styled.span`
  font-size: 13px;
  font-color: ${namiDarkGray};
  float: right;
`;

const IconWrapper = styled.span`
  svg {
    font-size: 18px;
    transform: translateY(3px);
    color: ${namiDarkGray};
  }
`;

export default function DeviceEvents({ deviceID }: { deviceID: string }) {
  const deviceEvents = useEventLogForDeviceQuery(deviceID);
  const history = useHistory();
  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: 18, background: namiPureWhite }}
    >
      <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
        Recent Events
      </Typography.Text>
      <List
        header={null}
        footer={
          deviceEvents.data?.results.length ? (
            <Button
              onClick={() => history.push(`/developer?device_id=${deviceID}`)}
            >
              See all Events
            </Button>
          ) : undefined
        }
        loading={deviceEvents.isLoading}
        dataSource={deviceEvents.data?.results || []}
        renderItem={(item) => (
          <List.Item>
            <Row gutter={[8, 8]} style={{ width: '100%', padding: 8 }}>
              <Col md={8}>
                <Space size={12}>
                  <IconWrapper>{eventTypeIcons[item.event_type]}</IconWrapper>
                  <b>{prettyEventTypeNames[item.event_type]}</b>
                </Space>
              </Col>
              <Col md={8}>{getListDescriptionForEvent(item)}</Col>
              <Col md={8}>
                <Tooltip
                  title={moment(item.created_date).format(
                    'YYYY-MM-DD h:mm:ss A'
                  )}
                >
                  <EventTimeItem>{item.created_date_humanized}</EventTimeItem>
                </Tooltip>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Space>
  );
}
