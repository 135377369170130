import React from 'react';

import { connect } from 'react-redux';
import { Button, Form, Segment } from 'semantic-ui-react';

import NamiIcon from '../../../components/NamiIcon/NamiIcon';
import Page from '../../../components/Page/Page';
import PasswordValidator from '../../../components/PasswordValidator/PasswordValidator';
import Wrapper from '../../../components/Wrapper/Wrapper';
import {
  handleResetPasswordField,
  resetPassword,
} from '../../../redux/actions/authActions';
import { extractFieldError } from '../../../services/utilsService';

const ResetPasswordPage = ({
  loading,
  match,
  resetPasswordData,
  handleResetPasswordField,
  resetPasswordError,
  resetPassword,
}) => {
  const { password, passwordConfirm } = resetPasswordData;
  const submit = () => {
    resetPassword({
      token: match.params.token,
      new_password1: password,
      new_password2: passwordConfirm,
    });
  };

  return (
    <Page title="Reset Password">
      <Wrapper>
        <Segment basic textAlign="center">
          <NamiIcon icon="ml logo" color="primary" size="massive" />
        </Segment>
        <Segment>
          <Form size="large">
            <Form.Input
              label="Password"
              name="password"
              fluid
              value={password}
              onChange={(e) =>
                handleResetPasswordField(e.target.name, e.target.value)
              }
              icon="lock"
              iconPosition="left"
              type="password"
              placeholder="Password"
              error={extractFieldError(resetPasswordError?.new_password1)}
            />
            <Form.Input
              label="Confirm Password"
              name="passwordConfirm"
              fluid
              value={passwordConfirm}
              onChange={(e) =>
                handleResetPasswordField(e.target.name, e.target.value)
              }
              icon="lock"
              iconPosition="left"
              type="password"
              placeholder="Confirm Password"
              error={extractFieldError(resetPasswordError?.new_password2)}
            />
            <PasswordValidator
              password={password}
              passwordConfirmation={passwordConfirm}
            />
            <Button
              className="nami-primary-button"
              fluid
              size="large"
              loading={loading}
              disabled={password !== passwordConfirm}
              onClick={submit}
            >
              Reset Password
            </Button>
          </Form>
        </Segment>
      </Wrapper>
    </Page>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (data) => dispatch(resetPassword(data)),
    handleResetPasswordField: (field, value) =>
      dispatch(handleResetPasswordField(field, value)),
  };
};

const mapStateToProps = ({ auth }) => {
  return {
    loading: auth.loading,
    resetPasswordData: auth.resetPasswordData,
    resetPasswordError: auth.resetPasswordError,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
