import React, { useCallback, useEffect, useMemo, useState } from 'react';

import * as Sentry from '@sentry/browser';
import { notification } from 'antd';
import { AxiosError } from 'axios';

import api from './api';
import { cubeHandlers } from './api/analytics.api';
import {
  AuthPayloadType,
  UserPermissionType,
  UserType,
} from './api/types/main.types';
import Loading from './components/Loading/Loading';
import { useBooleanState, usePromiseState } from './hooks';
import { useAppSelector } from './hooks/redux.hooks';
import store from './redux';
import { getApps } from './redux/actions/rootActions';
import * as TYPES from './redux/types';
import SegmentClient from './segment';
import history from './services/history';
import { removeAppId } from './services/LocalStorageService';
import Token from './services/token';

type AppContextProviderProps = {
  isAuthed: boolean;
  onAuthStatusChange: (isAuthed: boolean) => void;
  children: JSX.Element | JSX.Element[];
};

export type AppContextType = {
  performLogout: () => Promise<void>;
  getDocsLink: () => string;
  user: UserType | null;
  languages: { [key: string]: string };
  selectedOrg: UserPermissionType | null;
  setUser(user: UserType): void;
  authorizeAccess(payload: AuthPayloadType, redirectURL?: string): void;
  updateSelectedOrg(orgId: string): void;
  planHasEntitlement(entitlement: string): boolean;
  userHasEntitlement(entitlement: string): boolean;
  setSelectedOrgName(orgName: string): void;
  getPlanName(): string;
};

const FIELDS_HANDLED_GLOBALLY = Object.freeze([
  // TODO: Aside from "detail" and "non_field_errors", these are fields sent
  //  in the payload. Transfer the responsibility of handling each field to
  //  the caller.
  'detail',
  'non_field_errors',
  'token',
  'paywall',
  'external_id',
  'device_id',
  'namiml_user_id',
  'app_id',
  'active',
  'new_field',
  'org',
]);

// noinspection JSUnusedLocalSymbols
export const AppContext = React.createContext<AppContextType>({
  // These defaults provide overall stability and help IDEs with typing
  performLogout: () => new Promise((resolve) => resolve()),
  getDocsLink: () => '',
  user: null,
  languages: {},
  selectedOrg: null,
  setUser: (user) => {},
  authorizeAccess: (payload) => {},
  updateSelectedOrg: (orgId) => {},
  planHasEntitlement: (entitlement) => false,
  userHasEntitlement: (entitlement) => false,
  setSelectedOrgName: (name) => undefined,
  getPlanName: () => '',
});

const AppContextProvider = ({
  isAuthed,
  onAuthStatusChange,
  children,
}: AppContextProviderProps) => {
  const [userState, setUser] = useState<UserType | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<UserPermissionType | null>(
    null
  );
  const [languages, setLanguages] = useState({});
  const [userEntitlements, setUserEntitlements] = useState(new Set());
  const [planEntitlements, setPlanEntitlements] = useState(new Set());

  const authLoading = useAppSelector(({ auth }) => auth.loading as boolean);

  const [isLoggingOut, startLoggingOut, stopLoggingOut] =
    useBooleanState(false);

  const planHasEntitlement = useCallback(
    (entitlement: string): boolean => planEntitlements.has(entitlement),
    [planEntitlements]
  );
  const userHasEntitlement = useCallback(
    (entitlement: string): boolean => userEntitlements.has(entitlement),
    [userEntitlements]
  );

  const { isLoading: isFetchingData, trigger: fetchUserData } = usePromiseState(
    () =>
      api
        .getUser()
        .then((user) => {
          setUser(user);
          const orgId = Token.getOrgId();
          if (orgId) {
            updateContext(orgId, user);
          }
        })
        .finally(() => {
          // TODO: Remove dispatch when all root actions are brought here
          store.dispatch({ type: TYPES.STOP_LOADING });
        })
  );

  useEffect(() => {
    cubeHandlers.nullTokenHandler = () => {
      performLogout();
      notification.error({ message: 'Your credentials expired.' });
    };

    api.setResponseErrorInterceptor([api.client], (error) => {
      if (error.response?.status === 401) {
        performLogout();
      } else {
        showNonTokenError(error);
      }
      throw error;
    });

    api.setResponseErrorInterceptor(
      [api.bareClient, api.preAuthClient],
      (error: AxiosError) => {
        showNonTokenError(error);
        throw error;
      }
    );
    if (isAuthed) {
      fetchUserData();
    }
    api.getLanguages().then((languages) => {
      const languageMap = languages.reduce(
        (output, { code, language }) => ({ ...output, [code]: language }),
        {}
      );
      setLanguages(languageMap);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const planName = useMemo(() => selectedOrg?.plans[0]!, [selectedOrg?.plans]);

  const isLoading = authLoading || isFetchingData || isLoggingOut;
  if (isLoading) {
    return (
      <div style={{ height: '100%' }}>
        <Loading />
      </div>
    );
  }

  const context: AppContextType = {
    user: userState,
    selectedOrg,
    languages,
    setUser,
    performLogout,
    getDocsLink,
    authorizeAccess,
    planHasEntitlement,
    userHasEntitlement,
    updateSelectedOrg,
    // If/when we update more org attributes, just use "setSelectedOrg" instead
    setSelectedOrgName: (name: string): void =>
      setSelectedOrg((org) => ({ ...org!, name })),
    getPlanName: () => planName,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;

  function authorizeAccess(
    payload: AuthPayloadType,
    redirectURL?: string
  ): void {
    SegmentClient.identifyUser(payload.profile);
    Token.setAccess(payload.access_token);
    Token.setRefresh(payload.refresh_token);
    setUser(payload.profile);
    // TODO: Remove dispatch when all root actions are brought here
    store.dispatch({ type: TYPES.STOP_LOADING });
    onAuthStatusChange(true);
    if (redirectURL) {
      history.push(redirectURL);
    } else {
      history.push('/overview/');
    }
  }

  function updateSelectedOrg(orgId: string): void {
    removeAppId();
    api.generateToken(orgId).then(() => {
      updateContext(orgId, userState!);
    });
  }

  // TODO: Find a better naming for this function
  function updateContext(orgId: string, user: UserType): void {
    // TODO: Remove dispatch when all root actions are brought here
    store.dispatch(getApps());
    const shallowOrg = user.orgs.find(({ id }) => id === orgId);
    const org = user.permissions.find(({ id }) => id === orgId);
    setSelectedOrg(org!);
    setUserEntitlements(new Set(org?.entitlements || []));
    setPlanEntitlements(new Set(shallowOrg?.entitlements || []));
  }

  function performLogout(): Promise<void> {
    startLoggingOut();
    return api.logout().finally(() => {
      removeAppId();
      setSelectedOrg(null);
      setUserEntitlements(new Set());
      setPlanEntitlements(new Set());
      onAuthStatusChange(false);
      // TODO: Remove this dispatch when auth reducer is gone
      store.dispatch({ type: TYPES.RESTART_STATE });
      history.push('/login/');
      stopLoggingOut();
    });
  }

  function getDocsLink(): string {
    let docsLink = 'https://docs.namiml.com';
    const currentOrg = userState?.orgs.find(
      (value) => value.id === selectedOrg?.id
    );
    if (currentOrg && currentOrg.docs_link) {
      docsLink = currentOrg.docs_link;
    }
    return docsLink;
  }
};

export default AppContextProvider;

function showNonTokenError(error: AxiosError): void {
  console.log(`capturing non token error`);
  Sentry.captureException(error);

  const data = error.response?.data;
  const status = error.response?.status || 0;
  const messages = [];
  console.log(data);
  console.log(status);

  if (new Set([500, 502, 404]).has(status || 0)) {
    messages.push(`Something went wrong (${status}).`);
  } else if (data?.length) {
    messages.push(Array.isArray(data) ? data[0] : data);
  } else {
    messages.push(
      ...FIELDS_HANDLED_GLOBALLY.map((field) => {
        if (typeof data === 'undefined' || !(field in data)) return null;
        return Array.isArray(data[field]) ? data[field][0] : data[field];
      })
    );
  }
  console.log(messages);

  messages.forEach((message) => {
    if (!!message && !api.TOKEN_ERRORS.has(message.toLowerCase())) {
      notification.error({ message: message });
    }
  });
}
