import React, { useMemo } from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { ConfigProvider, Empty, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  convertCountryCode,
  TCustomerDevice,
} from 'src/api/types/customer.types';
import {
  formFactorIcons,
  getDeviceType,
  getOperatingSystemType,
  osIcons,
} from 'src/api/types/main.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useApiOptions } from 'src/hooks/queries/apiOptions.hooks';
import { useExternalUserDevicesQuery } from 'src/hooks/queries/customer.hooks';
import { getDevicePlatformFormFactorSummary } from 'src/utils/customer';
import { translateStringToLanguage } from 'src/utils/formatting';
import { namiDarkGray, namiPrimaryBlue, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const DeviceModel = styled(Space)`
  cursor: pointer;
  :hover {
    text-decoration: underline;
    color: ${namiPrimaryBlue};
  }
`;

export default function CustomerDevices({
  customerId,
}: {
  customerId: string;
}) {
  const [apiOptions, updateApiOptions] = useApiOptions();
  const history = useHistory();
  const customerDevicesQuery = useExternalUserDevicesQuery(
    customerId,
    apiOptions
  );

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: customerDevicesQuery.data?.count,
      pageSize: 10,
      onChange: (page, pageSize) =>
        updateApiOptions((state) => ({ ...state, page, pageSize })),
      current: customerDevicesQuery.data?.page_number,
      showSizeChanger: false,
      style: { paddingBottom: 18 },
      showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
      size: 'small',
    }),
    [customerDevicesQuery.data, updateApiOptions]
  );

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: 18, background: namiPureWhite }}
      className="intercom-customerDevices"
    >
      <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
        Devices
      </Typography.Text>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            imageStyle={{ display: 'none' }}
            description={
              <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
                No devices found
              </span>
            }
          ></Empty>
        )}
      >
        <Table
          loading={customerDevicesQuery.isLoading}
          rowKey="id"
          dataSource={customerDevicesQuery.data?.results || []}
          columns={getColumns()}
          pagination={
            (customerDevicesQuery.data?.count || 0) > 10 ? pagination : false
          }
          className="horizontalScrollTable"
          scroll={{ x: 750 }}
        />
      </ConfigProvider>
    </Space>
  );

  function getColumns(): ColumnsType<TCustomerDevice> {
    return [
      {
        title: <Typography.Text strong>Device Model</Typography.Text>,
        render: (device: TCustomerDevice) => (
          <DeviceModel
            direction="horizontal"
            onClick={() => history.push(`/customers-beta/devices/${device.id}`)}
          >
            <Space direction="horizontal" size={4}>
              {device.profile.os_name &&
                osIcons[getOperatingSystemType(device.profile.os_name)]}
              {formFactorIcons[getDeviceType(device)]}
            </Space>
            {getDevicePlatformFormFactorSummary(device)}
          </DeviceModel>
        ),
        width: 250,
        fixed: 'left',
      },
      {
        title: <Typography.Text strong>OS Version</Typography.Text>,
        render: (device: TCustomerDevice) => {
          return (
            <>
              <MonospaceText>{device.profile.os_version || '-'}</MonospaceText>
            </>
          );
        },
        width: 125,
      },
      {
        title: <Typography.Text strong>Nami SDK Version</Typography.Text>,
        render: (device: TCustomerDevice) => {
          return (
            <>
              <MonospaceText>{device.profile.sdk_version || '-'}</MonospaceText>
            </>
          );
        },
        width: 150,
      },
      {
        title: <Typography.Text strong>Language</Typography.Text>,
        render: (device: TCustomerDevice) => {
          let value = '-';
          if (device.profile.os_language) {
            const langCode = translateStringToLanguage(
              device.profile.os_language
            );
            value = `${langCode} - (${device.profile.os_language})`;
          }

          return <>{value}</>;
        },
        width: 150,
      },
      {
        title: <Typography.Text strong>Country</Typography.Text>,
        render: (device: TCustomerDevice) => {
          let value = '-';
          if (device.profile.marketplace_country) {
            return (
              <>{convertCountryCode(device.profile.marketplace_country)}</>
            );
          }

          return <>{value}</>;
        },
        width: 285,
      },
      {
        title: <Typography.Text strong>ID</Typography.Text>,
        dataIndex: 'id',
        render: (id: string) => {
          return (
            <>
              <MonospaceText>{id}</MonospaceText>
            </>
          );
        },
        width: 225,
      },
      {
        title: <Typography.Text strong>CDP Id</Typography.Text>,
        render: (device: TCustomerDevice) => {
          return (
            <>
              <MonospaceText>
                {device.profile.customer_data_platform_id || '-'}
              </MonospaceText>
            </>
          );
        },
        width: 225,
      },
      {
        title: <Typography.Text strong>Created</Typography.Text>,
        dataIndex: 'created_date',
        render: (created_date: string) => (
          <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer' }}>
              {moment(created_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        title: <Typography.Text strong>Updated</Typography.Text>,
        dataIndex: 'updated_date',
        render: (updated_date: string) => (
          <Tooltip title={moment(updated_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer' }}>
              {moment(updated_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        ellipsis: true,
        render: (device: TCustomerDevice) => (
          <IconActionButton
            type="text"
            size="small"
            icon={<EyeOutlined style={{ fontSize: '13px' }} />}
            //disabled={!appContext.userHasEntitlement('app.productsku.get')} //TODO
            onClick={() =>
              history.push(`/customers-beta/devices/${device.id}/`)
            }
          >
            View
          </IconActionButton>
        ),
        width: 175,
      },
    ];
  }
}
