import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { useCampaignRuleTagsQuery } from 'src/hooks/queries/campaign.hooks';
import styled from 'styled-components';

import { TagFilterProps } from './PlacementTagFilter';
import StatusSelect from './StatusSelect';

export const WithWidth = styled.div<{ as: any }>`
  width: 100%;
`;

export const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

export default function CampaignTagFilter({
  selectedTag,
  onChange,
  onClear,
}: TagFilterProps) {
  const tagsQuery = useCampaignRuleTagsQuery({ pageSize: 100 });
  const options = (tagsQuery.data?.results || []).map((tag) => ({
    key: tag.id,
    name: tag.name,
    value: tag.name,
    label: tag.name,
  }));

  return (
    <SelectWrapper>
      <WithWidth
        as={StatusSelect}
        allowClear
        mode={undefined}
        showArrow={false}
        optionFilterProp="text"
        options={options}
        value={selectedTag}
        onChange={(value: string) => onChange(value)}
        onClear={onClear}
        clearIcon={<CloseOutlined />}
        label={selectedTag ? 'Tag:' : ''}
        className="intercom-campaignsFilterByTag"
        placeholder={
          <>
            Filter by <b>Tag</b>
          </>
        }
      />
    </SelectWrapper>
  );
}
