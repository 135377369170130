import React from 'react';

import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb as AntBreadcrumb, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { breakpointMd, breakpointSm, namiPrimaryBlue } from 'src/variables';
import styled, { css } from 'styled-components';

type BreadcrumbProps = {
  items: { url?: string; name: string }[];
};

const BreadcrumbItem = styled(AntBreadcrumb.Item)<{ haslink?: string }>`
  ${({ haslink }) => {
    if (haslink === 'true')
      return css`
        cursor: pointer;
        :hover {
          text-decoration: underline;
          color: ${namiPrimaryBlue};
        }
        span:hover {
          text-decoration: underline;
          color: ${namiPrimaryBlue};
        }
      `;
    return css`
      cursor: default;
    `;
  }}
`;

const SpecialText = styled(Typography.Text)`
  max-width: 500px !important;
  overflow-x: hidden;

  @media only screen and (max-width: ${breakpointMd}) {
    max-width: 300px !important;
  }

  @media only screen and (max-width: ${breakpointSm}) {
    max-width: 200px !important;
  }
`;

export default function Breadcrumb({ items }: BreadcrumbProps) {
  const history = useHistory();
  return (
    <AntBreadcrumb>
      <BreadcrumbItem
        onClick={() => history.push('/overview/')}
        haslink={'true'}
      >
        <HomeOutlined />
      </BreadcrumbItem>
      {items.map(({ url, name }) => (
        <BreadcrumbItem
          haslink={!!url ? 'true' : ''}
          onClick={() => url && history.push(url)}
          key={name}
        >
          <SpecialText ellipsis={true}>{name}</SpecialText>
        </BreadcrumbItem>
      ))}
    </AntBreadcrumb>
  );
}
