import React from 'react';

import { CheckCircleOutline, Warning } from '@mui/icons-material';
import {
  Button,
  ConfigProvider,
  Empty,
  List,
  Popover,
  Space,
  Typography,
} from 'antd';
import { TTemplateWarning } from 'src/api/types/paywallTemplate.types';
import { useActions } from 'src/hooks/redux.hooks';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { namiAliceGray, namiLightOrange, namiSmoke } from 'src/variables';
import styled, { css } from 'styled-components';

const StyledButton = styled(Button)<{ $errorStatus: boolean }>`
  ${({ $errorStatus }) => {
    if ($errorStatus) {
      return css`
        svg {
          color: ${namiLightOrange};
          font-size: 20px;
          :hover {
            color: #fccb68;
          }
        }
        transition: all 1s;
      `;
    }
    return css`
      svg {
        color: #b5b5b5;
        font-size: 20px;
        :hover {
          color: ${namiSmoke};
        }
      }
      transition: all 1s;
    `;
  }}
`;

const StyledListItem = styled(List.Item)`
  &:hover {
    background-color: ${namiAliceGray};
  }
`;

const StyledListText = styled(Typography.Text)`
  cursor: pointer;
`;

export default function PaywallBuilderWarnings({
  warnings,
}: {
  warnings: Set<TTemplateWarning>;
}) {
  const actions = useActions(PaywallBuilderSlice.actions);

  return (
    <Popover
      title={
        <Space>
          <Warning style={{ fontSize: 15 }} />
          {`${warnings.size} issue${warnings.size === 1 ? '' : 's'}`}
        </Space>
      }
      mouseLeaveDelay={0.3}
      content={generateWarningsList}
      overlayStyle={{ minWidth: 280 }}
      align={{ offset: [0, 8] }}
    >
      <StyledButton
        type="text"
        className="intercom-builder-warnings"
        icon={warnings.size ? <Warning /> : <CheckCircleOutline />}
        $errorStatus={warnings.size > 0}
      />
    </Popover>
  );

  function generateWarningsList() {
    return (
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            imageStyle={{ display: 'none' }}
            description={'0 issues to fix'}
          ></Empty>
        )}
      >
        <List
          dataSource={Array.from(warnings.values())}
          size="small"
          style={{ width: '100% ' }}
          renderItem={(item) => (
            <StyledListItem
              onClick={() => {
                if (item.menuItem) {
                  actions.setEditingMenuItem(item.menuItem);
                  actions.setEditingProductMenuTabItem('menu');
                } else if (item.editingComponentId) {
                  actions.setSelectedTreeKey(item.editingComponentId);
                  actions.setEditingComponentId(item.editingComponentId);
                  actions.setEditingSlideId(null);
                  if (item.conditionsTab)
                    actions.setEditingComponentEditorTabItem('conditions');
                }
                if (item.groupId) actions.setProductGroupId(item.groupId);
              }}
            >
              <StyledListText>{item.message}</StyledListText>
            </StyledListItem>
          )}
        />
      </ConfigProvider>
    );
  }
}
