import React, { useEffect, useState } from 'react';

import { BellOutlined } from '@ant-design/icons';
import { DeleteOutline } from '@mui/icons-material';
import { Alert, Button, notification, Space } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Checkbox, Divider, Form, Header } from 'semantic-ui-react';
import { useAppSelector } from 'src/hooks/redux.hooks';

import api from '../../../api';
import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import {
  useAppContext,
  useBooleanState,
  useFormState,
  usePageContext,
  usePromiseState,
} from '../../../hooks';
import { useIntegrationsMetadataQuery } from '../../../hooks/queries/integration.hooks';
import { extractFieldError } from '../../../services/utilsService';
import { TIntegrationParams } from './params.types';
import SchemaForm from './SchemaForm/SchemaForm';
import StreamOwnerRadioInput from './StreamOwnerRadioInput';

type MultiLineTextProps = { children: string; separator?: string };

export default function StreamForm() {
  const history = useHistory();
  const { type, integrationId: streamId } = useParams<TIntegrationParams>();
  const metadataQuery = useIntegrationsMetadataQuery();

  const appId = useAppSelector(({ root }) => root.currentApp?.id);
  const { userHasEntitlement } = useAppContext();
  const { setTitle } = usePageContext();
  const [config, setConfig] = useState({});
  const [topics, setTopics] = useState<string[]>([]);
  const [initialConfig, setInitialConfig] = useState({});
  const [initialTopics, setInitialTopics] = useState<string[]>([]);
  const [isModalOpen, openModal, closeModal] = useBooleanState(false);
  const canEdit = userHasEntitlement('app.event_stream.update');
  const {
    form: stream,
    setForm,
    setFormField,
    setFormInput,
  } = useFormState({
    app: appId,
    partner: type,
    name: '',
    enabled: false,
  });
  const [initialStream, setInitialStream] = useState(stream);

  const {
    isLoading,
    error,
    trigger: submit,
  } = usePromiseState(() => {
    const data = { ...stream, config, topics };
    if (streamId) {
      return api.updateStream(streamId, data).then(() => {
        const message = 'Your integration changes were successfully saved';
        notification.success({ message });
      });
    }
    return api.addStream(data).then(() => history.push('/integrations/'));
  });

  useEffect(() => {
    if (streamId) {
      api
        .getStream(streamId)
        .then((data) => {
          const { topics, config, ...streamData } = data;
          setConfig(config);
          setInitialConfig(config);
          setTopics(topics);
          setInitialTopics(topics);
          setForm(streamData);
          setInitialStream(streamData);
        })
        .catch(() => {
          notification.error({
            message: "Integration can't be found",
          });
          history.push('/integrations/');
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamId, type, setForm]);

  useEffect(() => {
    setTitle(stream?.name || type);
  }, [type, stream?.name, setTitle]);

  const integration = metadataQuery.data?.find(
    (integration) => integration.type === type
  );

  function deleteStream() {
    api.deleteStream(streamId!).then(() => history.push('/integrations/'));
    closeModal();
  }

  function notifyUser() {
    if (!integration) return;
    api.notifyUser(integration.entitlement).then(() => {
      const message = `You will be notified when ${integration.title} becomes available`;
      notification.success({ message });
    });
  }

  if (!!integration && !integration.implemented) {
    return (
      <>
        {integration.description && (
          <>
            <Header>Description</Header>
            <MultiLineText>{integration.description}</MultiLineText>
          </>
        )}
        <Alert
          message="Want to be notified?"
          description={`Get an alert when the ${integration.title} integration is available.`}
          type="info"
          showIcon
          action={
            <Button icon={<BellOutlined />} onClick={notifyUser}>
              Notify me
            </Button>
          }
        />
      </>
    );
  }

  return (
    <Form onSubmit={submit} loading={metadataQuery.isFetching}>
      {integration?.description && (
        <>
          <Header>Description</Header>
          <MultiLineText>{integration?.description}</MultiLineText>
          <Divider />
        </>
      )}
      <Header>Settings</Header>
      <Form.Field>
        <Checkbox
          label="Enable Integration"
          toggle
          disabled={!canEdit}
          checked={stream?.enabled}
          onChange={(e, { checked }) => {
            setFormField('enabled', checked);
          }}
        />
      </Form.Field>
      {type === 'mparticle' && (
        <StreamOwnerRadioInput
          isEditing={!!streamId}
          initialStream={initialStream}
          onChange={(owner: any) =>
            setForm((formState: any) => ({ ...formState, ...owner }))
          }
          disabled={!canEdit}
        />
      )}
      <Form.Input
        label="Name"
        name="name"
        placeholder="Name"
        value={stream?.name}
        onChange={setFormInput}
        disabled={!userHasEntitlement('app.event_stream.update')}
        error={extractFieldError(error?.name)}
      />
      <SchemaForm
        objectId={streamId}
        type={type}
        error={error}
        initialConfig={initialConfig}
        initialTopics={initialTopics}
        onFormChange={setConfig}
        onEventsChange={setTopics}
      />
      <Space style={{ marginBottom: '2rem' }}>
        <Button
          htmlType="submit"
          type="primary"
          disabled={!canEdit}
          loading={isLoading}
        >
          {streamId ? 'Save Changes' : 'Add New Integration'}
        </Button>
        {streamId && (
          <Button
            danger
            icon={
              <DeleteOutline className="inButton" style={{ fontSize: 15 }} />
            }
            onClick={openModal}
            disabled={!userHasEntitlement('app.event_stream.delete')}
          >
            Delete
          </Button>
        )}
      </Space>
      <ConfirmModal
        open={isModalOpen}
        onClose={closeModal}
        action={deleteStream}
        title="Delete Integration"
        body="Are you sure you want to delete this integration"
        buttonText="Yes. Delete This Integration."
      />
    </Form>
  );
}

function MultiLineText({ children, separator = '\n' }: MultiLineTextProps) {
  if (!children) return <span />;
  return (
    <span>
      {children.split(separator).map((text) => (
        <p key={text}>{text}</p>
      ))}
    </span>
  );
}
