import React, { useState } from 'react';

import { EyeOutlined, RetweetOutlined } from '@ant-design/icons';
import { CancelTwoTone, CheckCircleTwoTone } from '@mui/icons-material';
import {
  Button,
  Col,
  ConfigProvider,
  Empty,
  notification,
  Row,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment-timezone';
import api from 'src/api';
import {
  TEventLogItem,
  TEventLogPartnerMeta,
} from 'src/api/types/developer.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useOutboundEventsForPurchaseEventQuery } from 'src/hooks/queries/developer.hooks';
import {
  namiCoral,
  namiDarkGray,
  namiGreen,
  namiPureWhite,
} from 'src/variables';

import OutboundEventDetail from './OutboundEventDetail';

export default function CustomerPurchaseEventOutboundEvents({
  eventId,
}: {
  eventId: string;
}) {
  const outboundEvents = useOutboundEventsForPurchaseEventQuery(eventId);
  const [viewingEventId, setViewingEventId] = useState<string | null>(null);

  return (
    <>
      <Space
        direction="vertical"
        style={{ width: '100%', padding: 18, background: namiPureWhite }}
      >
        <Row gutter={[8, 8]} style={{ paddingBottom: 12 }}>
          <Col span={12}>
            <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
              Outbound Events
            </Typography.Text>
          </Col>
          <Col span={12}>
            <Button
              disabled={!outboundEvents.data?.count}
              onClick={redeliverWebhook}
              icon={<RetweetOutlined />}
              style={{ float: 'right' }}
            >
              Redeliver
            </Button>
          </Col>
        </Row>
        <ConfigProvider
          renderEmpty={() => (
            <Empty
              imageStyle={{ display: 'none' }}
              description={
                <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
                  No outbound events found
                </span>
              }
            ></Empty>
          )}
        >
          <Table
            loading={outboundEvents.isLoading}
            rowKey="id"
            dataSource={outboundEvents.data?.results || []}
            columns={getColumns()}
            pagination={false}
            className="horizontalScrollTable"
          />
        </ConfigProvider>
      </Space>
      {viewingEventId && (
        <OutboundEventDetail
          eventId={viewingEventId}
          open={true}
          onClose={() => setViewingEventId(null)}
        />
      )}
    </>
  );

  function getColumns(): ColumnsType<TEventLogItem> {
    return [
      {
        title: <Typography.Text strong>Provider</Typography.Text>,
        render: (logItem: TEventLogItem) => {
          return TEventLogPartnerMeta[logItem.subscription.partner];
        },
      },
      {
        title: <Typography.Text strong>Date</Typography.Text>,
        dataIndex: 'last_attempt',
        render: (last_attempt: string) => {
          return (
            <Tooltip title={moment(last_attempt).toISOString()}>
              <span style={{ cursor: 'pointer' }}>
                {moment(last_attempt).format('M/D/YY h:mm A')}
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: <Typography.Text strong>Delivered?</Typography.Text>,
        render: (logItem: TEventLogItem) => {
          return (
            <Space direction="horizontal" size={3}>
              {logItem.delivery_successful ? (
                <CheckCircleTwoTone
                  style={{
                    color: namiGreen,
                    transform: 'translateY(2px)',
                    fontSize: 18,
                  }}
                />
              ) : (
                <CancelTwoTone
                  style={{
                    color: namiCoral,
                    transform: 'translateY(2px)',
                    fontSize: 18,
                  }}
                />
              )}
              {logItem.delivery_successful ? 'Yes' : 'No'}
            </Space>
          );
        },
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        render: (logItem: TEventLogItem) => (
          <IconActionButton
            type="text"
            size="small"
            icon={<EyeOutlined style={{ fontSize: '13px' }} />}
            //disabled={!appContext.userHasEntitlement('app.productsku.get')} //TODO
            onClick={() => setViewingEventId(logItem.id)}
          >
            View
          </IconActionButton>
        ),
      },
    ];
  }

  function redeliverWebhook() {
    api.redeliverPurchaseEventNotifications(eventId).then(() => {
      notification.success({
        message: 'Events Redelivered',
      });
    });
  }
}
