import { RcFile } from 'antd/lib/upload';
import { getHash } from 'src/services/helpers';

import { client } from './clients';
import {
  CampaignSegmentPayloadType,
  CampaignSegmentType,
  TCDPAudience,
  TCampaignLabel,
  TCampaignLabelPayload,
  TCampaignLabelTag,
  TCampaignRule,
  TCampaignRuleAndFullLabel,
  TCampaignRulePayload,
  TCampaignRuleTag,
  TCampaignV2,
  TLegacyCampaign,
  TLegacyCampaignPayload,
} from './types/campaign.types';
import { TApiOptions, TListResult } from './types/main.types';
import {
  buildURLParams,
  extractErrorData,
  extractListResponseData,
  extractResponseData,
} from './utils';

export type TRuleApiOptions = TApiOptions & {
  enabled?: boolean;
  label_id?: string | null;
  archived?: boolean;
};

export type TLegacyCampaignApiOptions = TApiOptions & { active?: boolean };

const campaigns = {
  getCampaignRoot: (appId: string): Promise<TListResult<TCampaignV2>> => {
    return client
      .get(`/private/campaigns_v2/?app_id=${appId}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCampaignLabels: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TCampaignLabel>> => {
    const params = buildURLParams({ pageSize: 1000, ...apiOptions, appId });
    return client
      .get<TListResult<TCampaignLabel>>(`/private/campaign_labels/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCampaignLabel: (labelId: string): Promise<TCampaignLabel> => {
    return client
      .get(`/private/campaign_labels/${labelId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  addCampaignLabel: (data: TCampaignLabelPayload): Promise<TCampaignLabel> => {
    return client
      .post<TCampaignLabel>('/private/campaign_labels/', data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  updateCampaignLabel: (
    labelId: string,
    data: Partial<TCampaignLabelPayload>
  ): Promise<TCampaignLabel> => {
    return client
      .patch(`/private/campaign_labels/${labelId}/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  deleteCampaignLabel: (labelId: string): Promise<void> => {
    return client
      .delete<void>(`/private/campaign_labels/${labelId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  uploadLabelScreenshot: (
    file: File | Blob | RcFile,
    { labelId }: { labelId: string }
  ): Promise<string> => {
    const hash = getHash(9);
    const fileName = 'name' in file ? '-' + file.name : '';
    const filePath = `campaign_label/${labelId}/${hash}${fileName}`;
    return client
      .put(`/private/frontend/v1/upload/${filePath}`, file, {
        headers: { Accept: 'application/json', 'Content-Type': file.type },
      })
      .then(extractResponseData)
      .then((data) => data.url)
      .catch(extractErrorData);
  },
  getCampaignLabelTags: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TCampaignLabelTag>> => {
    const params = buildURLParams({ pageSize: 1000, ...apiOptions, appId });
    return client
      .get<TListResult<TCampaignLabelTag>>(
        `/private/campaign_label_tags/?${params}`
      )
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCampaignRuleTags: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TCampaignRuleTag>> => {
    const params = buildURLParams({ pageSize: 1000, ...apiOptions, appId });
    return client
      .get<TListResult<TCampaignRuleTag>>(
        `/private/campaign_rule_tags/?${params}`
      )
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  duplicateCampaignRule: (
    ruleId: string,
    { app, duplicatePaywalls }: { app?: string; duplicatePaywalls: boolean }
  ): Promise<TCampaignRule> => {
    const data = { app, duplicate_paywalls: duplicatePaywalls };
    return client
      .post(`/private/campaign_rules/${ruleId}/duplicate/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  bulkDuplicateCampaignRuleToPlacements: (
    ruleId: string,
    {
      app,
      labels,
      name_template,
    }: { app?: string; labels: string[]; name_template: string }
  ): Promise<void> => {
    const data = { app, labels, name_template };
    return client
      .post(`/private/campaign_rules/${ruleId}/bulk_duplicate/`, data)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  prioritizeCampaignLabelRules: (
    labelId: string,
    ruleIds: Pick<TCampaignRule, 'id'>[]
  ): Promise<void> => {
    return client
      .post<void>(
        `/private/campaign_labels/${labelId}/prioritize_rules/`,
        ruleIds
      )
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCampaignRules: (
    appId: string,
    apiOptions: TRuleApiOptions = {}
  ): Promise<TListResult<TCampaignRuleAndFullLabel>> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions, appId });
    return client
      .get(`/private/campaign_rules/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getCampaignRule: (campaignId: string): Promise<TCampaignRuleAndFullLabel> => {
    return client
      .get(`/private/campaign_rules/${campaignId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  addCampaignRule: (rule: TCampaignRulePayload): Promise<TCampaignRule> => {
    return client
      .post('/private/campaign_rules/', rule)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  updateCampaignRule: (
    campaignId: string,
    campaign: Partial<TCampaignRulePayload>
  ): Promise<TCampaignRule> => {
    return client
      .patch(`/private/campaign_rules/${campaignId}/`, campaign)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  deleteCampaignRule: (campaignId: string): Promise<void> => {
    return client
      .delete<void>(`/private/campaign_rules/${campaignId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  getCampaignSegments: (campaignId: string): Promise<CampaignSegmentType[]> => {
    return client
      .get(`/private/campaign_segments/?rule_id=${campaignId}`)
      .then(extractListResponseData)
      .catch(extractErrorData);
  },

  addCampaignSegment: (
    segment: CampaignSegmentPayloadType
  ): Promise<CampaignSegmentType> => {
    return client
      .post('/private/campaign_segments/', segment)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  updateCampaignSegment: (
    segmentId: string,
    segment: CampaignSegmentPayloadType
  ): Promise<CampaignSegmentType> => {
    return client
      .patch(`/private/campaign_segments/${segmentId}/`, segment)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  deleteCampaignSegment: (segmentId: string): Promise<void> => {
    return client
      .delete<void>(`/private/campaign_segments/${segmentId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  getCampaignsLegacy: (
    appId: string,
    apiOptions: TLegacyCampaignApiOptions = {}
  ): Promise<TListResult<TLegacyCampaign>> => {
    const params = buildURLParams({ pageSize: 50, ...apiOptions, appId });
    return client
      .get(`/private/campaigns/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  addCampaignLegacy: (
    campaign: TLegacyCampaignPayload
  ): Promise<TLegacyCampaign> => {
    return client
      .post('/private/campaigns/', campaign)
      .then(extractResponseData)
      .catch(extractErrorData);
  },

  getCDPAudiences: (appId: string): Promise<TListResult<TCDPAudience>> => {
    const params = buildURLParams({ pageSize: 250, appId });
    return client
      .get(`/private/cdp/cdp-audience/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default campaigns;
