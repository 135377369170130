import React, { useContext, useMemo, useState } from 'react';

import { EditOutlined } from '@ant-design/icons';
import {
  DeleteOutline,
  RestoreFromTrashOutlined,
  ShowChartOutlined,
  ZoomInRounded,
} from '@mui/icons-material';
import {
  Image,
  Space,
  Switch,
  TablePaginationConfig,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TAppliedFilters } from 'src/api/types/analytics.types';
import { TApiOptions } from 'src/api/types/main.types';
import { AppContext } from 'src/AppContextProvider';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import Responsive from 'src/components/Responsive/Responsive';
import DisabledBadgeDot from 'src/components/StatusDots/DisabledBadgeDot';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import { useQueryParams } from 'src/hooks';
import { namiMediumGray, namiPrimaryBlue } from 'src/variables';
import styled from 'styled-components';

import { TCampaignLabel } from '../../../api/types/campaign.types';
import {
  useArchiveCampaignLabelMutation,
  useCampaignLabelsQuery,
  useToggleCampaignLabelMutation,
} from '../../../hooks/queries/campaign.hooks';
import EditLabelModal from './EditLabelModal';
import PlacementHoverLabel from './PlacementHoverLabel';
import EmptyCampaignPlacementStates from './utils/EmptyCampaignPlacementStates';

type PlacementPageProps = {
  apiOptions: TApiOptions;
  onApiOptionsChange: React.Dispatch<React.SetStateAction<TApiOptions>>;
  selectedRowKeys: React.Key[];
  onSelectChange: (selectedRowKeys: React.Key[]) => void;
};

const LinkType = styled(Typography.Text)`
  cursor: pointer;
  :hover {
    color: ${namiPrimaryBlue};
    text-decoration: underline;
  }
`;

export default function PlacementsTable({
  apiOptions,
  onApiOptionsChange,
  selectedRowKeys,
  onSelectChange,
}: PlacementPageProps) {
  const { userHasEntitlement, planHasEntitlement } = useContext(AppContext);
  const queryParams = useQueryParams();
  const history = useHistory();
  const labelsQuery = useCampaignLabelsQuery({
    ...apiOptions,
  });

  const toggleLabelMutation = useToggleCampaignLabelMutation();
  const archiveLabelMutation = useArchiveCampaignLabelMutation();
  const [editingLabelId, setEditingLabelId] = useState<string | null>(
    queryParams.get('label_id') === '' ? null : queryParams.get('label_id')
  );

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: labelsQuery.data?.count,
      pageSize: apiOptions.pageSize,
      onChange: (page, pageSize) =>
        onApiOptionsChange((state) => ({ ...state, page, pageSize })),
      current: labelsQuery.data?.page_number,
      showSizeChanger: false,
      style: { paddingBottom: 54, paddingRight: 16 },
      showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
    }),
    [labelsQuery.data, apiOptions.pageSize, onApiOptionsChange]
  );

  const labels = labelsQuery.data?.results || [];

  if (labels.length === 0 && !labelsQuery.isLoading && labelsQuery.isFetched) {
    return (
      <EmptyCampaignPlacementStates
        searching={!!apiOptions.search || !!apiOptions.enabled}
        page="placements"
      />
    );
  }

  return (
    <>
      <Responsive size="mdUp">
        <Table
          loading={labelsQuery.isLoading}
          rowKey="id"
          dataSource={labels}
          scroll={{ x: 1300 }}
          columns={getColumns('mdUp')}
          pagination={pagination}
          className="horizontalScrollTable"
          rowSelection={{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }}
        />
      </Responsive>
      <Responsive size="mdDown">
        <Table
          loading={labelsQuery.isLoading}
          rowKey="id"
          dataSource={labels}
          scroll={{ x: 1200 }}
          columns={getColumns('mdDown')}
          pagination={pagination}
          className="horizontalScrollTable"
        />
      </Responsive>
      {editingLabelId && (
        <EditLabelModal
          labelId={editingLabelId}
          open={!!editingLabelId}
          onClose={() => {
            setEditingLabelId(null);
            setTimeout(() => labelsQuery.refetch(), 2000);
          }}
        />
      )}
    </>
  );

  function getColumns(size: 'mdUp' | 'mdDown'): ColumnsType<TCampaignLabel> {
    const displayNameCol = {
      title: (
        <Tooltip title={'Use Display Name for new campaign rules'}>
          <Typography.Text strong>Display Name</Typography.Text>
        </Tooltip>
      ),
      render: (label: TCampaignLabel) => (
        <Typography.Text style={{ fontSize: 'small' }}>
          {label.display_name || ''}
        </Typography.Text>
      ),
      width: 250,
    };
    const screenshotCol = {
      title: (
        <Tooltip
          title={
            'Upload an image of this placement in your app. Hover to view a larger image.'
          }
        >
          <Typography.Text strong>Screenshot</Typography.Text>
        </Tooltip>
      ),
      render: (label: TCampaignLabel) => renderScreenshot(label),
      width: 150,
    };
    const tagsCol = {
      title: <Typography.Text strong>Tags</Typography.Text>,
      render: (label: TCampaignLabel) => renderTags(label),
      width: 200,
    };

    let result: ColumnsType<TCampaignLabel> = [
      {
        title: <Typography.Text strong>On/Off</Typography.Text>,
        render: (label: TCampaignLabel) => (
          <Switch
            checked={label.enabled}
            size="small"
            onChange={() => toggleLabelMutation.mutate(label)}
            loading={toggleLabelMutation.isLoading}
            disabled={
              !userHasEntitlement('app.campaign.update') || label.archived
            }
            className="campaignSwitch"
          />
        ),
        fixed: size === 'mdUp' ? 'left' : undefined,
        width: 100,
      },
      {
        title: <Typography.Text strong>Label</Typography.Text>,
        render: (label: TCampaignLabel) => (
          <PlacementHoverLabel
            value={label.value}
            id={label.id}
            editAction={() => setEditingLabelId(label.id)}
          />
        ),
        width: 280,
        fixed: size === 'mdUp' ? 'left' : undefined,
      },
      {
        title: <Typography.Text strong>Delivery</Typography.Text>,
        render: (label: TCampaignLabel) => renderLabelDelivery(label),
        width: 250,
      },
      {
        title: (
          <Tooltip
            title={
              'Use Description to describe where this placement lives in your app'
            }
          >
            <Typography.Text strong>Description</Typography.Text>
          </Tooltip>
        ),
        render: (label: TCampaignLabel) => (
          <Space direction="horizontal" size={0}>
            <Typography.Text style={{ fontSize: 'small' }}>
              {label.description}
            </Typography.Text>
          </Space>
        ),
        width: 300,
      },
      {
        title: <Typography.Text strong>Created</Typography.Text>,
        dataIndex: 'created_date',
        render: (created_date: string) => (
          <Tooltip title={moment(created_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer', fontSize: 13 }}>
              {moment(created_date).fromNow()}
            </span>
          </Tooltip>
        ),
        ellipsis: true,
        width: 150,
      },
      {
        title: <Typography.Text strong>Updated</Typography.Text>,
        dataIndex: 'updated_date',
        render: (updated_date: string) => (
          <Tooltip title={moment(updated_date).format('YYYY-MM-DD h:mm A')}>
            <span style={{ cursor: 'pointer', fontSize: 13 }}>
              {moment(updated_date).fromNow()}
            </span>
          </Tooltip>
        ),
        width: 150,
        ellipsis: true,
        responsive: ['lg'],
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        ellipsis: true,
        render: (label: TCampaignLabel) => (
          <Space direction="horizontal" size="small">
            {getEditingLink(label)}
            {getArchiveLink(label)}
            {getReportingLink(label)}
          </Space>
        ),
        width: 325,
      },
    ];

    if (planHasEntitlement('app.placement.create_infinite')) {
      result.splice(3, 0, tagsCol);
      result.splice(4, 0, displayNameCol);
      result.splice(6, 0, screenshotCol);
    }

    return result;
  }

  function renderLabelDelivery(label: TCampaignLabel): React.ReactNode {
    if (label.archived) {
      return (
        <Typography.Text style={{ color: namiMediumGray }}>
          Archived
        </Typography.Text>
      );
    } else if (!label.enabled) {
      return (
        <Typography.Text style={{ color: namiMediumGray }}>Off</Typography.Text>
      );
    } else {
      const rulesLive = (label.rules || []).reduce((count, rule) => {
        return count + +(!!rule.enabled && !rule.archived);
      }, 0);

      if (rulesLive > 0) {
        return (
          <Space direction="horizontal" size="small">
            <LiveBadgeDot />
            <LinkType
              onClick={() =>
                history.push(
                  `/campaigns/rules/?label_id=${label.id}&status=enabled`
                )
              }
            >
              {rulesLive} Rule{rulesLive > 1 ? 's' : ''} Live
            </LinkType>
          </Space>
        );
      } else {
        return (
          <Space direction="horizontal" size="small">
            <DisabledBadgeDot />
            <Typography.Text style={{ color: namiMediumGray }}>
              {rulesLive} Rules Live
            </Typography.Text>
          </Space>
        );
      }
    }
  }

  function renderTags(label: TCampaignLabel): React.ReactNode {
    return (
      <span>
        {(label.tags || []).map((tag) => {
          return <Tag key={tag}>{tag}</Tag>;
        })}
      </span>
    );
  }

  function renderScreenshot(label: TCampaignLabel): React.ReactNode {
    if (!label.screenshot) return <></>;
    return (
      <Image
        src={label.screenshot}
        width={50}
        alt={`screenshot for ${label.value}`}
        preview={{
          mask: <ZoomInRounded />,
        }}
      />
    );
  }

  function getEditingLink(label: TCampaignLabel): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        size="small"
        icon={<EditOutlined style={{ fontSize: '13px' }} />}
        disabled={!userHasEntitlement('app.campaign.get')}
        onClick={() => editLabel(label)}
      >
        Edit
      </IconActionButton>
    );
  }

  function getArchiveLink(label: TCampaignLabel): React.ReactNode {
    return (
      <IconActionButton
        type="text"
        icon={
          label.archived ? (
            <RestoreFromTrashOutlined
              style={{
                fontSize: 15,
              }}
            />
          ) : (
            <DeleteOutline
              style={{
                fontSize: 15,
              }}
            />
          )
        }
        size="small"
        disabled={!userHasEntitlement('app.campaign.update')}
        onClick={() =>
          archiveLabelMutation.mutate({
            label_id: label.id,
            archived: !!label.archived,
          })
        }
      >
        {label.archived ? 'Restore' : 'Archive'}
      </IconActionButton>
    );
  }

  function getReportingLink(label: TCampaignLabel): React.ReactNode {
    if (!userHasEntitlement('app.analytics.impressions')) return null;
    const filters: TAppliedFilters = {
      filter_by_campaign: {
        identifier: 'CampaignLabel.id',
        operator: 'equals',
        values: [label.id],
      },
    };

    const urlMeta = '?filters=' + JSON.stringify([filters.filter_by_campaign]);

    return (
      <IconActionButton
        type="text"
        icon={<ShowChartOutlined style={{ fontSize: '15px' }} />}
        href={`/insights/impressions/${urlMeta}`}
        size="small"
      >
        Impressions
      </IconActionButton>
    );
  }

  function editLabel(label: TCampaignLabel) {
    setEditingLabelId(label.id);
  }
}
