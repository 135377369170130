import { apiRequest } from '../../services/apiService';
import * as TYPES from '../types';

export const getPaywalls = (appId) => {
  return apiRequest({
    type: TYPES.GET_PAYWALLS,
    url: `/private/paywalls/?app_id=${appId}&page_size=50`,
    method: 'GET',
  });
};

export const getPaywall = (paywallId) => {
  return apiRequest({
    type: TYPES.GET_PAYWALL,
    url: `/private/paywalls/${paywallId}/`,
    method: 'GET',
  });
};

export const updatePaywall = (paywallId, data) => {
  return apiRequest({
    type: TYPES.UPDATE_PAYWALL,
    meta: {
      segment: {
        event: 'Paywall Updated',
        context: data,
      },
    },
    url: `/private/paywalls/${paywallId}/`,
    method: 'PUT',
    data,
    options: {
      onSuccess({ dispatch }) {
        getPaywall(paywallId);
        dispatch({ type: TYPES.UPDATE_PAYWALL_SUCCESS });
      },
    },
  });
};

export const getPaywallStyles = (paywallStyleId) => {
  return apiRequest({
    type: TYPES.GET_PAYWALL_STYLES,
    url: `/private/paywall_styles/${paywallStyleId}/`,
    method: 'GET',
  });
};

export const updatePaywallStyles = (paywallStyleId, data) => {
  return apiRequest({
    type: TYPES.UPDATE_PAYWALL_STYLES,
    url: `/private/paywall_styles/${paywallStyleId}/`,
    method: 'PATCH',
    data,
  });
};

export const getPaywallMenusAvailable = (platformId, language, menuId) => {
  return apiRequest({
    type: TYPES.GET_PAYWALL_MENUS_AVAILABLE,
    url: `/private/skus/?platform_id=${platformId}&language=${language}&available_to_menu_id=${menuId}&page_size=50`,
    method: 'GET',
  });
};

export const getPaywallMenus = (paywallId, platformId) => {
  return apiRequest({
    type: TYPES.GET_PAYWALL_MENUS,
    url: `/private/paywall_menus/?paywall_id=${paywallId}&platform_id=${platformId}`,
    method: 'GET',
  });
};

export const updatePaywallMenus = (menuId, data) => {
  const orderedItems = data['ordered_items'].reduce((output, item) => {
    let newItem = { ...item };
    delete newItem['sku_id'];
    newItem.requested_sku_id = item['sku_id'];
    return [...output, newItem];
  }, []);
  const newData = {
    ...data,
    ordered_items: orderedItems,
  };
  return apiRequest({
    type: TYPES.UPDATE_PAYWALL_MENUS,
    url: `/private/paywall_menus/${menuId}/`,
    method: 'PATCH',
    data: newData,
  });
};

export const handlePaywallMenusChanges = () => {
  return {
    type: TYPES.HANDLE_PAYWALL_MENUS_CHANGES,
  };
};

export const handleToggleFeatured = (skuId, featured) => {
  return {
    type: TYPES.HANDLE_TOGGLE_FEATURED,
    payload: { skuId, featured },
  };
};

export const handlePaywallMenus = (data) => {
  return {
    type: TYPES.HANDLE_PAYWALL_MENUS,
    payload: { data },
  };
};

export const handlePaywallMenusAvailable = (data) => {
  return {
    type: TYPES.HANDLE_PAYWALL_MENUS_AVAILABLE,
    payload: { data },
  };
};

export const handlePaywallField = (data) => {
  return {
    type: TYPES.HANDLE_PAYWALL_FIELD,
    payload: { paywall: data },
  };
};

export const handlePaywallStylesField = (data) => {
  return {
    type: TYPES.HANDLE_PAYWALL_STYLES_FIELD,
    payload: { paywallStyles: data },
  };
};

export const handlePaywallKeyField = (key, value) => {
  return {
    type: TYPES.HANDLE_PAYWALL_KEY_FIELD,
    payload: { key, value },
  };
};

export const resetPaywallKeys = () => {
  return { type: TYPES.RESET_PAYWALL_KEYS };
};

export const handlePaywallColor = (key, color) => {
  return { type: TYPES.HANDLE_PAYWALL_COLOR, payload: { key, color } };
};

export const handlePaywallSize = (key, value) => {
  return { type: TYPES.HANDLE_PAYWALL_SIZE, payload: { key, value } };
};

export const toggleDisplayUnsavedChanges = () => {
  return {
    type: TYPES.TOGGLE_DISPLAY_UNSAVED_CHANGES,
  };
};
