import React from 'react';

import {
  Col,
  ConfigProvider,
  Empty,
  List,
  Row,
  Space,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import {
  purchaseEventTypeIcons,
  TNamiPurchaseEventPrettyNames,
} from 'src/api/types/customer.types';
import { usePurchaseEventsForPurchaseQuery } from 'src/hooks/queries/customer.hooks';
import { getListDescriptionForPurchaseEvent } from 'src/utils/customer';
import { namiDarkGray, namiPureWhite } from 'src/variables';
import styled from 'styled-components';

const ListItemStyled = styled(List.Item)`
  cursor: pointer;
  :hover {
    background-color: #f0f0f0;
  }
`;

const IconWrapper = styled.span`
  svg {
    font-size: 18px;
    transform: translateY(3px);
    color: ${namiDarkGray};
  }
`;

export default function PurchaseEvents({
  userID,
  purchaseId,
}: {
  userID: string;
  purchaseId: string;
}) {
  const purchaseEvents = usePurchaseEventsForPurchaseQuery(purchaseId);
  const history = useHistory();

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: 18, background: namiPureWhite }}
    >
      <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
        Purchase History
      </Typography.Text>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            imageStyle={{ display: 'none' }}
            description={
              <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
                No purchase history found
              </span>
            }
          ></Empty>
        )}
      >
        <List
          header={null}
          footer={null}
          dataSource={purchaseEvents.data?.results || []}
          loading={purchaseEvents.isLoading}
          style={{
            backgroundColor: namiPureWhite,
            padding: '12px 4px',
          }}
          renderItem={(item) => (
            <ListItemStyled
              onClick={() =>
                history.push(
                  `/customers-beta/${userID}/purchases/${purchaseId}/purchaseEvent/${item.id}`
                )
              }
            >
              <Row
                gutter={[8, 8]}
                style={{ width: '100%', padding: '12px 24px' }}
              >
                <Col md={8}>
                  <Space size="large">
                    <IconWrapper>
                      {purchaseEventTypeIcons[item.nami_event_type]}
                    </IconWrapper>
                    <b>{TNamiPurchaseEventPrettyNames[item.nami_event_type]}</b>
                  </Space>
                </Col>
                <Col md={8}>{getListDescriptionForPurchaseEvent(item)}</Col>
                <Col md={8}>
                  <Tooltip
                    title={moment(item.event_time).format('YYYY-MM-DD h:mm A')}
                  >
                    <span
                      style={{
                        float: 'right',
                        fontSize: 13,
                        color: namiDarkGray,
                      }}
                    >
                      {moment(item.event_time).fromNow()}
                    </span>
                  </Tooltip>
                </Col>
              </Row>
            </ListItemStyled>
          )}
        />
      </ConfigProvider>
    </Space>
  );
}
