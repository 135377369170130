import React, { useEffect, useState } from 'react';

import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { DeleteOutline, RestoreFromTrashOutlined } from '@mui/icons-material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Col, notification, Space } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import api from 'src/api';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import SearchInput from 'src/components/SearchInput';
import LiveBadgeDot from 'src/components/StatusDots/LiveBadgeDot';
import OffBadgeDot from 'src/components/StatusDots/OffBadgeDot';
import { placementCampaignStatusFilterOptions } from 'src/components/StatusFilter/statusFilterOptions';
import { useAppContext, useBooleanState, useQueryParams } from 'src/hooks';
import {
  useCampaignRuleApiOptions,
  useNewCampaignRuleMutation,
} from 'src/hooks/queries/campaign.hooks';
import QueryKeys from 'src/hooks/queries/queryKeys';
import CampaignTagFilter from 'src/pages/admin/campaigns/CampaignTagFilter';

import StatusFilter from '../../../components/StatusFilter/StatusFilter';
import AddRuleModal from './AddRuleModal';
import CampaignsTable from './CampaignsTable';
import PlacementFilter from './PlacementFilter';
import { ToolbarRow } from './PlacementsWrapper';

export default function CampaignsWrapper() {
  const { userHasEntitlement } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();
  const queryParams = useQueryParams();
  const [labelId, setLabelId] = useState<string | null>(
    queryParams.get('label_id') || null
  );
  const [isAddModalOpen, openAddModal, closeAddModal] = useBooleanState(false);
  const [status, setStatus] = useState<string | null>(
    queryParams.get('status') || null
  );
  const [tag, setTag] = useState<string | null>(
    queryParams.get('tag') === '' ? null : queryParams.get('tag')
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const hasSelected = selectedRowKeys.length > 0;
  const [apiOptions, updateApiOptions] = useCampaignRuleApiOptions({
    pageSize: 20,
    label_id: labelId || '',
    enabled: getEnabledStatus(status),
    archived: getArchivedStatus(),
    tag: tag || '',
  });
  const newLabelRuleMutation = useNewCampaignRuleMutation();

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      label_id: labelId || '',
      enabled: getEnabledStatus(status),
      archived: getArchivedStatus(),
      tag: tag || '',
    });
    queryParams.set('label_id', labelId || '');
    queryParams.set('status', status || '');
    queryParams.set('tag', tag || '');
    location.search = queryParams.toString();
    history.push(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelId, status, tag]);

  return (
    <>
      <ToolbarRow gutter={[8, 8]} align="middle">
        {hasSelected ? (
          <>
            <Col xs={24} md={12}>
              <IconActionButton
                type="text"
                size="small"
                icon={<CloseOutlined style={{ fontSize: '13px' }} />}
                onClick={() => setSelectedRowKeys([])}
              >
                {`${selectedRowKeys.length} selected`}
              </IconActionButton>
            </Col>
            <Col xs={24} md={12} style={{ textAlign: 'right' }}>
              <Space>
                {(!status || status === 'paused') && (
                  <Button
                    onClick={enableSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                  >
                    <Space direction="horizontal">
                      <LiveBadgeDot />
                      Set Live
                    </Space>
                  </Button>
                )}
                {(!status || status === 'enabled') && (
                  <Button
                    onClick={pauseSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                  >
                    <Space direction="horizontal">
                      <OffBadgeDot />
                      Pause
                    </Space>
                  </Button>
                )}
                {(!status || status !== 'archived') && (
                  <Button
                    onClick={archiveSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                    icon={
                      <DeleteOutline
                        style={{
                          fontSize: 15,
                          marginRight: 3,
                        }}
                      />
                    }
                  >
                    Archive
                  </Button>
                )}
                {status === 'archived' && (
                  <Button
                    onClick={unarchiveSelection}
                    disabled={!userHasEntitlement('app.campaign.update')}
                    icon={
                      <RestoreFromTrashOutlined
                        style={{
                          fontSize: 15,
                          marginRight: 3,
                        }}
                      />
                    }
                  >
                    Restore
                  </Button>
                )}
              </Space>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} md={6}>
              <SearchInput
                placeholder="Search campaign rule"
                value={apiOptions.search || ''}
                onChange={(search) => {
                  updateApiOptions({ search });
                }}
                intercomClassName="intercom-campaignsSearch"
              />
            </Col>
            <Col xs={12} md={5}>
              <PlacementFilter
                selectedLabel={labelId}
                onChange={setLabelId}
                onClear={() => setLabelId(null)}
              />
            </Col>
            <Col xs={12} md={4}>
              <StatusFilter
                status={status}
                onChange={setStatus}
                onClear={() => setStatus(null)}
                options={placementCampaignStatusFilterOptions}
                intercomClassName="intercom-campaignStatusFilter"
              />
            </Col>
            <Col xs={12} md={4}>
              <CampaignTagFilter
                selectedTag={tag}
                onChange={setTag}
                onClear={() => setTag(null)}
              />
            </Col>
            <Col xs={0} md={2}>
              <IconActionButton
                type="text"
                icon={<CloseOutlined style={{ fontSize: '13px' }} />}
                onClick={() => resetFilters()}
                disabled={!labelId && !status && !tag}
              >
                Reset
              </IconActionButton>
            </Col>
            <Col xs={24} md={3}>
              <Space
                direction="horizontal"
                size="small"
                style={{ float: 'right' }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    if (!labelId) openAddModal();
                    else {
                      newLabelRuleMutation.mutate({
                        label: labelId,
                        name: 'Untitled Campaign',
                        priority: 0,
                        enabled: false,
                      });
                    }
                  }}
                  icon={<PlusOutlined />}
                  disabled={!userHasEntitlement('app.campaign.create')}
                  className="intercom-addCampaign"
                >
                  Add
                </Button>
              </Space>
            </Col>
          </>
        )}
      </ToolbarRow>
      <CampaignsTable
        apiOptions={apiOptions}
        onApiOptionsChange={updateApiOptions}
        selectedRowKeys={selectedRowKeys}
        onSelectChange={onSelectChange}
      />
      <AddRuleModal isOpen={isAddModalOpen} onClose={closeAddModal} />
    </>
  );

  function resetFilters() {
    setStatus(null);
    setLabelId(null);
    setTag(null);
  }

  function getArchivedStatus(): boolean {
    if (status === 'archived') return true;
    return false;
  }

  function enableSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignRule(rowKey.toString(), { enabled: true })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} campaign${
            selectedRowKeys.length > 1 ? 's' : ''
          } set live.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't set campaigns live",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignRules]);
        setSelectedRowKeys([]);
      });
  }

  function pauseSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignRule(rowKey.toString(), { enabled: false })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} campaign${
            selectedRowKeys.length > 1 ? 's' : ''
          } paused.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't pause campaigns",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignRules]);
        setSelectedRowKeys([]);
      });
  }

  function archiveSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignRule(rowKey.toString(), { archived: true })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} campaign${
            selectedRowKeys.length > 1 ? 's' : ''
          } archived.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't archive campaigns",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignRules]);
        setSelectedRowKeys([]);
      });
  }

  function unarchiveSelection() {
    const promises = selectedRowKeys.map((rowKey) =>
      api.updateCampaignRule(rowKey.toString(), { archived: false })
    );
    Promise.all(promises)
      .then(() => {
        notification.success({
          message: `${selectedRowKeys.length} campaign${
            selectedRowKeys.length > 1 ? 's' : ''
          } restored.`,
        });
      })
      .catch((reason) => {
        notification.error({
          message: reason.detail || "Couldn't restore campaigns",
        });
      })
      .finally(() => {
        queryClient.invalidateQueries([QueryKeys.campaignRules]);
        setSelectedRowKeys([]);
      });
  }
}

export function getEnabledStatus(status: string | null): boolean | undefined {
  if (status === 'enabled') return true;
  if (status === 'paused') return false;
  return undefined;
}
