import { client } from './clients';
import {
  TCustomerDevice,
  TCustomerPurchase,
  TCustomerPurchaseEvent,
  TExternalUser,
} from './types/customer.types';
import { TApiOptions, TListResult } from './types/main.types';
import { buildURLParams, extractErrorData, extractResponseData } from './utils';

const customers = {
  getExternalUsers: (
    appId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TExternalUser>> => {
    const params = buildURLParams({
      pageSize: 50,
      ...apiOptions,
      appId,
    });
    return client
      .get(`/api/v2/external_user/?${params}&ordering=-created_date`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getExternalUser: (id: string): Promise<TExternalUser> => {
    return client
      .get(`/api/v2/external_user/${id}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getDevicesForExternalUser: (
    userId: string,
    apiOptions: TApiOptions = {}
  ): Promise<TListResult<TCustomerDevice>> => {
    const params = buildURLParams({
      pageSize: 50,
      ...apiOptions,
      external_user_id: userId,
      order_by: '-created_date',
    });
    return client
      .get(`/api/v2/device/?${params}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getDevice: (deviceId: string): Promise<TCustomerDevice> => {
    return client
      .get(`/api/v2/device/${deviceId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPurchasesForExternalUser: (
    userId: string
  ): Promise<TListResult<TCustomerPurchase>> => {
    return client
      .get(`/api/v2/purchase?external_user_id=${userId}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPurchase: (purchaseId: string): Promise<TCustomerPurchase> => {
    return client
      .get(`/api/v2/purchase/${purchaseId}/`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPurchaseEvents: (
    purchaseId: string
  ): Promise<TListResult<TCustomerPurchaseEvent>> => {
    return client
      .get(
        `/api/v2/purchase_events?purchase__id=${purchaseId}&ordering=-event_time`
      )
      .then(extractResponseData)
      .catch(extractErrorData);
  },
  getPurchaseEvent: (eventId: string): Promise<TCustomerPurchaseEvent> => {
    return client
      .get(`/api/v2/purchase_events/${eventId}`)
      .then(extractResponseData)
      .catch(extractErrorData);
  },
};

export default customers;
