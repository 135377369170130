import React, { useMemo } from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { PhotoLibraryOutlined } from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import { Form, Select, Space, Tooltip } from 'antd';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import { deconstructVariable } from 'src/utils/paywall';

import { FieldObject } from '../../../utils/formFieldBuilding';
import InputLabel from './InputLabel';

type CarouselInputSelectorProps = Omit<FieldObject, 'variable'> & {
  defaultValue?: string | number;
  onChange: (value: any) => void;
  label: string;
  controlled?: boolean;
  isSingle?: boolean;
  collapsible?: boolean;
  variable?: string;
  conditionAttribute?: boolean;
  variant: 'text' | 'image' | 'fillImage' | 'texts';
};

const selector = createSelector(
  [
    ({ paywallBuilder }: RootState) =>
      paywallBuilder.paywall?.template['ui.carousels'] || {},
  ],
  (carousels) => carousels
);

export default function CarouselInputSelector({
  defaultValue,
  controlled = false,
  componentLocation,
  variant = 'text',
  component,
  conditionAttribute = false,
  ...field
}: CarouselInputSelectorProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const carousels = useAppSelector(selector);

  const itemProps = {
    name: controlled ? undefined : field.variable,
    label: <InputLabel {...field} />,
  };

  const selectOptions = (carousels['carouselName'].fields || []).reduce(
    (output, field) => {
      if (variant === 'text' && ['text', 'textArea'].includes(field.type)) {
        return [
          ...output,
          {
            value: deconstructVariable(field.variable, false, true),
            label: field.label,
          },
        ];
      } else if (variant === 'texts' && field.type === 'splitTextArea') {
        return [
          ...output,
          {
            value: deconstructVariable(field.variable, false, true),
            label: field.label,
          },
        ];
      } else if (
        (variant === 'image' || variant === 'fillImage') &&
        field.type === 'image'
      ) {
        return [
          ...output,
          {
            value: deconstructVariable(field.variable, false, true),
            label: field.label,
          },
        ];
      }
      return output;
    },
    [] as Array<{ value: string; label: string }>
  );

  const variableValue = useMemo(() => {
    return deconstructVariable(field.variable || '', false, true);
  }, [field.variable]);

  return (
    <Form.Item
      {...itemProps}
      label={
        <Space direction="horizontal" size={2}>
          {field.name || 'Carousel Field'}
          <Tooltip title="View/Edit Carousel Slides" align={{ offset: [0, 4] }}>
            <IconActionButton
              type="text"
              icon={<PhotoLibraryOutlined style={{ fontSize: '14px' }} />}
              onClick={() => {
                actions.setEditingComponentId('carousel'); //TODO - handle multiple carousels
                actions.setSelectedTreeKey('carousel');
                actions.setEditingSlideId(null);
              }}
              size="small"
            />
          </Tooltip>
          {variant === 'fillImage' && (
            <Tooltip
              title="Remove property from component"
              align={{ offset: [0, 4] }}
            >
              <IconActionButton
                type="text"
                icon={<CloseOutlined style={{ fontSize: '13px' }} />}
                size="small"
                onClick={() =>
                  actions.removeProperty({
                    location: componentLocation,
                    propertyType: 'fillImage',
                  })
                }
              />
            </Tooltip>
          )}
        </Space>
      }
    >
      <Select
        value={variableValue}
        placeholder="Select input"
        options={selectOptions}
        onChange={(value: string) => handleChange(value)}
      />
    </Form.Item>
  );

  function handleChange(value: string) {
    if (!componentLocation) {
      console.warn(
        `Cannot update carousel input to ${value}, component location is unknown`
      );
      return;
    }

    const propertyName = variant === 'image' ? 'url' : variant;

    if (conditionAttribute) {
      actions.updateComponentConditionAttributes({
        idLocation: componentLocation,
        assertionIndex: field.currentAssertionIndex || 0,
        attributes: {
          [propertyName]: `\${slide.${value}}`,
        },
      });
    } else {
      actions.updateComponentCarouselProperty({
        location: componentLocation,
        value: `\${slide.${value}}`,
        property: propertyName,
      });
    }
  }
}
