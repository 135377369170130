import React from 'react';

import { CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { formFactorIcons } from 'src/api/types/main.types';
import styled from 'styled-components';

import StatusSelect from '../campaigns/StatusSelect';

type FormFactorFilterProps = {
  formFactor: string | null;
  onChange: (value: string | null) => void;
  onClear: () => void;
};

const WithWidth = styled.div<{ as: any }>`
  width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
`;

export default function FormFactorFilter({
  formFactor,
  onChange,
  onClear,
}: FormFactorFilterProps) {
  let options = [
    {
      label: (
        <Space direction="horizontal">Phone {formFactorIcons['phone']}</Space>
      ),
      text: 'Phone',
      value: 'phone',
    },
    {
      label: (
        <Space direction="horizontal">
          Tablet {formFactorIcons['tablet-portrait']}
        </Space>
      ),
      text: 'Tablet',
      value: 'tablet',
    },
    {
      label: (
        <Space direction="horizontal">TV {formFactorIcons['television']}</Space>
      ),
      text: 'TV',
      value: 'television',
    },
    {
      label: (
        <Space direction="horizontal">
          Desktop {formFactorIcons['desktop']}
        </Space>
      ),
      text: 'Desktop',
      value: 'desktop',
    },
  ];
  return (
    <SelectWrapper>
      <WithWidth
        as={StatusSelect}
        allowClear
        mode={undefined}
        showArrow={false}
        optionFilterProp="text"
        options={options}
        value={formFactor}
        onChange={(value: string) => onChange(value)}
        onClear={onClear}
        clearIcon={<CloseOutlined />}
        label={formFactor ? 'Form Factor:' : ''}
        className="intercom-paywallFormFactorFilter"
        placeholder={
          <>
            Filter by <b>Form Factor</b>
          </>
        }
      />
    </SelectWrapper>
  );
}
