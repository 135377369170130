import React, { useMemo } from 'react';

import { SkipNextOutlined } from '@mui/icons-material';
import { Button, Tooltip } from 'antd';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';

export default function PaywallVideoPlayStatePicker() {
  const actions = useActions(PaywallBuilderSlice.actions);
  const { slides, currentGroupId, slideId } = useAppSelector(
    ({ paywallBuilder: { slides, paywall, groupId } }: RootState) => ({
      slides: paywall?.template.initialState.slides,
      currentGroupId: groupId,
      slideId: slides['carouselName'],
    })
  );

  const nextSlideId = useMemo(() => {
    if (!slides || !currentGroupId) return null;
    const foundIndex = slides[currentGroupId]['carouselName'].findIndex(
      (value) => {
        return value.id === slideId;
      }
    );
    const currentIndex = foundIndex === -1 ? 0 : foundIndex;
    if (currentIndex === slides[currentGroupId]['carouselName'].length - 1)
      return slides[currentGroupId]['carouselName'][0].id;
    return slides[currentGroupId]['carouselName'][currentIndex + 1].id;
  }, [slides, currentGroupId, slideId]);

  if (!slides || !currentGroupId) return <></>;
  return (
    <>
      <Tooltip
        title={`Advance to the next slide in the carousel`}
        mouseEnterDelay={0.8}
      >
        <Button
          type={'ghost'}
          icon={<SkipNextOutlined className="inButton" />}
          size="small"
          onClick={advanceSlide}
          disabled={
            !nextSlideId ||
            nextSlideId === slideId ||
            slides[currentGroupId]['carouselName'].length < 2
          }
        />
      </Tooltip>
    </>
  );

  function advanceSlide() {
    if (nextSlideId && nextSlideId !== slideId)
      actions.setViewingSlideId(nextSlideId);
  }
}
