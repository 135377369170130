import React, { useMemo } from 'react';

import { Col, Row, TablePaginationConfig } from 'antd';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import Page from 'src/components/Page/Page';
import SearchInput from 'src/components/SearchInput';
import { useApiOptions } from 'src/hooks/queries/apiOptions.hooks';
import { useExternalUsersQuery } from 'src/hooks/queries/customer.hooks';

import CustomersTable from './CustomersTable';

export default function Customers() {
  const [apiOptions, updateApiOptions] = useApiOptions({
    pageSize: 20,
  });
  const customersQuery = useExternalUsersQuery(apiOptions);

  const pagination = useMemo(
    (): TablePaginationConfig => ({
      total: customersQuery.data?.count,
      pageSize: apiOptions.pageSize,
      onChange: (page, pageSize) =>
        updateApiOptions((state) => ({ ...state, page, pageSize })),
      current: customersQuery.data?.page_number,
      showSizeChanger: false,
      style: { paddingBottom: 72 },
      showTotal: (total, [n1, n2]) => `${n1} - ${n2} of ${total}`,
    }),
    [customersQuery.data, apiOptions.pageSize, updateApiOptions]
  );

  return (
    <Page title="Customers">
      <Row justify="start" gutter={[0, 36]} style={{ marginBottom: 16 }}>
        <Col xs={24}>
          <Breadcrumb
            items={[{ url: '/customers-beta/', name: 'Customers' }]}
          />
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ paddingBottom: 24 }}>
        <Col xs={24} md={8}>
          <SearchInput
            placeholder="Search by external id, case-sensitive"
            value={apiOptions.external_id || ''}
            onChange={(external_id) => {
              updateApiOptions({ external_id });
            }}
            intercomClassName="intercom-customersSearch"
          />
        </Col>
      </Row>
      <CustomersTable
        loading={customersQuery.isLoading}
        customers={customersQuery.data?.results || []}
        pagination={pagination}
      />
    </Page>
  );
}
