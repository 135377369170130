import React from 'react';

import { EyeOutlined } from '@ant-design/icons';
import { LaunchOutlined } from '@mui/icons-material';
import {
  ConfigProvider,
  Empty,
  Image,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { TCustomerPurchase } from 'src/api/types/customer.types';
import IconActionButton from 'src/components/ActionButtons/IconActionButton';
import MonospaceText from 'src/components/MonospaceText/MonospaceText';
import { useExternalUserPurchasesQuery } from 'src/hooks/queries/customer.hooks';
import { platformLogo } from 'src/services/helpers';
import { getUSDCurrency } from 'src/utils/math';
import { namiDarkGray, namiPureWhite } from 'src/variables';

export default function CustomerPurchases({
  customerId,
}: {
  customerId: string;
}) {
  const history = useHistory();
  const customerPurchases = useExternalUserPurchasesQuery(customerId);

  return (
    <Space
      direction="vertical"
      style={{ width: '100%', padding: 18, background: namiPureWhite }}
      className="intercom-customerPurchases"
    >
      <Typography.Text style={{ fontSize: 16, fontWeight: 500 }}>
        Purchases
      </Typography.Text>
      <ConfigProvider
        renderEmpty={() => (
          <Empty
            imageStyle={{ display: 'none' }}
            description={
              <span style={{ color: namiDarkGray, fontStyle: 'italic' }}>
                No purchases found
              </span>
            }
          ></Empty>
        )}
      >
        <Table
          loading={customerPurchases.isLoading}
          rowKey="id"
          dataSource={customerPurchases.data?.results || []}
          columns={getColumns()}
          pagination={false}
          className="horizontalScrollTable"
          scroll={{ x: 750 }}
        />
      </ConfigProvider>
    </Space>
  );

  function getColumns(): ColumnsType<TCustomerPurchase> {
    return [
      {
        title: <Typography.Text strong>ID</Typography.Text>,
        render: (purchase: TCustomerPurchase) => {
          return (
            <a href={`/customers-beta/${customerId}/purchases/${purchase.id}`}>
              <MonospaceText link={'true'}>
                {purchase.partner_guid}
              </MonospaceText>
            </a>
          );
        },
        width: 350,
        fixed: 'left',
      },
      {
        title: <Typography.Text strong>Product</Typography.Text>,
        render: (purchase: TCustomerPurchase) => {
          return (
            <a href={`/products/${purchase.product.id}`}>
              <Space direction="horizontal">
                <Image
                  height={15}
                  width={15}
                  preview={false}
                  src={platformLogo(purchase.product_platform_type)}
                />
                {purchase.product.name}
                <LaunchOutlined
                  style={{ fontSize: 13, transform: 'translateY(2px)' }}
                />
              </Space>
            </a>
          );
        },
        width: 275,
      },
      {
        title: <Typography.Text strong>Type</Typography.Text>,
        width: 200,
        render: (purchase: TCustomerPurchase) => {
          if (purchase.is_subscription) {
            return 'Subscription';
          }
          return 'One Time Subscription';
        },
      },
      {
        title: <Typography.Text strong>Date</Typography.Text>,
        render: (purchase: TCustomerPurchase) => {
          const date = purchase.not_before || purchase.created_date;
          return (
            <Tooltip title={moment(date).format('YYYY-MM-DD h:mm A')}>
              <span style={{ cursor: 'pointer' }}>
                {moment(date).format('M/D/YY')}
              </span>
            </Tooltip>
          );
        },
        width: 150,
      },
      {
        title: <Typography.Text strong>Expiration</Typography.Text>,
        dataIndex: 'expires_at',
        render: (expires_at: string) => {
          if (expires_at) {
            return (
              <Tooltip title={moment(expires_at).format('YYYY-MM-DD h:mm A')}>
                <span style={{ cursor: 'pointer' }}>
                  {moment(expires_at).format('M/D/YY')}
                </span>
              </Tooltip>
            );
          }
          return '-';
        },
        width: 150,
      },
      {
        title: <Typography.Text strong>Price (USD)</Typography.Text>,
        dataIndex: 'current_price_in_usd',
        render: (current_price_in_usd: number) => {
          if (current_price_in_usd) {
            return <span>{getUSDCurrency(current_price_in_usd)}</span>;
          }
          return '-';
        },
        width: 125,
      },
      {
        title: <Typography.Text strong>Actions</Typography.Text>,
        render: (purchase: TCustomerPurchase) => (
          <IconActionButton
            type="text"
            size="small"
            icon={<EyeOutlined style={{ fontSize: '13px' }} />}
            //disabled={!appContext.userHasEntitlement('app.productsku.get')} //TODO
            onClick={() =>
              history.push(
                `/customers-beta/${customerId}/purchases/${purchase.id}/`
              )
            }
          >
            View
          </IconActionButton>
        ),
        width: 150,
      },
    ];
  }
}
