export function parseToSnakeCase<T extends any>(input: T): T {
  return parseToCase(input, (value) =>
    value.replace(/[A-Z]/g, (char) => `_${char.toLowerCase()}`)
  );
}

function parseToCase<T extends any>(
  input: T,
  caseParser: (value: string) => string
): T {
  if (Array.isArray(input)) {
    return input.map((item) => parseToCase(item, caseParser)) as T;
  }
  if (typeof input === 'object') {
    return Object.entries(input as Object).reduce((newObject, [key, value]) => {
      const newKey = caseParser(key);
      return Object.assign({}, newObject, { [newKey]: value });
    }, {}) as T;
  }
  if (typeof input === 'string') return caseParser(input) as T;
  return input;
}

export type TSemverObj = {
  semver: string | undefined;
  major: number;
  minor: number;
  patch: number;
  prerelease: string;
  buildmetadata: string;
};

export function parseToSemver(versionString: string): TSemverObj {
  const [semVer, major, minor, patch, prerelease, buildmetadata] =
    versionString.match(
      /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/
    ) ?? [];
  return {
    semver: semVer,
    major: Number.parseInt(major) || 3,
    minor: Number.parseInt(minor) || 0,
    patch: Number.parseInt(patch) || 0,
    prerelease: prerelease,
    buildmetadata: buildmetadata,
  };
}

export function semverLarger(
  control: TSemverObj,
  compareTo: TSemverObj
): boolean {
  //TODO - handle prerelease
  return (
    compareTo.major >= control.major &&
    compareTo.minor >= control.minor &&
    compareTo.patch > control.patch
  );
}

export const INVALID_PAGE_SIZE = 1000000000;
