import React from 'react';

import Icon, {
  ArrowLeftOutlined,
  MenuFoldOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Button, Col, Layout, Row } from 'antd';
import { useHistory } from 'react-router-dom';
import NamiIcon from 'src/components/NamiIcon/NamiIcon';
import { useAppContext } from 'src/hooks';
import { namiPureWhite } from 'src/variables';
import styled from 'styled-components';

import { ReactComponent as HelpIcon } from '../../../images/icons/help.svg';

type SettingsHeaderProps = {
  size: 'mdUp' | 'mdDown';
  toggleMobileDrawerOpen: (state: boolean) => void;
  isMobileDrawerOpen: boolean;
};

const AlignRight = styled.div`
  text-align: right;
`;

export default function SettingsHeader({
  size,
  toggleMobileDrawerOpen,
  isMobileDrawerOpen,
}: SettingsHeaderProps) {
  const history = useHistory();
  const context = useAppContext();
  const docsLink = context.getDocsLink();
  return (
    <Layout.Header
      style={{
        backgroundColor: namiPureWhite,
        padding: '0px 24px',
        borderBottom: '0.2px solid rgba(76, 84, 90, 0.10)',
        zIndex: 999,
        position: 'sticky',
        top: 0,
      }}
    >
      <Row justify="space-between" align="middle">
        <Col span={3}>
          {size === 'mdUp' && (
            <Button
              type="text"
              onClick={() => history.push('/')}
              icon={<ArrowLeftOutlined />}
            >
              Back to Control Center
            </Button>
          )}
          {size === 'mdDown' && (
            <Button
              type="text"
              onClick={() => toggleMobileDrawerOpen(!isMobileDrawerOpen)}
              icon={
                isMobileDrawerOpen ? <MenuFoldOutlined /> : <MenuOutlined />
              }
            />
          )}
        </Col>
        <Col>
          {size === 'mdUp' && (
            <NamiIcon icon="ml logo" color="primary" size="large" />
          )}
          {size === 'mdDown' && (
            <a href="/overview/">
              <NamiIcon icon="ml logo" color="primary" size="medium-large" />
            </a>
          )}
        </Col>
        <Col span={3}>
          <AlignRight>
            {size === 'mdUp' && (
              <Button
                type="primary"
                ghost
                href={docsLink}
                target="_blank"
                icon={
                  <Icon
                    component={HelpIcon}
                    style={{ fontSize: 18, transform: 'translateY(2px)' }}
                  />
                }
              >
                Help Center
              </Button>
            )}
          </AlignRight>
        </Col>
      </Row>
    </Layout.Header>
  );
}
