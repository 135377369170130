import React, { useEffect, useState } from 'react';

import { Col, Radio, Row, Space, Tooltip } from 'antd';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import {
  prettyEventTypeNames,
  startDateLookbackInHours,
  TEventLogEventType,
} from 'src/api/types/developer.types';
import Breadcrumb from 'src/components/Breadcrumbs/Breadcrumbs';
import LiveDot from 'src/components/LiveDot/LiveDot';
import Page from 'src/components/Page/Page';
import { useQueryParams } from 'src/hooks';
import { useApiOptions } from 'src/hooks/queries/apiOptions.hooks';

import DeveloperPageList from './DeveloperPageList';
import DeveloperFeedFilters from './inputs/DeveloperFeedFilters';

export type TIDType = 'external_id' | 'device_id' | 'user_id' | 'cdp_id';

export default function DeveloperPageWrapper() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useQueryParams();
  const [eventType, setEventType] = useState<TEventLogEventType[]>(
    queryParams.get('event_type')
      ? (queryParams.get('event_type')?.split(',') as TEventLogEventType[])
      : (Object.keys(prettyEventTypeNames) as TEventLogEventType[])
  );
  const [startDate, setStartDate] = useState<string>(
    queryParams.get('start_date') ||
      moment().subtract(startDateLookbackInHours, 'hours').toISOString()
  );
  const [endDate, setEndDate] = useState<string | null>(
    queryParams.get('end_date') || null
  );
  const [platformId, setPlatformId] = useState<string | null>(
    queryParams.get('platform') || null
  );
  const [externalId, setExternalId] = useState<string | null>(
    queryParams.get('external_id') || null
  );
  const [deviceId, setDeviceId] = useState<string | null>(
    queryParams.get('device_id') || null
  );
  const [userId, setUserId] = useState<string | null>(
    queryParams.get('user_id') || null
  );
  const [cdpId, setCDPId] = useState<string | null>(
    queryParams.get('cdp_id') || null
  );
  const [filteringActive, setFilteringActive] = useState<boolean>(false);
  const [liveModeAvailable, setLiveModeAvailable] = useState<boolean>(true);
  const [apiOptions, updateApiOptions] = useApiOptions({
    pageSize: 20,
    created_date__gte: startDate,
    created_date__lte: endDate || '',
    event_type__in: eventType || undefined,
    platformId: platformId || '',
    device__id: deviceId || '',
    external_id: externalId || '',
    app_user_id: userId || '',
    cdp_id: cdpId || '',
  });
  const [live, setLive] = useState<boolean>(
    !(queryParams.get('start_date') || queryParams.get('end_date'))
  );

  useEffect(() => {
    updateApiOptions({
      ...apiOptions,
      event_type__in: eventType || undefined,
      created_date__gte: startDate,
      created_date__lte: endDate || '',
      platformId: platformId || '',
      external_id: externalId || '',
      device__id: deviceId || '',
      app_user_id: userId || '',
      cdp_id: cdpId || '',
    });
    queryParams.set('event_type', eventType.join(','));
    queryParams.set('start_date', startDate);
    queryParams.set('end_date', endDate || '');
    queryParams.set('platform', platformId || '');
    queryParams.set('external_id', externalId || '');
    queryParams.set('device_id', deviceId || '');
    queryParams.set('user_id', userId || '');
    queryParams.set('cdp_id', cdpId || '');
    location.search = queryParams.toString();
    history.push(location);
    const startDateInLast24 =
      moment().diff(moment(startDate), 'hour') <= startDateLookbackInHours;

    setFilteringActive(
      endDate != null ||
        platformId != null ||
        externalId != null ||
        deviceId != null ||
        userId != null ||
        cdpId != null
    );
    setLiveModeAvailable(endDate == null && startDateInLast24);
    setLive(live && startDateInLast24);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eventType,
    startDate,
    endDate,
    platformId,
    externalId,
    deviceId,
    userId,
    cdpId,
  ]);

  return (
    <Page title="Events Feed">
      <Row justify="start" gutter={[0, 36]}>
        <Col xs={24} style={{ paddingBottom: 12 }}>
          <Breadcrumb items={[{ name: 'Events Feed' }]} />
        </Col>
      </Row>
      <Row gutter={[8, 8]} style={{ paddingBottom: 24 }}>
        <Space direction="horizontal" style={{ width: '100%' }} wrap={true}>
          <Tooltip title={liveModeAvailable ? undefined : getRadioTooltip()}>
            <Radio.Group
              defaultValue="live"
              buttonStyle="solid"
              value={live ? 'live' : 'pause'}
              onChange={(e) => setLive(e.target.value === 'live')}
              disabled={!liveModeAvailable}
              className="intercom-eventsFeedLiveToggle"
            >
              <Radio.Button value="live">
                <Space size={6}>
                  Live
                  {live && <LiveDot />}
                </Space>
              </Radio.Button>
              <Radio.Button value="pause">Pause</Radio.Button>
            </Radio.Group>
          </Tooltip>
          <DeveloperFeedFilters
            allowDateFiltering={!live}
            eventTypes={eventType}
            setEventTypes={setEventType}
            platformId={platformId}
            setPlatformId={setPlatformId}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            deviceId={deviceId}
            setDeviceId={setDeviceId}
            externalId={externalId}
            setExternalId={setExternalId}
            userId={userId}
            setUserId={setUserId}
            cdpId={cdpId}
            setCDPId={setCDPId}
            resetFilters={resetFilters}
          />
        </Space>
      </Row>
      <DeveloperPageList
        apiOptions={apiOptions}
        onApiOptionsChange={updateApiOptions}
        filteringActive={filteringActive}
        live={live}
      />
    </Page>
  );

  function getRadioTooltip(): string {
    if (moment().diff(moment(startDate), 'hour') >= startDateLookbackInHours) {
      return `Live feed only available for the past ${startDateLookbackInHours} hours. Reset Start Date to enable live feed.`;
    }
    return `Remove End Date filter to enable live feed`;
  }

  function resetFilters() {
    setEventType(Object.keys(prettyEventTypeNames) as TEventLogEventType[]);
    setDeviceId(null);
    setUserId(null);
    setExternalId(null);
    setCDPId(null);
    setPlatformId(null);
    setLive(true);
    setStartDate(
      moment().subtract(startDateLookbackInHours, 'hours').toISOString()
    );
    setEndDate(null);
  }
}
