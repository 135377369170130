import React, { useMemo, useState } from 'react';

import Icon, { LockFilled, TagsOutlined, ToolFilled } from '@ant-design/icons';
import {
  AccountBalanceOutlined,
  AttachMoneyOutlined,
  BookmarkBorderOutlined,
  CancelOutlined,
  CheckBoxOutlined,
  ExpandCircleDownOutlined,
  ExpandOutlined,
  FormatListBulleted,
  HorizontalRuleRounded,
  ImageOutlined,
  LaunchOutlined,
  LayersOutlined,
  LinkOutlined,
  LocalOfferOutlined,
  LoginOutlined,
  MoodOutlined,
  NotesOutlined,
  NotListedLocationOutlined,
  NotStartedOutlined,
  PhotoAlbumOutlined,
  PhotoLibraryOutlined,
  RadioButtonChecked,
  RepeatOutlined,
  SettingsBackupRestoreOutlined,
  SignpostOutlined,
  TextSnippetOutlined,
  TitleOutlined,
  ToggleOnOutlined,
  TouchAppOutlined,
  VideoLibraryOutlined,
  ViewCarouselOutlined,
  VolumeOffOutlined,
  WebAssetOutlined,
} from '@mui/icons-material';
import { createSelector } from '@reduxjs/toolkit';
import { Badge, Card, Col, Popover, Row, Space, Tooltip } from 'antd';
import { TDevice } from 'src/api/types/paywall.types';
import {
  TAllComponentDetailValues,
  TComponent,
  TProductVariableType,
} from 'src/api/types/paywallTemplate.types';
import { useAppContext } from 'src/hooks';
import { useActions, useAppSelector } from 'src/hooks/redux.hooks';
import { ReactComponent as CustomWidthIcon } from 'src/images/custom-list-icon.svg';
import { ReactComponent as DatabaseIcon } from 'src/images/icons/database.svg';
import { RootState } from 'src/redux';
import PaywallBuilderSlice from 'src/redux/PaywallBuilderSlice';
import {
  findComponentFromLocation,
  generateNextIdForType,
  getComponentTitle,
  parseLocationString,
} from 'src/utils/paywall';
import { toCamelCase } from 'src/utils/string';
import {
  namiBrightBlue,
  namiBrightGreen,
  namiMediumGray,
  namiPrimaryBlue,
} from 'src/variables';
import styled from 'styled-components';

import {
  findCarouselChildIds,
  findParentRepeatingGridLocation,
  findRepeatingGridChildIds,
} from '../../utils/componentGeneration';
import SelectProductFieldModal from '../modals/SelectProductFieldModal';
import SelectSchemaSourceModal from '../modals/SelectSchemaFieldModal';

type AddComponentPopoverProps = {
  child: JSX.Element;
  value: TComponent;
  productChild?: boolean;
  openBuilderTreePaywall: () => void;
  overrideId?: string;
};

const TNewComponentsByFormFactor: Record<
  TDevice,
  Array<TAllComponentDetailValues>
> = {
  phone: [
    'titleText',
    'bodyText',
    'legalText',
    'textBlock',
    'bulletList',
    'customList',
    'image',
    'videoUrl',
    'icon',
    'radio',
    'productGroupToggle',
    'carousel',
    'carouselTitleText',
    'carouselBodyText',
    'carouselImage',
    'carouselBulletList',
    'carouselLegalText',
    'repeatingList',
    'repeatingTextSource',
    'repeatingImageSource',
    'loginButton',
    'restoreButton',
    'closeButton',
    'purchaseButton',
    'linkButton',
    'deeplinkButton',
    'playPauseButton',
    'volumeButton',
    'termsCheckbox',
    'collapse',
    'group',
    'spacer',
    'divider',
    'condition',
    'productText',
    'productBadge',
    'productWrapper',
    'buttonText',
    'listItem',
  ],
  tablet: [
    'titleText',
    'bodyText',
    'legalText',
    'textBlock',
    'bulletList',
    'customList',
    'image',
    'videoUrl',
    'icon',
    'radio',
    'productGroupToggle',
    'collapse',
    'termsCheckbox',
    'carousel',
    'carouselTitleText',
    'carouselBodyText',
    'carouselImage',
    'carouselBulletList',
    'carouselLegalText',
    'repeatingList',
    'repeatingTextSource',
    'repeatingImageSource',
    'loginButton',
    'restoreButton',
    'closeButton',
    'purchaseButton',
    'linkButton',
    'deeplinkButton',
    'playPauseButton',
    'volumeButton',
    'group',
    'spacer',
    'divider',
    'condition',
    'productText',
    'productBadge',
    'productWrapper',
    'buttonText',
    'listItem',
  ],
  television: [
    'titleText',
    'bodyText',
    'legalText',
    'textBlock',
    'bulletList',
    'customList',
    'image',
    // 'hostedImage',
    'videoUrl',
    'icon',
    // 'radio,
    // 'productGroupToggle',
    // 'collapse',
    // 'carousel',
    // 'carouselTitleText',
    // 'carouselBodyText',
    // 'carouselImage',
    // 'carouselBulletList',
    // 'carouselLegalText',
    // 'repeatingList',
    // 'repeatingTextSource',
    // 'repeatingImageSource',
    // 'termsCheckbox',
    'loginButton',
    'restoreButton',
    'closeButton',
    // 'purchaseButton',
    // 'linkButton',
    'deeplinkButton',
    'navigatePageButton',
    // 'playPauseButton',
    // 'volumeButton',
    'group',
    'spacer',
    'divider',
    'condition',
    'productText',
    'productBadge',
    'productWrapper',
    'buttonText',
    'listItem',
  ],
  desktop: [
    'titleText',
    'bodyText',
    'legalText',
    'textBlock',
    'bulletList',
    'customList',
    'image',
    // 'hostedImage',
    'videoUrl',
    'icon',
    // 'radio,
    'productGroupToggle',
    'collapse',
    // 'carousel',
    'repeatingList',
    'repeatingTextSource',
    'repeatingImageSource',
    'loginButton',
    // 'termsCheckbox',
    // 'restoreButton',
    'closeButton',
    'purchaseButton',
    'linkButton',
    'playPauseButton',
    'volumeButton',
    'group',
    'spacer',
    'divider',
    'condition',
    'productText',
    'productBadge',
    'productWrapper',
    'buttonText',
    'listItem',
  ],
};

export const TNewComponentsByParentType: Record<
  string,
  Array<TAllComponentDetailValues>
> = {
  backgroundContainer: [
    'group',
    'spacer',
    'image',
    'condition',
    'hostedImage',
    'videoUrl',
  ],
  header: [
    'titleText',
    'group',
    'spacer',
    'image',
    'hostedImage',
    'icon',
    'carousel',
    'closeButton',
    'loginButton',
    'restoreButton',
    'linkButton',
    'deeplinkButton',
    'navigatePageButton',
    'playPauseButton',
    'volumeButton',
    'productGroupToggle',
    'condition',
  ],
  productContainer: [
    'productText',
    'productBadge',
    'productButton',
    'titleText',
    'bulletList',
    'customList',
    'image',
    'hostedImage',
    'icon',
    'radio',
    'productWrapper',
    'collapse',
    'divider',
    'spacer',
    'condition',
  ],
  contentContainer: [
    'titleText',
    'bodyText',
    'legalText',
    'buttonText',
    'textBlock',
    'bulletList',
    'customList',
    'image',
    'hostedImage',
    'videoUrl',
    'icon',
    'productGroupToggle',
    'productContainer',
    'productButton',
    'productBadge',
    'productText',
    'productWrapper',
    'carousel',
    'slide',
    'repeatingList',
    'loginButton',
    'restoreButton',
    'closeButton',
    'purchaseButton',
    'linkButton',
    'deeplinkButton',
    'navigatePageButton',
    'playPauseButton',
    'volumeButton',
    'group',
    'collapse',
    'spacer',
    'divider',
    'condition',
    'termsCheckbox',
  ],
  footer: [
    'titleText',
    'bodyText',
    'legalText',
    'textBlock',
    'bulletList',
    'customList',
    'image',
    'hostedImage',
    'icon',
    'productContainer',
    'productButton',
    'productBadge',
    'productText',
    'productWrapper',
    'loginButton',
    'restoreButton',
    'closeButton',
    'purchaseButton',
    'linkButton',
    'deeplinkButton',
    'navigatePageButton',
    'playPauseButton',
    'volumeButton',
    'group',
    'spacer',
    'divider',
    'condition',
    // 'termsCheckbox',
  ],
  productWrapper: [
    'productText',
    'productBadge',
    'productButton',
    'titleText',
    'bulletList',
    'customList',
    'image',
    'hostedImage',
    'icon',
    'radio',
    'productWrapper',
    'collapse',
    'divider',
    'spacer',
    'condition',
  ],
  productButton: [
    'productText',
    'productBadge',
    'buttonText',
    'icon',
    'image',
    'hostedImage',
    'radio',
    'productWrapper',
    'condition',
  ],
  button: ['buttonText', 'icon', 'image', 'hostedImage', 'group', 'condition'],
  customList: ['listItem'], //TODO - condition?
  listItem: ['bodyText', 'icon', 'image', 'hostedImage', 'condition'],
  collapse: [],
  collapseBody: [
    'titleText',
    'bodyText',
    'legalText',
    'customList',
    'bulletList',
    'icon',
    'image',
    'hostedImage',
    'loginButton',
    'restoreButton',
    'closeButton',
    'linkButton',
    'deeplinkButton',
    'navigatePageButton',
    'repeatingList',
    'group',
    'condition',
    'divider',
  ],
  collapseHeader: ['titleText', 'icon', 'image', 'hostedImage', 'group'],
  stack: [],
  repeatingList: [
    'repeatingTextSource',
    'repeatingImageSource',
    'icon',
    'image',
    'titleText',
    'bodyText',
    'textBlock',
    'hostedImage',
    'group',
    'spacer',
    'condition',
    'divider',
  ],
  playPauseButton: ['buttonText', 'icon', 'image', 'hostedImage'],
  volumeButton: ['buttonText', 'icon', 'image', 'hostedImage'],
  carousel: ['slide'],
  slide: [
    'carouselTitleText',
    'carouselBodyText',
    'carouselImage',
    'carouselBulletList',
    'carouselLegalText',
    'titleText',
    'bodyText',
    'legalText',
    'image',
    // 'videoUrl',
    'productContainer',
    'productButton',
    'productBadge',
    'productText',
    'productWrapper',
    'loginButton',
    'restoreButton',
    'closeButton',
    'purchaseButton',
    'linkButton',
    'deeplinkButton',
    'navigatePageButton',
    'playPauseButton',
    'volumeButton',
    'group',
    // 'collapse',
    'spacer',
    'divider',
    'condition',
    'termsCheckbox',
  ],
};

const TileCard = styled(Card)`
  border-radius: 5px;
  border: 1px solid #d9dddf;
  background: #fff;
  padding: 8px;
  text-align: center;
  height: 94px;
  width: 88px;
  .ant-card-body {
    padding: 8px 0px;
  }
  cursor: pointer;
  color: #596168;

  .tileCardTitle {
    font-size: small;
    line-height: 10px !important;
  }

  .tileCardIcon > svg {
    width: 17px;
    height: 17px;
  }

  &:hover {
    border: 1px solid rgba(19, 116, 222, 0.75);
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    color: ${namiPrimaryBlue};
  }
`;

const componentEntitlements: Record<any, string> = {
  carousel: 'app.paywall.capabilities.carousel',
  productGroupToggle: 'app.paywall.capabilities.product_groups',
  deeplinkButton: 'app.paywall.capabilities.deeplink',
  navigatePageButton: 'app.paywall.capabilities.multipage',
  hostedImage: 'app.paywall.capabilities.hosted_image',
  videoUrl: 'app.paywall.capabilities.video',
  playPauseButton: 'app.paywall.capabilities.video',
  volumeButton: 'app.paywall.capabilities.video',
  repeatingList: 'app.paywall.capabilities.custom_launch_object',
};

const capabilityOnlyComponents: TAllComponentDetailValues[] = [
  'productGroupToggle',
];

const controlledComponents: TAllComponentDetailValues[] = [
  'carousel',
  'purchaseButton',
  'productGroupToggle',
  'videoUrl',
  'playPauseButton',
  'volumeButton',
  'radio',
  'termsCheckbox',
];

const enterpriseOnlyComponents: TAllComponentDetailValues[] = [
  'hostedImage',
  'videoUrl',
  'repeatingList',
  'playPauseButton',
  'volumeButton',
];

const repeatingComponents: TAllComponentDetailValues[] = [
  'repeatingTextSource',
  'repeatingImageSource',
];

const dynamicCarouselComponents: TAllComponentDetailValues[] = [
  'carouselTitleText',
  'carouselBodyText',
  'carouselImage',
  'carouselBulletList',
  'carouselLegalText',
];

const dynamicProductComponents: TAllComponentDetailValues[] = [
  'productBadge',
  'productText',
];

export const allProductComponents: TAllComponentDetailValues[] = [
  'productContainer',
  'productButton',
  'productBadge',
  'productText',
  'productWrapper',
  'radio',
];

export const allNewComponentOptions: Record<
  TAllComponentDetailValues,
  {
    title: string;
    icon: JSX.Element;
    description?: string;
  }
> = {
  contentContainer: {
    title: 'Not Used',
    icon: <></>,
  },
  header: {
    title: 'Group',
    icon: <WebAssetOutlined />,
  },
  backgroundContainer: {
    title: 'Not used',
    icon: <></>,
  },
  titleText: {
    title: 'Title Text',
    icon: <TitleOutlined />,
    description: 'A short line of large text.',
  },
  bodyText: {
    title: 'Body Text',
    icon: <NotesOutlined />,
    description: 'Multiple lines of smaller text.',
  },
  textBlock: {
    title: 'Text Block',
    icon: <TextSnippetOutlined />,
    description: 'Multiple lines of smaller text with a colored background.',
  },
  legalText: {
    title: 'Legal Text',
    icon: <AccountBalanceOutlined />,
    description:
      'Multiple lines of text with support for links and Legal Smart Text.',
  },
  bulletList: {
    title: 'Bullet List',
    icon: <FormatListBulleted />,
    description: 'A list of short text bullets with a shared icon.',
  },
  bulletComponent: {
    title: 'Icon',
    icon: <MoodOutlined />,
    description: 'A graphic representing a command or value prop.',
  },
  customList: {
    title: 'Custom List',
    icon: <Icon component={CustomWidthIcon} />,
    description:
      'A list of short bullets that support unique icons and images inside the list',
  },
  listItem: {
    title: 'List Item',
    icon: <WebAssetOutlined />,
    description: 'A list item containing icons, text, and/or images',
  },
  icon: {
    title: 'Icon',
    icon: <MoodOutlined />,
    description: 'A graphic representing a command or value prop.',
  },
  loginButton: {
    title: 'Login Button',
    icon: <LoginOutlined />,
    description: 'A button to sign users into your app.',
  },
  restoreButton: {
    title: 'Restore Button',
    icon: <SettingsBackupRestoreOutlined />,
    description: 'A button to trigger the store Restore Purchases flow.',
  },
  closeButton: {
    title: 'Close Button',
    icon: <CancelOutlined />,
    description: 'A button to close the paywall.',
  },
  purchaseButton: {
    title: 'Purchase Button',
    icon: <TouchAppOutlined />,
    description: 'A button to purchase the selected product.',
  },
  group: {
    title: 'Group',
    icon: <WebAssetOutlined />,
    description: 'A container with multiple components inside.',
  },
  productText: {
    title: 'Product Field',
    icon: <NotesOutlined />,
    description:
      'A line of text that lives on the product button and can be configured per product using product fields.',
  },
  buttonText: {
    title: 'Button Text',
    icon: <NotesOutlined />,
    description: 'Short text that lives inside a button',
  },
  productWrapper: {
    title: 'Group',
    icon: <WebAssetOutlined />,
    description: 'A container with multiple components inside.',
  },
  productButton: {
    title: 'Product Button',
    icon: <LocalOfferOutlined />,
    description:
      'A button that contains product information and can be tapped or selected to purchase.',
  },
  image: {
    title: 'Image',
    icon: <ImageOutlined />,
    description: 'Upload a PNG or JPEG image file.',
  },
  hostedImage: {
    title: 'Hosted Image',
    icon: <PhotoAlbumOutlined />,
    description: 'Provide a URL to an image.',
  },
  videoUrl: {
    title: 'Video',
    icon: <VideoLibraryOutlined />,
    description: 'Provide a URL to a video.',
  },
  productBadge: {
    title: 'Badge',
    icon: <BookmarkBorderOutlined />,
    description:
      'A short piece of text with colored background that sits inside or on top of a product button.',
  },
  condition: {
    title: 'Condition',
    icon: <NotListedLocationOutlined />,
    description:
      'A way to control when components are seen by adding visibility rules.',
  },
  spacer: {
    title: 'Spacer',
    icon: <ExpandOutlined />,
    description:
      'An empty container that grows to take up space on the paywall',
  },
  carousel: {
    title: 'Carousel',
    icon: <ViewCarouselOutlined />,
    description: 'A playable gallery of images and text props.',
  },
  slide: {
    //TODO - not needed
    title: 'Carousel Slide',
    icon: <ImageOutlined />,
  },
  productContainer: {
    title: 'Repeating Product Group',
    icon: <RepeatOutlined />,
    description:
      'A container that displays all child components once for each product on the paywall.',
  },
  divider: {
    title: 'Divider',
    icon: <HorizontalRuleRounded />,
    description: 'A short container used to visually divide content.',
  },
  productGroupToggle: {
    title: 'Toggle',
    icon: <ToggleOnOutlined />,
    description: 'Toggle between 2 product groups',
  },
  productGroupToggleItem: {
    //TODO - not needed
    title: 'Toggle Item',
    icon: <ToggleOnOutlined />,
  },
  deeplinkButton: {
    title: 'Deeplink Button',
    description: 'A button to launch another screen in the app',
    icon: <LaunchOutlined />,
  },
  navigatePageButton: {
    title: 'Navigation Button',
    description: 'A button to navigate to another page of the paywall',
    icon: <SignpostOutlined />,
  },
  linkButton: {
    title: 'URL Button',
    description: 'A button to launch a web URL',
    icon: <LinkOutlined />,
  },
  footer: {
    //TODO - not used
    title: 'Footer',
    description:
      'A section anchored to the bottom of the paywall floating over the content',
    icon: <WebAssetOutlined />,
  },
  collapse: {
    title: 'Collapse',
    description: 'An expandable drawer with content inside',
    icon: <ExpandCircleDownOutlined />,
  },
  collapseHeader: {
    //TODO - not used
    title: 'Collapse Header',
    description: 'Header for the expandable drawer container',
    icon: <ExpandCircleDownOutlined />,
  },
  collapseBody: {
    //TODO - not used
    title: 'Collapse Body',
    description: 'Body for the expandable drawer container',
    icon: <ExpandCircleDownOutlined />,
  },
  stack: {
    //TODO - not used
    title: 'Stack',
    description:
      'Component used for layering a carousel underneath other buttons',
    icon: <LayersOutlined />,
  },
  repeatingList: {
    title: 'Repeating List',
    description:
      'Component that uses a data source to quickly construct customizable list',
    icon: <RepeatOutlined />,
  },
  repeatingImageSource: {
    title: 'Image',
    description:
      'A hosted image that pulls its URL from the Repeating List data source',
    icon: <PhotoAlbumOutlined />,
  },
  repeatingTextSource: {
    title: 'Text',
    description:
      'A line of text that gets its content from the Repeating List data source',
    icon: <NotesOutlined />,
  },
  carouselTitleText: {
    title: 'Slide Title Text',
    icon: <TitleOutlined />,
    description:
      'A short line of large text that gets its content from the Carousel data source.',
  },
  carouselBodyText: {
    title: 'Slide Body Text',
    icon: <NotesOutlined />,
    description:
      'Multiple lines of smaller text that gets its content from the Carousel data source.',
  },
  carouselImage: {
    title: 'Slide Image',
    icon: <ImageOutlined />,
    description: 'Upload a PNG or JPEG image file.',
  },
  carouselBulletList: {
    title: 'Slide List',
    icon: <FormatListBulleted />,
    description:
      'A list of short text bullets with a shared icon that can be customized per slide.',
  },
  carouselLegalText: {
    title: 'Slide Legal Text',
    icon: <AccountBalanceOutlined />,
    description:
      'Multiple lines of text with support for links that can be customized per slide',
  },
  playPauseButton: {
    title: 'Play Control',
    description:
      'A custom designed button that allows you to control the playback of the paywall video',
    icon: <NotStartedOutlined />,
  },
  volumeButton: {
    title: 'Volume Control',
    description:
      'A custom designed button that allows you to control the volume of the paywall video',
    icon: <VolumeOffOutlined />,
  },
  radio: {
    title: 'Radio Button',
    description: 'A radio style button indicating which product is selected',
    icon: <RadioButtonChecked />,
  },
  termsCheckbox: {
    title: 'Terms Checkbox',
    description:
      'Require the user to check a box confirming they read the terms before purchasing',
    icon: <CheckBoxOutlined />,
  },
};

const selector = createSelector(
  [({ paywallBuilder }: RootState) => paywallBuilder],
  (paywallBuilder) => ({
    displaySingleGroup:
      paywallBuilder.paywall?.template['ui.displaySingleGroup'],
    formFactor: paywallBuilder.formFactor,
    capabilities: paywallBuilder.paywall?.template['ui.capabilities'] || [],
    idsMap: paywallBuilder.idLocations,
    idTypeLocations: paywallBuilder.idTypeLocations,
    template: paywallBuilder.paywall?.template,
  })
);

export default function AddComponentPopover({
  child,
  value,
  productChild = false,
  openBuilderTreePaywall,
  overrideId,
}: AddComponentPopoverProps) {
  const actions = useActions(PaywallBuilderSlice.actions);
  const appContext = useAppContext();
  const userCanAdd = appContext.planHasEntitlement(
    'app.paywall.component.create'
  );
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [isAddFieldModalOpen, setAddFieldModalOpen] = useState(false);
  const [isSchemaSourceModalOpen, setSchemaSourceModalOpen] = useState(false);
  const [schemaSubType, setSchemaSubType] =
    useState<TAllComponentDetailValues | null>(null);
  const {
    displaySingleGroup,
    formFactor,
    capabilities,
    idsMap,
    idTypeLocations,
    template,
  } = useAppSelector(selector);

  const id = overrideId || value.id;

  const repeatingListChildren = useMemo(() => {
    return findRepeatingGridChildIds(idsMap, idTypeLocations);
  }, [idsMap, idTypeLocations]);

  const carouselChildren = useMemo(() => {
    return findCarouselChildIds(idsMap, idTypeLocations);
  }, [idsMap, idTypeLocations]);

  const parentRepeatingList = useMemo(() => {
    if (!id) return null;
    const parentLoc = findParentRepeatingGridLocation(
      id,
      idsMap,
      idTypeLocations
    );
    if (!parentLoc) return null;
    const parsedRepeatingListLocation = parseLocationString(parentLoc);
    return findComponentFromLocation(
      template?.pages || [],
      parsedRepeatingListLocation
    );
  }, [template?.pages, id, idsMap, idTypeLocations]);

  let componentOptions = TNewComponentsByParentType['contentContainer'];
  if (id && id in TNewComponentsByParentType) {
    componentOptions = TNewComponentsByParentType[id];
  } else if (
    value.namiComponentType &&
    value.namiComponentType in TNewComponentsByParentType
  ) {
    componentOptions = TNewComponentsByParentType[value.namiComponentType];
  } else if (value.component in TNewComponentsByParentType) {
    componentOptions = TNewComponentsByParentType[value.component];
  } else if (productChild) {
    componentOptions = TNewComponentsByParentType['productContainer'];
  } else if (repeatingListChildren.includes(id || '')) {
    componentOptions = TNewComponentsByParentType['repeatingList'];
  } else if (carouselChildren.includes(id || '')) {
    componentOptions = TNewComponentsByParentType['slide'];
  }

  //Remove product components if not a product child
  if (!productChild && value.namiComponentType !== 'productContainer') {
    const hideProductOptionsList = componentOptions.reduce((output, option) => {
      if (allProductComponents.includes(option)) return output;
      return [...output, option];
    }, [] as TAllComponentDetailValues[]);
    componentOptions = hideProductOptionsList;
  }

  //Remove components based on device
  const formFactorOptionsList = componentOptions.reduce((output, option) => {
    if (!TNewComponentsByFormFactor[formFactor].includes(option)) return output;
    return [...output, option];
  }, [] as TAllComponentDetailValues[]);
  componentOptions = formFactorOptionsList;

  //Remove controlled components that have already been added and enterprise only components
  const newOptionsList = componentOptions.reduce((output, option) => {
    if (controlledComponents.includes(option) && idsMap.hasOwnProperty(option))
      return output;
    if (
      (option === 'playPauseButton' || option === 'volumeButton') &&
      !idTypeLocations.hasOwnProperty('videoUrl')
    )
      return output;
    if (
      enterpriseOnlyComponents.includes(option) &&
      !appContext.userHasEntitlement(componentEntitlements[option])
    )
      return output;
    if (option === 'purchaseButton' && !displaySingleGroup) return output;
    if (option === 'radio' && !capabilities.includes('selected_product'))
      return output;
    if (
      option === 'repeatingList' &&
      !capabilities.includes('custom_launch_object')
    )
      return output;
    if (option === 'navigatePageButton' && !capabilities.includes('multipage'))
      return output;
    if (option === 'carousel' && capabilities.includes('product_groups'))
      return output;
    if (option === 'productGroupToggle' && capabilities.includes('carousel'))
      return output;
    if (
      option === 'termsCheckbox' &&
      (!capabilities.includes('product_group_carousel') ||
        !capabilities.includes('selected_product'))
    )
      return output;
    return [...output, option];
  }, [] as TAllComponentDetailValues[]);
  componentOptions = newOptionsList;

  return (
    <>
      <Tooltip
        title={`Add a nested component inside the ${getComponentTitle(value)}`}
        mouseEnterDelay={0.3}
        align={{ offset: [0, 5] }}
      >
        <Popover
          overlayStyle={{ width: '328px', maxHeight: 480 }}
          overlayInnerStyle={{ maxHeight: 480, overflowY: 'scroll' }}
          title="Add Component"
          placement="bottom"
          key={id}
          align={{ offset: [0, -5] }}
          open={popoverOpen}
          trigger={['click']}
          onOpenChange={(open) => setPopoverOpen(open)}
          content={
            <>
              <Row gutter={[10, 10]}>
                {componentOptions.map((option) => (
                  <Col key={option}>{generateCard(option)}</Col>
                ))}
              </Row>
            </>
          }
        >
          {child}
        </Popover>
      </Tooltip>
      <SelectProductFieldModal
        isOpen={isAddFieldModalOpen}
        onClose={() => setAddFieldModalOpen(false)}
        onSubmitAdd={(
          label: string,
          type: TProductVariableType,
          defaultValue: any
        ) => addProductField(label, type, defaultValue, true)}
        onSubmit={(label: string) => {
          addProductField(label, 'string', '', false);
        }}
      />
      <SelectSchemaSourceModal
        isOpen={isSchemaSourceModalOpen}
        onClose={() => setSchemaSourceModalOpen(false)}
        parentRepeatingGrid={parentRepeatingList}
        onSubmit={(dataSource: string) => {
          if (schemaSubType === 'repeatingList') {
            addRepeatingList(dataSource);
          } else {
            addRepeatingListChild(dataSource);
          }
        }}
        type={schemaSubType}
      />
    </>
  );

  function generateCard(option: TAllComponentDetailValues): React.ReactNode {
    if (userCanAdd) {
      //If option can only be added through Capabilities tab, change click behavior
      if (capabilityOnlyComponents.includes(option)) {
        if (option === 'productGroupToggle') {
          const title = !displaySingleGroup
            ? `Update product group display mode to add toggle`
            : `${allNewComponentOptions[option].description} - Turn on capability to add`;
          return (
            <Tooltip
              title={title}
              mouseEnterDelay={0.5}
              align={{ offset: [0, 5] }}
            >
              <Badge
                count={
                  <ToolFilled style={{ color: namiMediumGray, fontSize: 12 }} />
                }
                offset={[-15, 15]}
              >
                <TileCard
                  onClick={() => {
                    setPopoverOpen(false);
                    actions.setEditingMenuItem('capabilities');
                  }}
                >
                  <Space direction="vertical" size={6} align="center">
                    <span className="tileCardIcon">
                      {allNewComponentOptions[option].icon}
                    </span>
                    <span className="tileCardTitle">
                      {allNewComponentOptions[option].title}
                    </span>
                  </Space>
                </TileCard>
              </Badge>
            </Tooltip>
          );
        }
      }
      //If option is setup through dynamic data source, add a Badge
      if (repeatingComponents.includes(option)) {
        return (
          <Tooltip
            title={allNewComponentOptions[option].description}
            mouseEnterDelay={0.5}
            align={{ offset: [0, 5] }}
          >
            <Badge
              count={
                <Icon
                  component={DatabaseIcon}
                  style={{ color: namiBrightGreen, fontSize: 15 }}
                />
              }
              offset={[-15, 15]}
            >
              <TileCard
                onClick={() => {
                  if (userCanAdd) {
                    addComponent(option);
                  } else {
                    setPopoverOpen(false);
                    openBuilderTreePaywall();
                  }
                }}
              >
                <Space direction="vertical" size={6} align="center">
                  <span className="tileCardIcon">
                    {allNewComponentOptions[option].icon}
                  </span>
                  <span className="tileCardTitle">
                    {allNewComponentOptions[option].title}
                  </span>
                </Space>
              </TileCard>
            </Badge>
          </Tooltip>
        );
      } else if (dynamicProductComponents.includes(option)) {
        return (
          <Tooltip
            title={allNewComponentOptions[option].description}
            mouseEnterDelay={0.5}
            align={{ offset: [0, 5] }}
          >
            <Badge
              count={
                <TagsOutlined
                  style={{ color: namiBrightGreen, fontSize: 15 }}
                />
              }
              offset={[-15, 15]}
            >
              <TileCard
                onClick={() => {
                  if (userCanAdd) {
                    addComponent(option);
                  } else {
                    setPopoverOpen(false);
                    openBuilderTreePaywall();
                  }
                }}
              >
                <Space direction="vertical" size={6} align="center">
                  <span className="tileCardIcon">
                    {allNewComponentOptions[option].icon}
                  </span>
                  <span className="tileCardTitle">
                    {allNewComponentOptions[option].title}
                  </span>
                </Space>
              </TileCard>
            </Badge>
          </Tooltip>
        );
      } else if (dynamicCarouselComponents.includes(option)) {
        return (
          <Tooltip
            title={allNewComponentOptions[option].description}
            mouseEnterDelay={0.5}
            align={{ offset: [0, 5] }}
          >
            <Badge
              count={
                <PhotoLibraryOutlined
                  style={{
                    color: namiBrightGreen,
                    fontSize: '15px',
                  }}
                />
              }
              offset={[-15, 15]}
            >
              <TileCard
                onClick={() => {
                  if (userCanAdd) {
                    addComponent(option);
                  } else {
                    setPopoverOpen(false);
                    openBuilderTreePaywall();
                  }
                }}
              >
                <Space direction="vertical" size={6} align="center">
                  <span className="tileCardIcon">
                    {allNewComponentOptions[option].icon}
                  </span>
                  <span className="tileCardTitle">
                    {allNewComponentOptions[option].title}
                  </span>
                </Space>
              </TileCard>
            </Badge>
          </Tooltip>
        );
      }
      if (!optionUnlocked(option)) {
        return (
          <Tooltip
            title={`${allNewComponentOptions[option].description} - Click to preview this Premium Feature.`}
            mouseEnterDelay={0.5}
            align={{ offset: [0, 5] }}
          >
            <Badge
              count={
                <AttachMoneyOutlined
                  style={{ color: namiBrightBlue, fontSize: 15 }}
                />
              }
              color={namiBrightBlue}
              offset={[-15, 15]}
            >
              <TileCard
                onClick={() => {
                  if (userCanAdd) {
                    addComponent(option);
                  } else {
                    setPopoverOpen(false);
                    openBuilderTreePaywall();
                  }
                }}
              >
                <Space direction="vertical" size={6} align="center">
                  <span className="tileCardIcon">
                    {allNewComponentOptions[option].icon}
                  </span>
                  <span className="tileCardTitle">
                    {allNewComponentOptions[option].title}
                  </span>
                </Space>
              </TileCard>
            </Badge>
          </Tooltip>
        );
      }
      return (
        <Tooltip
          title={allNewComponentOptions[option].description}
          mouseEnterDelay={0.5}
          align={{ offset: [0, 5] }}
        >
          <TileCard
            onClick={() => {
              if (userCanAdd) {
                addComponent(option);
              } else {
                setPopoverOpen(false);
                openBuilderTreePaywall();
              }
            }}
          >
            <Space direction="vertical" size={6} align="center">
              <span className="tileCardIcon">
                {allNewComponentOptions[option].icon}
              </span>
              <span className="tileCardTitle">
                {allNewComponentOptions[option].title}
              </span>
            </Space>
          </TileCard>
        </Tooltip>
      );
    }
    return (
      <Badge
        count={<LockFilled style={{ color: namiBrightBlue, fontSize: 11 }} />}
        color={namiBrightBlue}
        offset={[-15, 15]}
      >
        <TileCard
          onClick={() => {
            setPopoverOpen(false);
            openBuilderTreePaywall();
          }}
        >
          <Space direction="vertical" size={6} align="center">
            <span className="tileCardIcon">
              {allNewComponentOptions[option].icon}
            </span>
            <span className="tileCardTitle">
              {allNewComponentOptions[option].title}
            </span>
          </Space>
        </TileCard>
      </Badge>
    );
  }

  function optionUnlocked(option: any): boolean {
    if (componentEntitlements.hasOwnProperty(option)) {
      return appContext.userHasEntitlement(componentEntitlements[option]);
    }
    return true;
  }

  function addComponent(type: TAllComponentDetailValues | null) {
    if (type) {
      const newId = generateNextIdForType(type, idsMap);
      if (type === 'productText') {
        setPopoverOpen(false);
        setAddFieldModalOpen(true);
      } else if (
        type === 'repeatingList' ||
        type === 'repeatingTextSource' ||
        type === 'repeatingImageSource'
      ) {
        setPopoverOpen(false);
        setSchemaSubType(type);
        setSchemaSourceModalOpen(true);
      } else if (type === 'carousel') {
        actions.addCarousel({
          parentId: id!,
          location: idsMap[id!],
        });
      } else if (type === 'purchaseButton') {
        actions.addSelectedCapability();
      } else if (type === 'deeplinkButton') {
        actions.addDeeplink({
          location: idsMap[id!],
        });
      } else if (type === 'termsCheckbox') {
        actions.addTermsCheckbox({ location: idsMap[id!] });
      } else {
        actions.addComponent({
          id: newId,
          location: idsMap[id!],
          type: type,
          productChild: productChild,
        });
      }
    } else {
      console.warn(`Attempting to add null component type`);
    }
  }

  function addProductField(
    label: string,
    type: TProductVariableType,
    defaultValue: any,
    newField: boolean
  ) {
    if (newField) {
      actions.addSkuVariable({
        name: label,
        value: defaultValue,
        type: type,
      });
    }
    setAddFieldModalOpen(false);
    actions.addProductTextComponent({
      id: generateNextIdForType('productText', idsMap),
      location: idsMap[id!],
      productField: toCamelCase(label),
    });
  }

  function addRepeatingList(dataSource: string) {
    setSchemaSourceModalOpen(false);
    actions.addRepeatingGridComponent({
      id: generateNextIdForType('repeatingList', idsMap),
      location: idsMap[id!],
      dataSource: toCamelCase(dataSource),
    });
  }

  function addRepeatingListChild(dataSource: string) {
    setSchemaSourceModalOpen(false);
    if (!schemaSubType) return;
    actions.addRepeatingGridChild({
      id: generateNextIdForType(schemaSubType, idsMap),
      location: idsMap[id!],
      type: schemaSubType,
      repeatingGridParent: parentRepeatingList,
      schemaField: dataSource,
    });
  }
}
